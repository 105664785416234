import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axiosInstance';


const DismissedBtn = ({ bannerId, userNtid, title, setAnnouncementsArray }) => {
    const { t } = useTranslation();
    const setDismissedAd = () => {
        const url = `${process.env.REACT_APP_BE_URL}/api/v1/snow/portal/announcements`;

        let payload = {
            bannerId: bannerId,
            userId: userNtid,
        };

        axiosInstance
            .put(url, payload).then((res) => {
                if (res.data.data[0].response.isDismissed) {
                    setAnnouncementsArray((prev) => (prev.filter((item) => item.BannerID !== bannerId)));
                }
            }).catch(e => console.log("Error from DismissedBtn Component:", e));
    }
    return (
        <div className="tooltip tooltip-left absolute top-0 right-0 pr-2" data-tip={`${t('ui_spear.announcement.dismiss_announcement')} ${title}`}>
            <button onClick={setDismissedAd} className='boschicon-bosch-ic-close-small text-4xl font-extrabold'></button>
        </div>
    )
}

export default DismissedBtn;
