import { useState } from "react";
import AnnouncementBanner from "./AnnouncementBanner";
import ExpandAllDivider from "./ExpandAllDivider";


const getLanguage = (lang) => {
    if (lang == 'en') lang = 'en_US';
    if (lang == 'de') lang = 'de_DE';
    if (lang == 'es') lang = 'es_ES';
    if (lang == 'fr') lang = 'fr_FR';
    if (lang == 'ja') lang = 'ja_JP';
    if (lang == 'zh') lang = 'zh_CN';
    if (lang == 'pb') lang = 'pt_BR';
    if (lang == 'ko') lang = 'ko_KR';
    if (lang == 'cs') lang = 'cs_CZ';
    if (lang == 'hu') lang = 'hu_HU';
    if (lang == 'it') lang = 'it_IT';
    if (lang == 'tr') lang = 'tr_TR';
    if (lang == 'ru') lang = 'ru_RU';
    if (lang == 'pl') lang = 'pl_PL';
    return lang;
}

function AnnouncementManager({ announcementsArray, setAnnouncementsArray, language, userNtid }) {

    const [isExpandAll, setIsExpandAll] = useState(false);
    const acronymLang = getLanguage(language);

    // Filter the announcements than already dismissed by User
    const announcementDismissed = announcementsArray.filter((item) => !item.isDismissed);

    const AnnouncementItems = announcementDismissed.map((item) => (
        <AnnouncementBanner
            key={item.BannerID}
            bannerId={item.BannerID}
            userNtid={userNtid}
            title={item['Title_' + acronymLang] ? item['Title_' + acronymLang] : item.Title_en_US}
            description={item['Description_' + acronymLang] ? item['Description_' + acronymLang] : item.Description_en_US}
            detailsLinkText={item['DetailsLinkText_' + acronymLang] ? item['DetailsLinkText_' + acronymLang] : item.DetailsLinkText_en_US}
            clickTarget={item.ClickTarget}
            detailsPage={item.DetailsPage}
            detailsUrl={item.DetailsUrl}
            dismissOptions={item.DismissOptions}
            setAnnouncementsArray={setAnnouncementsArray}
            setIsExpandAll={setIsExpandAll}
        />
    ));

    return (
        <div className='w-full max-h-[40vh] bg-blue_announcement text-white block overflow-y-auto'>
            {(AnnouncementItems.length <= 1) ? (
                // Display only one announcement. 
                <div>
                    {AnnouncementItems}
                </div>
            ) : (
                // Display the first announcement and hide the rest in a drop down section
                <>
                    {AnnouncementItems[0]}
                    <ExpandAllDivider isExpandAll={isExpandAll} setIsExpandAll={setIsExpandAll} itemsLength={AnnouncementItems.length} />
                    {isExpandAll &&
                        <>
                            {AnnouncementItems.slice(1)}
                            <ExpandAllDivider isExpandAll={isExpandAll} setIsExpandAll={setIsExpandAll} itemsLength={AnnouncementItems.length} />
                        </>
                    }
                </>
            )
            }
        </div >
    )
}

export default AnnouncementManager;
