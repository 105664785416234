export const getLocStorageSearchedSearches = () => {
  return JSON.parse(localStorage.getItem('searchedSearchesITSP'));
};

export const addLocStorageSearchedSearches = (searchedSearches) => {
  const currLocStorage = getLocStorageSearchedSearches();
  if (currLocStorage === null) {
    localStorage.setItem(
      'searchedSearchesITSP',
      JSON.stringify([searchedSearches])
    );
  } else {
    if (isLocStorageOverflow()) {
      currLocStorage.pop();
    }
    localStorage.setItem(
      'searchedSearchesITSP',
      JSON.stringify([searchedSearches, ...currLocStorage])
    );
  }
};

export const removeLocStorageSearchedSearches = (searchedID) => {
  const currLocStorage = getLocStorageSearchedSearches();
  const updateLocStorage = currLocStorage.filter(
    (searchedSearches) => searchedSearches.id !== searchedID
  );
  localStorage.setItem(
    'searchedSearchesITSP',
    JSON.stringify(updateLocStorage)
  );
};

export const getSearchedSearchById = (searchedID) => {
  const currLocStorage = getLocStorageSearchedSearches();
  return currLocStorage.filter((searched) => searched.id === searchedID)[0];
};

const isLocStorageOverflow = () => {
  const currLocStorage = getLocStorageSearchedSearches();
  return currLocStorage.length === 50;
};
