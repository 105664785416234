import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NewTicketBanner = () => {
  //Translations
  const { t } = useTranslation();
  const yourTicketsLabel = t(
    'ui_spear.myTickets.Banner.Here_you_have_your_tickets'
  );
  return (
    <section className="bg-sky-900">
      <div className="text-center py-10 bg-gradient-to-r from-sky-700 to-sky-500">
        <h2 className="text-white py-5 text-3xl">{yourTicketsLabel}</h2>
        <p className="">
          <Link to={process.env.REACT_APP_SNOW_SP_URL} className="text-sky-500">

          </Link>
        </p>
      </div>
    </section>
  );
};

export default NewTicketBanner;
