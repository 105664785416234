import { Fade } from '@mui/material';
import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { TooltipContext } from '../../context/TooltipContext';
import ButtonsSearchBar from './ButtonSearchBar';

export default function ButtonsSpeedDial({ children, showScrollButton }) {
  const [isBottom, setIsBottom] = useState(false);
  const [showSupportButton, setShowSupportButton] = useState(false);

  function checkPositionBanner() {
    const contactBanner = document.querySelector('.contact-banner');
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 0.25, 0.5, 0.75, 1]
    };

    function handleIsertion(entries, observer) {
      entries.forEach((element) => {
        if (element.isIntersecting === true) {
          setShowSupportButton(false);
          document.querySelector('.contact-banner')?.classList.remove('circle');
          document.querySelector('.contact-banner')?.classList.add('rectangle');
        } else if (element.isIntersecting === false) {
          document.querySelector('.contact-banner')?.classList.add('circle');
          document
            .querySelector('.contact-banner')
            ?.classList.remove('rectangle');
          setShowSupportButton(true);
        }
      });
    }

    const observer = new IntersectionObserver(handleIsertion, options);
    observer.observe(contactBanner);
  }

  const controlDirection = (e) => {
    const y = e.target.scrollTop;
    if (y >= 900) {
      setIsBottom(true);
    } else if (y < 900) {
      setIsBottom(false);
    }
  };

  useEffect(() => {
    const el=document.getElementById('main-container');
    el.addEventListener('scroll', controlDirection);
    checkPositionBanner();
    return () => {
      el.removeEventListener('scroll', controlDirection);
    };
  }, []);

  function handleClickTop() {
    document
      .getElementById('main-container')
      .scrollTo({ top: 0, behavior: 'smooth' });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const [tooltipCount] = useContext(TooltipContext);

  useEffect(() => {
    if (tooltipCount !== 0) {
      setIsBottom(false);
    }
  }, [tooltipCount]);

  return (
    <>
      <Fade in={showScrollButton && isBottom}>
        <div
          className={`fixed z-[6] animation-scroll-btn ${
            showSupportButton ? 'fadeIn-up' : ''
          } right-[20px]`}
          style={{
            transition:
              'width 0.5s, height 0.5s, opacity 0.5s 0.5s, bottom 0.5s'
          }}
        >
          <button
            className="boschicon-bosch-ic-up text text-[36px] text-[#005691] h-[64px] w-[64px] rounded-[50%] bg-white border-2 border-blue_border_btn hover:border-[#7FC6E7]"
            onClick={handleClickTop}
          ></button>
        </div>
      </Fade>
      <ButtonsSearchBar show={showSupportButton}>{children}</ButtonsSearchBar>
    </>
  );
}
