import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DevicesBanner = () => {
  const { t } = useTranslation();
  return (
    <section className="bg-sky-900">
      <div className="text-center py-10 bg-gradient-to-r from-sky-700 to-sky-500">
        <h2 className="text-white py-5 text-3xl font-Bosch_sans">
          {t('ui_spear.devicesOverview.header')}
        </h2>
        <Typography className="text-white font-Bosch_sans_light text-lg">
          {t('ui_spear.devicesOverview.subtitle')}
        </Typography>
      </div>
    </section>
  );
};

export default DevicesBanner;
