import { Grid, Container, CircularProgress } from '@mui/material';

const SoftwareLoader = () => {
    return (
        <Grid className="overflow-hidden">
            <Container className="relative ml-[50%] mr-[50%] mt-[5%]">
                <div className="loading-services">
                    <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </Container>
        </Grid>
    );
};

export default SoftwareLoader;