import {
  Dialog,
} from '@mui/material';
import React, { useState } from 'react';
import CancelStep1 from './CancelStep1';
import CancelStep2 from './CancelStep2';

const CancelCallback = (props) => {
  const [cancelStep, setCancelStep] = useState(0);

  const handleClose = () => {
    props.setOpenCancelCallback(false);
    props.setCallbackChange(!props.callbackChange);
    setTimeout(() => {
      setCancelStep(0);
    }, 1000);
  };

  return (
    <>
      <Dialog
        open={props.openCancelCallback}
        fullWidth={true}
        maxWidth={'sm'}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {cancelStep === 0 && (
          <CancelStep1
            existingDesiredDate={props.existingDesiredDate}
            handleClose={handleClose}
            setCancelStep={setCancelStep}
            callbackPrimaryLanguage={props.callbackPrimaryLanguage}
            callbackSecondaryLanguage={props.callbackSecondaryLanguage}
            existingPrimaryCallback={props.existingPrimaryCallback}
            existingSecondaryCallback={props.existingSecondaryCallback}
            setCallbackChange={props.setCallbackChange}
            callbackChange={props.callbackChange}
            ticketId={props.ticketId}
            serviceName={props.serviceName}
            userNTID={props.userNTID}
            userLang={props.existingDesiredLang}
          ></CancelStep1>
        )}

        {cancelStep === 1 && (
          <CancelStep2
            handleClose={handleClose}
            existingDesiredDate={props.existingDesiredDate}
          ></CancelStep2>
        )}
      </Dialog>
    </>
  );
};

//

export default CancelCallback;
