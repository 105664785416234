import React from 'react';
import { useTranslation } from 'react-i18next';

const EmptyTickets = () => {
  //Translations
  const { t } = useTranslation();
  const notTicketsFoundLabel = t('ui_spear.myTickets.NotTicketFound');
  const redefineYourSearchLabel = t(
    'ui_spear.myTickets.pleaseTryRedefineYourSearch'
  );

  return (
    <div className="border border-black py-10 mt-20 flex items-center gap-5 justify-center">
      <span
        className={`boschicon-bosch-ic-emoji-sad text-gray-300 px-1 text-[90px] }`}
      ></span>
      <p className="flex flex-col">
        <span className="font-medium text-2xl py-3">
          {notTicketsFoundLabel}
        </span>
        <span className="text-lg py-3">{redefineYourSearchLabel}</span>
      </p>
    </div>
  );
};

export default EmptyTickets;
