import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Rating,
  Typography,
  styled
} from '@mui/material';
import { ReactComponent as EmptyStar } from '../../static/empty_star.svg';
import { ReactComponent as FilledStar } from '../../static/filled_star.svg';
import { useTranslation } from 'react-i18next';

const GeneralFeedbackModal = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [feedbackLink, setFeebackLink] = useState('');
  const [loader, setLoader] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.generalFeedbackLoad !== false) { createFeedbackLink(); }
    props.setGeneralFeedbackLoad(false);
  }, [props.generalFeedbackLoad]);

  useEffect(() => {
    if (feedbackLink !== '') setLoader(false);
  }, [feedbackLink]);

  const LoadingAnimation = () => {
    return (
      <div className="animate-pulse">
        <div className="w-[70%] h-[30px] bg-gray-300 m-2"></div>
        <div className="w-[30%] h-[30px] bg-gray-300 m-2"></div>
        <div className="w-[60%] h-[50px] bg-gray-300 m-2"></div>
        <div className="w-[80%] h-[20px] bg-gray-300 m-2"></div>
        <div className="w-[20%] h-[20px] bg-gray-300 m-2"></div>
        <div className="w-[90%] h-[30px] bg-gray-300 m-2"></div>
        <div className="w-[90%] h-[90px] bg-gray-300 m-2"></div>
        <div className="w-[80%] h-[20px] bg-gray-300 m-2"></div>
        <div className="w-[75%] h-[20px] bg-gray-300 m-2"></div>
        <div className="w-[20%] h-[20px] bg-gray-300 m-2"></div>
        <div className="w-[60%] h-[30px] bg-gray-300 m-2"></div>
        <div className="w-[90%] h-[40px] bg-gray-300 m-2"></div>
        <div className="w-[30%] h-[45px] bg-gray-300 m-2"></div>
      </div>
    );
  };

  const createFeedbackLink = () => {
    // Click Identifier Data
    let feedbackURL = '';
    let feedbackStruct = {
      offeringID: 'FB-0000257',
      offeringName: 'IT Service Portal',
      source: 'ITSP',
      sourceCategory: 'Use',
      stage: 'Dev',
      surveyStyle: 'Flyout',
      pageID: 'Support page - Search result',
      userCountryID: props.userInfo.userCountry ? props.userInfo.userCountry : '',
      userGB: props.userInfo.userBusinessUnit ? props.userInfo.userBusinessUnit : ''
    }

    if (process.env.NODE_ENV === 'production') {
      feedbackStruct.stage = 'Prod';
    } else {
      feedbackStruct.stage = 'Dev';
    }

    feedbackURL = 'https://boschdigital.qualtrics.com/jfe/form/SV_et9eWrQTWuzZym2?' +
      'OfferingID=' + feedbackStruct.offeringID +
      '&Offering=' + encodeURIComponent(feedbackStruct.offeringName) +
      (feedbackStruct.source ? '&Source=' + encodeURIComponent(feedbackStruct.source) : '') +
      (feedbackStruct.sourceCategory ? '&SourceCategory=' + encodeURIComponent(feedbackStruct.sourceCategory) : '') +
      (feedbackStruct.stage ? '&Stage=' + encodeURIComponent(feedbackStruct.stage) : '') +
      (feedbackStruct.surveyStyle ? '&SurveyStyle=' + encodeURIComponent(feedbackStruct.surveyStyle) : '') +
      (feedbackStruct.pageID ? '&PageID=' + encodeURIComponent(feedbackStruct.pageID) : '') +
      (feedbackStruct.userCountryID ? '&UserCountryID=' + encodeURIComponent(feedbackStruct.userCountryID) : '') +
      (feedbackStruct.userGB ? '&UserGB=' + encodeURIComponent(feedbackStruct.userGB) : '')

    setFeebackLink(feedbackURL);
  };

  return (
    <>
      <Button
        className="h-11 rounded-none bg-feedback_btn hover:bg-feedback_btn_hover text-[12px] mr-1.5 "
        onClick={handleClickOpen}
      >
        <Typography className="text-white normal-case text-[12px] flex content-center">
          <span className="boschicon-bosch-ic-message text-[16px] mx-1"></span>
          {t('ui_spear.search.Button.Share_feedback')}
        </Typography>
      </Button>

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="flex items-center px-[50px] pt-10">
          <IconButton
            className="boschicon-bosch-ic-close text-2xl absolute top-2 right-2 color-black"
            aria-label="close"
            onClick={handleClose}
          ></IconButton>
        </DialogTitle>
        <DialogContent>
          {loader ? (
            <LoadingAnimation />
          ) : (
            <iframe
              src={feedbackLink}
              frameBorder="0"
              id="pse-feedback-iframe"
              width={'100%'}
              height={680}
              title={'unique'}
            ></iframe>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GeneralFeedbackModal;
