import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RedefineSearch from '../RedefineSearch/RedefineSearch';
import Article from './Article';
import ResultsOptions from './ResultsOptions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const sendAnalyticsData = String(process.env.REACT_APP_SEND_ANALYTICS_DATA);

const SearchResults = (props) => {
  const { t } = useTranslation();
  const [articlePopUp, setArticlePopUp] = useState(null);
  const [imagePopUp, setImagePopUp] = useState(null);
  const [openImage, setOpenImage] = useState(false);
  const [popUpActive, setPopUpActive] = useState(false);
  const [popUpArticleData, setPopUpArticleData] = useState({});
  const [targetUserAlias, setTargetUserAlias] = useState('');

  useEffect(() => {
    props.targetUserAlias
      ? setTargetUserAlias(props.targetUserAlias)
      : setTargetUserAlias('');
  }, [props.targetUserAlias]);

  const handleImageClose = () => {
    setOpenImage(false);
    setImagePopUp(null);
  };

  const analyticOnArticleCollapse =
    (resultItem, index) => (event, accordionExpanded) => {
      if (sendAnalyticsData !== 'true') return;
      const openEventType = 'open';
      const closeEventType = 'close';
      const allResultNames = props.searchResponse.map((result) => result.title);
      const eventType = accordionExpanded ? openEventType : closeEventType;
      const role = props.userEventRole;
      const alias = props.userAlias;
      const eventData = {
        position: index,
        allResultsNames: allResultNames,
        result: {
          type: resultItem.title,
          id: resultItem.id,
          distance: resultItem.distance
        }
      };
    };

  const analyticOnArticlePopUp = (resultItem, articleData, listIndex) => {
    setPopUpArticleData({ resultItem, articleData, listIndex });
    const role = props.userEventRole;
    const alias = props.userAlias;
    let eventData = {};
    let eventType = null;
    if (resultItem.boxes[0]) {
      eventType = 'open-subpage';
      eventData = {
        subpageType: resultItem.boxes[0].boxType,
        subpageTitle: resultItem.title,
        subpagePosition: articleData.property.subpages.findIndex(
          (article) => article.linkText === resultItem.title
        ),
        pageId: articleData.id,
        pageTitle: articleData.title,
        subpages: articleData.property.subpages
      };
    } else {
      eventType = 'open-howto';
      eventData = {
        position: listIndex,
        title: resultItem.title,
        positionHowto: articleData.property.subpages.findIndex(
          (article) => article.linkText === resultItem.title
        ),
        result: {
          type: resultItem.title,
          id: resultItem.id,
          distance: null
        }
      };
    }
  };

  useEffect(() => {
    if (articlePopUp == null) {
      setPopUpActive(false);
      overFlowSwitch(false);
    } else {
      setPopUpActive(true);
      overFlowSwitch(true);
    }
  }, [articlePopUp]);

  let overFlowSwitch = (state) => {
    var searchBox = document.getElementById('dash-search-box-id');
    var appBody = document.body;
    if (state) {
      //searchBox.style.overflow = 'hidden';
      //appBody.style.overflow = 'hidden';
    } else {
      //searchBox.style.removeProperty('overflow');
      //appBody.style.removeProperty('overflow');
    }
  };

  return (
    <>
      <div className="w-full">
        {
          // eslint-disable-next-line react/no-unknown-property
          <div className="w-full results-bar" xs={10}>
            {/* RESULTS COUNT LABEL */}
            <ResultsOptions {...props}></ResultsOptions>
            {/* ARTICLES MAP FUNCTION */}
            {props.searchResponse.length > 0 &&
              props.searchResponse.map((resultItem, index) => {
                return (
                  <Article
                    togglePopUp={props.togglePopUp}
                    atcTabError={props.atcTabError}
                    setATCTabError={props.setATCTabError}
                    setPendingTabError={props.setPendingTabError}
                    pendingTabError={props.pendingTabError}
                    autoTicket={props.autoTicket}
                    pendingTicket={props.pendingTicket}
                    toggleFeedbackModal={props.toggleFeedbackModal}
                    alternativeSearch={props.alternativeSearch}
                    userEventRole={props.userEventRole}
                    userAlias={props.userAlias}
                    targetUserAlias={targetUserAlias}
                    articleData={resultItem}
                    onArticleCollapse={analyticOnArticleCollapse}
                    onArticlePopUp={analyticOnArticlePopUp}
                    listIndex={index}
                    searchedQuery={props.searchedQuery}
                    analyticId={props.analyticId}
                    solutionData={props.solutionArticleData}
                    popUpActive={popUpActive}
                    setArticlePopUp={setArticlePopUp}
                    setImagePopUp={setImagePopUp}
                    hasAccordion={true}
                    itsd2Role={props.itsd2Role}
                    key={resultItem.id}
                    showArticleFeedback={props.showArticleFeedback}
                    toggleToastState={props.toggleToastState}
                    openArticleAccordion={props.openArticleAccordion}
                    userInfo={props.userInfo}
                    indexClasses={`article-${index}`}
                  />
                );
              })}
            {/* REDEFINE SEARCH */}
            <RedefineSearch
              generalFeedbackLoad={props.generalFeedbackLoad}
              setGeneralFeedbackLoad={props.setGeneralFeedbackLoad}
              userAlias={props.userAlias}
              targetUserAlias={props.targetUserAlias}
              searchedQuery={props.searchedQuery}
              userEventRole={props.userEventRole}
              analyticId={props.analyticId}
              scrollTop={props.scrollTop}
              userInfo={props.userInfo}
              highlightSearchBar={props.highlightSearchBar}
            />
          </div>
        }
      </div>
      {/* ARTICLE POPUP WHEN A KMS LINK IS CLICKED */}
      {articlePopUp !== null && (
        <>
          <div>
            <Article
              itsd2Role={props.itsd2Role}
              toggleFeedbackModal={props.toggleFeedbackModal}
              alternativeSearch={props.alternativeSearch}
              userAlias={props.userAlias}
              targetUserAlias={targetUserAlias}
              userEventRole={props.userEventRole}
              articleData={articlePopUp}
              onArticleCollapse={analyticOnArticleCollapse}
              onArticlePopUp={analyticOnArticlePopUp}
              listIndex={0}
              searchedQuery={props.searchedQuery}
              analyticId={props.analyticId}
              popUpActive={popUpActive}
              toggleToastState={props.toggleToastState}
              setArticlePopUp={setArticlePopUp}
              setImagePopUp={setImagePopUp}
              hasAccordion={false}
              showArticleFeedback={props.showArticleFeedback}
              userInfo={props.userInfo}
            />
          </div>
        </>
      )}
      {/* popup an image is clicked */}
      {imagePopUp !== null && (
        <>
          <Dialog
            open={true}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={'xl'}
            keepMounted
            onClose={handleImageClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle className="flex justify-start items-center ">
              <button
                onClick={handleImageClose}
                className="bg-inherit text-[#005691] flex items-center m-[5px]"
              >
                <span className="text-[25px] pr-2 font-Bosch_sans">{'<'}</span>
                <p className="text-[14px] font-Bosch_sans">
                  {t('ui_spear.support.search_back_to_article')}
                </p>
              </button>
            </DialogTitle>
            <DialogContent>
              <div className="h-full w-full flex items-center justify-center">
                <img
                  src={imagePopUp.src}
                  style={{ height: '80%', width: '75%', maxWidth: '1500px' }}
                  alt=""
                />
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default SearchResults;
