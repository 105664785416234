import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import {
  TableRow,
  Stack,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Pagination,
  Button,
  Grid
} from '@mui/material';
import SoftwareTableHeader from './SoftwareTableHeader';
import { getImageIconForSoftware } from '../../../utils/softwareFunctions';

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'indexData'
})(({ indexData }) => ({
  '&.MuiTableCell-root': {
    fontFamily: 'BoschSans-Light',
    fontSize: '1rem',
    color: '#000',
    padding: '0.5rem',
    width: '20%',
    borderBottom: '1px solid #BFC0C2'
  }
}));

const StyledTableCellForImgContainer = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'indexData'
})(({ indexData }) => ({
  '&.MuiTableCell-root': {
    fontFamily: 'BoschSans-Light',
    fontSize: '1rem',
    color: '#000',
    padding: '0.5rem',
    width: '40%',
    maxWidth: '40%',
    borderBottom: '1px solid #BFC0C2'
  }
}));

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'indexData'
})(({ indexData }) => ({
  '&.MuiPaper-root': {
    width: '100%',
    overflow: 'hidden',
    boxShadow: 'none',
    borderRadius: '0px'
  }
}));

const SoftwareTable = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <TableContainer component={StyledPaper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <SoftwareTableHeader
            order={props?.order}
            orderBy={props?.orderBy}
            onRequestSort={props?.handleRequestSort}
            originSoftwareFlag={props.originSoftwareFlag}
          />
          <TableBody>
            {props?.visibleRows
              // We slice 19, because that's how may rows we need to show on each view table
              .slice(props?.page * 19 - 19, props?.page * 19)
              .map((row, index) => (
                <TableRow key={row.RBCMNR}>
                  <StyledTableCellForImgContainer
                    className="break-words"
                    component="th"
                    scope="row"
                  >
                    <Grid className="inline-flex">
                      <img
                        src={getImageIconForSoftware(row?.RBCMNR)}
                        alt={row?.SWNAME}
                        style={{ height: '36px', width: '36px' }}
                      />
                      <p className="ml-4 mt-2">{row?.SWNAME}</p>
                    </Grid>
                  </StyledTableCellForImgContainer>
                  <StyledTableCell align="left">
                    {row?.SWCATEGORY}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {props.handleSoftwareCost(row?.price)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.INSTALLATION_STATUS}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Link to={`/software/details/${row?.SWP_ID}`}>
                      <Button
                        className="border-2 border-change_cust_btn rounded-none text-[#005691] text-[1rem] px-10 h-10 hover:text-hover_blue_btns hover:border-hover_blue_btns capitalize"
                        variant="outlined"
                      >
                        {t('ui_spear.deviceDetails.software_details')}
                      </Button>
                    </Link>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack>
        <Pagination
          sx={{
            paddingLeft: '45%',
            paddingTop: '1.2rem',
            fontSize: '1.2rem',
            '& .MuiPaginationItem-root.Mui-selected': {
              background: 'rgb(0 86 145)',
              color: 'rgb(255, 255, 255)'
            },
            '& .MuiPaginationItem-root': {
              color: '#005580',
              fontWeight: 'bold',
              fontSize: '1.1rem'
            },
            '& .MuiPaginationItem-root:hover': {
              background: '#4eb5ea'
            },
            '& .MuiPaginationItem-icon': {
              fontSize: '2rem',
              fill: 'rgba(0, 0, 0, 0.6)'
            }
          }}
          count={props?.paginationNumber}
          page={props?.page}
          onChange={props?.handleChangePage}
          color="primary"
        />
      </Stack>
    </>
  );
};

export default SoftwareTable;
