export const setCPSCtemForTicketJourney = (ticket) => {
  const itemsFormatted = {
    activity_id: ticket.requisitionid,
    status: ticket.status,
    serviceName: ticket.serviceName,
    serviceDescription: ticket.serviceDescription ? ticket.serviceDescription : '',
    performer: ticket.performerFirst + ' ' + ticket.performerLast,
    submittedDate: ticket.submittedDate.replaceAll('-', '/').substring(0, 10),
    closedDate: ticket.closedDate,
    taskDate: ticket.TaskDate,
    lastDateTime: ticket.submittedDate.replaceAll('-', '/').substring(0, 10),
    cost: ticket.Cost,
    type: ticket.serviceName === 'IT Support Request' ? 'failure' : 'request',
    priority: '',
    onBehalf: ticket.OnBehalf,
    requested_for: '',
    requisitionDueDate: ticket.RequisitionDueDate.replaceAll(
      '-',
      '/'
    ).substring(0, 10),
    sys_id: '',
    reqEntryId: ticket.ReqEntryId,
    serviceid: ticket.serviceid,
    rejection_reason: '',
    performerLogin: ticket.performerLogin,
    performerDept: ticket.performerDept
  };
  return itemsFormatted;
};
