import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DevicesTabs = (props) => {
  const { t } = useTranslation();
  const devicesLinks = [
    { title: t('ui_spear.header.My_Devices'), path: `/devices` },
    { title: t('ui_spear.deviceDetails.software_tab'), path: `/software` }
  ];

  return (
    <nav>
      <ul className="px-10 pt-4 flex flex-row justify-center font-light text-base">
        <Link
          className={` list-none pb-3.5 px-7 hover:text-ITSP_blue hover:no-underline ${
            props.devices
              ? 'cursor-pointer border-b-2 border-ITSP_blue text-ITSP_blue'
              : 'cursor-pointer border-b-2 border-transparent'
          }`}
          to={devicesLinks[0].path}
        >
          {devicesLinks[0].title}
        </Link>
        <Link
          className={`list-none pb-3.5 px-7 hover:text-ITSP_blue hover:no-underline ${
            !props.devices
              ? 'cursor-pointer border-b-2 border-ITSP_blue text-ITSP_blue'
              : 'cursor-pointer border-b-2 border-transparent'
          }`}
          to={devicesLinks[1].path}
        >
          {devicesLinks[1].title}
        </Link>
      </ul>
      <hr />
    </nav>
  );
};

export default DevicesTabs;
