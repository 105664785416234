export const getSNItemsFormatted = (list) => {
  const itemsFormatted = list.map((ticket) => {
    return {
      ticketId: ticket.items[0].request_item_id,
      name: ticket.items[0].cat_item_name,
      status: ticket.items[0].status,
      lastUpdate: ticket.items[0].last_updated,
      openedAt: ticket.items[0].opened_at,
      submittedDate: ticket.items[0].opened_at,
      closedAt: ticket.items[0].closed_at,
      type: ticket.items[0].type,
      priority: ticket.items[0].priority,
      person: ticket.requested_for,
      source: 'sn'
    };
  });
  return itemsFormatted;
};
