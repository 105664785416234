import React, { useState, useEffect, useRef } from 'react';
import {
  Autocomplete,
  CircularProgress,
  Grid,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useTranslation } from 'react-i18next';
import SaveSearch from './SaveSearch';
import backButton from '../../static/ITSP1_back_button.svg';
import TooltipSearchBar from '../TooltipSearchBar/TooltipSearchBar';
import { useContext } from 'react';
import { TooltipContext } from '../../context/TooltipContext';
import axiosInstance from '../../utils/axiosInstance';

const Searchbar = (props) => {
  let inputRef;

  const containerGridPaper = useRef(null);
  const { t } = useTranslation();
  let [rightIcon, setRightIcon] = useState('searchIcon');
  let [leftIcon, setLeftIcon] = useState(false);
  const [options, setOptions] = useState([]);
  let [contentLevels, setContentLevels] = useState([]);
  let [suggestedOption, setSuggestedOption] = useState([]);
  let [popperWidth, setPopperWidth] = useState(null);
  let [showSaveSearch, setShowSaveSearch] = useState(false);
  let [mouseInListBox, setMouseInListBox] = useState(false);
  let [targetUserAlias, setTargetUserAlias] = useState('');
  let [alias, setAlias] = useState('');
  let [displayAutocomplete, setDisplayAutocomplete] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [handleDeleteSavedSearched, setHandleDeleteSavedSearched] =
    useState(false);

  const [openControlled, setOpenControlled] = useState(false);

  const [abortControllers, setAbortControllers] = useState([]);

  const resetSearchBar = () => {
    props.clearSearchBar();
    setRightIcon('');
    redefineDropDownAS();
  };

  const redefineDropDownAS = () => {
    inputRef.focus();
  };

  const backHomePage = () => {
    setRightIcon('searchIcon');
    setLeftIcon(false);
    props.handleBackHomePage();
  };

  const deleteSavedSearch = (id, type, query_description = '') => {
    props.handleDeleteSearch(id, type);
    setOptions(
      options.filter((item) => {
        return item.id !== id;
      })
    );
    if (type === 'saved' && query_description === props.searchTerm) {
      setHandleDeleteSavedSearched(true);
    }
  };

  const focusSearchBar = () => {
    if (props.searchTerm === '') {
      setRightIcon('');
    }
    setLeftIcon(true);
    handleAutocompleteSubmit();
  };

  const blurSearchBar = () => {
    if (props.searchTerm === '') {
      setRightIcon('searchIcon');
      setLeftIcon(false);
    }
  };

  const onChange = (event, value) => {
    if (event.type === 'blur') return;
    // handle event when users was clicked the option in the autocomplete
    const inputValue = value?.query_description;
    if (!event.target.className.includes('delete-save-icon')) {
      const value = inputValue ?? props.searchTerm;
      props.handleChangeAutoComplete(event, value);
    }
  };

  function clearInput() {
    return (
      <div
        // eslint-disable-next-line react/no-unknown-property
        classes={{ root: 'clear-search-button' }}
        // eslint-disable-next-line react/no-unknown-property
        disableRipple={true}
        onClick={resetSearchBar}
      >
        <div
          className="bosch-icon"
          style={{ fontSize: '20px', cursor: 'pointer' }}
        >
          &#xe0f0;
        </div>
      </div>
    );
  }

  const setupRightIcon = () => {
    var tempRightIcon = null;
    switch (rightIcon) {
      case '':
        tempRightIcon = '';
        break;
      case 'searchIcon':
        tempRightIcon = (
          <div className="bosch-icon search-icon" style={{ fontSize: '20px' }}>
            &#xe235;
          </div>
        );
        break;
      default:
        tempRightIcon = '';
        break;
    }
    return tempRightIcon;
  };

  const onInputChange = async (event, value) => {
    if (event.type === 'blur') return;
    if (!event.target.className.includes('delete-save-icon')) {
      const inputValue = value === 'undefined' ? event.target.value : value;
      props.handleInputChange(inputValue);
      handleAutocompleteSubmit();
    }
    if (props.searchTerm !== '') {
      setRightIcon('clearIcon');
    }
  };

  const triggerSubmitSearch = () => {
    props.handleSearchSubmit();
  };

  useEffect(
    function () {
      if (props.searchTerm.length === 0 && abortControllers.length !== 0) {
        abortControllers.forEach(function (abortController) {
          abortController.abort();
        });
        setAbortControllers([]);
        setOptions(props.userSearchedQueries);
        setIsLoading(false);
      }
      if (props.savedSarch) {
        setRightIcon('');
      }
    },
    [props.searchTerm]
  );

  useEffect(
    function () {
      if (props.scrollYPage >= 90) {
        setDisplayAutocomplete(false);
      } else {
        setDisplayAutocomplete(true);
      }
    },
    [props.scrollYPage]
  );

  function removeDuplicateObjects(objects) {
    const result = [];
    const queryDescription = new Set();

    for (const obj of objects) {
      if (queryDescription.has(obj.query_description)) {
        const index = result.findIndex(
          (item) =>
            item.query_description === obj.query_description &&
            item.source === 'searched' &&
            obj.source !== 'auto'
        );

        if (index !== -1) {
          result.splice(index, 1, obj);
        }
      } else {
        queryDescription.add(obj.query_description);
        result.push(obj);
      }
    }
    return result;
  }

  const handleAutocompleteSubmit = async (nextSaved, nextSearched) => {
    const submitURL = `${process.env.REACT_APP_BE_URL}/api/v1/articles/suggestions`;
    if (
      props.searchTerm.length === 0 &&
      props.userSearchedQueries &&
      props.userSearchedQueries !== null
    ) {
      setOptions(props.userSearchedQueries);
    }
    try {
      if (
        props.searchTerm &&
        props.searchTerm !== null &&
        typeof props.searchTerm !== 'undefined'
      ) {
        const controller = new AbortController();
        setAbortControllers((prev) => [...prev, controller]);
        setIsLoading(true);
        const response = await axiosInstance.post(
          submitURL,
          { searchString: props.searchTerm, contentLevels: [0], agent: false },
          {
            signal: controller.signal
          }
        );
        let arrayOptions = [];
        if (props.userSavedQueries !== null) {
          arrayOptions = [...props.userSavedQueries, ...arrayOptions];
        }
        if (props.userSearchedQueries !== null) {
          arrayOptions = [...props.userSearchedQueries, ...arrayOptions];
        }
        arrayOptions = [...arrayOptions, ...response.data];
        setOptions(() => [...arrayOptions]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error.response &&
        error.response.status &&
        error.response.status === 404
      ) {
        /*props.history.push({
              pathname: '/not-found',
              state: this.state
            })*/
      } else if (
        error.response &&
        error.response.status &&
        error.response.status === 500 &&
        error.response.data ===
          'Error while calling the Backbone Autocomplete API'
      ) {
        //this.props.handleErrorChange('BackboneDown');
      } else {
        /*this.props.history.push({
              pathname: '/error',
              state: this.state
            })*/
      }
    }
  };

  function popperAutocomplete(propsPopper, popperWidth) {
    const width = popperWidth ? popperWidth + 'px' : '50%';
    if (displayAutocomplete) {
      return (
        <Popper
          {...propsPopper}
          id="autocomplet-popper"
          style={{
            width: width,
            maxWidth: '50%',
            flexBasis: '50%',
            flexGrow: '0'
          }}
          placement="bottom-end"
          className={propsPopper.className + ' [&>div]:rounded-none'}
        />
      );
    }
    return <div></div>;
  }

  function customSort(arr, searchString) {
    const copyArr = [...arr];
    copyArr.sort(function (a, b) {
      const sourceA = a.source || '';
      const sourceB = b.source || '';

      if (sourceA === 'saved' && sourceB !== 'saved') {
        return -1;
      } else if (sourceA !== 'saved' && sourceB === 'saved') {
        return 1;
      } else if (
        sourceA === 'searched' &&
        sourceB !== 'saved' &&
        sourceB !== 'searched'
      ) {
        return -1;
      } else if (
        sourceA !== 'saved' &&
        sourceA !== 'searched' &&
        sourceB === 'searched'
      ) {
        return 1;
      }

      return 0;
    });

    return copyArr;
  }

  async function handleNextTipFirst() {
    await props.handleSearchSubmitTutorial();
    setTimeout(() => {
      setOpenControlled(true);
      const autocomplete = document.querySelector('#autocomplete-search');
      autocomplete.dispatchEvent(
        new Event('click', {
          bubbles: true
        })
      );
      setTimeout(() => {
        setOpenControlled(false);
      }, 1700);
    }, 1800);
  }

  const [tooltipCount, setTooltipCount] = useContext(TooltipContext);

  useEffect(() => {
    if (tooltipCount === 5) {
      backHomePage();
      window.scrollTo({
        top: 0
      });
    }
  }, [tooltipCount]);

  return (
    <Grid className="relative" id="itsp1-blue-banner">
      <div className="absolute top-0 left-0 bg-gradient-to-r from-support_banner_color_1 to-support_banner_color_2 w-full h-full -z-10"></div>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          spacing={0}
          align="center"
          wrap="nowrap"
          justify="center"
          direction="column"
          className="w-[80vw]"
        >
          <Grid item>
            <Grid
              container
              spacing={0}
              align="center"
              wrap="nowrap"
              justify="center"
              className="pt-[50px]"
            >
              {!props.showHomeSearches ? (
                <div className="flex items-center">
                  <Grid className="min-w-[90px] max-w-[90px] ml-[100px]">
                    <div
                      onClick={(e) => backHomePage()}
                      className="back-button text-white hover:cursor-pointer w-9"
                    >
                      <img src={backButton} alt="" />
                    </div>
                  </Grid>
                  <Grid item xs={10} align="left" className="">
                    <span className="font-Bosch_sans_light text-white text-[35px] leading-none">
                      {props.searchPrompt}
                    </span>
                  </Grid>
                </div>
              ) : (
                <>
                  <Grid item xs={12} align="center" className="">
                    <p className="font-Bosch_sans_light text-white text-[35px] leading-none">
                      {t('ui_spear.support.Banner.How_may_we_help_you')}
                    </p>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid
              spacing={0}
              align="center"
              wrap="nowrap"
              justify="center"
              className="py-[50px]"
            >
              <Grid item align="center">
                <TooltipSearchBar
                  open={tooltipCount === 2}
                  index={2}
                  handleNextTipButton={() => {}}
                  closeTooltip={backHomePage}
                >
                  <Grid
                    container
                    spacing={0}
                    wrap="nowrap"
                    alignItems="center"
                    justify="center"
                  >
                    {props.showSaveSearch ? (
                      <Grid item xs={1} />
                    ) : (
                      <Grid item xs={2.5} />
                    )}
                    <TooltipSearchBar
                      open={tooltipCount === 1}
                      index={1}
                      handleNextTipButton={handleNextTipFirst}
                      closeTooltip={backHomePage}
                    >
                      <Grid item xs={7} align="right" ref={containerGridPaper}>
                        <Paper
                          component="form"
                          style={{ width: '100%' }}
                          className="flex h-14"
                          square={true}
                          onSubmit={(e) => {
                            e.preventDefault();
                            triggerSubmitSearch(e);
                          }}
                          onFocus={focusSearchBar}
                          onBlur={blurSearchBar}
                        >
                          <CSSTransition
                            in={leftIcon}
                            timeout={300}
                            classNames="search-slide"
                            mountOnEnter
                            unmountOnExit
                          >
                            <div
                              className="bosch-icon search-icon"
                              style={{ fontSize: '20px' }}
                            >
                              &#xe235;
                            </div>
                          </CSSTransition>
                          <Autocomplete
                            open={openControlled}
                            onOpen={function () {
                              setOpenControlled(true);
                            }}
                            onClose={function () {
                              setOpenControlled(false);
                            }}
                            options={options}
                            id="autocomplete-search"
                            autoSelect
                            freeSolo
                            disableCloseOnSelect
                            style={{ width: '100%', display: 'flex' }}
                            openOnFocus={true}
                            autoHighlight={false}
                            selectOnFocus={false}
                            clearOnEscape={true}
                            getOptionLabel={(option) =>
                              option.query_description + ''
                            }
                            inputValue={props.searchTerm}
                            fullWidth={true}
                            disableClearable
                            PopperComponent={(propsPopper) =>
                              popperAutocomplete(
                                propsPopper,
                                containerGridPaper.current
                                  ? containerGridPaper.current.offsetWidth
                                  : null
                              )
                            }
                            filterOptions={(options, { inputValue }) => {
                              let content = [];
                              const keys = new Set();
                              if (options[0] != null) {
                                let value = '';
                                if (inputValue === undefined) {
                                  value = props.searchTerm.toLowerCase();
                                } else {
                                  value =
                                    inputValue?.toLowerCase() === ''
                                      ? props.searchTerm.toLowerCase()
                                      : inputValue?.toLowerCase() ?? '';
                                }
                                content = options.filter(function (entry) {
                                  return (
                                    entry.source === 'auto' ||
                                    entry.query_description
                                      .toLowerCase()
                                      .includes(value)
                                  );
                                });

                                const arrayWithoutDuplicates = content.filter(
                                  (item) => {
                                    const objString = JSON.stringify(item);
                                    const newObject = !keys.has(objString);
                                    if (newObject) {
                                      keys.add(objString);
                                    }
                                    return newObject;
                                  }
                                );
                                const removerItems = removeDuplicateObjects(
                                  arrayWithoutDuplicates
                                );
                                return customSort(removerItems);
                              }
                              return [];
                            }}
                            renderInput={(params, value) => {
                              return (
                                <TextField
                                  {...params}
                                  placeholder={t(
                                    'ui_spear.support.Search-Bar.State_a_problem'
                                  )}
                                  variant="standard"
                                  className=""
                                  value={value}
                                  onFocus={(event) => {
                                    event.target.placeholder = '';
                                  }}
                                  onBlur={(event) => {
                                    event.target.placeholder = t(
                                      'ui_spear.support.Search-Bar.State_a_problem'
                                    );
                                  }}
                                  inputRef={(input) => {
                                    inputRef = input;
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    endAdornment: (
                                      <React.Fragment>
                                        {isLoading ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                            className="m-2"
                                          />
                                        ) : null}
                                        {props.searchTerm !== '' &&
                                          clearInput()}
                                        {params.InputProps.endAdornment}
                                        <Grid>{setupRightIcon()}</Grid>
                                      </React.Fragment>
                                    )
                                  }}
                                ></TextField>
                              );
                            }}
                            renderOption={(props, option, { inputValue }) => {
                              var title = option.query_description;
                              var icon = String.fromCharCode('0xe235'); // Magnifying Glass
                              let trashIcon = String.fromCharCode('0xe118');
                              const matches = match(title, inputValue);
                              const parts = parse(title, matches);
                              let trash = (
                                <Grid item align="right">
                                  <div
                                    className="ac-bosch-icon delete-save-icon grid place-content-center h-full w-full text-xl py-1"
                                    onClick={(e) =>
                                      deleteSavedSearch(
                                        option.id,
                                        option.source,
                                        title
                                      )
                                    }
                                  >
                                    {trashIcon}
                                  </div>
                                </Grid>
                              );

                              if (option.source === 'searched')
                                icon = String.fromCharCode('0xe0ef'); //Clock
                              else if (option.source === 'saved')
                                icon = String.fromCharCode('0xe2C5'); //Star
                              else if (option.source === 'auto') {
                                icon = String.fromCharCode('0xe235'); //Mag Glass
                                trash = '';
                              }

                              // Function to set title text when onBlur with regular fontWeight and not bold
                              const getClassNameArticle = () => {
                                if (
                                  inputValue === '' ||
                                  inputValue.length === 0
                                ) {
                                  return 'searched-article-title';
                                } else {
                                  return 'searched-article-autosuggestion';
                                }
                              };
                              return (
                                <div
                                  {...props}
                                  className={
                                    props.className +
                                    ' grid-autocomplete-options'
                                  }
                                >
                                  <Grid>
                                    <div
                                      className="ac-bosch-icon"
                                      style={{ fontSize: '20px' }}
                                    >
                                      {icon}
                                    </div>
                                  </Grid>
                                  <Grid className="w-full truncate-autocomplete">
                                    <Grid item align="left" className="ac-text">
                                      <div className="max-w-[95%] truncate-autocomplete">
                                        {parts.map((part, index) => (
                                          <span
                                            key={index}
                                            className={`${getClassNameArticle()}`}
                                            style={{
                                              fontWeight: part.highlight
                                                ? 400
                                                : 600
                                            }}
                                          >
                                            {part.text}
                                          </span>
                                        ))}
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid>{trash}</Grid>
                                </div>
                              );
                            }}
                            onChange={onChange}
                            onInputChange={(event, newValue) => {
                              event && onInputChange(event, newValue);
                            }}
                            ListboxProps={{
                              onClick: () => setMouseInListBox(true),
                              onMouseLeave: () => setMouseInListBox(false),
                              sx: {
                                '& li.Mui-focused': {
                                  backgroundColor: !mouseInListBox
                                    ? 'white'
                                    : undefined
                                },
                                "& .MuiAutocomplete-option[aria-selected='true']":
                                  {
                                    backgroundColor: '#fff'
                                  },
                                "& .MuiAutocomplete-option[aria-selected='true'].Mui-focused":
                                  {
                                    backgroundColor: 'rgba(25, 118, 210, 0.12)'
                                  },

                                '& .MuiAutocomplete-option.Mui-focused': {
                                  backgroundColor: 'rgba(25, 118, 210, 0.12)'
                                }
                              }
                            }}
                            classes={{
                              inputRoot: 'h-full p-0 pl-3',
                              listbox: `max-h-64 overflow-hidden`
                            }}
                          ></Autocomplete>
                        </Paper>
                      </Grid>
                    </TooltipSearchBar>
                    {props.showSaveSearch && (
                      <Grid item xs={4} align="left">
                        <SaveSearch
                          searchTerm={props.searchTerm}
                          userNTID={props.userNTID}
                          alias={alias}
                          targetUserAlias={targetUserAlias}
                          customerUser={props.customerUser}
                          searchResponse={props.searchResponse}
                          lastSearchedTerm={props.lastSearchedTerm}
                          analyticId={props.analyticId}
                          backHomePage={backHomePage}
                          deleteSavedSearch={deleteSavedSearch}
                          handleDeleteSavedSearched={handleDeleteSavedSearched}
                          setHandleDeleteSavedSearched={
                            setHandleDeleteSavedSearched
                          }
                          updateUserSavedQueries={props.updateUserSavedQueries}
                          wasUndo={props.wasUndo}
                          setWasUndo={props.setWasUndo}
                        />
                      </Grid>
                    )}
                  </Grid>
                </TooltipSearchBar>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Searchbar;
