import boschBg from '../../media/navTopBar.png';

const BoschTopBar = () => {
  return (
    <div
      style={{
        width: '100%',
        borderImage: `url(${boschBg}) 10% 10% 10% 10%`,
        borderStyle: 'solid none none',
        borderWidth: '.8rem 0rem 0rem'
      }}
      alt="bosch-rainbow"
    />
  );
};

export default BoschTopBar;
