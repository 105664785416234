import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Grid, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledHomeButton = styled(Button)({
  backgroundColor: '#005691 !important',
  color: '#ffffff',
  fontFamily: 'BoschSans',
  fontSize: '1.25rem',
  textTransform: 'none',
  boxSizing: 'border-box',
  border: '1px solid #005691',
  borderRadius: '0px',
  marginTop: '1rem'
});

export default function Errpr() {
  const { t } = useTranslation();
  const oopsText = t('ui_spear.errorPage.Oops_something_went_wrong');
  const clearCacheOpenTicketText = t(
    'ui_spear.errorPage.Please_clear_cache_or_open_a_ticket'
  );
  const goToHomeLabel = t('ui_spear.errorPage.Button_Go_to_Home');

  return (
    <Container>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '50vh' }}
      >
        <Grid
          item
          alt="sad-face-emoji"
          style={{
            color: '#999999',
            fontFamily: 'bosch-icon',
            marginRight: '2rem',
            fontSize: '14rem'
          }}
        >
          &#xe15d;
        </Grid>
        <Grid item>
          <Typography className="font-Bosch_sans text-3xl">
            {oopsText}
          </Typography>
          <Typography className="font-Bosch_sans text-lg mt-4">
            {clearCacheOpenTicketText}
          </Typography>
          <StyledHomeButton href={process.env.REACT_APP_SNOW_SP_URL}>
            {goToHomeLabel}
          </StyledHomeButton>
        </Grid>
      </Grid>
    </Container>
  );
}
