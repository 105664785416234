import React from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle, IconButton, styled } from '@mui/material';

const StyledDialog = styled(Dialog)(() => ({
    "& .MuiDialog-container": {
        alignItems: "flex-start",
        marginTop: 80
    }
}));

const NotificationModal = ({ open, setOpen, modalData }) => {
    const fullWidth = true;
    const maxWidth = 'md';

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <StyledDialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle className='px-[56px] py-6'>
                    <span className='text-[36px] mt-10 text-support_banner_color_1 font-Bosch_sans_light'>{modalData.tittle}</span>
                    <IconButton
                        className="boschicon-bosch-ic-close text-[40px] absolute top-2 right-2 color-black"
                        aria-label="close"
                        onClick={handleClose}
                    ></IconButton>
                </DialogTitle>

                <DialogContent className='font-Bosch_sans_light text-[19px] px-[56px] min-h-[90px]'>
                    <div className='flex items-center'>
                        <img className='h-[36px] w-[36px] mr-4' src={modalData.icon} alt="" />
                        <span>{modalData.description} </span>
                        <span className='font-bold'>{modalData.errorMessage}</span>
                    </div>
                </DialogContent>

            </StyledDialog>
        </>
    );
};

export default NotificationModal;