const getGenesysLanguageString = (url) => {
  let urlString = url.toString();

  if (urlString.includes('english')) {
    return { Tag: 'English', Code: 'en' };
  }
  if (urlString.includes('german')) {
    return { Tag: 'German', Code: 'de' };
  }
  if (urlString.includes('spanish')) {
    return { Tag: 'Spanish', Code: 'es' };
  }
  if (urlString.includes('portuguese')) {
    return { Tag: 'Portuguese', Code: 'pb' };
  }
  if (urlString.includes('french')) {
    return { Tag: 'French', Code: 'fr' };
  }
  if (urlString.includes('italian')) {
    return { Tag: 'Italian', Code: 'it' };
  }
  if (urlString.includes('japanese')) {
    return { Tag: 'Japanese', Code: 'ja' };
  }
  if (urlString.includes('korean')) {
    return { Tag: 'Korean', Code: 'ko' };
  }
  if (urlString.includes('mandarin')) {
    return { Tag: 'Chinese', Code: 'zh' };
  }
  if (urlString.includes('czech')) {
    return { Tag: 'Czech', Code: 'cs' };
  }
  if (urlString.includes('dutch')) {
    return { Tag: 'Dutch', Code: 'en' };
  }
  if (urlString.includes('hungarian')) {
    return { Tag: 'Hungarian', Code: 'hu' };
  }
  if (urlString.includes('polish')) {
    return { Tag: 'Polish', Code: 'pl' };
  }
  if (urlString.includes('romanian')) {
    return { Tag: 'Romanian', Code: 'en' };
  }
  if (urlString.includes('russian')) {
    return { Tag: 'Russian', Code: 'ru' };
  }
  if (urlString.includes('turkish')) {
    return { Tag: 'Turkish', Code: 'tr' };
  }
  if (urlString.includes('vietnamese')) {
    return { Tag: 'Vietnamese', Code: 'en' };
  }
  return { Tag: 'English', Code: 'en' };
};

export { getGenesysLanguageString };
