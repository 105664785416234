import React from 'react';
import TicketSolutionButton from '../../Overview/Table/ModalsTickets/TicketSolutionButton';
import { useTranslation } from 'react-i18next';

const RateCell = ({ source, incidentId, reqEntryId, serviceId, status }) => {
  const { t } = useTranslation();
  let icon = 'boschicon-bosch-ic-ticket';
  let label = 'Created';
  let localizationLabel = 'Created';

  switch (String(status.toLowerCase())) {
    case 'created':
      icon = 'boschicon-bosch-ic-ticket';
      label = 'Created';
      localizationLabel = t('ui_spear.ticketDetails.Details_Created');
      break;
    case 'in approval':
      icon = 'boschicon-bosch-ic-coffee-break';
      label = 'In approval';
      localizationLabel = t('ui_spear.myTickets.In_approval');
      break;
    case 'in progress':
      icon = 'boschicon-bosch-ic-refresh';
      label = 'In progress';
      localizationLabel = t('ui_spear.myTickets.In_progress');
      break;
    case 'Pending':
    case 'pending':
      icon = 'boschicon-bosch-ic-clock';
      label = 'Pending';
      localizationLabel = t('ui_spear.myTickets.Pending');
      break;
    case 'cancelled':
      icon = 'boschicon-bosch-ic-abort-frame';
      label = 'Cancelled';
      localizationLabel = t('ui_spear.ticketDetails.Banner.Cancelled');
      break;
    case 'rejected':
      icon = 'boschicon-bosch-ic-abort-frame';
      label = 'Rejected';
      localizationLabel = t('ui_spear.ticketDetails.Banner.Rejected');
      break;
    case 'closed':
      icon = 'boschicon-bosch-ic-document-edit';
      label = 'Closed';
      localizationLabel = t('ui_spear.myTickets.Closed');
      break;
    case 'assigned':
      icon = 'boschicon-bosch-ic-ticket';
      label = 'Assigned';
      localizationLabel = status;
      break;
    default:
      break;
  }

  const statusClosed = (e) => {
    if (
      status === 'cancelled' ||
      status === 'rejected' ||
      status === 'closed'
    ) {
      e.stopPropagation();
    }
  };

  let style =
    status === 'cancelled' || status === 'rejected' || status === 'closed'
      ? 'text-blue_font hover:text-ITSP_blue'
      : 'text-black';

  return (
    <>
      {status === 'cancelled' ||
      status === 'rejected' ||
      status === 'closed' ? (
        <TicketSolutionButton
          source={source}
          incidentId={incidentId}
          reqEntryId={reqEntryId}
          serviceId={serviceId}
          status={status}
          icon={icon}
          label={label}
          localizationLabel={localizationLabel}
        ></TicketSolutionButton>
      ) : (
        <div
          className={`${style} group border-none flex items-center justify-left `}
          onClick={statusClosed}
        >
          <span
            style={{
              color:
                status == 'Pending' || status == 'pending'
                  ? 'rgb(255, 153, 51)'
                  : '#000000'
            }}
            className={`${icon} text text-[16px]`}
          ></span>
          <span
            style={{
              color:
                status == 'Pending' || status == 'pending'
                  ? 'rgb(255, 153, 51)'
                  : '#000000'
            }}
            className="mx-3"
          >
            {localizationLabel}
          </span>
          {(status === 'cancelled' ||
            status === 'rejected' ||
            status === 'closed') && (
            <div className="transform -rotate-90 group-hover:translate-x-2">
              <span
                className={`boschicon-bosch-ic-down text text-[18px]`}
              ></span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RateCell;
