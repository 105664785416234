import React from 'react';
import { useTranslation } from 'react-i18next';

const ButtonsSection = ({ nodeName }) => {
  const { t } = useTranslation();

  const devOverviewLinkBtns = [
    {
      txt: t('ui_spear.deviceDetails.return_device'),
      link: `${process.env.REACT_APP_SNOW_SP_URL}?id=ui_sc_cat_item&sys_id=486eed501bfc615078087403dd4bcbdb&device=${nodeName}`
    },
    {
      txt: t('ui_spear.deviceDetails.transfer_device'),
      link: `${process.env.REACT_APP_SNOW_SP_URL}?id=ui_sc_cat_item&sys_id=1e9173741b1d51d078087403dd4bcbde&device=${nodeName}`
    },
    {
      txt: t('ui_spear.deviceDetails.device_lost'),
      link: `${process.env.REACT_APP_UI_URL}/support`
    },
    {
      txt: t('ui_spear.deviceDetails.help_with_the_device'),
      link: `${process.env.REACT_APP_UI_URL}/support`
    }
  ];

  const renderDevOverviewBtns = () => {
    return devOverviewLinkBtns.map((link) => {
      return (
        <a
          key={link.txt}
          href={link.link}
          className="group flex items-center justify-center border border-gray-400 bg-ITSP_blue text-white font-normal px-4 py-3 mt-3 lg:mt-0 w-full lg:w-1/5 hover:no-underline"
        >
          <span className="mr-2">{link.txt}</span>
          <div className="transform -rotate-90 group-hover:translate-x-2">
            <span className={`boschicon-bosch-ic-down text text-[16px]`}></span>
          </div>
        </a>
      );
    });
  };

  return (
    <div className="flex flex-wrap justify-between m-5 gap-1 font-Bosch_sans_light">
      {renderDevOverviewBtns()}
    </div>
  );
};

export default ButtonsSection;
