import React from 'react';
import { useTranslation } from 'react-i18next';

const MobileButtonsSection = (props) => {
    const { t } = useTranslation();

    const _showViewLost = () => {
        window.location.replace(`${process.env.REACT_APP_MOBILE_SERVICE_WORKPLACE}page=lost_stolen`);
    }
    const _showViewUnlock = () => {
        window.location.replace(`${process.env.REACT_APP_MOBILE_SERVICE_WORKPLACE}page=faq&only=39`);
    }

    const _goToSupport = () => {
        window.location.replace(`${process.env.REACT_APP_MOBILE_SERVICE_WORKPLACE}page=contact_support`);
    }

    return (
        <div className='grid gap-x-6 gap-y-4 grid-cols-1 lg:grid-cols-3' style={{ padding: '4% 15%' }}>
            <button type='button' onClick={(e) => _showViewLost()} style={{ padding: '5px' }}
                className='group text-white font-Bosch_sans_light w-full border border-gray-400 bg-ITSP_blue'>
                <div className='p-0 lg:p-2 w-[80%] inline-block align-middle'> {t('ui_spear.deviceMobileDetails.device_lost')} </div>
                <div className='transform -rotate-90 group-hover:translate-x-2 w-[10%] inline-block align-middle'>
                    <div className='boschicon-bosch-ic-down text text-[18px]'></div>
                </div>
            </button>
            <button type='button' onClick={(e) => _showViewUnlock()} style={{ padding: '5px' }}
                className='group text-white font-Bosch_sans_light w-full border border-gray-400 bg-ITSP_blue'>
                <div className='w-[80%] inline-block align-middle'> {t('ui_spear.deviceMobileDetails.unlock_device')} </div>
                <div className='transform -rotate-90 group-hover:translate-x-2 w-[10%]  inline-block align-middle'>
                    <div className='boschicon-bosch-ic-down text text-[18px] py-2'></div>
                </div>
            </button>
            <button type='button' onClick={(e) => _goToSupport()} style={{ padding: '5px' }}
                className='group text-white font-Bosch_sans_light w-ful border border-gray-400 bg-ITSP_blue'>
                <div className='w-[80%] inline-block align-middle' style={{ textAlign: 'center' }}> {t('ui_spear.deviceMobileDetails.help_with')} </div>
                <div className='transform -rotate-90 group-hover:translate-x-2 w-[10%] inline-block align-middle'>
                    <div className='boschicon-bosch-ic-down text text-[18px] py-2'></div>
                </div>
            </button>
        </div>
    );
};

export default MobileButtonsSection;