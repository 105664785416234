import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Headline from '../Headline';
import Pagination from '../Pagination';
import Head from './Head';
import Body from './Body';

const OthersClosedTickets = ({
  handleSorting,
  data,
  setData,
  currentPageClosedTable,
  setCurrentPageClosedTable
}) => {
  //Translations
  const { t } = useTranslation();
  const closedLabel = t('ui_spear.myTickets.Closed');
  const ticketIdLabel = t('ui_spear.myTickets.Ticket_ID');
  const nameLabel = t('ui_spear.myTickets.Name');
  const solutionLabel = t('ui_spear.myTickets.Solution');
  const availableUntilLabel = t('ui_spear.myTickets.Available_until');
  const personLabel = t('ui_spear.myTickets.Person');

  const columns = [
    {
      label: ticketIdLabel,
      accessor: 'ticketId',
      sortable: true,
      alignedText: 'text-left'
    },
    {
      label: nameLabel,
      accessor: 'name',
      sortable: true,
      alignedText: 'text-left'
    },
    {
      label: solutionLabel,
      accessor: 'status',
      sortable: true,
      alignedText: 'text-left'
    },
    {
      label: 'Creation Date',
      accessor: 'submittedDate',
      sortable: true,
      alignedText: 'text-left'
    },
    {
      label: availableUntilLabel,
      accessor: 'lastUpdate',
      sortable: true,
      alignedText: 'text-left'
    },
    {
      label: personLabel,
      accessor: 'person',
      sortable: true,
      alignedText: 'text-left'
    },
    {
      label: closedLabel,
      accessor: 'lastUpdate',
      sortable: true,
      alignedText: 'text-left'
    }
  ];

  const [itemsPerPage, setItemsPerPage] = useState(8); //pagination
  const indexOfLastItem = currentPageClosedTable * itemsPerPage; //pagination
  const indexOfFirstItem = indexOfLastItem - itemsPerPage; //pagination
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem); //pagination

  return (
    <div className="mt-5 mb-8">
      <Headline
        title={closedLabel}
        subtitle={true}
        itemsQuantity={data.length}
      />
      <div className="">
        <table className="cursor-pointer w-full table-auto bg-white my-5">
          <Head
            columns={columns}
            handleSorting={handleSorting}
            data={data}
            setData={setData}
          />
          <Body data={currentItems} />
        </table>
        <Pagination
          data={data}
          itemsPerPage={itemsPerPage}
          currentPage={currentPageClosedTable}
          setCurrentPage={setCurrentPageClosedTable}
        />
      </div>
    </div>
  );
};

export default OthersClosedTickets;
