import React from 'react';
import { useTranslation } from 'react-i18next';

const EmojisNotAllowedMessage = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className='flex flex-start'>
                <p className='mb-4 text-red-600'>{t('ui_spear.generalAlerts.smt_doesnt_support_emojis_v3')}</p>
            </div>
        </>
    )
};

export default EmojisNotAllowedMessage;