import React from 'react';
import StatusCell from '../../Table/StatusCell';
import { useNavigate } from 'react-router-dom';

const Row = ({ ticketId, name, status, lastUpdate, type, priority, bgRow, submittedDate }) => {
  let navigate = useNavigate();
  const goTicketDetails = () => {
    navigate(`/tickets/ticket-journey/${ticketId}`);
  };

  return (
    <tr
      className={`cursor-pointer border-b hover:bg-gray-100 ${bgRow}`}
      onClick={goTicketDetails}
    >
      <td className="px-6 py-3 w-[14%]">
        <a href={`/tickets/ticket-journey/${ticketId}`}>{ticketId}</a>
      </td>
      <td className="px-6 py-3 w-[32%]">{name}</td>
      <td className="px-6 py-3 w-[12%]">
        <StatusCell status={status} />
      </td>
      <td className="w-[12%] px-6 py-3 text-left">{submittedDate}</td>
      <td className="px-6 py-3 w-[12%]">{lastUpdate}</td>
      <td className="px-6 py-3 capitalize w-[12%]">{type}</td>
      <td className="px-6 py-3 capitalize w-[12%]">{priority}</td>
    </tr>
  );
};

export default Row;
