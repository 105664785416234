import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import TicketCreatedConfirm from '../components/Confirmations/TicketCreatedConfirm';
import NewTicketBanner from '../components/Overview/NewTicketBanner';
import Tabs from '../components/Overview/Tabs';
import Filters from '../components/Overview/Filters/Filters';
import MyOpenTicketsTable from '../components/Overview/Table/Open/MyOpenTickets';
import MyClosedTicketsTable from '../components/Overview/Table/Closed/MyClosedTickets';
import OthersOpenTicketsTable from '../components/Overview/Table/OpenForOthers/OthersOpenTickets';
import OthersClosedTicketsTable from '../components/Overview/Table/ClosedForOthers/OthersClosedTickets';
import EmptyTickets from '../components/Overview/EmptyTickets';
import NotTicketsFound from '../components/Overview/NotTicketsFound';
import UserKeySection from '../components/Overview/SearchUserKey/UserKeySection';
import Loader from '../components/Confirmations/Loader';

import { getSNItemsFormatted } from '../utils/snFormatting';
import {
  filterDataByPropertyValues,
  handleSorting,
  filterPersonListNames
} from '../utils/myTicketsFunctions';

import {
  fetchSmtTicketsForMyself,
  fetchSmtTicketsForOthers,
  fetchSNTicketsForMyself,
  fetchSNTicketsForOthers,
  fetchCPSCOpenTicketsForMyself,
  fetchCPSCClosedTicketsForMyself,
  fetchCPSCOpenTicketsForOthers,
  fetchCPSCClosedTicketsForOthers,
  getSapKeyUser
} from '../api/ticketsEndpoints';

const MyTickets = ({ userInfo }) => {
  //Translations 
  const { t } = useTranslation();
  const noTicketsForYouLabel = t(
    'ui_spear.myTickets.No_tickets_created_for_you'
  );
  const noTicketsForOthersLabel = t(
    'ui_spear.myTickets.No_tickets_created_for_others'
  );

  const isSapUserKey = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const overviewTabs = {
    myTickets: true,
    ticketsForOthers: false,
    searchByTicketId: false
  };

  const [isLoading, setIsLoading] = useState(false);
  const [toggleTicketsTabs, setToggleTicketsTabs] = useState(overviewTabs);
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [priorityFilter, setPriorityFilter] = useState([]);
  const [personFilter, setPersonFilter] = useState('');
  const [personList, setPersonList] = useState([]);
  const [isFiltersActive, setIsFiltersActive] = useState(false);
  const [showFilterContain, setShowFilterContain] = useState(false);

  useEffect(() => {
    const NTID = userInfo?.userNTID;
    const codeLanguage = userInfo?.userLanguage || 'en';

    async function fetchData() {
      try {
        if (toggleTicketsTabs.myTickets) {
          setIsLoading(true);
          const smtMySelf = await fetchSmtTicketsForMyself(NTID);
          const snMySelf = await fetchSNTicketsForMyself(NTID);
          const cpscOpenMySelf = await fetchCPSCOpenTicketsForMyself(
            NTID,
            codeLanguage
          );
          const cpscClosedMySelf = await fetchCPSCClosedTicketsForMyself(
            NTID,
            codeLanguage
          );
          
          // Tickets already come formated
          const smtItemsFormatted = smtMySelf;
          const snItemsFormatted = getSNItemsFormatted(snMySelf);
          const cpscOpenItemsFormatted = cpscOpenMySelf;
          const cpscClosedItemsFormatted = cpscClosedMySelf;

          const myTickets = [
            ...smtItemsFormatted,
            ...snItemsFormatted,
            ...cpscOpenItemsFormatted,
            ...cpscClosedItemsFormatted
          ];
          const myOpenItemsTable = filterDataByPropertyValues(
            myTickets,
            'status',
            ['created', 'in approval', 'in progress', 'pending']
          );
          const myClosedItemsTable = filterDataByPropertyValues(
            myTickets,
            'status',
            ['cancelled', 'rejected', 'closed']
          );


          setMyOpenItemsBackup(myOpenItemsTable);
          setMyOpenAfterFilters(myOpenItemsTable);
          handleSorting('lastUpdate', 'desc', myOpenItemsTable, setMyOpenTickets);

          setMyClosedItemsBackup(myClosedItemsTable);
          setMyClosedAfterFilters(myClosedItemsTable);
          handleSorting('lastUpdate', 'desc', myClosedItemsTable, setMyClosedTickets);

          setIsLoading(false);
        } else if (toggleTicketsTabs.ticketsForOthers) {
          //console.log('OTHERS');
          setIsLoading(true);
          const smtForOthers = await fetchSmtTicketsForOthers(NTID);
          const snForOthers = await fetchSNTicketsForOthers(NTID);
          const cpscOpenForOthers = await fetchCPSCOpenTicketsForOthers(
            NTID,
            codeLanguage
          );
          const cpscClosedForOthers = await fetchCPSCClosedTicketsForOthers(
            NTID,
            codeLanguage
          );

          // Fetch Ticket from their sources
          const smtItemsFormatted = smtForOthers;
          const snItemsFormatted = getSNItemsFormatted(snForOthers);
          const cpscOpenItemsFormatted = cpscOpenForOthers;
          const cpscClosedItemsFormatted = cpscClosedForOthers;

          const ticketsForOthers = [
            ...smtItemsFormatted,
            ...snItemsFormatted,
            ...cpscOpenItemsFormatted,
            ...cpscClosedItemsFormatted
          ];

          const personListNames = filterPersonListNames(ticketsForOthers);
          setPersonList(personListNames);

          const othersOpenItems = filterDataByPropertyValues(
            ticketsForOthers,
            'status',
            ['created', 'in approval', 'in progress', 'pending']
          );
          const othersClosedItems = filterDataByPropertyValues(
            ticketsForOthers,
            'status',
            ['cancelled', 'rejected', 'closed']
          );

          setOthersOpenItemsBackup(othersOpenItems);
          setOthersOpenAfterFilters(othersOpenItems);
          handleSorting('lastUpdate', 'desc', othersOpenItems, setOthersOpenTickets);

          setOthersClosedItemsBackup(othersClosedItems);
          setOthersClosedAfterFilters(othersClosedItems);
          handleSorting('lastUpdate', 'desc', othersClosedItems, setOthersClosedTickets);

          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching data in pages/myTickets: ', error);
        // TODO: Implement a error message here
        setIsLoading(false);
      }
    }

    async function fetchSapKeyUser() {
      try {
        const sapKeyUSer = await getSapKeyUser(NTID);
        isSapUserKey.current = sapKeyUSer.data.json;
        setLoaded(true);
      } catch (error) {
        console.log('error fetchSapKeyUser', error);
        isSapUserKey.current = false;
        setLoaded(true);
      }
    }

    if (NTID) fetchSapKeyUser();
    if (NTID) fetchData();

    // Clean up searchbar and filters
    setSearchText('');
    setStatusFilter([]);
    setTypeFilter([]);
    setPriorityFilter([]);
    setPersonFilter('');
    setIsFiltersActive(false);
    setShowFilterContain(false);
  }, [toggleTicketsTabs, userInfo]);

  // VARIABLES MY TICKETS
  const [currentPageMyOpenTable, setCurrentPageMyOpenTable] = useState(1); //pagination
  const [currentPageMyClosedTable, setCurrentPageMyClosedTable] = useState(1); //pagination

  const [myOpenItemsBackup, setMyOpenItemsBackup] = useState([]); //backup from initial state
  const [myClosedItemsBackup, setMyClosedItemsBackup] = useState([]); //backup from initial state

  const [myOpenTickets, setMyOpenTickets] = useState([]); // to display on the table
  const [myOpenAfterFilters, setMyOpenAfterFilters] = useState([]); // to handle checkboxes filters

  const [myClosedTickets, setMyClosedTickets] = useState([]); // to display on the table
  const [myClosedAfterFilters, setMyClosedAfterFilters] = useState([]); // to handle checkboxes filters

  // VARIABLES TICKETS FOR OTHERS
  const [currentPageOthersOpenTable, setCurrentPageOthersOpenTable] =
    useState(1); //pagination
  const [currentPageOthersClosedTable, setCurrentPageOthersClosedTable] =
    useState(1); //pagination

  const [othersOpenItemsBackup, setOthersOpenItemsBackup] = useState([]); //backup from initial state
  const [othersClosedItemsBackup, setOthersClosedItemsBackup] = useState([]); //backup from initial state

  const [othersOpenTickets, setOthersOpenTickets] = useState([]); // to display on the table
  const [othersOpenAfterFilters, setOthersOpenAfterFilters] = useState([]); // to handle checkboxes filters

  const [othersClosedTickets, setOthersClosedTickets] = useState([]); // to display on the table
  const [othersClosedAfterFilters, setOthersClosedAfterFilters] = useState([]); // to handle checkboxes filters

  const renderEmptyOrNotFoundBanner = (
    initialOpenItemsQty,
    initialClosedItemsQty,
    openItemsTableQty,
    closedItemsTableQty,
    message
  ) => {
    if (!isLoading) {
      if (initialOpenItemsQty === 0 && initialClosedItemsQty === 0) {
        return <EmptyTickets text={message} />;
      } else if (openItemsTableQty === 0 && closedItemsTableQty === 0) {
        return <NotTicketsFound />;
      }
    }
  };

  /** Alert message new SMT creation ticket  - ROW1GA **/
  const location = useLocation();
  const navigate = useNavigate();
  const [openINCTicketSnackBar, setOpenSnackBar] = useState(false);
  const [ticketCreatedId, setTicketCreatedId] = useState('');

  useEffect(() => {
    if (location.state !== null) {
      if (location.state.inc_id !== null) {
        setOpenSnackBar(true);
        setTicketCreatedId(location.state.inc_id);
        navigate(location.pathname, { replace: true });
      }
    }
  }, [location]);

  const handleCloseINCTicketSnackBar = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const renderTicketCreated = () => {
    if (openINCTicketSnackBar) {
      return (
        <TicketCreatedConfirm
          incidentID={ticketCreatedId}
          openINCTicketSnackBar={openINCTicketSnackBar}
          handleCloseINCTicketSnackBar={handleCloseINCTicketSnackBar}
          setOpenSnackBar={setOpenSnackBar}
        />
      );
    } else {
      return <></>;
    }
  };
  /** Alert message new SMT creation ticket  - ROW1GA  **/
  return (
    <div>
      <NewTicketBanner />
      {loaded ? (
        <div className="mx-32">
          <Tabs
            toggleTicketsTabs={toggleTicketsTabs}
            setToggleTicketsTabs={setToggleTicketsTabs}
            isSapUserKey={isSapUserKey.current}
          />
          {toggleTicketsTabs.searchByTicketId ? (
            <UserKeySection />
          ) : (
            <div className="px-14 w-5/6 mx-auto">
              {isLoading ? (
                <Loader customStyle="inset-x-1/2 inset-y-1/3 mt-36" />
              ) : null}
              {!isLoading && (
                <Filters
                  searchText={searchText} //search bar
                  setSearchText={setSearchText} // search bar
                  statusFilter={statusFilter}
                  setStatusFilter={setStatusFilter}
                  typeFilter={typeFilter}
                  setTypeFilter={setTypeFilter}
                  priorityFilter={priorityFilter}
                  setPriorityFilter={setPriorityFilter}
                  personFilter={personFilter}
                  setPersonFilter={setPersonFilter}
                  isFiltersActive={isFiltersActive}
                  setIsFiltersActive={setIsFiltersActive}
                  showFilterContain={showFilterContain}
                  setShowFilterContain={setShowFilterContain}
                  personList={personList}
                  toggleTicketsTabs={toggleTicketsTabs}
                  openItemsBackup={
                    toggleTicketsTabs.myTickets
                      ? myOpenItemsBackup
                      : othersOpenItemsBackup
                  } // API data
                  closedItemsBackup={
                    toggleTicketsTabs.myTickets
                      ? myClosedItemsBackup
                      : othersClosedItemsBackup
                  } // API data
                  openTickets={
                    toggleTicketsTabs.myTickets
                      ? myOpenTickets
                      : othersOpenTickets
                  } // open data management
                  setOpenTickets={
                    toggleTicketsTabs.myTickets
                      ? setMyOpenTickets
                      : setOthersOpenTickets
                  } // open data management
                  openAfterFilters={
                    toggleTicketsTabs.myTickets
                      ? myOpenAfterFilters
                      : othersOpenAfterFilters
                  } // open data management after filters
                  setOpenAfterFilters={
                    toggleTicketsTabs.myTickets
                      ? setMyOpenAfterFilters
                      : setOthersOpenAfterFilters
                  } // open data management after filters
                  closedTickets={
                    toggleTicketsTabs.myTickets
                      ? myClosedTickets
                      : othersClosedTickets
                  } // closed data management
                  setClosedTickets={
                    toggleTicketsTabs.myTickets
                      ? setMyClosedTickets
                      : setOthersClosedTickets
                  } // closed data management
                  closedAfterFilters={
                    toggleTicketsTabs.myTickets
                      ? myClosedAfterFilters
                      : othersClosedAfterFilters
                  } // closed data management after filters
                  setClosedAfterFilters={
                    toggleTicketsTabs.myTickets
                      ? setMyClosedAfterFilters
                      : setOthersClosedAfterFilters
                  } // closed data management after filters
                  setCurrentPageOpenTable={
                    toggleTicketsTabs.myTickets
                      ? setCurrentPageMyOpenTable
                      : setCurrentPageOthersOpenTable
                  } //pagination
                  setCurrentPageClosedTable={
                    toggleTicketsTabs.myTickets
                      ? setCurrentPageMyClosedTable
                      : setCurrentPageOthersClosedTable
                  } //pagination
                />
              )}
              {!isLoading &&
                (toggleTicketsTabs.myTickets ? (
                  <>
                    {myOpenTickets.length > 0 && (
                      <MyOpenTicketsTable
                        handleSorting={handleSorting}
                        data={myOpenTickets}
                        setData={setMyOpenTickets}
                        currentPageOpenTable={currentPageMyOpenTable}
                        setCurrentPageOpenTable={setCurrentPageMyOpenTable}
                      />
                    )}
                    {myClosedTickets.length > 0 && (
                      <MyClosedTicketsTable
                        handleSorting={handleSorting}
                        data={myClosedTickets}
                        setData={setMyClosedTickets}
                        currentPageClosedTable={currentPageMyClosedTable}
                        setCurrentPageClosedTable={setCurrentPageMyClosedTable}
                      />
                    )}
                    {renderEmptyOrNotFoundBanner(
                      myOpenItemsBackup.length,
                      myClosedItemsBackup.length,
                      myOpenTickets.length,
                      myClosedTickets.length,
                      noTicketsForYouLabel
                    )}
                  </>
                ) : (
                  <>
                    {othersOpenTickets.length > 0 && (
                      <OthersOpenTicketsTable
                        handleSorting={handleSorting}
                        data={othersOpenTickets}
                        setData={setOthersOpenTickets}
                        currentPageOpenTable={currentPageOthersOpenTable}
                        setCurrentPageOpenTable={setCurrentPageOthersOpenTable}
                      />
                    )}
                    {othersClosedTickets.length > 0 && (
                      <OthersClosedTicketsTable
                        handleSorting={handleSorting}
                        data={othersClosedTickets}
                        setData={setOthersClosedTickets}
                        currentPageClosedTable={currentPageOthersClosedTable}
                        setCurrentPageClosedTable={
                          setCurrentPageOthersClosedTable
                        }
                      />
                    )}
                    {renderEmptyOrNotFoundBanner(
                      othersOpenItemsBackup.length,
                      othersClosedItemsBackup.length,
                      othersOpenTickets.length,
                      othersClosedTickets.length,
                      noTicketsForOthersLabel
                    )}
                  </>
                ))}
            </div>
          )}
        </div>
      ) : (
        <Loader customStyle="inset-x-1/2 inset-y-1/2" />
      )}
      {renderTicketCreated()}
    </div>
  );
};

export default MyTickets;
