export const getSMTItemsFormatted = (list, skUserFlag = null) => {
  const itemsFormatted = list.map((item) => {
    return {
      ticketId: item.Incident_Number[0],
      name: item.Description[0],
      status: skUserFlag ? item.Status[0] : localizeStatus(item.Status[0]),
      lastUpdate: formatSmtDate(item.Last_Modified_Date),
      submittedDate: formatSmtDate(item.Submit_Date),
      type: skUserFlag
        ? item.Incident_Type[0]
        : defineType(item.Incident_Type[0]),
      priority: calculatePriority(item.Urgency[0], item.Impact[0]),
      person: joinFullName(item.First_Name[0], item.Last_Name[0]),
      source: 'smt'
    };
  });

  return itemsFormatted;
};

export const setSMTItemForTicketJourney = (ticket) => {
  var itemsFormatted;

  // Response payload depend's on status, payload changes
  if (ticket.Status[0] === 'Resolved') {
    itemsFormatted = {
      activity_id: ticket.Incident_Number[0],
      serviceName: ticket.Description[0],
      status: ticket.Status[0],
      lastDateTime: formatSmtDate(ticket.Last_Modified_Date[0]),
      originalLastDateTime: ticket.Last_Modified_Date[0],
      serviceDescription: ticket.Resolution[0],
      performer: ticket.Corporate_ID[0],
      submittedDate: formatSmtDate(ticket.Submit_Date[0]),
      originalSubmittedDate: ticket.Submit_Date[0],
      closedDate: '',
      taskDate: '',
      cost: '',
      type: defineType(ticket.Incident_Type[0]),
      priority: calculatePriority(ticket.Urgency[0], ticket.Impact[0]),
      onBehalf: '',
      requested_for: joinFullName(ticket.First_Name[0], ticket.Last_Name[0]),
      requisitionDueDate: '',
      sys_id: '',
      rejection_reason: ''
    };
  } else if (ticket.Status[0] === 'Pending') {
    itemsFormatted = {
      activity_id: ticket.Incident_Number[0],
      serviceName: ticket.Description[0],
      status: ticket.Status[0],
      lastDateTime: formatSmtDate(ticket.Last_Modified_Date[0]),
      originalLastDateTime: ticket.Last_Modified_Date[0],
      serviceDescription: ticket.Description[0],
      performer: ticket.Corporate_ID[0],
      submittedDate: formatSmtDate(ticket.Submit_Date[0]),
      originalSubmittedDate: ticket.Submit_Date[0],
      closedDate: '',
      taskDate: '',
      statusReason: ticket.Incident_Status_History.reverse().map(
        (ticket, index) => {
          return ticket.Incident__Status_Reason
            ? ticket.Incident__Status_Reason[0]
            : [];
        }
      ),
      cost: '',
      type: defineType(ticket.Incident_Type[0]),
      priority: calculatePriority(ticket.Urgency[0], ticket.Impact[0]),
      onBehalf: '',
      requested_for: joinFullName(ticket.First_Name[0], ticket.Last_Name[0]),
      requisitionDueDate: '',
      sys_id: '',
      rejection_reason: ''
    };
  } else if (ticket.Status[0] === 'Closed') {
    itemsFormatted = {
      activity_id: ticket.Incident_Number[0],
      serviceName: ticket.Description[0],
      status: ticket.Status[0],
      lastDateTime: formatSmtDate(ticket.Last_Modified_Date[0]),
      originalLastDateTime: ticket.Last_Modified_Date[0],
      serviceDescription: ticket.Detailed_Decription
        ? ticket.Detailed_Decription[0]
        : '-',
      performer: ticket.Corporate_ID[0],
      submittedDate: formatSmtDate(ticket.Submit_Date[0]),
      originalSubmittedDate: ticket.Submit_Date[0],
      closedDate: '',
      taskDate: '',
      cost: '',
      type: defineType(ticket.Incident_Type[0]),
      priority: calculatePriority(ticket.Urgency[0], ticket.Impact[0]),
      onBehalf: '',
      requested_for: joinFullName(ticket.First_Name[0], ticket.Last_Name[0]),
      requisitionDueDate: '',
      sys_id: '',
      rejection_reason: '',
      resolution: ticket.Resolution[0],
    };
  } else {
    itemsFormatted = {
      activity_id: ticket.Incident_Number[0],
      serviceName: ticket.Description[0],
      status: ticket.Status[0],
      lastDateTime: formatSmtDate(ticket.Last_Modified_Date[0]),
      originalLastDateTime: ticket.Last_Modified_Date[0],
      serviceDescription: ticket.Detailed_Decription
        ? ticket.Detailed_Decription[0]
        : '-',
      performer: ticket.Corporate_ID[0],
      submittedDate: formatSmtDate(ticket.Submit_Date[0]),
      originalSubmittedDate: ticket.Submit_Date[0],
      closedDate: '',
      taskDate: '',
      cost: '',
      type: defineType(ticket.Incident_Type[0]),
      priority: calculatePriority(ticket.Urgency[0], ticket.Impact[0]),
      onBehalf: '',
      requested_for: joinFullName(ticket.First_Name[0], ticket.Last_Name[0]),
      requisitionDueDate: '',
      sys_id: '',
      rejection_reason: ''
    };
  }

  return itemsFormatted;
};

export const calculatePriority = (urgency, impact) => {
  var priority = '';
  if (
    urgency === '4-Low' &&
    (impact === '1-Extensive/Widespread' ||
      impact === '2-Significant/Large' ||
      impact === '3-Moderate/Limited' ||
      impact === '4-Minor/Localized')
  ) {
    priority = 'low';
  } else if (
    (urgency === '3-Medium' &&
      (impact === '2-Significant/Large' ||
        impact === '3-Moderate/Limited' ||
        impact === '4-Minor/Localized')) ||
    (urgency === '2-High' && impact === '4-Minor/Localized')
  ) {
    priority = 'medium';
  } else if (
    (urgency === '2-High' &&
      (impact === '2-Significant/Large' || impact === '3-Moderate/Limited')) ||
    (urgency === '1-Critical' &&
      (impact === '3-Moderate/Limited' || impact === '4-Minor/Localized'))
  ) {
    priority = 'high';
  } else if (
    (urgency === '2-High' && impact === '1-Extensive/Widespread') ||
    (urgency === '1-Critical' &&
      (impact === '1-Extensive/Widespread' || impact === '2-Significant/Large'))
  ) {
    priority = 'critical';
  } else {
    priority = '-';
  }
  return priority;
};

export const localizeStatus = (status) => {
  let localizedStatus = status;
  if (status === 'Closed' || status.indexOf('Resolved') !== -1) {
    localizedStatus = 'closed';
  } else if (status === 'Cancelled') {
    localizedStatus = 'cancelled';
  } else if (status === 'Rejected') {
    localizedStatus = 'rejected';
  } else if (status === 'Assigned') {
    localizedStatus = 'in progress';
  } else if (status.indexOf('approval') !== -1) {
    localizedStatus = 'in approval';
  } else if (
    status.indexOf('progress') !== -1 ||
    status.indexOf('Progress') !== -1
  ) {
    localizedStatus = 'in progress';
  } else if (status.indexOf('Pending') !== -1) {
    localizedStatus = 'pending';
  } else if (status.indexOf('review') !== -1) {
    localizedStatus = 'In review'; // :TODO: dont used?
  } else if (status.indexOf('Created') !== -1) {
    localizedStatus = 'created';
  } else if (status.indexOf('Draft') !== -1) {
    localizedStatus = 'Draft'; // :TODO: dont used?
  } else if (status.indexOf('Template') !== -1) {
    localizedStatus = 'Template'; // :TODO: dont used?
  }
  return localizedStatus;
};

export const formatSmtDate = (inputDate) => {
  var date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
    var day = date.getDate().toString();
    var month = (date.getMonth() + 1).toString();

    return (
      date.getFullYear() +
      '/' +
      (month[1] ? month : '0' + month[0]) +
      '/' +
      (day[1] ? day : '0' + day[0])
    );
  }
};

export const add3Weeks = (date) => {
  var newdate = new Date(date);
  newdate.setDate(newdate.getDate() + 22);

  if (!isNaN(newdate.getTime())) {
    var day = newdate.getDate().toString();
    var month = (newdate.getMonth() + 1).toString();

    return (
      newdate.getFullYear() +
      '/' +
      (month[1] ? month : '0' + month[0]) +
      '/' +
      (day[1] ? day : '0' + day[0])
    );
  }
};

export const defineType = (type) => {
  if (type.indexOf('Request') === -1) {
    return 'failure';
  } else {
    return 'request';
  }
};

export const joinFullName = (firstName, lastName) => {
  return firstName + ' ' + lastName;
};
