import CircularProgress from '@mui/material/CircularProgress';

const LoaderTicketCreation = () => {
  return (
    <div className="bg-gray-300 opacity-50 h-full w-full fixed">
      <div className="absolute inset-x-1/2 inset-y-1/3 z-50">
        <CircularProgress size={80} />
      </div>
    </div>
  );
};

export default LoaderTicketCreation;
