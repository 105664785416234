import React from 'react';
import { useTranslation } from 'react-i18next';

const ComputerDetails = ({ pcDetails, deviceType }) => {
  const { t } = useTranslation();
  let iconDevice = 'laptop_icon_150.png';
  switch (deviceType) {
    case 'Desktop':
      iconDevice = 'desktop_icon_150.png';
      break;
    case 'Notebook':
      iconDevice = 'laptop_icon_150.png';
      break;
    case 'Tablet':
      iconDevice = 'tablet_icon_150.png';
      break;
    default:
      iconDevice = 'laptop_icon_150.png';
      break;
  }

  return (
    <div className='mt-8'>
      <h2 className='text-center m-devices-type'>{pcDetails.NODE_NAME}</h2>
      <div className='flex flex-wrap justify-center'>
        <div className='flex justify-center lg:justify-end items-center w-full lg:w-1/2 '>
          <img className='img-gradient-png object-contain w-[213px] h-[152px]'
            alt='mobile-Device'
            src={require(`../../../static/deviceIcons/${iconDevice}`)}
          />
        </div>
        <div className='flex lg:justify-start justify-center font-Bosch_sans_light w-full lg:w-1/2  '>
          <table className="text-md tracking-wide min-w-[32rem]">
            <tr className="h-12">
              <td className="text-right w-1/2">{t('ui_spear.deviceDetails.device_name')}:</td>
              <td className="p-3 w-1/2">{pcDetails.NODE_NAME}</td>
            </tr>
            <tr className="h-12">
              <td className="text-right w-1/2">{t('ui_spear.deviceDetails.model')}:</td>
              <td className="p-3 w-1/2">{pcDetails.MODEL}</td>
            </tr>
            <tr className="h-12">
              <td className="text-right w-1/2">{t('ui_spear.deviceDetails.current_device_class')}:</td>
              <td className="p-3 w-1/2">{pcDetails.DEVICE_CLASS}</td>
            </tr>
            <tr className="h-12">
              <td className="text-right w-1/2">{t('ui_spear.deviceDetails.replacement_device_class')}:</td>
              <td className="p-3 w-1/2">{pcDetails.REPLACEMENTCATEGORY}</td>
            </tr>
            <tr className="h-12">
              <td className="text-right w-1/2">{t('ui_spear.deviceDetails.planned_device_exchange')}:</td>
              <td className="p-3 w-1/2">{(pcDetails.LIFECYCLE_EXPIRATION_Q) && (`${t('ui_spear.deviceDetails.in')} ${pcDetails.LIFECYCLE_EXPIRATION_Q}`)}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ComputerDetails;