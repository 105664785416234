export const handleSorting = (sortField, sortOrder, data, setData) => {
  if (sortField) {
    const sorted = [...data].sort((a, b) => {
      if (a[sortField] === null) return 1;
      if (b[sortField] === null) return -1;
      if (a[sortField] === null && b[sortField] === null) return 0;
      return (
        a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
          numeric: true
        }) * (sortOrder === 'asc' ? 1 : -1)
      );
    });
    setData(sorted);
  }
};

export const filterDataByPropertyValues = (data, property, values) => {
  // TODO: create validations for this function
  return data.filter((obj) => values.includes(obj[property]));
};

export const filterPersonListNames = (data) => {
  // TODO: create validations for this function
  return [...new Set(data.map((obj) => obj['person']))];
};
