import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import {
  TextField,
  Stack,
  Box,
  Autocomplete,
  Grid,
  Popper
} from '@mui/material';
import LoadingEllipsis from './LoadingEllipsis';
import axiosInstance from '../../../utils/axiosInstance';

const PREFIX = 'ServiceSearch';
const classes = {
  inputBar: `${PREFIX}-circularProgressIcon`,
  boschIcon: `${PREFIX}-boschIcon`
};

const StyledIconBox = styled(Box)({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  [`& .${classes.inputBar}`]: {
    padding: 0,
    marginRight: '10px'
  }
});

const StyledPopper = styled(Popper)({
  [`& .MuiAutocomplete-paper`]: {
    maxHeight: '12.3rem',
    overflowY: 'hidden'
  },
  '& .MuiAutocomplete-listbox': {
    overflowY: 'hidden'
  }
});

const StyledPopperSixOptions = styled(Popper)({
  [`& .MuiAutocomplete-paper`]: {
    maxHeight: '15rem',
    overflowY: 'hidden'
  },
  '& .MuiAutocomplete-listbox': {
    overflowY: 'hidden'
  }
});

const ServiceSearch = (props) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [optionsLenght, setOptionsLenght] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInputVal, setSearchInputVal] = useState('');
  const [mouseInListBox, setMouseInListBox] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event == 'enterClick') {
      if (props.searchTerm.trim() !== '') {
        // Waits for the user to stop typing and perform a search, to prevent multiple requests are being sent.
        props.setWasSearchOnEnter(true);
        const timer = setTimeout(() => {
          performServiceSearch();
        }, 1000);

        return () => clearTimeout(timer);
      }
    }
  };

  const handlePaperClose = (e) => {
    setOptions([]);
    setValue(null);
    setIsLoading(false);
    props.setSearchTerm('');
    setSearchInputVal('');
  };

  const handleInputClean = (e) => {
    setOptions([]);
    setOpen(false);
    setValue(null);
    props.setSearchTerm('');
    setSearchInputVal('');
  };

  const handlePaperOpen = () => {
    setOpen(true);
    setOptions([]);
  };

  const onInputChange = (event, value) => {
    const inputValue = value === 'undefined' ? event.target.value : value;
    props.handleInputChange(inputValue);
    setSearchInputVal(inputValue);
  };

  const performServiceSearch = async () => {
    let suggestionsArray = [];
    let encodedSearchTerm = encodeURIComponent(props.searchTerm);
    const serviceURL = `/api/v1/smt/services?searchTerm=${encodedSearchTerm}`;
    try {
      const response = await axiosInstance.get(serviceURL);
      if (response.data.length === 0) {
        props.setAlertEmptyResponse(true);
      }
      response.data.map((option) => {
        setOptionsLenght(response.data.length);
        if (!suggestionsArray.some(function keywordExists(e) { return e.CI_KEYWORD == option.CI_KEYWORD })) {
          suggestionsArray.push(option);
        }
      })
      setOptions(suggestionsArray);
      props.setSearchResponse(response.data);
      setIsLoading(true);
    } catch (error) {
      console.log(
        'Errow while retrieving services, please clean cache and try again.',
        error
      );
      props.setAlertErrorResponse(true);
    } finally {
      setIsLoading(false);
      props.setIsServiceSearchLoading(false);
    }
  };

  const handleSuggestionSelection = (optionSelected) => {
    props.setSearchResponse([optionSelected]);
    setOptions([optionSelected]);
    props.setWasSuggestionSelected(true);
  };

  const ClearInputIcon = (e) => {
    return (
      <span
        onClick={handleInputClean}
        className="bosch-icon-np mb-5 text-[1.5rem] cursor-pointer"
      >
        &#xe0f0;
      </span>
    );
  };

  const SearchInputIcon = (e) => {
    return (
      <span className="bosch-icon-np mb-3 text-[1.5rem] cursor-pointer">
        &#xe235;
      </span>
    );
  };

  const LoadingBanner = (e) => {
    const messageVal = t('ui_spear.createTicket.service_are_loading');
    const originTrigger = 'autosuggestions';
    return <LoadingEllipsis message={messageVal} trigger={originTrigger} />;
  };

  useEffect(() => {
    if (props.wasSearchOnEnter === true) {
      setOpen(false);
      setIsLoading(true);
      props.setIsServiceSearchLoading(true);
    }
  }, [props.wasSearchOnEnter]);

  useEffect(() => {
    if (props.searchTerm.trim() === '' && searchInputVal.trim() === '') {
      handlePaperClose();
    }
  }, [props.searchTerm, searchInputVal]);

  useEffect(() => {
    if (props.searchTerm.trim() !== '') {
      // Waits for the user to stop typing and perform a search, to prevent multiple requests are being sent.
      const timer = setTimeout(() => {
        performServiceSearch();
      }, 1000);
      setIsLoading(true);
      props.setIsServiceSearchLoading(true);
      props.setWasSuggestionSelected(false);
      props.setWasSearchOnEnter(false);

      return () => clearTimeout(timer);
    }
  }, [props.searchTerm]);

  return (
    <Stack spacing={2} sx={{ width: 500 }}>
      <Autocomplete
        freeSolo
        id="service-ticket-autocomplete"
        open={open}
        value={value}
        inputValue={searchInputVal}
        autoHighlight={true}
        options={options}
        sx={{
          '& .MuiAutocomplete-listbox': {
            overflow: 'hidden'
          }
        }}
        filterOptions={(options) => {
          let result = [];
          if (optionsLenght >= 2) {
            let newOptionsArray = [];
            newOptionsArray = options.slice(0, 5);
            newOptionsArray.push({ 'CI_NAME': `${t('ui_spear.createTicket.press_enter_to_see_more_services')}`, 'CI_KEYWORD': `${t('ui_spear.createTicket.press_enter_to_see_more_services')}` })
            result = newOptionsArray
          } else {
            result = [...options]
          }

          return result;
        }}
        getOptionLabel={(option) => option.CI_KEYWORD}
        onOpen={handlePaperOpen}
        onClose={handlePaperClose}
        loading={isLoading}
        loadingText={<>{LoadingBanner()}</>}
        PopperComponent={options.length >= 6 ? StyledPopperSixOptions : StyledPopper}
        ListboxProps={{
          onClick: () => setMouseInListBox(true),
          onMouseLeave: () => setMouseInListBox(false),
          sx: {
            '& li.Mui-focused': {
              backgroundColor: !mouseInListBox ? 'white' : undefined
            },
            "& .MuiAutocomplete-option[aria-selected='true']": {
              backgroundColor: '#fff'
            },
            "& .MuiAutocomplete-option[aria-selected='true'].Mui-focused": {
              backgroundColor: 'rgba(25, 118, 210, 0.12)'
            },
            '& .MuiAutocomplete-option.Mui-focused': {
              backgroundColor: 'rgba(25, 118, 210, 0.12)'
            },
            '&.MuiAutocomplete-listbox': {
              overflow: 'hidden'
            }
          }
        }}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onInputChange={(event, newValue) => {
          event && onInputChange(event, newValue);
        }}
        renderOption={(props, option, state) => {
          if (options.length > 0 && isLoading === false) {
            return (
              <Grid>
                <li {...props}>
                  <Grid
                    container
                    alignItems="center"
                    onClick={option.CI_KEYWORD == `${t('ui_spear.createTicket.press_enter_to_see_more_services')}` ? () => handleKeyDown('enterClick') : () => handleSuggestionSelection(option)}
                  >
                    <Grid item></Grid>
                    <Grid
                      item
                      sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
                    >
                      <Box component="span" className="uppercase" style={{ fontWeight: option.CI_KEYWORD == `${t('ui_spear.createTicket.press_enter_to_see_more_services')}` ? 'bold' : '' }}>
                        {option.CI_NAME}
                      </Box>
                    </Grid>
                  </Grid>
                </li>
              </Grid>
            );
          }

        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onKeyDown={handleKeyDown}
            autoFocus={false}
            placeholder={t('ui_spear.createTicket.service_search_by_ke')}
            variant="standard"
            sx={{
              '& .MuiPaper-root-MuiAutocomplete-paper': {
                maxHeight: '180px',
                overflowY: 'hidden'
              },
              '& .MuiInput-input': {
                marginLeft: '10px'
              },
              '& .MuiInput-root::after': {
                borderBottom: '2px solid #BBB',
                background: '#BBB',
                transition: 'none',
                transform: 'none'
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <StyledIconBox>{ClearInputIcon()}</StyledIconBox>
                </>
              ),
              startAdornment: (
                <>
                  <StyledIconBox>{SearchInputIcon()}</StyledIconBox>
                </>
              )
            }}
          />
        )}
      />
    </Stack>
  );
};

export default ServiceSearch;
