import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getInstalledSoftwareForSpecificDevice } from '../../../api/software';
import SoftwareTable from './SoftwareTable';
import SoftwareNotAvailable from './SoftwareNotAvailable';
import SoftwareLoader from '../../Confirmations/SoftwareLoader';
import BoschIcCashIcon from '../../../static/Bosch-IC-cash-frame.png';

const SoftwareContainer = (props) => {
  const { t } = useTranslation();
  const [installedSoftwareList, setInstalledSoftwareList] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('SWNAME');
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [paginationNumber, setPaginationNumber] = useState(0);
  const [searchNumber, setSearchNumber] = useState(0);
  const [loadingSoftware, setLoadingSoftware] = useState(true);
  const [alertErrorResponse, setAlertErrorResponse] = useState(false);
  const [originSoftwareFlag, setOriginSoftwareFlag] = useState(
    'deviceDetailSoftware'
  );

  useEffect(() => {
    getInstalledSoftware();
  }, [props?.deviceId]);

  useEffect(() => {
    if (installedSoftwareList?.length > 0) {
      const pagination =
        searchNumber % 19
          ? Math.floor(searchNumber / 19) + 1
          : searchNumber / 19;
      if (page > pagination && pagination > 0) {
        setPage(pagination);
      }
      setSearchNumber(installedSoftwareList?.length);
      setPaginationNumber(pagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installedSoftwareList, searchNumber]);

  const getInstalledSoftware = async () => {
    setLoadingSoftware(true);
    try {
      const response = await getInstalledSoftwareForSpecificDevice(
        props?.deviceId
      );
      if (response.length === 0 && Array.isArray(response)) {
        setInstalledSoftwareList(response);
      } else {
        setInstalledSoftwareList(response?.data?.data);
      }
    } catch (error) {
      setLoadingSoftware(false);
      setAlertErrorResponse(true);
      console.log(error, 'Error retrieving installed software for device');
    } finally {
      setLoadingSoftware(false);
    }
  };

  const handleCloseErrorModal = () => {
    setAlertErrorResponse(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleWhenSoftwareHasNumPrice = (cost) => {
    if (cost.includes('EUR')) {
      return cost;
    }
    return;
  };

  const handleSoftwareCost = (price) => {
    switch (price) {
      case 'Not-Available':
        return (
          <>
            <img
              src={BoschIcCashIcon}
              alt="price"
              style={{ height: '36px', width: '36px' }}
            />
          </>
        );
      case 'Free':
        return price;
      case handleWhenSoftwareHasNumPrice(price):
        return `${price}`;
      default:
        return 'Free';
    }
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const visibleRows = useMemo(
    () =>
      // Nullish coalescing operator handles case when API call fail's, sets empty array
      stableSort(
        installedSoftwareList ?? [],
        getComparator(order, orderBy)
      ).slice(0, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, installedSoftwareList]
  );

  return (
    <>
      {loadingSoftware ? (
        <SoftwareLoader />
      ) : installedSoftwareList?.length === 0 ? (
        <SoftwareNotAvailable originComponent={'softwareDeviceDetails'} />
      ) : (
        <SoftwareTable
          visibleRows={visibleRows}
          order={order}
          orderBy={orderBy}
          page={page}
          stableSort={stableSort}
          getComparator={getComparator}
          handleRequestSort={handleRequestSort}
          handleChangePage={handleChangePage}
          handleSoftwareCost={handleSoftwareCost}
          paginationNumber={paginationNumber}
          originSoftwareFlag={originSoftwareFlag}
        />
      )}
    </>
  );
};

export default SoftwareContainer;
