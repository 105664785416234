import React, { useEffect } from 'react';
import MobileDetails from './MobileDetails';
import MobileButtonsSection from './MobileButtonsSection';
import MobileTabsSection from './MobileTabsSection';
import MobileDetailsTab from './MobileDetailsTab';

const MobileDevices = (props) => {
    const { pcDetails, deviceType, userInfo } = props;
    const renderMobileDevices = () => {
        if (props.deviceBelongstoUser) {
            return (
                <>
                    <div>
                        <MobileDetails pcDetails={pcDetails} deviceType={deviceType} />
                        <MobileButtonsSection />
                        <MobileTabsSection />
                        <MobileDetailsTab userInfo={userInfo} />
                    </div>
                </>
            )
        } else {
            return (<></>)
        }
    }

    return (
        <>
            {renderMobileDevices()}
        </>
    );
};

export default MobileDevices;