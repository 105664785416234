import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import StepSchedule from './StepSchedule';
import ScheduleStep1 from './ScheduleStep1';
import ScheduleStep2 from './ScheduleStep2';
import ScheduleStep3 from './ScheduleStep3';
import axiosInstance from '../../../utils/axiosInstance';

const ScheduleCallback = (props) => {
  const [currentLocalDate, setCurrentLocalDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState('');
  const [localLanguage, setLocalLanguage] = useState(true);
  const [callbackPhone, setCallbackPhone] = useState('');
  const [scheduleStep, setScheduleStep] = useState(0);

  const handleClose = () => {
    props.setOpenScheduleCallback(false);
    setTimeout(() => {
      setScheduleStep(0);
    }, 1000);
    setSelectedSlot('');
    setCallbackPhone('');
    props.setCallbackChange(!props.callbackChange);
  };

  const makeGenesysScheduleCallback = () => {
    let emailType = 'scheduled';

    if (props.existingPrimaryCallback || props.existingSecondaryCallback) {
      //we cancel the previous callback if any
      emailType = 'rescheduled';
      triggerCancel();
    }

    let currentTimeMs = currentLocalDate.getTime();
    let generatedCallbackId =
      props.userInfo.userNTID + props.ticketId + currentTimeMs;
    let datetimeObject = getDatetimeObjectInUTC();
    let selectedTimeslotToFormatedString =
      datetimeObject['year'] +
      '-' +
      datetimeObject['month'] +
      '-' +
      datetimeObject['day'] +
      'T' +
      datetimeObject['hours'] +
      ':00:00.000Z';

    let urlForCti =
      '<a href="https://rb-smt.de.bosch.com/arsys/forms/smt-backend/' +
      'BOSCH%3ACTI/ITSD_Call/?F820001029=' +
      props.userInfo.userNTID +
      '&F777100010=' +
      generatedCallbackId +
      '&F810070101=' +
      props.userLocation +
      '" title="CTI Link" />';

    let data = {
      _customer_number: callbackPhone,
      ticketid: props.ticketId,
      userid: props.userInfo.userNTID,
      _desired_time: selectedTimeslotToFormatedString,
      popup_url: '', //urlForPopup,
      cti_url: urlForCti
    };

    let selectedLanguageCode = props.callbackPrimaryLanguage.languageCode;
    let submitURL = props.callbackPrimaryLanguage.scheduleEndpoint;
    if (!localLanguage) {
      submitURL = props.callbackSecondaryLanguage.scheduleEndpoint;
      selectedLanguageCode = props.callbackSecondaryLanguage.languageCode;
    }

    let scheduleUrl = `/api/v1/genesys/support/callback`;
    axiosInstance
      .post(scheduleUrl, { data: data, url: submitURL })
      .then((response) => {
        setScheduleStep(2);
        let emailURL = `/api/v1/snow/support/notification`;
        let emailDate = new Date(selectedSlot.selectedDate);
        let monthZero = '0' + (emailDate.getUTCMonth() + 1);
        monthZero = monthZero.slice(-2);
        let dayZero = '0' + emailDate.getDate();
        dayZero = dayZero.slice(-2);
        let hourZero = '0' + selectedSlot.hour;
        hourZero = hourZero.slice(-2);
        let emailObject = {
          contact: callbackPhone,
          ntid: props.userNTID,
          lang: selectedLanguageCode,
          type: emailType,
          ticketId: props.ticketId,
          ticketName: props.serviceName,
          dateTime:
            emailDate.getFullYear() +
            '-' +
            monthZero +
            '-' +
            dayZero +
            ' ' +
            hourZero +
            ':00:00'
        };

        axiosInstance
          .post(emailURL, emailObject)
          .then((response) => {})
          .catch((error) => {
            //HANDLE ERROR
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const triggerCancel = () => {
    const submitURL = `/api/v1/genesys/support/unschedule`;

    if (props.existingPrimaryCallback) {
      axiosInstance
        .post(submitURL, {
          url: props.callbackPrimaryLanguage.scheduleEndpoint,
          callbackId: props.existingPrimaryCallback._id
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    if (props.existingSecondaryCallback) {
      axiosInstance
        .post(submitURL, {
          url: props.callbackSecondaryLanguage.scheduleEndpoint,
          callbackId: props.existingSecondaryCallback._id
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getDatetimeObjectInUTC = () => {
    let slotDate = new Date(selectedSlot.selectedDate);
    slotDate.setHours(selectedSlot.hour, 0, 0);
    let datetimeObject = {};
    datetimeObject['year'] = slotDate.getUTCFullYear();
    datetimeObject['month'] = ('0' + (slotDate.getUTCMonth() + 1)).slice(-2);
    datetimeObject['day'] = ('0' + slotDate.getUTCDate()).slice(-2);
    datetimeObject['hours'] = ('0' + slotDate.getUTCHours()).slice(-2);
    return datetimeObject;
  };

  return (
    <>
      <Dialog
        open={props.openScheduleCallback}
        fullWidth={true}
        maxWidth={'sm'}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <StepSchedule scheduleStep={scheduleStep}></StepSchedule>

        {scheduleStep === 0 && (
          <ScheduleStep1
            handleClose={handleClose}
            currentLocalDate={currentLocalDate}
            setSelectedSlot={setSelectedSlot}
            selectedSlot={selectedSlot}
            setScheduleStep={setScheduleStep}
            callbackPrimaryLanguage={props.callbackPrimaryLanguage}
            callbackSecondaryLanguage={props.callbackSecondaryLanguage}
            localLanguage={localLanguage}
            setLocalLanguage={setLocalLanguage}
          ></ScheduleStep1>
        )}
        {scheduleStep === 1 && (
          <ScheduleStep2
            setScheduleStep={setScheduleStep}
            setCallbackPhone={setCallbackPhone}
            userAvailablePhones={props.userAvailablePhones}
            callbackPhone={callbackPhone}
            callbackPrimaryLanguage={props.callbackPrimaryLanguage}
            callbackSecondaryLanguage={props.callbackSecondaryLanguage}
            localLanguage={localLanguage}
            selectedSlot={selectedSlot}
            makeGenesysScheduleCallback={makeGenesysScheduleCallback}
            existingPrimaryCallback={props.existingPrimaryCallback}
            existingSecondaryCallback={props.existingSecondaryCallback}
          ></ScheduleStep2>
        )}
        {scheduleStep === 2 && (
          <ScheduleStep3
            handleClose={handleClose}
            selectedSlot={selectedSlot}
            existingPrimaryCallback={props.existingPrimaryCallback}
            existingSecondaryCallback={props.existingSecondaryCallback}
          ></ScheduleStep3>
        )}
      </Dialog>
    </>
  );
};

export default ScheduleCallback;
