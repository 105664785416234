import React from 'react'
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { ClickAwayListener } from '@mui/base';

const BannerInfo = (props) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const openPopover = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
    <>
        <div className='flex flex-row'>
            <button className="ml-2 -mt-[.100rem]" onClick={handlePopoverOpen}>
            <span
                style={{
                    fontFamily: 'bosch-icon',
                    fontWeight: 500,
                    color: '#005691',
                    fontSize: '1.2rem'
                }}
            >
                &#xe1ac;
            </span>
            </button>
            {openPopover && (
            <ClickAwayListener onClickAway={handlePopoverClose}>
                <Paper
                onClose={handlePopoverClose}
                sx={{ width: '25rem', height: '8rem', zIndex: 30, marginBottom: '-7rem' }}
                className='overflow-x-scroll '
                >
                <Grid className="grid" sx={{ p: 2 }}>
                    <p className=' flex justify-between'>
                    {props.messageString}
                    <span
                        onClick={handlePopoverClose}
                        className="justify-end bosch-icon-np mt-0 mr-6 text-md cursor-pointer"
                    >
                        &#xe0f0;
                    </span>
                    </p>
                </Grid>
                </Paper>
            </ClickAwayListener>
            )}
        </div>
    </>
    )
}



export default BannerInfo