import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  Typography
} from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import PersonSearchField from './PersonSearchField';

const StyledTInpuLabel = styled(InputLabel)({
  border: '.1rem solid #bdc0c4',
  padding: '4px'
});

const StyledButton = styled(Button)({
  textTransform: 'none',
  color: '#2e2e2e',
  backgroundColor: '#ffffff !important',
  border: '.1rem solid #939393'
});

const StyledSubmitButton = styled(Button, {
  shouldForwardProp: (props) => props != 'disabled'
})(({ disabled }) => ({
  textTransform: 'none',
  marginLeft: '5px',
  color: disabled ? '#939393' : '#ffffff',
  backgroundColor: disabled ? '#ffffff !important' : '#005691 !important',
  border: '.1rem solid #939393'
}));

const PersonPicker = (props) => {
  const { t } = useTranslation();
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [tmpUser, setTmpUser] = useState([]);

  const handleUser = () => {
    props.setUserInfoSuppTicket(tmpUser);
    props.handleCloseDetailedTicketDialog(true);
  };
  return (
    <Fragment>
      <Dialog
        open={props.openPersonPicker}
        onClose={() => {
          props.handleCloseDetailedTicketDialog();
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '50rem',
            overflow: 'hidden'
          }
        }}
        maxWidth="lg"
      >
        <Grid container>
          <DialogTitle className="font-Bosch_sans_bold">
            {t('ui_spear.createTicket.select_customer')}
          </DialogTitle>
          <IconButton
            className="bosch-icon"
            onClick={props.handleCloseDetailedTicketDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            &#xe0f0;
          </IconButton>
        </Grid>
        <hr></hr>
        <DialogContent>
          <Typography className="font-Bosch_sans">
            {t('ui_spear.createTicket.request_for')}
          </Typography>
          <StyledTInpuLabel className="font-Bosch_sans_light">
            {t('ui_spear.createTicket.Search_can_be_performed')}
          </StyledTInpuLabel>
          <PersonSearchField
            tmpUser={tmpUser}
            setTmpUser={setTmpUser}
            setDisabledSubmit={setDisabledSubmit}
          ></PersonSearchField>
        </DialogContent>
        <hr></hr>
        <DialogActions>
          <StyledButton
            className="font-Bosch_sans_light"
            onClick={() => props.handleCloseDetailedTicketDialog()}
          >
            {t('ui_spear.createTicket.cancel')}
          </StyledButton>
          <StyledSubmitButton
            className="font-Bosch_sans_light"
            disabled={disabledSubmit}
            onClick={() => handleUser()}
          >
            {t('ui_spear.createTicket.select_person')}
          </StyledSubmitButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default PersonPicker;
