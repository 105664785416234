import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './kms-styles.css';
import './styles/fonts/Bosch-Icon/style.css';


import App from './App';
import { StyledEngineProvider } from '@mui/styled-engine';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import './i18n';

import Loader from './components/Confirmations/Loader';

const app = document.getElementById('root');
const root = ReactDOM.createRoot(app);

function tutorialTooltip() {
  let urlParams = new URLSearchParams(window.location.search);
  const item = localStorage.getItem("spear_load_counter")
  if (item === undefined) {
    localStorage.setItem('spear_load_counter', '0');
  } else {
    if (
      !urlParams.has("article")
    ) {
      localStorage.setItem("spear_load_counter", +item + 1)
    }
  }
}

const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: app
      }
    },
    MuiPopper: {
      defaultProps: {
        container: app
      }
    },
    MuiDialog: {
      defaultProps: {
        container: app
      }
    }
  }
});

tutorialTooltip();

root.render(
  <React.StrictMode>
    <Suspense fallback={<Loader customStyle="inset-x-1/2 inset-y-1/2" />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  </React.StrictMode>
);
