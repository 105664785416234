import axiosInstance from '../utils/axiosInstance';

export const getInstalledSoftwareForSpecificDevice = async (UUID, installed) => {

    let url = `/api/v1/cms/software/installed/${UUID}`;
    if (installed === false) {
        url = `/api/v1/cms/software/available/${UUID}`;
    }

    try {
        const response = await axiosInstance.get(url);
        if (response.status === 200) {
            return response;
        } else {
            console.error('Software installed & available software for device not found:');
            return [];
        }
    } catch (error) {
        console.error('Error fetching installed & available software for device:', error);
        return [];
    }
};