import React, { useState, useEffect } from 'react';
import sadArticle from '../../static/sad-article-small.png';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@mui/material';
import GeneralFeedbackModal from '../Feedback/GeneralFeedbackModal';

function RedefineSearch(props) {
  const { t } = useTranslation();
  const [targetUserAlias, setTargetUserAlias] = useState('');
  const sadMessage = t('ui_spear.search.Sorry_it_was_the_last_result');
  const sadAdvice = t('ui_spear.search.Share_your_feedback_so_we_may_improve');
  const showResultsFeedback = String(
    process.env.REACT_APP_SHOW_RESULTS_FEEDBACK
  );

  useEffect(() => {
    props.targetUserAlias
      ? setTargetUserAlias(props.targetUserAlias)
      : setTargetUserAlias('');
  }, [props.targetUserAlias]);

  async function redefineSearch() {

    await props.highlightSearchBar();
    window.searchBarComponent.redefineDropDownAS();
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <div className="w-[105px] m-10 p-2.5">
        <img src={sadArticle} alt="sad-article"></img>
      </div>

      <div className="w-[435px] text-black p-2 text-left">
        <h5 className="font-Bosch_sans text-[16px] mb-4 font-normal">
          {sadMessage}
        </h5>
        <p className="font-BoschSans-Light text-[14px] font-extralight mb-2.5">
          {sadAdvice}
        </p>
        {showResultsFeedback === 'true' && (
          <GeneralFeedbackModal
            userInfo={props.userInfo}
            searchedQuery={props.searchedQuery}
            generalFeedbackLoad={props.generalFeedbackLoad}
            setGeneralFeedbackLoad={props.setGeneralFeedbackLoad} />
        )}
        <Button
          className="h-11 rounded-none border-solid border border-change_cust_btn bg-white text-[12px] text-change_cust_btn normal-case hover:text-blue_hover_btns hover:border-blue_hover_btns mx-1.5"
          onClick={() => redefineSearch()}
        >
          <Typography className=" text-[12px] flex content-center">
            <span className="boschicon-bosch-ic-rotation text-[16px] mx-1"></span>
            {t('ui_spear.search.Button.Redefine_search')}
          </Typography>
        </Button>
      </div>
    </Grid>
  );
}

export default RedefineSearch;
