import React from 'react'
import { useTranslation } from 'react-i18next';


const TabsSection = ({ toggleComputerTab, setToggleComputerTab }) => {
  const { t } = useTranslation();

  return (
    <nav className='bg-gray-200 font-Bosch_sans_light'>
      <ul className="px-10 pt-4 flex flex-row justify-center font-light text-base">
        <li
          className={` list-none pb-3.5 px-7 hover:text-ITSP_blue hover:no-underline ${toggleComputerTab
            ? 'cursor-pointer border-b-2 border-ITSP_blue text-ITSP_blue'
            : 'cursor-pointer border-b-2 border-transparent'
            }`}
          onClick={() => setToggleComputerTab(true)}
        >
          {t('ui_spear.ticketDetails.Details')}
        </li>
        <li
          className={`list-none pb-3.5 px-7 hover:text-ITSP_blue hover:no-underline ${!toggleComputerTab
            ? 'cursor-pointer border-b-2 border-ITSP_blue text-ITSP_blue'
            : 'cursor-pointer border-b-2 border-transparent'
            }`}
          onClick={() => setToggleComputerTab(false)}
        >
          {t('ui_spear.deviceDetails.software_tab')}
        </li>
      </ul>
      <hr />
    </nav>
  )
}

export default TabsSection