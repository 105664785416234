import { Fade, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { TooltipContext } from '../../context/TooltipContext';
import TooltipSearchBar from '../TooltipSearchBar/TooltipSearchBar';

export default function ButtonsSearchBar({ children, show }) {
  const supportBarRef = useRef(null);
  const [isShowBtnSupportBar, setIsShowBtnSupportBar] = useState(show);
  const [isShowSupportBar, setIsShowSuportBar] = useState(false);

  function handleClick() {
    setIsShowSuportBar((prev) => !prev);
    setIsShowBtnSupportBar((prev) => !prev);
  }

  useEffect(() => {
    setIsShowBtnSupportBar(show);
    if (isShowSupportBar) {
      setIsShowBtnSupportBar(false);
    }
    if (!show) {
      setIsShowSuportBar(false);
    }
  }, [show]);

  function checkPositionBanner() {
    const contactBanner = document.querySelector('.contact-banner');
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 0.25, 0.5, 0.75, 1]
    };

    function handleIsertion(entries, observer) {
      entries.forEach((element) => {
        if (element.isIntersecting === true) {
          setIsShowBtnSupportBar(false);
        } else if (element.isIntersecting === false) {
          setIsShowBtnSupportBar(true);
        }
      });
    }

    const observer = new IntersectionObserver(handleIsertion, options);
    observer.observe(contactBanner);
  }

  // Detect click outside the menu and close it
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        supportBarRef.current &&
        !supportBarRef.current.contains(event.target)
      ) {
        setIsShowSuportBar(false);
        checkPositionBanner();
      }
    }
    document.addEventListener('click', (e) => handleClickOutside(e));
    return () => {
      document.removeEventListener('click', (e) => handleClickOutside(e));
    };
  }, []);

  const [tooltipCount] = useContext(TooltipContext);

  return (
    <div ref={supportBarRef}>
      {tooltipCount === 4 ? (
        <div
          className={`${!isShowBtnSupportBar && 'hidden'
            } fixed bottom-[20px] right-[20px] z-5`}
        >
          {
            tooltipCount === 4 ? (
              <div className={`${!isShowBtnSupportBar && "hidden"} fixed bottom-[20px] right-[20px] z-5`}>
                <TooltipSearchBar
                  open={tooltipCount === 4}
                  index={4}
                  handleNextTipButton={() => { }}
                  closeTooltip={() => { }}
                >
                  <div>
                    <button
                      className="boschicon-bosch-ic-service-agent text text-[48px]  text-white h-[64px] w-[64px] rounded-[50%] border-2 border-blue_border_btn hover:border-[#7FC6E7] bg-[#005691]"
                      onClick={handleClick}
                      disabled={true}
                    ></button>
                  </div>
                </TooltipSearchBar>
              </div>
            ) : (
              <Fade in={isShowBtnSupportBar} timeout={{ enter: 1500 }} style={{ display: isShowBtnSupportBar ? 'block' : 'none' }}>
                <div className='fixed bottom-[20px] right-[20px]'>
                  <button className='boschicon-bosch-ic-service-agent text text-[48px]  text-white h-[64px] w-[64px] rounded-[50%] border-2 border-blue_border_btn hover:border-[#7FC6E7] bg-[#005691]' onClick={handleClick}></button>
                </div>
              </Fade>
            )
          }
          <section className={`fixed bottom-0 w-full flex items-center h-[80px] bg-gradient-to-r from-support_banner_color_1 to-support_banner_color_2 justify-center ${!isShowSupportBar && "hidden"}`}
          >
            <Stack direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={0}
            >
              <article className="">
                {
                  React.cloneElement(children, { className: "h-[80px] from-transparent to-transparent [&>.support-icon-image]:text-[48px] [&>.support-icon-image]:mt-0 [&>.time-for-call]:text-[13px] [&>.time-for-call]:mr-[25px] [&_.btn-search-bar]:h-[40px] [&_.wrapper-btn]:mr-[15px] [&_.contact-it-label]:mr-40" })
                }
              </article>
              <article className="flex justify-center items-center">
                <button className="boschicon-bosch-ic-close text-white text-[20px] hover:opacity-70" onClick={handleClick}></button>
              </article>
            </Stack>
          </section>
        </div >
      ) : (
        <Fade in={isShowBtnSupportBar} style={{ display: isShowBtnSupportBar ? 'block' : 'none' }} timeout={{ enter: 1500 }}>
          <div className="fixed bottom-[20px] right-[20px]">
            <button
              className="boschicon-bosch-ic-service-agent text text-[48px]  text-white h-[64px] w-[64px] rounded-[50%] border-2 border-blue_border_btn hover:border-[#7FC6E7] bg-[#005691]"
              onClick={handleClick}
            ></button>
          </div>
        </Fade>
      )
      }
      <section
        className={`fixed bottom-0 w-full flex items-center h-[80px] bg-gradient-to-r from-support_banner_color_1 to-support_banner_color_2 justify-center ${!isShowSupportBar && 'hidden'
          }`}
      >
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={0}
        >
          <article className="">
            {React.cloneElement(children, {
              className:
                'h-[80px] from-transparent to-transparent [&>.support-icon-image]:text-[48px] [&>.support-icon-image]:mt-0 [&>.time-for-call]:text-[13px] [&>.time-for-call]:mr-[25px] [&_.btn-search-bar]:h-[40px] [&_.wrapper-btn]:mr-[15px] [&_.contact-it-label]:mr-40'
            })}
          </article>
          <article className="flex justify-center items-center">
            <button
              className="boschicon-bosch-ic-close text-white text-[20px] hover:opacity-70"
              onClick={handleClick}
            ></button>
          </article>
        </Stack>
      </section>
    </div >
  );
}
