import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Hours from './Hours';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const SlotSelector = (props) => {
  const { t } = useTranslation();

  // Translations
  const strMonday = t('ui_spear.support.schedulle_callback_monday');
  const strTuesday = t('ui_spear.support.schedulle_callback_tuesday');
  const strWednesday = t('ui_spear.support.schedulle_callback_wednesday');
  const strThursday = t('ui_spear.support.schedulle_callback_thursday');
  const strFriday = t('ui_spear.support.schedulle_callback_friday');
  const strToday = t('ui_spear.support.schedulle_callback_today');
  const strTomorrow = t('ui_spear.support.schedulle_callback_tomorrow');

  const [selectedDay, setSelectedDay] = useState(0);
  const [day1, setDay1] = useState(new Date());
  const [day2, setDay2] = useState(new Date());
  const [day3, setDay3] = useState(new Date());
  const [day4, setDay4] = useState(new Date());
  const [dayTags, setDayTags] = useState([
    'Today',
    'Tomorrow',
    'Day 3',
    'Day 4'
  ]);
  const [slotsDay1, setSlotsDay1] = useState([]);
  const [slotsDay2, setSlotsDay2] = useState([]);
  const [slotsDay3, setSlotsDay3] = useState([]);
  const [slotsDay4, setSlotsDay4] = useState([]);

  const weekTag = [
    { daySlot: 1, label: strMonday },
    { daySlot: 2, label: strTuesday },
    { daySlot: 3, label: strWednesday },
    { daySlot: 4, label: strThursday },
    { daySlot: 5, label: strFriday }
  ];

  const monthTag = [
    { monthSlot: 1, label: 'Jan' },
    { monthSlot: 2, label: 'Feb' },
    { monthSlot: 3, label: 'Mar' },
    { monthSlot: 4, label: 'Apr' },
    { monthSlot: 5, label: 'May' },
    { monthSlot: 6, label: 'Jun' },
    { monthSlot: 7, label: 'Jul' },
    { monthSlot: 8, label: 'Aug' },
    { monthSlot: 9, label: 'Sep' },
    { monthSlot: 10, label: 'Oct' },
    { monthSlot: 11, label: 'Nov' },
    { monthSlot: 12, label: 'Dec' }
  ];

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  //Set Day tag on tabs
  useEffect(() => {
    //let sawppy = props.currentLocalDate;
    //sawppy = addDays(sawppy,6);
    setDay1(props.currentLocalDate);
    let swapDay2 = props.currentLocalDate;
    let swapDay3 = props.currentLocalDate;
    let swapDay4 = props.currentLocalDate;

    let tagDay2 = strTomorrow;
    let tagDay3 = 'Day 3';
    let tagDay4 = 'Day 4';

    if (
      props.currentLocalDate.getDay() > 0 &&
      props.currentLocalDate.getDay() < 3
    ) {
      //Today is Monday or Tuesday
      setDay2(addDays(swapDay2, 1));
      swapDay3 = addDays(swapDay3, 2);
      swapDay4 = addDays(swapDay4, 3);
    } else if (props.currentLocalDate.getDay() === 3) {
      //Today is Wednesday
      setDay2(addDays(swapDay2, 1));
      swapDay3 = addDays(swapDay3, 2);
      swapDay4 = addDays(swapDay4, 5);
    } else if (props.currentLocalDate.getDay() === 4) {
      //Today is Thursday
      setDay2(addDays(swapDay2, 1));
      swapDay3 = addDays(swapDay3, 4);
      swapDay4 = addDays(swapDay4, 5);
    } else {
      if (props.currentLocalDate.getDay() === 0) {
        //Today is Sunday
        swapDay2 = addDays(swapDay2, 1);
        swapDay3 = addDays(swapDay3, 2);
        swapDay4 = addDays(swapDay4, 3);
      } else if (props.currentLocalDate.getDay() === 6) {
        //Today is Saturday
        swapDay2 = addDays(swapDay2, 2);
        swapDay3 = addDays(swapDay3, 3);
        swapDay4 = addDays(swapDay4, 4);
      } else {
        //Today is Friday
        swapDay2 = addDays(swapDay2, 3);
        swapDay3 = addDays(swapDay3, 4);
        swapDay4 = addDays(swapDay4, 5);
      }
      setDay2(swapDay2);
      tagDay2 =
        weekTag[swapDay2.getDay() - 1].label +
        ', ' +
        swapDay2.getDate() +
        ' ' +
        monthTag[swapDay2.getMonth()].label;
    }
    tagDay3 =
      weekTag[swapDay3.getDay() - 1].label +
      ', ' +
      swapDay3.getDate() +
      ' ' +
      monthTag[swapDay3.getMonth()].label;
    tagDay4 =
      weekTag[swapDay4.getDay() - 1].label +
      ', ' +
      swapDay4.getDate() +
      ' ' +
      monthTag[swapDay4.getMonth()].label;
    setDay3(swapDay3);
    setDay4(swapDay4);
    setDayTags([strToday, tagDay2, tagDay3, tagDay4]);
  }, [props.currentLocalDate]);

  useEffect(() => {
    let tempSlotsDay1 = [];
    let tempSlotsDay2 = [];
    let tempSlotsDay3 = [];
    let tempSlotsDay4 = [];
    props.availableSlots.map((slot) => {
      switch (slot.getDate()) {
        case day1.getDate():
          tempSlotsDay1.push(slot);
          break;
        case day2.getDate():
          tempSlotsDay2.push(slot);
          break;
        case day3.getDate():
          tempSlotsDay3.push(slot);
          break;
        case day4.getDate():
          tempSlotsDay4.push(slot);
          break;
        default:
          break;
      }
    });

    setSlotsDay1(tempSlotsDay1);
    setSlotsDay2(tempSlotsDay2);
    setSlotsDay3(tempSlotsDay3);
    setSlotsDay4(tempSlotsDay4);
  }, [props.availableSlots]);

  const setSelectedDateSlot = (
    selectedDayValue,
    selectedHourValue,
    selectedHourLabel
  ) => {
    let selectedSlotObject = {
      selectedDayTab: selectedDayValue,
      selectedDate: '',
      selectedDateTag: '',
      hour: selectedHourValue,
      selectedHourLabel: selectedHourLabel
    };

    switch (selectedDayValue) {
      case 0:
        selectedSlotObject.selectedDate = day1;
        selectedSlotObject.selectedDateTag = dayTags[0];
        break;
      case 1:
        selectedSlotObject.selectedDate = day2;
        selectedSlotObject.selectedDateTag = dayTags[1];
        break;
      case 2:
        selectedSlotObject.selectedDate = day3;
        selectedSlotObject.selectedDateTag = dayTags[2];
        break;
      case 3:
        selectedSlotObject.selectedDate = day4;
        selectedSlotObject.selectedDateTag = dayTags[3];
        break;
      default:
        break;
    }

    props.setSelectedSlot(selectedSlotObject);
  };

  useEffect(() => {
    setSelectedDay(0);
  }, [slotsDay1, slotsDay2, slotsDay3, slotsDay4]);

  const handleChange = (event, newValue) => {
    setSelectedDay(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="mt-3">
        <Tabs
          value={selectedDay}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
        >
          <Tab
            className="normal-case font-Bosch_sans"
            label={dayTags[0]}
            {...a11yProps(0)}
          />
          <Tab
            className="normal-case font-Bosch_sans"
            label={dayTags[1]}
            {...a11yProps(1)}
          />
          <Tab
            className="normal-case font-Bosch_sans"
            label={dayTags[2]}
            {...a11yProps(2)}
          />
          <Tab
            className="normal-case font-Bosch_sans"
            label={dayTags[3]}
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>

      <TabPanel value={selectedDay} index={0}>
        <Hours
          handleClose={props.handleClose}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
          slotsDay={slotsDay1}
          setSelectedDateSlot={setSelectedDateSlot}
          selectedSlot={props.selectedSlot}
          setScheduleStep={props.setScheduleStep}
        ></Hours>
      </TabPanel>
      <TabPanel value={selectedDay} index={1}>
        <Hours
          handleClose={props.handleClose}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
          slotsDay={slotsDay2}
          setSelectedDateSlot={setSelectedDateSlot}
          selectedSlot={props.selectedSlot}
          setScheduleStep={props.setScheduleStep}
        ></Hours>
      </TabPanel>
      <TabPanel value={selectedDay} index={2}>
        <Hours
          handleClose={props.handleClose}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
          slotsDay={slotsDay3}
          setSelectedDateSlot={setSelectedDateSlot}
          selectedSlot={props.selectedSlot}
          setScheduleStep={props.setScheduleStep}
        ></Hours>
      </TabPanel>
      <TabPanel value={selectedDay} index={3}>
        <Hours
          handleClose={props.handleClose}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
          slotsDay={slotsDay4}
          setSelectedDateSlot={setSelectedDateSlot}
          selectedSlot={props.selectedSlot}
          setScheduleStep={props.setScheduleStep}
        ></Hours>
      </TabPanel>
    </>
  );
};

export default SlotSelector;
