import React from 'react';
import { Outlet } from 'react-router-dom';
import NotEntitlePage from '../NotEntitle/NotEntitlePage';

export default function PrivateRoute({ isEntitle }) {
  if (isEntitle === null)
    return <div className="bg-gray-200 opacity-10 h-full w-full fixed" />;

  return isEntitle ? <Outlet /> : <NotEntitlePage />;
}
