import { Link } from 'react-router-dom';
import DismissedBtn from './DismissedBtn';

function AnnouncementBanner({ bannerId, userNtid, title, description, detailsLinkText, clickTarget, detailsPage, detailsUrl, dismissOptions, setAnnouncementsArray, setIsExpandAll }) {

    const handleClick = (event) => {
        if (event.target.tagName !== 'BUTTON') {
            setIsExpandAll((prev) => !prev);
        }
    };

    const getAnnouncementLink = (clickTarget) => {
        switch (clickTarget) {
            case 'page':
                return `${process.env.REACT_APP_SNOW_SP_URL}?id=${detailsPage}`;
            case 'urlCurrent':
                return `${process.env.REACT_APP_SNOW_SP_URL}?id=${detailsUrl.replace("sp?id=", "")}`;
            case 'urlNew':
                return detailsUrl;
            default:
                return `${process.env.REACT_APP_SNOW_SP_URL}`;
        }
    }

    return (
        <div className='w-full  flex' onClick={handleClick}>
            <div className='grow py-2 flex-row my-2'>
                <div className='flex'>
                    <h4 className='font-bold text-xl ml-[2.3rem]'>{title}</h4>
                </div>
                <p className='pl-9 text-lg'>{description}</p>
            </div>
            <div className='min-w-max  pl-[2rem] pr-[4.8rem] px-[1.2rem] relative flex items-center justify-end'>
                {detailsLinkText &&
                    <Link to={getAnnouncementLink(clickTarget)}>
                        <div className="tooltip tooltip-bottom" data-tip={detailsLinkText}>
                            <button className='bg-transparent p-1 m-2 max-w-[30ch] rounded-md border border-slate-100 text-white text-center text-lg'>
                                {detailsLinkText}
                            </button>
                        </div>
                    </Link>
                }
                {dismissOptions !== 'not_dismissible' &&
                    <DismissedBtn
                        bannerId={bannerId}
                        userNtid={userNtid}
                        title={title}
                        setAnnouncementsArray={setAnnouncementsArray}
                    />
                }
            </div>
        </div>
    )
}

export default AnnouncementBanner
