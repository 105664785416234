import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ScheduleStep3 = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle className="items-center px-[50px] pt-8">
        <Grid item xs={12} align="center">
          <Typography
            variant="caption"
            className="font-Bosch_sans_light text-[26px]"
          >
            <span className="support-icon-image text-4xl bg-green_confirmation text-white rounded-full mr-4 top-1 relative">
              &#xe0e9;
            </span>
            {props.existingPrimaryCallback ||
            props.existingSecondaryCallback ? (
              <>{t('ui_spear.support.schedulle_callback_rescheduled')}</>
            ) : (
              <>{t('ui_spear.support.schedulle_callback_callback_scheduled')}</>
            )}
          </Typography>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Typography className="font-Bosch_sans_light text-base mt-7 text-center">
          {t(
            'ui_spear.support.schedulle_callback_ITSD_first_level_will_call_you'
          )}{' '}
          <strong>
            {props.selectedSlot.selectedDateTag},{' '}
            {t('ui_spear.support.schedulle_callback_between')}{' '}
            {props.selectedSlot.selectedHourLabel}.{' '}
            {t(
              'ui_spear.support.schedulle_callback_we_sent_invitation_outlook_book_slot'
            )}
          </strong>{' '}
          {t(
            'ui_spear.support.schedulle_callback_add_your_calendar_to_have_reminder'
          )}
        </Typography>

        <Grid
          container
          spacing={2}
          className="mt-7"
          xs={12}
          justifyContent="center"
        >
          <Grid item>
            <Button
              className="m-auto bg-ITSP_blue h-10 w-auto text-white flex items-center normal-case font-Bosch_sans rounded-none text-xl p-7"
              onClick={() => {
                props.handleClose();
              }}
            >
              {t('ui_spear.support.schedulle_callback_close')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default ScheduleStep3;
