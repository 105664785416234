import React, { useState, useEffect } from 'react';
import { Button } from '@mui/base';
import { Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ScheduleCallback from './ScheduleCallback/ScheduleCallback';
import CancelCallback from './CancelCallback/CancelCallback';
import TelephonyList from './TelephonyList/TelephonyList';
import TelephonyCallBanner from './TelephonyList/TelephonyCallBanner'
import { getGenesysLanguageString } from './helpers/LanguageFromGenesys';
import axiosInstance from '../../utils/axiosInstance';

const SupportBar = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isSupportTicketURL, setIsSupportTicketURL] = useState(false);
  const [openScheduleCallback, setOpenScheduleCallback] = useState(false);
  const [openCancelCallback, setOpenCancelCallback] = useState(false);
  const [openTelephonyList, setOpenTelephonyList] = useState(false);
  const [userHasTelephony, setUserHasTelephony] = useState(false);
  const [openTelephonyModal, setOpenTelephonyModal] = useState(false);
  const [userLocation, setUserLocation] = useState('');
  const [callbackPrimaryLanguage, setCallbackPrimaryLanguage] = useState();
  const [callbackSecondaryLanguage, setCallbackSecondaryLanguage] = useState();
  const [existingPrimaryCallback, setExistingPrimaryCallback] = useState('');
  const [existingSecondaryCallback, setExistingSecondaryCallback] =
    useState('');
  const [existingDesiredDate, setExistingDesiredDate] = useState({
    dateObject: '',
    dayTag: '',
    hourTag: ''
  });
  const [existingDesiredLang, setExistingDesiredLang] = useState('');
  const [callbackChange, setCallbackChange] = useState(false);
  const [genesysWaitingTime, setGenesysWaitingTime] = useState(0);
  const [userAvailablePhones, setUserAvailablePhones] = useState([]);

  const monthTag = [
    { monthSlot: 1, label: 'Jan' },
    { monthSlot: 2, label: 'Feb' },
    { monthSlot: 3, label: 'Mar' },
    { monthSlot: 4, label: 'Apr' },
    { monthSlot: 5, label: 'May' },
    { monthSlot: 6, label: 'Jun' },
    { monthSlot: 7, label: 'Jul' },
    { monthSlot: 8, label: 'Aug' },
    { monthSlot: 9, label: 'Sep' },
    { monthSlot: 10, label: 'Oct' },
    { monthSlot: 11, label: 'Nov' },
    { monthSlot: 12, label: 'Dec' }
  ];

  const timeslots = [
    { hour: 0, label: '12am - 1am', active: false },
    { hour: 1, label: '1am - 2am', active: false },
    { hour: 2, label: '2am - 3am', active: false },
    { hour: 3, label: '3am - 4am', active: false },
    { hour: 4, label: '4am - 5am', active: false },
    { hour: 5, label: '5am - 6am', active: false },
    { hour: 6, label: '6am - 7am', active: false },
    { hour: 7, label: '7am - 8am', active: false },
    { hour: 8, label: '8am - 9am', active: false },
    { hour: 9, label: '9am - 10am', active: false },
    { hour: 10, label: '10am - 11am', active: false },
    { hour: 11, label: '11am - 12pm', active: false },
    { hour: 12, label: '12pm - 1pm', active: false },
    { hour: 13, label: '1pm - 2pm', active: false },
    { hour: 14, label: '2pm - 3pm', active: false },
    { hour: 15, label: '3pm - 4pm', active: false },
    { hour: 16, label: '4pm - 5pm', active: false },
    { hour: 17, label: '5pm - 6pm', active: false },
    { hour: 18, label: '6pm - 7pm', active: false },
    { hour: 19, label: '7pm - 8pm', active: false },
    { hour: 20, label: '8pm - 9pm', active: false },
    { hour: 21, label: '9pm - 10pm', active: false },
    { hour: 22, label: '10pm - 11pm', active: false },
    { hour: 23, label: '11pm - 12am', active: false }
  ];

  const weekTag = [
    { daySlot: 0, label: 'Sunday' },
    { daySlot: 1, label: 'Monday' },
    { daySlot: 2, label: 'Tuesday' },
    { daySlot: 3, label: 'Wednesday' },
    { daySlot: 4, label: 'Thursday' },
    { daySlot: 5, label: 'Friday' },
    { daySlot: 6, label: 'Saturday' }
  ];

  useEffect(() => {
    function animation() {
      document.querySelector('.contact-banner')?.classList.add('circle');
      setTimeout(function () {
        document.querySelector('.contact-banner')?.classList.remove('circle');
        document.querySelector('.contact-banner')?.classList.add('rectangle');
      }, 300);
    }
    animation();
  }, []);

  useEffect(() => {
    const URL = window.location.pathname;
    if (URL === '/support/ticket') {
      setIsSupportTicketURL(true);
    } else {
      setIsSupportTicketURL(false);
    }
  }, [isSupportTicketURL]);

  useEffect(() => {
    if (
      props.openCallFromTicket != undefined &&
      props.openCallFromTicket != false
    ) {
      supportCall();
      props.setOpenCallFromTicket(false);
    }
  }, [props.openCallFromTicket]);

  useEffect(() => {
    if (
      props.openChatFromTicket != undefined &&
      props.openChatFromTicket != false
    ) {
      goToITSupportChat();
      props.setOpenChatFromTicket(false);
    }
  }, [props.openChatFromTicket]);

  useEffect(() => {
    getUserTelephony();
    if (!props.fromSupport) {
      getUserCountryCode();
      getUserPhones();
    }
  }, [props.userInfo]);

  useEffect(() => {
    if (!userLocation) return;
    setGenesysUrls();
  }, [userLocation]);

  useEffect(() => {
    verifyCallbackStatus();
    verifyWaitingTime();
  }, [callbackPrimaryLanguage, callbackSecondaryLanguage, callbackChange]);

  const verifyWaitingTime = () => {
    if (callbackPrimaryLanguage) {
      let waitingLanguage = callbackPrimaryLanguage.waitingTimeEndpoint;
      const submitURL = `/api/v1/genesys/support/waiting`;
      axiosInstance
        .post(submitURL, {
          url: waitingLanguage
        })
        .then((response) => {
          let timeInMinutes = (
            parseInt(response.data.data[0][waitingLanguage]) / 60
          ).toFixed(0);
          setGenesysWaitingTime(timeInMinutes);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const verifyCallbackStatus = () => {
    const submitURL = `/api/v1/genesys/support/status`;
    if (callbackPrimaryLanguage && !props.fromSupport) {
      let submitURL1 =
        callbackPrimaryLanguage.scheduleEndpoint +
        '?ticketid=' +
        props.ticketId +
        '&userid=' +
        props.userInfo?.userNTID;

      axiosInstance
        .post(submitURL, {
          url: submitURL1
        })
        .then((response) => {
          let existingFlag = false;
          response.data.data.map((item) => {
            if (
              item._callback_state === 'SCHEDULED' ||
              item._callback_state === 'QUEUED' ||
              item._callback_state === 'ROUTING'
            ) {
              setExistingPrimaryCallback(item);
              setDesiredSlotObject(item._desired_time);
              setExistingDesiredLang(getGenesysLanguageString(item._url).Code);
              existingFlag = true;
            }
          });
          if (!existingFlag) {
            setExistingPrimaryCallback('');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (callbackSecondaryLanguage && !props.fromSupport) {
      let submitURL2 =
        callbackSecondaryLanguage.scheduleEndpoint +
        '?ticketid=' +
        props.ticketId +
        '&userid=' +
        props.userInfo?.userNTID;
      axiosInstance
        .post(submitURL, {
          url: submitURL2
        })
        .then((response) => {
          let existingFlag = false;
          response.data.data.map((item) => {
            if (
              item._callback_state === 'SCHEDULED' ||
              item._callback_state === 'QUEUED' ||
              item._callback_state === 'ROUTING'
            ) {
              setExistingSecondaryCallback(item);
              setDesiredSlotObject(item._desired_time);
              setExistingDesiredLang(getGenesysLanguageString(item._url).Code);
              existingFlag = true;
            }
          });
          if (!existingFlag) {
            setExistingSecondaryCallback('');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const setDesiredSlotObject = (item) => {
    let desiredDate = new Date(item);
    let currentDate = new Date();

    let hourTag = timeslots[desiredDate.getHours()].label;

    //Calculate number of days between today and desired slot
    let swapCurrentDate = currentDate;
    swapCurrentDate.setHours(0, 0, 0);
    let swapDesiredDate = desiredDate;
    swapDesiredDate.setHours(0, 0, 0);
    let Difference_In_Time =
      swapDesiredDate.getTime() - swapCurrentDate.getTime();
    let Difference_In_Days = Math.round(
      Difference_In_Time / (1000 * 3600 * 24)
    );

    let dayTag;

    if (Difference_In_Days === 0) {
      dayTag = 'Today';
    }
    if (Difference_In_Days === 1) {
      dayTag = 'Tomorrow';
    } else {
      dayTag =
        weekTag[desiredDate.getDay()].label +
        ' ' +
        monthTag[desiredDate.getMonth()].label +
        ' ' +
        desiredDate.getDate();
    }

    setExistingDesiredDate({
      dateObject: item,
      dayTag: dayTag,
      hourTag: hourTag
    });
  };

  const setGenesysUrls = () => {
    let submitURL = `/api/v1/genesys/support/availability`;

    let dataPayload = {
      country: userLocation
    };

    axiosInstance
      .post(submitURL, dataPayload)
      .then((response) => {
        setCallbackPrimaryLanguage({
          languageTag: getGenesysLanguageString(
            response.data.data[0].Callback_Schedule_URL
          ).Tag,
          languageCode: getGenesysLanguageString(
            response.data.data[0].Callback_Schedule_URL
          ).Code,
          locale: true,
          slotEndpoint: buildAvailabilityURL(
            response.data.data[0].Callback_Availability_URL
          ),
          scheduleEndpoint: response.data.data[0].Callback_Schedule_URL,
          waitingTimeEndpoint: response.data.data[0].Chat_Queue
        });
      })
      .catch((error) => {
        console.error(error);
      });

    if (userLocation !== 'US') {
      let dataPayload2 = {
        country: 'US'
      };

      axiosInstance
        .post(submitURL, dataPayload2)
        .then((response) => {
          setCallbackSecondaryLanguage({
            languageTag: 'English',
            languageCode: 'en',
            locale: false,
            slotEndpoint: buildAvailabilityURL(
              response.data.data[0].Callback_Availability_URL
            ),
            scheduleEndpoint: response.data.data[0].Callback_Schedule_URL,
            waitingTimeEndpoint: response.data.data[0].Chat_Queue
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const buildAvailabilityURL = (baseURL) => {
    //get next 5 working days
    let todayDate = new Date();
    let swapDate = new Date();
    let dayCount = 1;
    let daysForSlot = 1;
    while (daysForSlot < 4) {
      swapDate = new Date(
        swapDate.setDate(todayDate.getDate() + (dayCount + 1))
      );
      dayCount++;
      if (swapDate.getDay() !== 0 && swapDate.getDay() !== 6) {
        daysForSlot++;
      }
    }

    let startDatetimeObject = getDatetimeObjectInUTC(todayDate);
    let endDatetimeObject = getDatetimeObjectInUTC(swapDate);

    let inputURL =
      baseURL +
      '/availability?start=' +
      startDatetimeObject['year'] +
      '-' +
      startDatetimeObject['month'] +
      '-' +
      startDatetimeObject['day'] +
      'T' +
      startDatetimeObject['hours'] +
      '%3A00%3A00.000Z&number-of-days=' +
      1 +
      '&max-time-slots=&end=' +
      endDatetimeObject['year'] +
      '-' +
      endDatetimeObject['month'] +
      '-' +
      endDatetimeObject['day'] +
      'T' +
      endDatetimeObject['hours'] +
      '%3A00%3A00.000Z';

    return inputURL;
  };

  const getDatetimeObjectInUTC = (date) => {
    let slotDate = new Date(date);
    let datetimeObject = {};
    datetimeObject['year'] = slotDate.getUTCFullYear();
    datetimeObject['month'] = ('0' + (slotDate.getUTCMonth() + 1)).slice(-2);
    datetimeObject['day'] = ('0' + slotDate.getUTCDate()).slice(-2);
    datetimeObject['hours'] = ('0' + slotDate.getUTCHours()).slice(-2);
    return datetimeObject;
  };

  const getUserTelephony = () => {
    const activeDirectoryUrl = `/api/v1/ad/user/attributes?user=${props.userInfo?.userNTID}&attribute=telephony`;
    axiosInstance
      .get(activeDirectoryUrl)
      .then((response) => {
        if (response.data.data[0].telephony !== false) {
          setUserHasTelephony(true);
        } else {
          setUserHasTelephony(false);
        }
      })
      .catch((error) => {
        //TO DO
        props.showErrorPopUp();
        props.setErrorMessage(error);
        console.log(props);
        console.error(error);
      });
  };

  const getUserPhones = () => {
    const submitURL = `/api/v1/smt/user/phone`;
    const data = {
      ntid: props.userInfo?.userNTID
    };
    axiosInstance
      .post(submitURL, data)
      .then(async (result) => {
        setUserAvailablePhones(result.data.json);
      })
      .catch((error) => {
        console.error(error);
        setUserAvailablePhones([]);
      });
  };

  const getUserCountryCode = () => {
    let getCountryUrl = `/api/v1/snow/user/country_code`;
    axiosInstance
      .post(getCountryUrl, { ntid: props.userInfo?.userNTID })
      .then((response) => {
        if (response.status === 200 && response.data.isSuccessful) {
          setUserLocation(
            response.data.data[0].result[0]['location.u_country_code']
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const goToITSupportChat = () => {
    window.firstName = props.userInfo?.userGivenName;
    window.lastName = props.userInfo?.userFamilyName;
    window.userEmail = props.userInfo?.userEmail;
    window.loginName = props.userInfo?.userNTID;
    window.homeOUName = props.userInfo?.userLocation;
    window.langcode = props.userInfo?.userLanguage;
    window.genesysUrl = process.env.REACT_APP_GENESYS_URL;

    var openChat = window.open(
      `${process.env.REACT_APP_CHAT_URI}`,
      'IT Support Chat',
      'toolbar=no,scrollbars=no,location=no,resizable=no,top=500,left=500,width=730,height=580'
    );

    //Check to see if child windows is closed every 200 milisecond
    var pollTimer = window.setInterval(function () {
      if (openChat.closed !== false) {
        window.clearInterval(pollTimer);

        var chatSessionId = getCookie('_genesys.widgets.webchat.state.session');
        //get keys object
        var keys = getCookie('_genesys.widgets.webchat.state.keys');
        var keysObject = '';
        var alias = '';
        var secureKey = '';
        var userId = '';
        if (keys) {
          keysObject = JSON.parse(keys);
          //extract alias
          alias = keysObject.alias;
          //extract secureKey
          secureKey = keysObject.secureKey;
          //extract userId
          userId = keysObject.userId;
          sessionStorage.setItem('keys', keys);
          sessionStorage.setItem('chatId', chatSessionId);
        }
        //make sure all  four variable exists before closing chat session
        if (chatSessionId && alias && secureKey && userId) {
          let chatServer = process.env.REACT_APP_GENESYS_URL + '/';
          let urlInput = chatServer + chatSessionId + '/disconnect';

          //Call genesys widget webservices directly to close session
          let disconnectUrl = `/api/v1/genesys/support/disconnect`;
          axiosInstance
            .post(disconnectUrl, {
              url: urlInput,
              alias: alias,
              secureKey: secureKey,
              userId: userId
            })
            .then((response) => {
              console.log('closed session closed properly');
            })
            .catch((error) => {
              console.error('Chat Session did not close properly');
              console.error(error);
            });
        }
      }
    }, 200);
  };

  const getCookie = (cname) => {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  const goToTicketCreation = () => {
    navigate(`/support/ticket`);
    document.getElementById('dash-search-box-id')?.scrollIntoView();
  };

  const supportCall = () => {
    if (userHasTelephony) {
      setOpenTelephonyModal(true);
    } else {
      setOpenTelephonyList(true);
    }
  };

  const renderOpenATicket = () => {
    return !isSupportTicketURL ? (
      <Button
        className="btn-search-bar m-auto bg-transparent border-2 h-14 w-auto p-3 border-slate-100 text-white flex items-center"
        onClick={() => {
          goToTicketCreation();
        }}
      >
        <span className="support-icon-image text-2xl bg-transparent text-white mr-2">
          &#xe12E;
        </span>
        {t('ui_spear.contactIT.Button.Open_a_ticket')}
      </Button>
    ) : (
      <></>
    );
  };

  const renderSchedulecallbackOptions = () => {
    return !existingPrimaryCallback && !existingSecondaryCallback ? (
      <>
        <div className="w-5"></div>
        <Button
          className="m-auto bg-transparent border-2 h-14 w-auto p-3 border-slate-100 text-white flex items-center"
          onClick={() => {
            setOpenScheduleCallback(true);
          }}
        >
          <span className="support-icon-image text-2xl bg-transparent text-white mr-2">
            &#xe0c8;
          </span>
          {t('ui_spear.support.schedulle_callback_schedule_a_callback')}
        </Button>
      </>
    ) : (
      <>
        <div className="w-5"></div>
        <Button
          className="m-auto bg-transparent border-2 h-14 w-auto p-3 border-slate-100 text-white flex items-center"
          onClick={() => {
            setOpenScheduleCallback(true);
          }}
        >
          <span className="support-icon-image text-2xl bg-transparent text-white mr-2">
            &#xe0c8;
          </span>
          {t('ui_spear.support.schedulle_callback_reschedule_a_callback')}
        </Button>

        <div className="w-5"></div>
        <Button
          className="m-auto bg-transparent border-2 h-14 w-auto p-3 border-slate-100 text-white flex items-center"
          onClick={() => {
            setOpenCancelCallback(true);
          }}
        >
          <span className="support-icon-image text-2xl bg-transparent text-white mr-2">
            &#xe118;
          </span>
          {t('ui_spear.support.schedulle_callback_cancel')}
        </Button>
      </>
    );
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
        className={`contact-banner bg-gradient-to-r from-support_banner_color_1 to-support_banner_color_2 h-28 ${
          props.className ?? ''
        }`}
      >
        <Stack direction="row" className="mr-20">
          <div className="support-icon-image ms-2 text-8xl bg-transparent text-white mt-3">
            &#xe23d;
          </div>
          {!existingPrimaryCallback && !existingSecondaryCallback && (
            <Typography className="contact-it-label bg-transparent ms-2 my-auto text-white text-2xl">
              {t('ui_spear.contactIT.Contact_IT_service_desk')}
            </Typography>
          )}
        </Stack>
        {existingPrimaryCallback && (
          <>
            <Typography className="bg-transparent my-auto text-white text-2xl right-32 relative font-Bosch_sans">
              {t('ui_spear.support.schedulle_callback_callback_scheduled')}{' '}
              {existingDesiredDate.dayTag},{' '}
              {t('ui_spear.support.schedulle_callback_between')}{' '}
              {existingDesiredDate.hourTag}
            </Typography>
          </>
        )}
        {existingSecondaryCallback && (
          <>
            <Typography className="bg-transparent my-auto text-white text-2xl right-32 relative font-Bosch_sans">
              {t('ui_spear.support.schedulle_callback_callback_scheduled')}{' '}
              {existingDesiredDate.dayTag},{' '}
              {t('ui_spear.support.schedulle_callback_between')}{' '}
              {existingDesiredDate.hourTag}
            </Typography>
          </>
        )}

        <Stack
          className={`wrapper-btn mr-10 ${props.fromDial && 'ml-[600px]'}`}
          direction="row"
        >
          {!existingPrimaryCallback && !existingSecondaryCallback && (
            <>
              <Button
                className="btn-search-bar m-auto bg-white h-14 w-auto p-3 text-blue-600 flex items-center"
                onClick={() => {
                  supportCall();
                }}
              >
                <span className="support-icon-image text-2xl bg-transparent text-blue-800 mr-2">
                  &#xe0cb;
                </span>
                {t('ui_spear.contactIT.Button.Call')}
              </Button>
              <div className="w-5"></div>
              <Button
                className="btn-search-bar m-auto bg-white h-14 w-auto p-3 text-blue-600 flex items-center"
                onClick={() => {
                  goToITSupportChat();
                }}
              >
                <span className="support-icon-image text-2xl bg-transparent text-blue-800 mr-2">
                  &#xe0e4;
                </span>
                {t('ui_spear.contactIT.Button.Chat')}
              </Button>
            </>
          )}

          <div className="w-5"></div>

          {props.fromSupport && renderOpenATicket()}

          {!props.fromSupport && renderSchedulecallbackOptions()}
        </Stack>
      </Stack>

      <ScheduleCallback
        userInfo={props.userInfo}
        userAvailablePhones={userAvailablePhones}
        ticketId={props.ticketId}
        callbackChange={callbackChange}
        setCallbackChange={setCallbackChange}
        userLocation={userLocation}
        openScheduleCallback={openScheduleCallback}
        setOpenScheduleCallback={setOpenScheduleCallback}
        callbackPrimaryLanguage={callbackPrimaryLanguage}
        callbackSecondaryLanguage={callbackSecondaryLanguage}
        existingPrimaryCallback={existingPrimaryCallback}
        existingSecondaryCallback={existingSecondaryCallback}
        serviceName={props.serviceName}
        userNTID={props.userInfo?.userNTID}
        userLang={props.userInfo?.userLanguage}
      ></ScheduleCallback>

      <CancelCallback
        existingDesiredDate={existingDesiredDate}
        callbackChange={callbackChange}
        setCallbackChange={setCallbackChange}
        openCancelCallback={openCancelCallback}
        setOpenCancelCallback={setOpenCancelCallback}
        callbackPrimaryLanguage={callbackPrimaryLanguage}
        callbackSecondaryLanguage={callbackSecondaryLanguage}
        existingPrimaryCallback={existingPrimaryCallback}
        existingSecondaryCallback={existingSecondaryCallback}
        ticketId={props.ticketId}
        serviceName={props.serviceName}
        userNTID={props.userInfo?.userNTID}
        userLang={props.userInfo?.userLanguage}
        existingDesiredLang={existingDesiredLang}
      ></CancelCallback>

      <TelephonyList
        openTelephonyList={openTelephonyList}
        setOpenTelephonyList={setOpenTelephonyList}
      ></TelephonyList>

      <TelephonyCallBanner
        openTelephonyModal={openTelephonyModal}
        setOpenTelephonyModal={setOpenTelephonyModal}
      ></TelephonyCallBanner>

    </>
  );
};

export default SupportBar;
