import { useTranslation } from 'react-i18next';

function Tabs({ toggleTicketsTabs, setToggleTicketsTabs, isSapUserKey }) {
  const { t } = useTranslation();
  const switchTab = (currentTab) => {
    let tabs = {
      myTickets: false,
      ticketsForOthers: false,
      searchByTicketId: false
    };
    tabs[currentTab] = true;
    return tabs;
  };

  return (
    <nav>
      <ul className="px-10 pt-4 flex flex-row justify-center font-light text-base">
        <li
          className={` list-none pb-3.5 px-7 hover:text-ITSP_blue  ${
            toggleTicketsTabs.myTickets
              ? 'cursor-pointer border-b-2 border-ITSP_blue text-ITSP_blue'
              : 'cursor-pointer border-b-2 border-transparent'
          }`}
          onClick={() => setToggleTicketsTabs(switchTab('myTickets'))}
        >
          <span>{t('ui_spear.myTickets.My_tickets')}</span>
        </li>
        <li
          className={`list-none pb-3.5 px-7 hover:text-ITSP_blue  ${
            toggleTicketsTabs.ticketsForOthers
              ? 'cursor-pointer border-b-2 border-ITSP_blue text-ITSP_blue'
              : 'cursor-pointer border-b-2 border-transparent'
          }`}
          onClick={() => setToggleTicketsTabs(switchTab('ticketsForOthers'))}
        >
          <span>{t('ui_spear.myTickets.Tickets_for_others')}</span>
        </li>
        {isSapUserKey && (
          <li
            className={`list-none pb-3.5 px-7 hover:text-ITSP_blue  ${
              toggleTicketsTabs.searchByTicketId
                ? 'cursor-pointer border-b-2 border-ITSP_blue text-ITSP_blue'
                : 'cursor-pointer border-b-2 border-transparent'
            }`}
            onClick={() => setToggleTicketsTabs(switchTab('searchByTicketId'))}
          >
            <span>{t('ui_spear.sapKeyUser.Search_by_TicketID')}</span>
          </li>
        )}
      </ul>
      <hr />
    </nav>
  );
}

export default Tabs;
