import React from 'react';

const LoadingButton = () => {
    return (
        <>
            <div className="loading-services flex justify-center">
                <div className="lds-ellipsis-button flex justify-center">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </>
    );
};

export default LoadingButton;