import React from 'react';
import { Button } from '@mui/base';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const WhoIsAffected = (props) => {
  const { t } = useTranslation();

  const renderCustomerDetails = () => {
    // Translations
    const trsName = t('ui_spear.createTicket.name');
    const trsEmail = t('ui_spear.createTicket.email');
    const trsPhone = t('ui_spear.createTicket.phone');

    const customerDetails = [
      [trsName, props.Name],
      [trsEmail, props.Email],
      [trsPhone, props.Phone]
    ];

    return customerDetails.map((info, index) => {
      const [title, value] = info;
      let infoToDisplay = '';
      switch (title) {
        case trsName:
          infoToDisplay = (
            <>
              <span>{value}</span>
              <span className="change-customer-pencil-icon ml-4 mr-[-1rem]">
                &#xe152;
              </span>
              <Button
                onClick={() => props.setOpenPersonPicker(true)}
                className="mx-6 text-change_cust_btn hover:text-blue_hover_btns"
              >
                {t('ui_spear.createTicket.change_customer')}
              </Button>
            </>
          );
          break;
        case trsPhone:
          infoToDisplay = (
            <>
              <select
                value={props.selectedPhone}
                name="user-phones"
                id="user-phones"
                className="border-b-2 border-border_gray py-4 pr-10 pl-4 hover:text-change_cust_btn hover:border-change_cust_btn hover:cursor-pointer min-w-[13em]"
                onChange={props.handlePhoneChosen}
              >
                <option key={''} value={''} className="" />
                {renderUserPhones(value)}
              </select>
            </>
          );
          break;
        default:
          infoToDisplay = <span>{value}</span>;
          break;
      }
      return (
        <div key={index.toString()} className="mb-4 flex flex-row items-center">
          <span className="font-bold mr-16 inline-block w-16">{title}: </span>
          {infoToDisplay}
        </div>
      );
    });
  };

  const renderUserPhones = (phones) => {
    return phones.map((phone, i) => {
      return (
        <>
          <option key={i.toString()} value={phone} className="">
            {phone}
          </option>
        </>
      );
    });
  };

  return (
    <Fragment>
      <div className="my-6">
        <h3 className="font-bold">
          {t('ui_spear.createTicket.Who_is_affected')}
        </h3>
        <div className="my-10">{renderCustomerDetails()}</div>
      </div>
    </Fragment>
  );
};

export default WhoIsAffected;
