import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableRow,
  TableHead,
  TableCell,
  TableSortLabel,
  Box
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

const EnhancedTableHead = (props) => {
  const { t } = useTranslation();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const columns = [
    {
      id: 'CI_NAME',
      label: t('ui_spear.createTicket.service_label_name'),
      minWidth: 170
    },
    {
      id: 'CI_KEYWORD',
      label: t('ui_spear.createTicket.service_label_alias'),
      minWidth: 100
    }
  ];

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.numeric ? 'right' : 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === column.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
              sx={{
                '& .MuiTableSortLabel-icon': {
                  opacity: '0.25'
                },
                '& .MuiTableSortLabel-icon.Mui-active': {
                  opacity: '3'
                }
              }}
            >
              <p className="font-[800] text-[rgb(0,0,0,0.89)]">
                {column.label}
              </p>
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
