import React, { Fragment } from 'react';
import ErrorMessage from './ErrorMessage';

const ErrorPopUp = (props) => {
  return (
    <Fragment>
      <ErrorMessage {...props}></ErrorMessage>
    </Fragment>
  );
};

export default ErrorPopUp;
