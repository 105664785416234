import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../../utils/axiosInstance';

const CancelStep1 = (props) => {
  const { t } = useTranslation();

  const triggerCancel = () => {
    const submitURL = `/api/v1/genesys/support/unschedule`;

    if (props.existingPrimaryCallback) {
      axiosInstance
        .post(submitURL, {
          url: props.callbackPrimaryLanguage.scheduleEndpoint,
          callbackId: props.existingPrimaryCallback._id
        })
        .then((response) => {
          props.setCancelStep(1);
          sendCancelEmail();
        })
        .catch((error) => {
          //handle ERROR
          console.error(error);
        });
    }
    if (props.existingSecondaryCallback) {
      axiosInstance
        .post(submitURL, {
          url: props.callbackSecondaryLanguage.scheduleEndpoint,
          callbackId: props.existingSecondaryCallback._id
        })
        .then((response) => {
          props.setCancelStep(1);
          sendCancelEmail();
        })
        .catch((error) => {
          //handle ERROR
          console.error(error);
        });
    }
  };

  const sendCancelEmail = () => {
    const emailUrl = `/api/v1/snow/support/notification`;

    let scheduledDate = new Date(props.existingDesiredDate?.dateObject);

    let monthZero = '0' + (scheduledDate.getUTCMonth() + 1);
    monthZero = monthZero.slice(-2);
    let dayZero = '0' + scheduledDate.getDate();
    dayZero = dayZero.slice(-2);
    let hourZero = '0' + scheduledDate.getHours();
    hourZero = hourZero.slice(-2);

    let emailObject = {
      contact:
        props.existingPrimaryCallback?._customer_number ||
        props.existingSecondaryCallback?._customer_number,
      ntid: props.userNTID,
      lang: props.userLang,
      type: 'cancel',
      ticketId: props.ticketId,
      ticketName: props.serviceName,
      dateTime:
        scheduledDate.getFullYear() +
        '-' +
        monthZero +
        '-' +
        dayZero +
        ' ' +
        hourZero +
        ':00:00'
    };

    axiosInstance
      .post(emailUrl, emailObject)
      .then((response) => {})
      .catch((error) => {
        //handle ERROR
        console.error(error);
      });
  };

  return (
    <>
      <DialogTitle className="items-center px-[50px]">
        <Grid item xs={12} align="center">
          <Grid item xs={12}>
            <span className="support-icon-image text-[100px] bg-transparent text-yellow_warning relative ">
              &#xeb0c;
            </span>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              className="font-Bosch_sans_light text-[30px]"
            >
              {t('ui_spear.support.schedulle_callback_cancel_callback')}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Typography className="font-Bosch_sans_light text-2xl mt-7 text-center">
          {t(
            'ui_spear.support.schedulle_callback_are_you_sure_want_to_cancel_your_callback'
          )}{' '}
          {props.existingDesiredDate?.dayTag},{' '}
          {t('ui_spear.support.schedulle_callback_between')}{' '}
          {props.existingDesiredDate?.hourTag}?
        </Typography>

        <Grid
          container
          spacing={2}
          className="mt-7"
          xs={12}
          justifyContent="center"
        >
          <Grid item>
            <Button
              variant="outlined"
              className="m-auto bg-transparent border-1 border-ITSP_blue h-10 w-auto text-ITSP_blue flex items-center normal-case font-Bosch_sans rounded-none text-xl p-7"
              onClick={() => {
                props.handleClose();
              }}
            >
              {t('ui_spear.support.schedulle_callback_no_leave_it')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="m-auto bg-ITSP_blue h-10 w-auto text-white flex items-center normal-case font-Bosch_sans rounded-none text-xl p-7"
              onClick={() => {
                triggerCancel();
              }}
            >
              {t('ui_spear.support.schedulle_callback_yes_cancel')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default CancelStep1;
