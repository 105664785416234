import { useEffect, useState } from 'react';
import DevicesBanner from './DevicesBanner';
import DevicesTabs from './DevicesTabs';
import styled from '@emotion/styled/macro';
import { useNavigate } from 'react-router-dom';
import LoaderTicketCreation from '../../components/Confirmations/LoaderTicketCreation';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Box
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axiosInstance';

const StyledImage = styled('img', {
  shouldForwardProp: (props) => props !== 'left'
})(({ left }) => ({
  position: 'left',
  marginLeft: left,
  filter:
    'invert(17%) sepia(44%) saturate(6994%) hue-rotate(188deg) brightness(80%) contrast(98%)'
}));
const StyledCardMedia = styled(CardMedia)({
  padding: '60px',
  border: '1px solid grey'
});

const StyledCardContent = styled(CardContent)({
  borderBottom: '1px solid grey',
  borderLeft: '1px solid grey',
  borderRight: '1px solid grey'
});

const StyledCard = styled(Card)({
  boxShadow: 'none',
  [`&:hover`]: {
    [`${StyledCardMedia}`]: {
      backgroundColor: '#036AA2 !important'
    },
    [`${StyledImage}`]: {
      filter: 'sepia(0%) hue-rotate(150deg) saturate(10000%) brightness(100%)'
    }
  }
});

const MyDevices = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [devicesArray, setDevicesArray] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.userInfo) getDevices();
  }, [props?.userInfo]);

  const getDevices = async () => {
    setLoading(true);
    const devicesURL = `/api/v1/cms/devices`;
    try {
      await axiosInstance
        .get(`${devicesURL}/${props?.userInfo?.userNTID}`)
        .then((result) => {
          setDevicesArray(result.data.data);
          setLoading(false);
        });
    } catch (error) {
      console.log(error, 'Error while retrieving devices');
    }
  };

  const renderDeviceImage = (type) => {
    if (type == 'Mobile Phone') {
      return { type: 'phone_icon_70.png', marginLeft: '50px' };
    } else if (type == 'Laptop' || type == 'Notebook') {
      return { type: 'laptop_icon_70.png', marginLeft: '20px' };
    } else if (type == 'Desktop') {
      return { type: 'desktop_icon_70.png', marginLeft: '30px' };
    } else if (type == 'Tablet') {
      return { type: 'tablet_icon_70.png', marginLeft: '20px' };
    } else {
      return 'Asset 55.png';
    }
  };

  const handleClick = (device) => {
    navigate(`/devices/details/${device.ID}`, {
      state: {
        device_id: device.ID,
        device_type: device.ITSP_SERVICE_TYPE_NAME
      }
    });
  };

  return (
    <>
      {loading && <LoaderTicketCreation></LoaderTicketCreation>}
      <DevicesBanner></DevicesBanner>
      <DevicesTabs devices={true}></DevicesTabs>
      <Box sx={{ flexGrow: 1 }}></Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          className="px-14 w-4/6 mx-auto"
          style={{ marginTop: '50px' }}
          container
          spacing={3}
        >
          {devicesArray.map((device) => {
            const imageType = renderDeviceImage(device.ITSP_SERVICE_TYPE_NAME);
            return (
              <Grid key={device} item xs={12} md={3}>
                <StyledCard
                  square={true}
                  style={{ cursor: 'pointer', borderRadius: '0px !important' }}
                  onClick={() => {
                    handleClick(device);
                  }}
                >
                  <StyledCardMedia className="">
                    <StyledImage
                      left={imageType.marginLeft}
                      src={require(
                        `../../static/deviceIcons/${imageType.type}`
                      )}
                    ></StyledImage>
                  </StyledCardMedia>
                  <StyledCardContent>
                    <Typography style={{ width: '100%', marginBottom: '10px' }}>
                      {device.MODEL}
                    </Typography>
                    <Typography style={{ width: '100%' }}>
                      {device.NODE_NAME}
                    </Typography>
                    <Typography>{device.DEVICE_CLASS}</Typography>
                  </StyledCardContent>
                </StyledCard>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default MyDevices;
