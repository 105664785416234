import React from 'react';
import { Grid, Typography } from '@mui/material';

import sadArticle from '../../static/sad-article.png';
import { useTranslation } from 'react-i18next';

export default function NotEntitlePage() {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
      id="no-entitle-container"
      className=""
    >
      <Grid item>
        <div>
          <img
            id="sad-article"
            src={sadArticle}
            className="max-h-[300px]"
            alt="article-guy"
          ></img>
        </div>
      </Grid>
      <Grid item>
        <Typography className="max-w-[400px] font-Bosch_sans text-lg mb-4">
          {t('ui_spear.support.location_is_not_entitled')}
        </Typography>
      </Grid>
    </Grid>
  );
}
