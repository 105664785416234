import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

const TicketSearchBar = (props) => {
  const { searchError, setSearchText, searchText, searchLabel, errorMsg } =
    props;
  const [isFocused, setIsFocused] = useState(false);
  const [searchKey, setSearchKey] = useState('');

  let [rightIcon, setRightIcon] = useState('searchIcon');
  let [leftIcon, setLeftIcon] = useState(false);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event) => {
    if (event.keyCode === 13) {
      validateSearchKey(event.target.value);
    }
  };

  const clearSearch = () => {
    setSearchKey('');
    setSearchText('');
  };

  const validateSearchKey = (value) => {
    let incidentID = value.toUpperCase().trim();
    let subfix = value;
    if (incidentID.indexOf('INC') === 0 && incidentID.length === 15) {
      subfix = incidentID.substring(incidentID.indexOf('INC') + 3);
    } else {
      let leadingZeros = 'INC';
      for (var i = 0; i < 12 - value.length; i++) {
        leadingZeros += '0';
      }
      incidentID = leadingZeros + value;
    }
    if (/^[0-9]+$/.test(subfix) && subfix.length >= 8) {
      setSearchText(incidentID);
    } else {
      clearSearch();
    }
  };

  const blurSearchBar = () => {
    if (searchText === '') {
      setRightIcon('searchIcon');
      setLeftIcon(false);
    }
  };
  const focusSearchBar = () => {
    if (searchText === '') {
      setRightIcon('');
    }
    setLeftIcon(true);
  };

  const setupRightIcon = () => {
    var tempRightIcon = null;
    switch (rightIcon) {
      case '':
        tempRightIcon = '';
        break;
      case 'searchIcon':
        tempRightIcon = (
          <div className="bosch-icon search-icon text-[26px]">&#xe235;</div>
        );
        break;
      default:
        tempRightIcon = '';
        break;
    }
    return tempRightIcon;
  };

  return (
    <section className="mt-5 flex justify-end">
      <div
        className=" relative flex w-96 mr-4"
        onFocus={focusSearchBar}
        onBlur={blurSearchBar}
      >
        <CSSTransition
          in={leftIcon}
          timeout={300}
          classNames="search-slide-ticket"
          mountOnEnter
          unmountOnExit
          onFocus={focusSearchBar}
          onBlur={blurSearchBar}
        >
          <span className={`inset-y-0  flex items-center`}>
            <div className="bosch-icon search-icon text-[26px] absolute left-[-40px]">
              &#xe235;
            </div>
          </span>
        </CSSTransition>

        <input
          type="text"
          value={searchKey}
          placeholder={isFocused ? '' : searchLabel}
          className={`w-full py-2 border-b border-gray-500 focus:outline-none placeholder:text-gray-950 font-light text-lg`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={(e) => setSearchKey(e.target.value)}
          onKeyDown={(e) => handleChange(e)}
        />
        <span
          className={`absolute inset-y-0  flex items-center ${
            isFocused ? 'left-[-30px]' : 'right-0 translate-x-0'
          } transition-all duration-3000`}
        >
          <div>{setupRightIcon()}</div>
        </span>
        <span
          className={`absolute cursor-pointer inset-y-0  right-0 flex items-center pr-3 w-9`}
          onClick={clearSearch}
        >
          <span
            className={`boschicon-bosch-ic-close text text-[26px] ${
              isFocused ? 'block' : 'hidden'
            }`}
          ></span>
        </span>
        {searchError && (
          <div className="absolute top-[39px] w-96 mr-4 bg-fail_search h-[40px] flex items-center p-3 border-t-[#E6E6E6]">
            <span className="font-Bosch_sans_light text-base">{errorMsg}</span>
          </div>
        )}
      </div>
    </section>
  );
};

export default TicketSearchBar;
