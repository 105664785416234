import React, { useState } from 'react';
import {
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ScheduleStep2 = (props) => {
  const { t } = useTranslation();

  // Translations
  const strProvideCorrectNum = t(
    'ui_spear.support.schedulle_callback_provide_a_correct_number'
  );
  const strCountryCodeShouldStart = t(
    'ui_spear.support.schedulle_callback_country_code_should_start_with_plus'
  );
  const strSevenToFifteenDigits = t(
    'ui_spear.support.schedulle_callback_should_contain_from_digits'
  );

  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const [customTelephone, setCustomTelephone] = useState(false);

  const validatePhone = (e, lostFocus) => {
    let inputValue = e.target.value;

    inputValue = inputValue.trim();
    inputValue = inputValue.split(' ').join('');
    inputValue = inputValue.split('(').join('').split(')').join('');
    inputValue = inputValue.split('-').join('');

    if (inputValue === '' || inputValue === null) {
      // Provide a correct number 99990673
      setErrorMessage(strProvideCorrectNum);
      setActiveButton(false);
      if (lostFocus) {
        setShowError(true);
      }
      return;
    }

    if (inputValue[0] !== '+') {
      // Country code should start with + 99990674
      setErrorMessage(strCountryCodeShouldStart);
      setActiveButton(false);
      if (lostFocus) {
        setShowError(true);
      }
      return;
    }

    let phoneNumberNoPlus = inputValue.split('+').join('');

    if (/^\d+$/.test(phoneNumberNoPlus) === false) {
      // Provide a correct number 99990673
      setErrorMessage(strProvideCorrectNum);
      setActiveButton(false);
      if (lostFocus) {
        setShowError(true);
      }
      return;
    }

    if (phoneNumberNoPlus.length < 7 || phoneNumberNoPlus.length > 15) {
      // Should contain from 7 to 15 digits 99990675
      setErrorMessage(strSevenToFifteenDigits);
      setActiveButton(false);
      if (lostFocus) {
        setShowError(true);
      }
      return;
    }
    setErrorMessage('');
    setShowError(false);
    setActiveButton(true);
    props.setCallbackPhone(inputValue);
  };

  const onLostFocus = (e) => {
    validatePhone(e, true);
  };

  let SetTelephoneOptions = () => {
    let table = '';

    table = (
      <>
        {props.userAvailablePhones.map((telephone) => (
          <FormControlLabel
            key={telephone}
            value={telephone}
            control={<Radio />}
            label={telephone}
          />
        ))}
      </>
    );

    return table;
  };

  const handleChange = (event) => {
    if (event.target.value === 'other') {
      setCustomTelephone(true);
      setActiveButton(false);
    } else {
      props.setCallbackPhone(event.target.value);
      setCustomTelephone(false);
      setActiveButton(true);
    }
  };

  return (
    <>
      <DialogTitle className="items-center px-[50px] pt-8">
        <Grid item xs={12} align="center">
          <Typography
            variant="caption"
            className="font-Bosch_sans_light text-[26px]"
          >
            {t('ui_spear.support.schedulle_callback_provide _contact_number')}
          </Typography>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Typography className="font-Bosch_sans_light text-xl ml-6">
          {t('ui_spear.support.schedulle_callback_which_number_we_call _you')}
        </Typography>
        {props.userAvailablePhones.length > 0 ? (
          <>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                className="font-Bosch_sans text-base mt-7 ml-6"
                onChange={handleChange}
              >
                <SetTelephoneOptions></SetTelephoneOptions>
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label={`${t(
                    'ui_spear.support.schedulle_callback_type_phone_number'
                  )} ${t(
                    'ui_spear.support.schedulle_callback_with_a_country_code'
                  )}`}
                />
              </RadioGroup>
            </FormControl>
            {customTelephone && (
              <>
                <Input
                  className="ml-6 mt-11 bg-gray_background text-xl p-2 normal-case font-Bosch_sans"
                  placeholder="+49 111 55533 22"
                  onChange={(event) => validatePhone(event, false)}
                  onBlur={onLostFocus}
                />
                {showError && (
                  <Typography className="font-Bosch_sans text-sm text-red-600 ml-6">
                    {errorMessage}
                  </Typography>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Typography className="font-Bosch_sans text-base mt-7 ml-6">
              {t('ui_spear.support.schedulle_callback_type_phone_number')}{' '}
              <strong>
                {t('ui_spear.support.schedulle_callback_with_a_country_code')}
              </strong>
            </Typography>

            <Input
              className="ml-6 mt-11 bg-gray_background text-xl p-2 normal-case font-Bosch_sans"
              placeholder="+49 111 55533 22"
              onChange={(event) => validatePhone(event, false)}
              onBlur={onLostFocus}
            />
            {showError && (
              <Typography className="font-Bosch_sans text-sm text-red-600 ml-6">
                {errorMessage}
              </Typography>
            )}
          </>
        )}

        <Grid
          container
          spacing={2}
          className="mt-7"
          xs={12}
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              variant="outlined"
              className="m-auto h-10 w-auto p-3 border-1 border-ITSP_blue text-blue-600 flex items-center normal-case font-Bosch_sans rounded-none text-lg"
              onClick={() => {
                props.setScheduleStep(0);
              }}
            >
              &lt; {t('ui_spear.support.schedulle_callback_back')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={`m-auto ${activeButton
                  ? 'bg-ITSP_blue'
                  : 'bg-gray-200 hover:cursor-default'
                } h-10 w-auto p-3 text-white flex items-center normal-case font-Bosch_sans rounded-none text-lg`}
              onClick={() => {
                if (activeButton) {
                  props.makeGenesysScheduleCallback();
                }
              }}
            >
              {props.existingPrimaryCallback ||
                props.existingSecondaryCallback ? (
                <>
                  {t('ui_spear.support.schedulle_callback_reschedule_callback')}
                </>
              ) : (
                <>
                  {t(
                    'ui_spear.support.schedulle_callback_schedule callback_text'
                  )}{' '}
                </>
              )}
            </Button>
          </Grid>
        </Grid>

        <Typography className="font-Bosch_sans_light text-sm text-right mt-7 ml-6">
          {t('ui_spear.support.schedulle_callback_call_will_be_done')}{' '}
          {props.localLanguage
            ? props.callbackPrimaryLanguage?.languageTag
            : props.callbackSecondaryLanguage?.languageTag}
          , {props.selectedSlot.selectedDateTag}{' '}
          {t('ui_spear.support.schedulle_callback_between')}{' '}
          {props.selectedSlot.selectedHourLabel}{' '}
          {t('ui_spear.support.schedulle_callback_ITSD_1st_level')}
        </Typography>
      </DialogContent>
    </>
  );
};

export default ScheduleStep2;
