import { useTranslation } from 'react-i18next';

const ExpandAllDivider = ({ isExpandAll, setIsExpandAll, itemsLength }) => {
    const { t } = useTranslation();
    return (
        <div className="uppercase text-center font-bold text-xs py-[0.22rem] bg-blue_announcement brightness-90 shadow-[0_0_0_0.15rem_rgb(0,86,145,0.4)]" onClick={() => setIsExpandAll((prev) => !prev)}>
            <span>{isExpandAll ? t('ui_spear.announcement.hide_all') : `${t('ui_spear.announcement.expand_all')} ${itemsLength - 1}`}</span>
            <span className={`${isExpandAll ? 'boschicon-bosch-ic-up' : 'boschicon-bosch-ic-down'}  boschicon-bosch-ic-down text-sm pl-1 font-bold`}></span>
        </div>
    )
}

export default ExpandAllDivider
