import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as EmptyStar } from '../../static/empty_star.svg';
import { ReactComponent as FilledStar } from '../../static/filled_star.svg';
import { Alert, Button, Grid, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchSnackBar from '../SearchSnakBar/SearchSnackBar';
import axiosInstance from '../../utils/axiosInstance';

function SaveSearch(props) {
  const { t } = useTranslation();
  let [savedSearch, setSavedSearch] = useState(false);
  let [existingSave, setExistingSave] = useState(false);
  let [styleClass, setStyleClass] = useState('save-search-button');
  let [buttonDisabled, setButtonDisabled] = useState(false);
  let [savedQueryId, setSavedQueryId] = useState(-1);
  let [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSearchSnackbar, setOpenSearchSnackbar] = useState(false);
  const [snackBarTexts, setSnackBarTexts] = useState({
    msgLabel: '',
    btnLabel: '',
    searchWasSaved: false
  });

  const submitURL = `/api/v1/queries/saved`;

  const sendSaveSearchEvent = () => {
    let resultList = [];
    props.searchResponse.forEach((result) => {
      let element = {
        distance: result.distance,
        id: result.id,
        type: result.type
      };
      resultList.push(element);
    });
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const { searchTerm, userNTID, lastSearchedTerm } = props;
  const prevTerm = usePrevious(searchTerm);

  useEffect(() => {
    if (prevTerm !== searchTerm) {
      axiosInstance
        .get(submitURL, {
          params: {
            ntid: userNTID,
            type: 'SINGLE_QUERY_BY_DESCRIPTION',
            description: searchTerm
          }
        })
        .then((response) => {
          if (response.data.dataset[0]) {
            setSavedQueryId(response.data.dataset[0].id);
            setButtonDisabled(false);
            setSavedSearch(true);
            setStyleClass('saved-search-button');
          } else {
            setButtonDisabled(false);
            setSavedSearch(false);
            setStyleClass('save-search-button');
          }
          setExistingSave(true);
        })
        .catch((error) => {
          console.error(error);
          setExistingSave(false);
        });
    }
  });

  const handleClick = (e) => {
    if (!buttonDisabled) {
      setStyleClass('loading-search-button');
      setButtonDisabled(true);
      if (savedSearch) {
        //delete last saved search
        axiosInstance
          .delete(submitURL, {
            data: {
              id: savedQueryId
            }
          })
          .then((response) => {
            setSavedSearch(false);
            setButtonDisabled(false);
            setStyleClass('save-search-button');
            setSnackBarTexts({
              ...snackBarTexts,
              msgLabel: t('ui_spear.support.Alert.Search_was_deleted'),
              btnLabel: t('ui_spear.support.Alert.Search_was_deleted_UNDO'),
              searchWasSaved: false
            });
            setOpenSearchSnackbar(true);
            props.deleteSavedSearch(savedQueryId, 'saved');
            // props.updateUserSavedQueries();
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        //trigger send event data via websocket
        sendSaveSearchEvent();
        //save search request
        axiosInstance
          .post(submitURL, {
            ntid: userNTID,
            description: lastSearchedTerm
          })
          .then((response) => {
            setSnackBarTexts({
              ...snackBarTexts,
              msgLabel: '',
              btnLabel: t('ui_spear.support.Alert.Search_was_deleted_UNDO'),
              searchWasSaved: true
            });
            setOpenSearchSnackbar(true);
            setSavedQueryId(response.data.id);
            setSavedSearch(true);
            setButtonDisabled(false);
            setStyleClass('saved-search-button');
            props.setWasUndo(false);
            props.updateUserSavedQueries();
          })
          .catch((error) => {
            console.error(error);
            if (lastSearchedTerm.length > 255) {
              setStyleClass('save-search-button');
              setButtonDisabled(false);
              setOpenSnackBar(true);
            }
          });
      }
    }
  };

  useEffect(
    function () {
      if (props.handleDeleteSavedSearched) {
        setSavedSearch(false);
        setButtonDisabled(false);
        props.setHandleDeleteSavedSearched(false);
      }
      if (props.wasUndo) {
        handleClick();
      }
    },
    [props.handleDeleteSavedSearched, props.wasUndo]
  );

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleCloseSearchSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSearchSnackbar(false);
  };

  const undoDeleteSearch = () => {
    handleClick();
  };

  return (
    <>
      {existingSave && (
        <Grid item>
          <Button
            onClick={() => {
              if (props.searchTerm !== '') {
                handleClick();
              }
            }}
            className={styleClass}
          >
            <div>
              {savedSearch ? (
                <FilledStar className="star_logo mr-3 " />
              ) : (
                <EmptyStar className="star_logo mr-3" />
              )}
            </div>
            <span>
              {savedSearch
                ? t('ui_spear.support.search_saved')
                : t('ui_spear.search.Save_search')}
            </span>
          </Button>
        </Grid>
      )}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          elevation={2}
          severity="error"
          className="snack-bar-error snack-back"
        >
          <Grid
            container
            spacing={2}
            align="center"
            wrap="nowrap"
            justify="center"
          >
            <Grid item xs={12} align="left" className="snack-bar-text">
              {t('ui_spear.support.search_term_too_long')}
            </Grid>
          </Grid>
        </Alert>
      </Snackbar>
      <SearchSnackBar
        backHomePage={props.backHomePage}
        wasSearchSaved={snackBarTexts.searchWasSaved}
        openSnackBar={openSearchSnackbar}
        handleCloseSnackBar={handleCloseSearchSnackbar}
        undoDeleteSearch={undoDeleteSearch}
        MsgLabel={snackBarTexts.msgLabel}
        BtnLabel={snackBarTexts.btnLabel}
      />
    </>
  );
}

export default SaveSearch;
