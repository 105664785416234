/**
 * Encode ITSP and SN IncidentId
 * @param {*} value Incident ID
 * @returns 10 characters string
 */

export const encodeHash = (value) => {
  const scrathpad = 'XZOaJWhRifA1FVL9SDF3m6xTSDP2e';
  let result = '';
  let vlen = String(value).length;
  let slen = scrathpad.length;
  let h = 0;
  for (let i = 0; i < String(value).length; i++) {
    h = h + String(value).charCodeAt(i) * (i % 5);
  }
  for (let j = 0; j < 10; j++) {
    result =
      result +
      scrathpad.charAt(
        (String(value).charCodeAt(j % vlen) + (j + 1) * h) % slen
      );
  }
  return result;
};
