import React from 'react';
import { useTranslation } from 'react-i18next';

const Headline = ({ title, subtitle, itemsQuantity }) => {
  const { t } = useTranslation();
  const announcementLabel = t('ui_spear.myTickets.Due_to_privacy_reasons');

  return (
    <div className="">
      <h4 className="text-3xl font-normal">
        {title} ({itemsQuantity})
      </h4>
      {subtitle && (
        <p className="text-sm py-3">
          <span
            className={`boschicon-bosch-ic-alert-warning-filled text-yellow-500 px-1 text-[17px] }`}
          ></span>
          {announcementLabel}
        </p>
      )}
    </div>
  );
};

export default Headline;
