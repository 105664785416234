import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import axiosInstance from './utils/axiosInstance';

const fetchString = async (url) => {
  try {
    const translation = await axiosInstance.get(url);
    return {
      status: 200,
      data: JSON.stringify(translation.data.data[0])
    };
  } catch (e) {
    return e;
  }
};

const backendOptions = {
  loadPath: `${process.env.REACT_APP_BE_URL}/api/v1/snow/localizations/all?lang={{lng}}`,
  crossDomain: false,
  request: async (options, url, payload, callback) => {
    fetchString(url)
      .then((res) => {
        callback(null, res);
      })
      .catch((e) => {
        console.log(e);
        callback(e);
      });
  }
};

i18next
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    ns: 'translations',
    saveMissing: false,
    debug: process.env.REACT_APP_SSO_NODE_ENV !== 'prod',
    backend: {
      backends: [
        Backend,
        resourcesToBackend((lng, ns) => {
          return import(`../public/${ns}/${lng}.json`);
        })
      ],
      backendOptions: [backendOptions, null]
    },
    interpolation: {
      escapeValue: false,
      useSuspense: true
    },
    react: {
      wait: true
    }
  });

export default i18next;
