import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import { ClickAwayListener } from '@mui/base';

const AddAttachments = ({
  fileList,
  errorFileTooBigMsg,
  handleFileChange,
  handleRemoveFiles,
  showLimitedError
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? 'simple-popover' : undefined;

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const renderFilesUploaded = () => { 
    return (
      fileList &&
      fileList.map((file) => (
        <span key={file.id}>
          <button
            onClick={() => handleRemoveFiles(file.id)}
            className="text-red-600"
          >
            <strong>X</strong>
          </button>
          {'\t' + file.file.name}
        </span>
      ))
    );
  };
  

  const renderTitle = () => {
    return (
      <>
        <div className='flex flex-row'>
          <h1 className="mb-9 font-bold">{t('ui_spear.createTicket.Would_you_like_to_add')}</h1>
          <button className="mb-9 ml-5" onClick={handlePopoverOpen}>
            <span
              style={{
                fontFamily: 'bosch-icon',
                fontWeight: 400,
                color: '#005691'
              }}
            >
              &#xe1ac;
            </span>
          </button>
          {openPopover && (
            <ClickAwayListener onClickAway={handlePopoverClose}>
              <Paper
                onClose={handlePopoverClose}
                sx={{ width: '25rem', height: '8rem', zIndex: 30, marginBottom: '-7rem' }}
                className='overflow-x-scroll '
              >
                <Grid className="grid" sx={{ p: 2 }}>
                  <p className=' flex justify-between'>
                    {t('ui_spear.createTicket.allowedAttachmentsTitleV2')}
                    <span
                      onClick={handlePopoverClose}
                      className="justify-end bosch-icon-np mt-0 mr-6 text-md cursor-pointer"
                    >
                      &#xe0f0;
                    </span>
                  </p>
                </Grid>
              </Paper>
            </ClickAwayListener>
          )}
        </div>
      </>
    )
  }

  return (
    <section className="mt-11">
      <div>
        {renderTitle()}
        <span className="inline-block mb-8">
          {t('ui_spear.createTicket.perfectTicketAttachmentsMessage')}
        </span>
      </div>
      <div className="flex flex-row">
        <label className="flex justify-center items-center border border-change_cust_btn p-3 h-10 text-change_cust_btn hover:text-hover_blue_btns hover:border-hover_blue_btns">
          <input type="file" className="hidden" onChange={handleFileChange} />+{' '}
          {t('ui_spear.createTicket.Add_attachment')}
        </label>
        <div className="ml-44 w-5/12 flex flex-col">
          <span className="text-red-600 text-sm font-bold">
            {errorFileTooBigMsg.trim() !== '' ? errorFileTooBigMsg : ''}
          </span>
          {showLimitedError && (
            <span className="text-red-600 text-sm font-bold">
              {t('ui_spear.createTicket.attachment_only_three_time')}
            </span>
          )}
          {renderFilesUploaded()}
        </div>
      </div>
    </section>
  );
};

export default AddAttachments;
