import React from 'react';
import { useTranslation } from 'react-i18next';

const InstallActionButton = (props) => {
    const { t } = useTranslation();
    const {
        installationStatus,
        installableStatus,
        setOpenInfoModal,
        setOpenUninstallModal,
        setOpenInstallModal,
        modalData,
        setModalData,
        loginName,
        softwareName,
        rbcmid,
        rbcmnr,
        computerId,
        computer
    } = props;

    const localizedTexts = {
        install: t('ui_spear.softwareDetails.install'),
        installation: t('ui_spear.softwareDetails.installation'),
        uninstall: t('ui_spear.softwareDetails.uninstall'),
        Uninstalling: t('ui_spear.softwareDetails.uninstalling'),
        the_software_can_not: t('ui_spear.softwareDetails.the_software_can_not'),
        installation_not_possible: t('ui_spear.softwareDetails.installation_not_possible'),
        pending: t('ui_spear.softwareDetails.pending'),
    }

    const handleClickAvialable = () => {
        if (installableStatus.toUpperCase() === "OK") {
            setModalData({
                ...modalData,
                tittle: localizedTexts.installation,
                description: localizedTexts.the_software_can_not,
                errorMessage: installableStatus,
                icon: require(`../../../static/deviceIcons/bubble-exclamation.png`),
                loginName: loginName,
                softwareName: softwareName,
                rbcmid: rbcmid,
                rbcmnr: rbcmnr,
                computerId: computerId,
                computer: computer
            });
            setOpenInstallModal(true);

        } else {
            setModalData({
                ...modalData,
                tittle: localizedTexts.installation_not_possible,
                description: localizedTexts.the_software_can_not,
                errorMessage: installableStatus,
                icon: require(`../../../static/deviceIcons/bubble-exclamation.png`)
            });
            setOpenInfoModal(true);
        }

    }

    const handleClickInstalled = () => {

        if (installableStatus.toUpperCase() === "OK") {
            setModalData({
                ...modalData,
                tittle: localizedTexts.Uninstalling,
                description: localizedTexts.the_software_can_not,
                errorMessage: installableStatus,
                icon: ``,
                loginName: loginName,
                softwareName: softwareName,
                rbcmid: rbcmid,
                rbcmnr: rbcmnr,
                computerId: computerId,
                computer: computer
            });
            setOpenUninstallModal(true);
        } else {
            setModalData({
                ...modalData,
                tittle: localizedTexts.installation_not_possible,
                description: localizedTexts.the_software_can_not,
                errorMessage: installableStatus,
                icon: require(`../../../static/deviceIcons/bubble-exclamation.png`)
            });
            setOpenInfoModal(true);
        }

    }

    const _renderActionButton = () => {
        let actionBtn = null;
        if (installationStatus != null && installableStatus != null) {
            if (installationStatus.toUpperCase() === "AVAILABLE") {
                if (installableStatus.toUpperCase() === "OK") {
                    actionBtn = <button className='bg-ITSP_blue text-white  border-solid border-ITSP_blue  border-[1px] w-[211px] h-[38px] font-Bosch_sans font-light text-[19px]'
                        onClick={e => handleClickAvialable()}>{localizedTexts.install}</button>
                } else {
                    actionBtn = <button className='bg-white border-solid border-[#525c36] text-[#525c36] border-[1px] w-[211px] h-[38px] font-Bosch_sans font-light text-[19px]'
                        onClick={e => handleClickAvialable()}>{localizedTexts.install}</button>
                }
            } else if (installationStatus.toUpperCase() === "INSTALLED") {
                //if software is ok to uninstall
                if (installableStatus.toUpperCase() === "OK") {
                    actionBtn = <button className='bg-white border-solid border-ITSP_blue text-ITSP_blue border-[1px] w-[211px] h-[38px] font-Bosch_sans font-light text-[19px]'
                        onClick={e => handleClickInstalled()}>{localizedTexts.uninstall}</button>
                } else {
                    actionBtn = <button className='bg-white border-solid border-[#525c36] text-[#525c36] border-[1px] w-[211px] h-[38px] font-Bosch_sans font-light text-[19px]'
                        onClick={e => handleClickInstalled()}>{localizedTexts.uninstall}</button>
                }
            }
            else {
                actionBtn = <button className="w-[209] text-[14px] pl-3 text-[#525c36]">{installationStatus}</button>
            }

        }
        return actionBtn;
    }

    return (
        <div>
            {_renderActionButton()}
        </div>
    );
};

export default InstallActionButton;