import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const InstallationTab = (props) => {
  const { t } = useTranslation();

  return (
    <nav>
      <ul className="px-10 pt-4 flex flex-row justify-center font-light text-base">
        <Link
          className={` list-none pb-3.5 px-7 hover:text-ITSP_blue hover:no-underline ${!props.installedSoftware
            ? 'cursor-pointer border-b-2 border-ITSP_blue text-ITSP_blue'
            : 'cursor-pointer border-b-2 border-transparent'
            }`}
          onClick={() => {
            props.setInstalledSoftware(false);
            props.setSoftwareSearch('');
            props.setCostFilter('No Filter');
            props.setSelectedCategory('All');
          }}
        >
          {t('ui_spear.softwarePage.available_software')}
        </Link>
        <Link
          className={`list-none pb-3.5 px-7 hover:text-ITSP_blue hover:no-underline ${props.installedSoftware
            ? 'cursor-pointer border-b-2 border-ITSP_blue text-ITSP_blue'
            : 'cursor-pointer border-b-2 border-transparent'
            }`}
          onClick={() => {
            props.setInstalledSoftware(true);
            props.setSoftwareSearch('');
            props.setCostFilter('No Filter');
            props.setSelectedCategory('All');
          }}
        >
          {t('ui_spear.softwarePage.installed_software')}
        </Link>
      </ul>
      <hr />
    </nav>
  );
};

export default InstallationTab;
