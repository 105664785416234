import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axiosInstance';

const SoftwareFilters = (props) => {
  const { t } = useTranslation();

  const renderCategories = () => {
    let categoryList;

    categoryList = props.softwareCategories.map((category, index) => (
      <MenuItem
        value={category.SWCATEGORY}
        key={index + 1}
        className="font-Bosch_sans text-sm"
      >
        {category.SWCATEGORY}
      </MenuItem>
    ));
    return categoryList;
  };

  const renderDevices = () => {
    let deviceList;

    deviceList = props.userDevices.map((device, index) => (
      <MenuItem
        value={device.deviceId}
        key={index + 1}
        className="font-Bosch_sans text-sm"
      >
        {device.deviceName}
      </MenuItem>
    ));
    return deviceList;
  };

  useEffect(() => {
    loadCategories();
    loadDevices();
  }, [props.userInfo]);

  const loadCategories = () => {
    let categoriesURL = `${process.env.REACT_APP_BE_URL}/api/v1/cms/software/categories`;

    axiosInstance
      .get(categoriesURL)
      .then((response) => {
        props.setSoftwareCategories(response.data.data);
      })
      .catch((error) => {
        props.setSoftwareCategories([]);
      });
  };

  const loadDevices = () => {
    let devicesURL = `/api/v1/cms/devices/${props?.userInfo?.userNTID}`;

    axiosInstance
      .get(devicesURL)
      .then((response) => {
        let devices = response.data.data;
        let validDevices = [];

        devices.map((device) => {
          if (
            device.ITSP_SERVICE_TYPE_NAME === 'Desktop' ||
            device.ITSP_SERVICE_TYPE_NAME === 'Notebook'
          ) {
            let validDevice = {
              deviceName: `${device.NODE_NAME} (${device.DEVICE_CLASS})`,
              deviceId: device.ID
            };
            validDevices.push(validDevice);
            if (validDevices.length === 1) {
              props.setSelectedDevice(device.ID);
            }
          }
        });
        props.setUserDevices(validDevices);
      })
      .catch((error) => {
        props.setUserDevices([]);
      });
  };

  const handleDeviceChange = (event) => {
    props.setSelectedDevice(event.target.value);
  };

  const handleCostChange = (event) => {
    props.setCostFilter(event.target.value);
  };

  const handleCategoryChange = (event) => {
    props.setSelectedCategory(event.target.value);
  };

  const handleSearchChange = (event) => {
    props.setSoftwareSearch(event.target.value);
  };

  return (
    <>
      <Grid className="mt-5 mb-16" container spacing={2}>
        <Grid item xs={3} className="w-full">
          <Typography className="font-Bosch_sans_bold text-gray-400 text-sm mb-3">
            {t('ui_spear.softwarePage.search_filter')}
          </Typography>
          <TextField
            placeholder={t('ui_spear.softwarePage.search_placeholder')}
            variant="standard"
            className="font-Bosch_sans text-sm w-full"
            onChange={handleSearchChange}
            value={props.softwareSearch}
            inputProps={{ style: { fontFamily: 'BoschSans' } }}
          />
        </Grid>
        <Grid item xs={3} className="w-full">
          <Typography className="font-Bosch_sans_bold text-gray-400 text-sm mb-3">
            {t('ui_spear.softwarePage.device_filter')}
          </Typography>
          <FormControl variant="standard" className="w-full">
            <Select
              MenuProps={{
                sx: {
                  position: 'absolute'
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                PaperProps: {
                  style: {
                    maxHeight: 400,
                    width: 250
                  }
                }
              }}
              className="font-Bosch_sans text-sm w-full mt-[2px]"
              value={props.selectedDevice}
              onChange={handleDeviceChange}
            >
              {renderDevices()}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} className="w-full">
          <Typography className="font-Bosch_sans_bold text-gray-400 text-sm mb-3">
            {t('ui_spear.softwarePage.cost_filter')}
          </Typography>
          <FormControl variant="standard" className="w-full">
            <Select
              MenuProps={{
                sx: {
                  position: 'absolute'
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                PaperProps: {
                  style: {
                    maxHeight: 400,
                    width: 250
                  }
                }
              }}
              defaultValue={'No Filter'}
              className="font-Bosch_sans text-sm w-full mt-[2px]"
              value={props.costFilter}
              onChange={handleCostChange}
            >
              <MenuItem value={'No Filter'} className="font-Bosch_sans text-sm">
                {t('ui_spear.softwarePage.cost_no_filter')}
              </MenuItem>
              <MenuItem value={'Free'} className="font-Bosch_sans text-sm">
                {t('ui_spear.softwarePage.cost_free')}
              </MenuItem>
              <MenuItem
                value={'Chargeable'}
                className="font-Bosch_sans text-sm"
              >
                {t('ui_spear.softwarePage.cost_chargeable')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} className="w-full">
          <Typography className="font-Bosch_sans_bold text-gray-400 text-sm mb-3">
            {t('ui_spear.softwarePage.software_category')}
          </Typography>
          <FormControl variant="standard" className="w-full">
            <Select
              MenuProps={{
                sx: {
                  position: 'absolute'
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                PaperProps: {
                  style: {
                    maxHeight: 400,
                    width: 250
                  }
                }
              }}
              defaultValue={'All'}
              className="font-Bosch_sans text-sm w-full mt-[2px]"
              value={props.selectedCategory}
              onChange={handleCategoryChange}
            >
              <MenuItem
                value={'All'}
                key={0}
                className="font-Bosch_sans text-sm"
              >
                {t('ui_spear.softwarePage.all_category')}
              </MenuItem>
              {renderCategories()}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default SoftwareFilters;
