import React, { useEffect } from 'react';
import { Box, Avatar, Menu, MenuItem, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import LanguageSelector from './LanguageSelector';

const Profile = ({ portalLanguages, userInfo, handleLanguageChange }) => {
  const { t } = useTranslation();
  const languageLabel = t('ui_spear.Profile.Language');
  const serviceManagerLabel = t('ui_spear.Profile.Service_Manager');
  const logoutLabel = t('ui_spear.Profile.Logout');
  const [userInitials, setUserInitials] = React.useState('');
  const [userFullName, setUserFullName] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const SERVICE_MANAGER_URL = `${process.env.REACT_APP_CPSC_URL}/RequestCenter/servicemanager/homepage.do?datatableID=ServiceMgrStaticViews`;

  useEffect(() => {
    if (userInfo) {
      setUserInitials(userInfo?.userInitials);
      setUserFullName(`${userInfo?.userGivenName} ${userInfo?.userFamilyName}`);
    }
  }, [userInfo]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    try {
      Cookies.remove('spear-sso');
    } catch (err) {
      //do nothing, cookie eventually expires
    }
    window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
  };

  return (
    userInfo && (
      <React.Fragment>
        <Box
          className="float-right"
          sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
        >
          <IconButton
            onClick={handleClick}
            disableRipple
            size="small"
            sx={{
              ml: 2,
              fontSize: 14,
              '&:hover': { backgroundColor: 'transparent' }
            }}
            aria-controls={open ? 'profile-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar
              sx={{ width: 32, height: 32, fontSize: 10 }}
              className="font-normal bg-[#0000026] cursor-pointer"
            >
              {userInitials && userInitials}
            </Avatar>
            <span className="font-normal text-[#343d47] no-underline hover:text-[#428bca] ml-1.5 cursor-pointer">
              {userFullName && userFullName}
            </span>
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="profile-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleClose}>
            <div>{languageLabel}</div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <LanguageSelector
              userLanguage={userInfo?.userLanguage}
              portalLanguages={portalLanguages}
              handleLanguageChange={handleLanguageChange}
            />
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link
              to={`${SERVICE_MANAGER_URL}`}
              className="float-left font-normal text-[#343d47] no-underline text-md "
            >
              {serviceManagerLabel}
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link
              onClick={() => {
                handleLogout();
              }}
              className="float-left font-normal text-[#343d47] no-underline text-md "
            >
              {logoutLabel}
            </Link>
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  );
};

export default Profile;
