import React, { useState, useRef, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography,
  Button,
  Box,
  Stack
} from '@mui/material';
import * as ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import Feedback from '../Feedback/Feedback';
import ShareArticle from '../ShareArticle/ShareArticle';
import { styled } from '@mui/system';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const showArticleFeedbackEnv = String(
  process.env.REACT_APP_SHOW_ARTICLE_FEEDBACK
);

const StyledDivSolution = styled('div')({
  fontFamily: 'BoschSans !important'
});

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        '.MuiAccordionSummary-expandIconWrapper.Mui-expanded & > .collapsIconWrapper':
        {
          display: 'none'
        },
        '.expandIconWrapper': {
          display: 'none'
        },
        '.MuiAccordionSummary-expandIconWrapper.Mui-expanded & > .expandIconWrapper':
        {
          display: 'block'
        }
      }}
    >
      <div className="expandIconWrapper">
        <span className="boschicon-bosch-ic-less-minimize result-toggle-icon text-5xl text-ITSP_blue hover:text-hover_blue_btns" />
      </div>
      <div className="collapsIconWrapper">
        {' '}
        <span className="boschicon-bosch-ic-add  result-toggle-icon text-5xl text-ITSP_blue hover:text-hover_blue_btns" />
      </div>
    </Box>
  );
};

const Article = (props) => {
  const { t } = useTranslation();
  const [processedDescription, setProcessedDescription] = useState('');
  const [processedSolution, setProcessedSolution] = useState('');
  const [expandableSolution, setExpandableSolution] = useState(false);
  const [subPages, setSubPages] = useState([]);
  const [open, setOpen] = useState(false);
  const [openArticle, setOpenArticle] = useState(false);
  const [feedbackButton, setFeedbackButton] = useState('');
  const [lastDayModified, setLastDateModified] = useState('');

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const solutionDecriptionRef = useRef();
  const decriptionRef = useRef();
  const boxSubpages = useRef([]);

  useEffect(() => {
    if (props.articleData) {
      processArticleHtml();
    }

    if (!props.hasAccordion) {
      setOpenArticle(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.articleData]);

  useEffect(() => {
    const lastDate = props.articleData.versionCreatedDate;
    const days = Math.ceil(
      Math.abs(new Date(lastDate) - new Date()) / (1000 * 60 * 60 * 24)
    );
    setLastDateModified(days);
  }, [props]);

  const getArticleType = () => {
    if (props.articleData.pageType === 'HOWTO') {
      return (
        <>
          <div className="boschicon-bosch-ic-document text text-[16px] mr-1"></div>
          {t('ui_spear.search.solution_article')}
        </>
      );
    }
    if (props.articleData.pageType === 'FAQ') {
      return (
        <>
          <div className="boschicon-bosch-ic-document text text-[16px] mr-1"></div>
          {t('ui_spear.search.FAQ')}
        </>
      );
    }
    if (props.articleData.pageType === 'PROBLEM') {
      return (
        <>
          <div className="boschicon-bosch-ic-document-copy text text-[16px] mr-1"></div>
          {t('ui_spear.search.Problem')}
        </>
      );
    }
  };

  const getArticleTypeText = () => {
    if (props.articleData.pageType === 'HOWTO') return 'Solution Article';
    if (props.articleData.pageType === 'FAQ') return 'FAQ';
    if (props.articleData.pageType === 'PROBLEM') return 'Problem';
  };

  const handleArticleClose = () => {
    props.setArticlePopUp(null);
    setOpenArticle(false);
  };

  const getArticleLevel = () => {
    if (
      props.articleData.generatedLevel &&
      props.articleData.generatedLevel.length > 1
    ) {
      return `${props.articleData?.generatedLevel[0]}, ${props.articleData?.generatedLevel[1]}`;
    } else {
      return `${props.articleData.contentLevel}`;
    }
  };

  const getClassNameArticle = () => {
    return 'result-title';
  };

  const imageClick = (image) => {
    if (!image.getAttribute('src')) {
      return;
    }
    let expandedImage = { src: image.getAttribute('src') };
    props.setImagePopUp(expandedImage);
  };

  const processArticleHtml = async () => {
    let descriptionHtml = props.articleData.description;
    let solutionHtml = props.articleData.solutionDescription;

    if (descriptionHtml !== '') {
      //eslint-disable-next-line
      const pattern = /<p><br[\/]?><[\/]?p>/g;
      descriptionHtml = descriptionHtml.replace(pattern, '');
    }

    // embed boxes in description
    if (props.articleData?.property?.boxes?.length > 0) {
      descriptionHtml = await embedBoxes(
        descriptionHtml,
        props.articleData.property.boxes
      );
    }

    // remove empty <p> tags from solution HTML
    if (solutionHtml !== '' && solutionHtml !== undefined) {
      solutionHtml = solutionHtml.replace(/<p><br\s[\/]?><[\/]?p>/g, '');  // eslint-disable-line no-useless-escape
    }

    // embed boxes in solution-description
    if (solutionHtml !== '' && props.articleData?.property?.boxes?.length > 0) {
      solutionHtml = await embedBoxes(
        descriptionHtml,
        props.articleData.property.boxes
      );
    }
    setProcessedDescription(descriptionHtml);
    setProcessedSolution(solutionHtml);
  };

  const setupLinksNew = (availableCollection) => {

    const links = decriptionRef.current.querySelectorAll('a');
    const parent = decriptionRef.current.querySelector('ul');
    let noLinkArticle = [];
    /*     console.log(availableCollection)
        console.log(links)
        console.log(parent)
        console.log(noLinkArticle); */
    links.forEach(link => {
      let filteredTitle = link.text
      // Look for an element's id in the availableCollection that matches the placeholderId
      const matchingElement = availableCollection.find((el) => filteredTitle.includes(el.title.toString()));
      /* console.log(matchingElement) */
      // If matchingElement doesn't have body, It will open in a new tab
      if (matchingElement) {
        let tempArray = matchingElement.kmsArticleUrl.split("=");
        let snowKmId = (tempArray.length > 0) ? tempArray[1] : '';

        if (matchingElement.body !== undefined) {
          // If available, configure link
          // We add href with an id because browser needs to know what link was clicked
          var current_url = window.location.href;
          //set the new title into the link
          link.innerHTML = matchingElement.title;
          // add new styles
          link.style.pointerEvents = "auto";
          link.style.color = "#0c6dce";
          link.style.fontWeight = "200";

          link.setAttribute("href", "#" + snowKmId)
          link.addEventListener("click", (e) => {
            e.preventDefault();
            window.history.pushState({}, ``, e.target.href);
            window.history.replaceState({}, ``, current_url);
            handleKmsClick(matchingElement);
          });
        } else {
          // If not available, open in a new tab
          link.setAttribute("href", "/?article=" + snowKmId)
          link.setAttribute("target", "_blank")
        }
      }
      else {
        if (link.className !== 'external-link') {
          noLinkArticle.push(link);
          link.parentElement.remove();
        }
      }
    });
    if (parent) {
      noLinkArticle.forEach(link => {
        let li = document.createElement('li');
        li.appendChild(link);
        parent.appendChild(li);
      })
    }
  }

  // ----------- BOXES LOGIC
  const embedBoxes = async (html, boxesArray) => {
    const tempParent = document.createElement('div');
    tempParent.innerHTML = html;
    const foundElements = tempParent.querySelectorAll('[box_place_holder_id]');
    for (let x = 0; x < foundElements.length; x++) {
      // find box container in the parent markup
      const container = foundElements[x];
      const containerId = container.getAttribute('box_place_holder_id');
      const box = boxesArray.find(
        (b) => b.boxPlaceHolderId.toString() === containerId.toString()
      );
      if (box) {
        // insert box in the html
        boxSubpages.current = [...boxSubpages.current, ...box.boxSubpages];
        const insertableBox = await wrapCondition(boxesArray[x]);
        container.innerHTML = insertableBox;
      }
    }
    return tempParent.innerHTML;
  };

  const wrapCondition = async (box) => {
    let boxBody = box.boxBody;
    const parent = document.createElement('div');
    let boxClass = '';

    // Validation to set class depending of incoming icon in span
    if (props.articleData?.property?.boxes?.length > 0) {
      if (
        props.articleData?.property?.boxes[0]?.boxBody.includes(
          'aui-iconfont-warning'
        )
      ) {
        boxClass = 'confluence-information-macro-note';
      } else {
        boxClass = 'confluence-information-macro-information';
      }
    }

    const reactElement = (
      <Grid item className="result-pre-condition">
        <div className={boxClass}>
          <div className="confluence-information-macro-body">
            <div
              dangerouslySetInnerHTML={{
                __html: boxBody
              }}
            />
          </div>
        </div>
      </Grid>
    );
    // Resolve promise when the component is rendered
    return new Promise((resolve) => {
      ReactDOM.render(reactElement, parent, () => {
        resolve(parent.innerHTML);
      });
    });
  };

  // ---------------------> KMS LINKS LOGIC
  const setupExpanders = async (descRef) => {
    if (descRef.current) {
      // get all possible expanders
      const expanders = descRef.current.querySelectorAll('summary');
      if (expanders.length > 0) {
        expanders.forEach((expander) => {
          //creating span icon
          var span = document.createElement("span");
          span.classList.add("expand-icon");
          span.classList.add("aui-icon");
          span.classList.add("aui-icon-small");
          span.classList.add("aui-iconfont-chevron-right")
          span.innerHTML = "&nbsp;";
          expander.prepend(span);
          //adding mising classes
          expander.classList.add("expand-control");
          expander.classList.add("expand-control-text");
          expander.classList.add("conf-macro-render");
          // Assign a click event to every found expander
          expander.addEventListener('click', () => {
            // Assign self a show class to identify the icon
            expander.classList.toggle('expand-control-show');
            // Toggle the hidden class for the content div
            expander.nextSibling.nextSibling.classList.toggle('expand-hidden');
          });
        });
      }
    }
  };

  // ********* Setup backbone subpages (subpages, etc...) *********** //
  // This function receives 2 parameters required to setup links in result articles
  // -------------> availableCollection (array of article objects)
  // -------------> string of query selection for finding the clickable element in the HTML
  const setupLinks = (availableCollection) => {
    const links = decriptionRef?.current?.querySelectorAll('a');
    const parent = decriptionRef?.current?.querySelectorAll('ul');
    let noLinkArticles = [];
    links.forEach((link) => {
      let filteredLinkTitle = link.text;
      const matchingLinkElement = availableCollection.find((el) => filteredLinkTitle.includes(el.title.toString()));
      // If matchingElement doesn't have body, It will open in a new tab
      if (matchingLinkElement) {
        let tempArray = matchingLinkElement.kmsArticleUrl.split('=');
        let snowArticleId = (tempArray.length > 0) ? tempArray[1] : '';

        if (matchingLinkElement.body !== undefined) {
          // If available, configure link
          // We add href with an id because browser needs to know what link was clicked
          var current_url = window.location.href;
          //set the new title into the link
          link.innerHTML = matchingLinkElement.title;
          // add new styles
          link.style.pointerEvents = "auto";
          link.style.color = "#0c6dce";
          link.style.fontWeight = "200";

          link.setAttribute("href", "#" + snowArticleId);
          link.addEventListener("click", (e) => {
            e.preventDefault();
            window.history.pushState({}, ``, e.target.href);
            window.history.replaceState({}, ``, current_url);
            handleKmsClick(matchingLinkElement);
          });
        } else {
          // If not available, open in a new tab
          link.setAttribute("href", "/?article=" + snowArticleId)
          link.setAttribute("target", "_blank")
        }
      } else {
        if (link.className !== 'external-link' && link.parentElement !== null && link.parentElement.tagName === 'LI') {
          noLinkArticles.push(link);
          link.parentElement.remove();
        }
      }
    });
    if (parent) {
      noLinkArticles.forEach(link => {
        let li = document.createElement('li');
        li.appendChild(link);
        parent.appendChild(li);
      });
    }
  };

  // update links after view is rendered
  useEffect(() => {
    if (decriptionRef?.current) {
      //iterate colums to resize
      const tablesConfluence = decriptionRef?.current?.querySelectorAll(
        '.confluenceTable tr td:nth-child(2) .content-wrapper'
      );
      // ********** Subpages configuration ********** //
      if (props.articleData.property || props.articleData.subpages) {
        /* setupLinks(
          [
            ...boxSubpages.current,
            ...(props.articleData.property.subpages ||
              props.articleData.subpages)
          ]
          ],
          '[link_place_holder_id]'
        ); */
        setupLinksNew(
          [...boxSubpages.current, ...(props.articleData.property.subpages || props.articleData.subpages)]
        );
      } else {
        setupLinks([...boxSubpages.current, []]);
      }

      //Add image clickable method
      const images = decriptionRef.current.querySelectorAll('.kms_thumbnail');
      images.forEach((image) => {
        image.addEventListener('click', () => {
          imageClick(image);
        });
      });
    }

    // Check solution article Height
    if (
      solutionDecriptionRef?.current !== undefined ||
      solutionDecriptionRef?.current != null
    ) {
      var solArtHeight = solutionDecriptionRef?.current.clientHeight;
      solArtHeight > 60
        ? setExpandableSolution(true)
        : setExpandableSolution(false);
    }

    // Setup expanders
    setupExpanders(decriptionRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedDescription]);

  // update links after view is rendered
  useEffect(() => {
    if (solutionDecriptionRef?.current) {
      // get list of all possible kms links
      const links = solutionDecriptionRef.current.querySelectorAll("a");
      if (links.length > 0) {
        const linksCollection = [
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...props?.articleData?.property.subpages,
          ...boxSubpages.current
        ];
        setSubPages(linksCollection);
        // iterate kms links embeded in solutionDescription
        links.forEach((link) => {
          /* const id = link.getAttribute('link_place_holder_id');
          const linkItem = linksCollection.find(
            (l) => String(l.subpageLinkPlaceHolderId) === id
          ); */
          let filteredTitle = link.text;
          const linkItem = linksCollection.find((el) => filteredTitle.includes(el.title.toString()));
          // We add href with an id because browser needs to know what link was clicked
          if (linkItem) {
            // We add href with an id because browser needs to know what link was clicked
            let tempArray = linkItem.kmsArticleUrl.split("=");
            let snowKmId = (tempArray.length > 0) ? tempArray[1] : '';
            // We add href with an id because browser needs to know what link was clicked
            var current_url = window.location.href;

            link.innerHTML = linkItem.title;
            // add new styles
            link.style.pointerEvents = "auto";
            link.style.color = "#0c6dce";
            link.style.fontWeight = "200";

            link.setAttribute("href", "#" + snowKmId)

            //link.setAttribute("href", "#" + id);
            link.addEventListener("click", (e) => {
              e.preventDefault();
              window.history.pushState({}, ``, e.target.href);
              window.history.replaceState({}, ``, current_url);
              handleKmsClick(linkItem);
            });
            // Copy links title as plain text and not as a hyperlink
            link.addEventListener("copy", () => {
              navigator.clipboard.writeText(linkItem.title)
            })
          }
        });
      } else {
        setSubPages([]);
      }
      //Add sulution images clickable method
      const sulutionImages =
        solutionDecriptionRef?.current?.querySelectorAll('.kms_thumbnail');
      sulutionImages.forEach((image) => {
        image.addEventListener('click', () => {
          imageClick(image);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedSolution, expandableSolution, props.popUpActive]);

  // TODO: Review with backbone data structure of different article layers (root, subpage, boxSubpage)
  const handleKmsClick = (link) => {
    console.log(link)
    const linkCasted = {
      ...link,
      description: link?.body || link?.description || '',
      property: {
        boxes: link?.boxes || link?.property?.boxes || [],
        subpages: link?.subpages || link?.property?.subpages || []
      }
    };
    props.setArticlePopUp(linkCasted);
    props.onArticlePopUp(linkCasted, props.articleData, props.listIndex);
  };

  const ArticleHeader = () => {
    //Remove slashs foward quotes from title
    const articleTitle = props.articleData.title.replace(/\/"/g, "\"");
    const replaceSemicolon = props.articleData.availableLocations
      ? props.articleData.availableLocations.replaceAll(';', ' / //')
      : '';
    const splitLocations = replaceSemicolon
      ? [replaceSemicolon.split(' //')]
      : '';
    const cleanLocations = splitLocations;
    const tooltipTitle = [];
    if (cleanLocations !== '') {
      for (let location of cleanLocations[0]) {
        tooltipTitle.push(
          <span
            style={{ display: 'flex', paddingBottom: '5px', paddingTop: '5px' }}
            key={location}
          >
            {location.replaceAll('/', '')}
          </span>
        );
      }
      tooltipTitle.splice(1, 2);
    } else {
      tooltipTitle.pop();
    }

    return (
      <>
        <Grid align="left">
          <div>
            <Grid
              item
              className="result-breadcrumb text-ITSP_blue font-Bosch_sans flex gap-[15px]"
            >
              <div
                style={{ marginBottom: '10px' }}
                className="flex items-center text-[10px]"
              >
                {getArticleType()}
              </div>
              <div className="">
                {!props.articleData.availableLocations ? (
                  ''
                ) : cleanLocations[0].length <= 3 ? (
                  <div className="flex items-center text-xs">
                    <div className="boschicon-bosch-ic-locator text text-[16px]"></div>
                    <div style={{ border: 'solid red!important' }}>
                      {cleanLocations}
                    </div>
                  </div>
                ) : cleanLocations[0].length > 3 ? (
                  <div className="flex">
                    <div className="result-icon">&#xe1c3;</div>
                    {cleanLocations[0].length > 10 ? (
                      <Tooltip
                        disableFocusListener
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        title={tooltipTitle}
                        arrow
                        placement="right-start"
                      >
                        <span>
                          {cleanLocations[0][0]}{' '}
                          {cleanLocations[0][1].replace('/', '')}{' '}
                          {t('ui_spear.support.search_and')}{' '}
                          {cleanLocations[0].length - 2}{' '}
                          {t('ui_spear.support.search_other')}
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        disableFocusListener
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        title={tooltipTitle}
                        arrow
                        placement="right-start"
                      >
                        <span>
                          {cleanLocations[0][0]}{' '}
                          {cleanLocations[0][1].replace('/', '')}{' '}
                          {t('ui_spear.support.search_and')}{' '}
                          {cleanLocations[0].length - 2}{' '}
                          {t('ui_spear.support.search_other')}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Grid>
          </div>
          <Grid
            item
            className="result-title font-Bosch_sans text-2xl hover:text-article_blue"
          >
            {articleTitle}
          </Grid>
        </Grid>
      </>
    );
  };

  const ArticleBody = (hasAccordion) => {
    let articleType = getArticleTypeText();
    let articleID = String(props.articleData.id).slice(0, -4);
    let articleSnowID = String(props.articleData.serviceNowId);

    return (
      <>
        <Grid container>
          <Button
            style={{ textTransform: 'none', top: '-10px' }}
            disabled
          >{`${t('ui_spear.support.search_last_update')} ${lastDayModified} ${t(
            'ui_spear.support.search_days_ago'
          )}`}</Button>
          {
            <ShareArticle
              articleSnowID={articleSnowID}
              articleType={articleType}
              articleID={articleID}
              toggleToastState={props.toggleToastState}
              popUpActive={props.popUpActive}
            />
          }

          <Grid>
            {props.articleData.pageType === 'PROBLEM' ? feedbackButton : ''}
          </Grid>
          {/* DECRIPTION */}
          {processedDescription !== '' && (
            <Grid item style={{ width: '100%' }}>
              {/* If there's a solution, there's a problem */}

              {processedSolution && (
                <span
                  className="font-Bosch_sans text-[14px] "
                  style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    fontWeight: 'bold',
                    color: '#000'
                  }}
                >
                  {t('ui_spear.search.Problem')}
                </span>
              )}
              <Typography className="result-description font-Bosch_sans_light text-base">
                <div
                  className="containerScroll"
                  ref={decriptionRef}
                  dangerouslySetInnerHTML={{
                    __html: processedDescription.replace(
                      // eslint-disable-next-line no-useless-escape
                      /class=\"radio\"/g,
                      'class="radio-frame"'
                    )
                  }}
                />
              </Typography>
            </Grid>
          )}
          {/* SOLUTION DECRIPTION */}
          {processedSolution !== '' &&
            expandableSolution &&
            !props.popUpActive ? (
            <Grid item style={{ width: '100%' }}>
              {hasAccordion && (
                <span
                  className="font-Bosch_sans text-[14px] block text-[#000]"
                  style={{
                    marginBottom: '20px',
                    marginTop: '30px',
                    fontWeight: 'bold'
                  }}
                >
                  {t('ui_spear.support.search_solutions')}
                </span>
              )}
              <Accordion
                className="result-head border sol-desc-result mb-10"
                elevation={0}
                square={false}
                style={{ width: '100%' }}
              >
                <AccordionSummary
                  className="result-title"
                  style={{ zIndex: 1 }}
                  expandIcon={<CustomExpandIcon />}
                  elevation={0}
                >
                  <Grid align="left">
                    <Grid
                      item
                      className="result-breadcrumb text-ITSP_blue font-Bosch_sans flex items-center"
                    >
                      <div className="flex items-center text-[10px]">
                        <div className="boschicon-bosch-ic-document text text-[18px] mr-1"></div>
                        {t('ui_spear.search.solution_article')}
                      </div>
                    </Grid>
                    <Grid item className="result-title">
                      &nbsp;
                    </Grid>
                  </Grid>
                </AccordionSummary>
                {/* ARTICLE BODY CONTENT */}
                <AccordionDetails
                  elevation={0}
                  className="result-details"
                  align="left"
                  style={{ fontSize: '16px', fontWeight: 'light' }}
                >
                  <Grid>
                    <StyledDivSolution
                      ref={solutionDecriptionRef}
                      dangerouslySetInnerHTML={{
                        __html: processedSolution
                      }}
                    />
                  </Grid>
                </AccordionDetails>
                {/* ARTICLE FEEDBACK */}
                {ArticleFeedback()}
              </Accordion>
            </Grid>
          ) : processedSolution !== '' &&
            !expandableSolution &&
            !props.popUpActive ? (
            <Grid item style={{ width: '100%' }}>
              {hasAccordion && (
                <h3 style={{ marginBottom: '20px', marginTop: '20px' }}>
                  {t('ui_spear.support.search_solutions')}
                </h3>
              )}
              <div
                style={{ borderTop: '2px solid #E4E4E4', marginBottom: '10px' }}
              ></div>
              <Typography
                className="result-description font-Bosch_sans_light text-base"
                style={{ marginLeft: '20px', marginBottom: '20px' }}
              >
                <Grid
                  item
                  className="result-breadcrumb text-ITSP_blue font-Bosch_sans"
                >
                  <Stack className="flex items-baseline gap-2 flex-row">
                    <div className="flex items-center text-[10px] mt-1">
                      <div className="boschicon-bosch-ic-document text text-[18px] mr-1"></div>
                      {t('ui_spear.search.solution_article')}
                    </div>
                  </Stack>
                </Grid>
                <div
                  className="pl-2 font-Bosch_sans [&>.radio]:bg-red-900"
                  ref={solutionDecriptionRef}
                  dangerouslySetInnerHTML={{
                    __html: processedSolution
                  }}
                />
              </Typography>
              {/* ARTICLE FEEDBACK */}
              {ArticleFeedback()}
            </Grid>
          ) : (
            processedDescription !== '' &&
            !expandableSolution &&
            !props.popUpActive && (
              <Grid item style={{ width: '100%' }} className="plain-article">
                <Typography className="result-description font-Bosch_sans_light text-base">
                  <div
                    className=""
                    ref={solutionDecriptionRef}
                    dangerouslySetInnerHTML={{
                      __html: processedSolution
                    }}
                  />
                </Typography>
                {/* ARTICLE FEEDBACK */}
                {ArticleFeedback()}
              </Grid>
            )
          )}
        </Grid>
      </>
    );
  };

  const ArticleFeedback = () => {
    return (
      <>
        {showArticleFeedbackEnv === 'true' &&
          (props.articleData.pageType === 'FAQ' ||
            props.articleData.pageType === 'HOWTO' ||
            (props.articleData.pageType === 'PROBLEM' &&
              subPages.length === 0)) && (
            <Feedback
              userAlias={props.userAlias}
              targetUserAlias={props.targetUserAlias}
              searchedQuery={props.searchedQuery}
              resultItem={props.articleData}
              howToArticle={props.articleData}
              index={props.listIndex}
              indexHowTo={-1}
              setArticlePopUp={props.setArticlePopUp}
              setImagePopUp={props.setImagePopUp}
              resultItemTitle={''}
              analyticId={props.analyticId}
              userInfo={props.userInfo}
              first={props.indexClasses === 'article-0'}
            />
          )}
      </>
    );
  };

  return (
    <>
      {props.articleData && (
        <>
          {/* VALIDATION (IF HAS ACCORDION) : (IF HAS NOT ACCORDION) */}
          {props.hasAccordion ? (
            <Accordion
              onChange={props.onArticleCollapse(
                props.articleData,
                props.listIndex
              )}
              className="result-head border"
              elevation={0}
              key={props.articleData.id}
              square={false}
              defaultExpanded={props.openArticleAccordion ? true : false}
            >
              {/* ARTICLE HEADER CONTENT */}
              <AccordionSummary
                className={`${props.indexClasses} ${getClassNameArticle()}`}
                expandIcon={<CustomExpandIcon />}
                elevation={0}
              >
                {ArticleHeader()}
              </AccordionSummary>
              {/* ARTICLE BODY CONTENT */}
              <AccordionDetails
                elevation={0}
                className="result-details"
                align="left"
              >
                {ArticleBody(props.hasAccordion)}
              </AccordionDetails>
            </Accordion>
          ) : (
            <>
              <Dialog
                open={openArticle}
                TransitionComponent={Transition}
                fullWidth={false}
                maxWidth={'xl'}
                keepMounted
                onClose={handleArticleClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle className="flex justify-end items-center h-3">
                  <IconButton
                    className="boschicon-bosch-ic-close text-3xl mt-3"
                    aria-label="close"
                    onClick={handleArticleClose}
                  ></IconButton>
                </DialogTitle>
                <DialogContent>
                  {/* ARTICLE HEADER CONTENT */}
                  {ArticleHeader()}
                  {/* ARTICLE BODY CONTENT */}
                  <br className="mt-2" />
                  {ArticleBody(props.hasAccordion)}
                  {/* ARTICLE FEEDBACK */}
                  {ArticleFeedback()}
                </DialogContent>
              </Dialog>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Article;
