const FooterSectionListItem = ({ content }) => {
  const renderItemWithLink = () => {
    // split the content in the about IT Services
    let splitAboutContentPartOne =
      content.contentTitle.length > 120
        ? content.contentTitle.slice(0, 120)
        : '';
    let splitAboutContentPartTwo =
      content.contentTitle.length > 120 ? content.contentTitle.slice(120) : '';

    if (content.link.trim() === '') {
      return <>{content.contentTitle}</>;
    } else if (splitAboutContentPartOne.trim() !== '') {
      return (
        <>
          {splitAboutContentPartOne}
          {createLink(content.link, splitAboutContentPartTwo)}
        </>
      );
    } else {
      return createLink(content.link, content.contentTitle);
    }
  };

  const createLink = (link, linkName) => {
    return (
      <a
        href={link}
        className="a_footer_blue hover:text-[#474747] hover:underline"
      >
        {linkName}
      </a>
    );
  };

  const renderItemContentInfo = () => {
    if (content.contentInfo !== '') {
      return <span> {content.contentInfo} </span>;
    }
    return null;
  };

  //TODO replace with bosch icons?
  const renderFooterIcon = (icon) => {
    let iconInComponent = null;
    switch (icon) {
      case 'phone':
        iconInComponent = 'fa-solid fa-phone';
        break;
      case 'headset':
        iconInComponent = 'boschicon-bosch-ic-customerservice';
        break;
      case 'bookOpen':
        iconInComponent = 'boschicon-bosch-ic-book';
        break;
      case 'asterisk':
        iconInComponent = 'fa-solid fa-asterisk';
        break;
      default:
        iconInComponent = null;
        break;
    }
    return iconInComponent === null ? null : (
      <i className={`${iconInComponent} text text-[8px] m-0 p-0 pr-1`}></i>
    );
  };

  return (
    <li className="list-none m-0 pt-0 pb-2 px-0">
      {renderFooterIcon(content.iconForm)}
      {renderItemContentInfo()}
      {renderItemWithLink()}
    </li>
  );
};

export default FooterSectionListItem;
