import React from 'react';

const EmptyTickets = ({ text }) => {
  return (
    <div className="border border-black py-20 mt-20 text-3xl font-light text-center">
      {text}
    </div>
  );
};

export default EmptyTickets;
