import { useTranslation } from 'react-i18next';

let icon = 'boschicon-bosch-ic-ticket';
let statusLabel = '';

const TopBanner = ({ ticketId, serviceName, status }) => {
  const { t } = useTranslation();
  switch (String(status).toLocaleLowerCase()) {
    case 'in_progress':
    case 'in progress': // sn response
    case 'ongoing':
      icon = 'boschicon-bosch-ic-refresh';
      statusLabel = `${t('ui_spear.ticketDetails.Banner.In_Progress')}`;
      break;
    case 'in approval': // sn response
    case 'in_approval':
      icon = 'boschicon-bosch-ic-coffee-break';
      statusLabel = `${t('ui_spear.ticketDetails.TicketJourney.In_Approval')}`;
      break;
    // TODO: check sn response
    case 'closed':
    case 'resolved':
    case 'completed':
      icon = 'boschicon-bosch-ic-lock-closed';
      statusLabel = `${t('ui_spear.ticketDetails.Details_Closed')}`;
      break;
    case 'rejected':
      // TODO: check sn response
      icon = 'boschicon-bosch-ic-abort-frame';
      statusLabel = `${t('ui_spear.ticketDetails.Banner.Rejected')}`;
      break;
    case 'cancelled':
      // TODO: check sn response
      icon = 'boschicon-bosch-ic-abort-frame';
      statusLabel = `${t('ui_spear.ticketDetails.Banner.Cancelled')}`;
      break;
    case 'assigned':
    case 'created':
      icon = 'boschicon-bosch-ic-ticket';
      statusLabel = `${t('ui_spear.ticketDetails.Details_Created')}`;
      break;
    case 'Pending':
    case 'pending':
      icon = 'boschicon-bosch-ic-clock';
      statusLabel = `${t('ui_spear.myTickets.Pending')}`;
      break;
    default:
      icon = 'boschicon-bosch-ic-refresh';
  }

  return (
    <div
      id="title"
      className="text-center font-thin py-10 bg-gradient-to-r from-sky-700 to-sky-500 "
    >
      <h4 className="text-white py-2 text-2xl"># {ticketId}</h4>
      <h3 className="text-white py-2 text-4xl">{serviceName}</h3>
      <h5 className="text-white py-2 text-2xl">
        <span
          className={`${icon} text text-2xl`}
          style={{ position: 'relative', top: '2px', marginRight: '5px' }}
        ></span>
        {statusLabel}
      </h5>
    </div>
  );
};

export default TopBanner;
