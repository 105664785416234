import React from 'react';
import { softwareSupport } from '../../../utils/softwareFunctions';
import { useTranslation } from 'react-i18next';



const Details = ({ software }) => {
    const { t } = useTranslation();

    const localizedTexts = {
        software_information: t('ui_spear.softwareDetails.software_information'), //"Software Information:",
        technical_details: t('ui_spear.softwareDetails.technical_detail'),//"Technical details:",
        no_licensing_required: t('ui_spear.softwareDetails.no_licensing_required'),//"No licensing required",
        separate_licensing: t('ui_spear.softwareDetails.separate_licensing'), //"Separate licensing of the software is required via the department.",
        suport_throught_IT: t('ui_spear.softwareDetails.support_through_it'),//"Support through IT-Support Interface: Installation support by CI. Individual Support Channels: Application support outside CI",
        installation_support_interface: t('ui_spear.softwareDetails.full_support_through'),//"Full Support through IT-Support Interface: Installation support by CI, Application support outside CI",
        everything_supported: t('ui_spear.softwareDetails.full_support_through_it'),//"Full Support through IT-Support Interface: Everything supported by CI",
        data_not_aviable: t('ui_spear.softwareDetails.data_not_available'),//"Data not available",
        official_website_of: t('ui_spear.softwareDetails.official_website'),//"Official website of the Software",
        software_category: t('ui_spear.softwareDetails.software_category'),//"Software Category:",
        needed_licenses: t('ui_spear.softwareDetails.needed_licenses'),//Needed Licenses:
        software_support: t('ui_spear.softwareDetails.software_support'),//"Software Support:",
        further_software_information: t('ui_spear.softwareDetails.further_software_information'),//Further software information:,
        alternative_software: t('ui_spear.softwareDetails.alternative_software'),// "Alternative Software:",
        manufacture: t('ui_spear.softwareDetails.manufacture'),//Manufacture:",
        version: t('ui_spear.softwareDetails.version'),//Version:,
        platform: t('ui_spear.softwareDetails.platform'),//Platform:,
        rbcm_number: t('ui_spear.softwareDetails.rbcm_number'),//RBCM Number:
        cost: t('ui_spear.ticketDetails.Details_Cost'),//cost
        everithing_supported: t('ui_spear.softwareDetails.full_support_through_it'),
    }

    const _renderNeededLicense = () => {
        if (parseInt(software.needed_license) === 0) return localizedTexts.no_licensing_required;  //No license
        else if (parseInt(software.needed_license) === 1) return localizedTexts.separate_licensing; //separete license
        else return "-";
    }

    const _renderSoftwareSupport = () => {
        return softwareSupport(software.software_support, localizedTexts);
    }

    const _renderProductUrl = () => {
        return (
            <a className='font-Bosch_sans_light text-[14px]' href={software.producturl} target="_blank" rel="noreferrer">{`${localizedTexts.official_website_of} > `}</a>
        )
    }

    return (
        <>
            <div className='grid grid-cols-2 mt-11 font-Bosch_sans_light'>
                <div className='text-center text-support_banner_color_1 text-[14px]'>{localizedTexts.software_information}</div>
            </div>

            <div className='grid grid-cols-2 gap-8 mt-5 font-Bosch_sans_light text-[14px]'>
                <div className='text-right'>{localizedTexts.software_category}:</div>
                <div className='text-left'>{software.swcategory}</div>

                <div className='text-right'>{localizedTexts.cost}:</div>
                <div className='text-left'>{software.cost}</div>

                <div className='text-right'>{localizedTexts.needed_licenses}:</div>
                <div className='text-left'>{_renderNeededLicense()}</div>

                <div className='text-right'>{localizedTexts.software_support}:</div>
                <div className='text-left'>{_renderSoftwareSupport()}</div>

                <div className='text-right'>{localizedTexts.further_software_information}:</div>
                <div className='text-left'>{software.producturl !== null ? _renderProductUrl() : ''}</div>
                <div className='text-right'>{localizedTexts.alternative_software}</div>
            </div>

            <div className='grid grid-cols-2 mt-11 font-Bosch_sans_light'>
                <div className='text-center text-support_banner_color_1 text-[14px]'>{localizedTexts.technical_details}</div>
            </div>

            <div className='grid grid-cols-2 gap-8 mt-5 font-Bosch_sans_light text-[14px]'>
                <div className='text-right'>{localizedTexts.manufacture}:</div>
                <div className='text-left'>{software.manufacturer}</div>

                <div className='text-right'>{localizedTexts.version}:</div>
                <div className='text-left'>{software.version}</div>

                <div className='text-right'>{localizedTexts.platform}:</div>
                <div className='text-left'>{software.platform}</div>

                <div className='text-right'>{localizedTexts.rbcm_number}:</div>
                <div className='text-left'>{software.RBCMNR}</div>
            </div>
        </>
    );
};

export default Details;