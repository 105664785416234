import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getInstalledSoftwareForSpecificDevice } from '../../../../api/software';
import SoftwareLoader from '../../../Confirmations/SoftwareLoader';
import SoftwareNotAvailable from '../SoftwareNotAvailable';
import SoftwareTablePage from './SoftwareTablePage';
import BoschIcCashIcon from '../../../../static/Bosch-IC-cash-frame.png';

const SoftwareContainerPage = (props) => {
  const { t } = useTranslation();
  const [installedSoftwareFullList, setInstalledSoftwareFullList] = useState(
    []
  );
  const [installedSoftwareList, setInstalledSoftwareList] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('SWNAME');
  const [filters, setFilters] = useState([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [paginationNumber, setPaginationNumber] = useState(0);
  const [searchNumber, setSearchNumber] = useState(0);
  const [loadingSoftware, setLoadingSoftware] = useState(true);

  useEffect(() => {
    if (props?.deviceId !== '') {
      getInstalledSoftware();
    }
  }, [props?.deviceId, props?.installedSoftware]);

  useEffect(() => {
    stackFilterByCategory();
  }, [props?.selectedCategory]);

  useEffect(() => {
    stackFilterByPrice();
  }, [props?.costFilter]);

  useEffect(() => {
    stackFilterBySearch();
  }, [props?.softwareSearch]);

  const triggerFilters = (orderedFilters) => {
    let filteredSoftware = installedSoftwareFullList;
    orderedFilters.forEach((filter) => {
      switch (filter) {
        case 'search':
          filteredSoftware = filterBySearch(filteredSoftware);
          break;
        case 'price':
          filteredSoftware = filterByPrice(filteredSoftware);
          break;
        case 'category':
          filteredSoftware = filterByCategory(filteredSoftware);
          break;
        default:
          break;
      }
    });
    setInstalledSoftwareList(filteredSoftware);
  };

  const filterByPrice = (stackList) => {
    if (props?.costFilter) {
      let filteredList = [];
      switch (props?.costFilter) {
        case 'Free':
          filteredList = [];
          filteredList = stackList.filter((software) => {
            if (
              software.price &&
              software.price.toLowerCase().includes('free')
            ) {
              return software;
            }
          });
          break;
        case 'Chargeable':
          filteredList = [];
          filteredList = stackList.filter((software) => {
            if (
              software.price &&
              !software.price.toLowerCase().includes('free')
            ) {
              return software;
            }
          });
          break;
        default:
          filteredList = installedSoftwareFullList;
          break;
      }
      return filteredList;
    }
  };

  const stackFilterByPrice = () => {
    let tempFilterOrder = filters;
    let previewList = [];
    let index;
    if (props?.costFilter) {
      switch (props?.costFilter) {
        case 'Free':
          index = tempFilterOrder.indexOf('price');
          if (index > -1) {
            tempFilterOrder.splice(index, 1);
            tempFilterOrder.push('price');
          } else {
            tempFilterOrder.push('price');
          }

          previewList = tempFilterOrder;
          setFilters(previewList);
          triggerFilters(previewList);
          break;
        case 'Chargeable':
          index = tempFilterOrder.indexOf('price');
          if (index > -1) {
            tempFilterOrder.splice(index, 1);
            tempFilterOrder.push('price');
          } else {
            tempFilterOrder.push('price');
          }
          previewList = tempFilterOrder;
          setFilters(previewList);
          triggerFilters(previewList);
          break;
        default:
          tempFilterOrder.splice(index, 1);
          setFilters(tempFilterOrder);
          triggerFilters(tempFilterOrder);
          break;
      }
    }
  };

  const filterBySearch = (stackList) => {
    if (props?.softwareSearch) {
      let filteredList = [];
      filteredList = stackList.filter((software) => {
        if (
          software.SWNAME &&
          software.SWNAME.toLowerCase().includes(
            props?.softwareSearch.toLowerCase()
          )
        ) {
          return software;
        }
        if (
          software.SWCATEGORY &&
          software.SWCATEGORY.toLowerCase().includes(
            props?.softwareSearch.toLowerCase()
          )
        ) {
          return software;
        }
      });
      return filteredList;
    } else {
      return installedSoftwareFullList;
    }
  };

  const stackFilterBySearch = () => {
    let tempFilterOrder = filters;
    let previewList = [];
    let index;
    if (props?.softwareSearch) {
      index = tempFilterOrder.indexOf('search');
      if (index > -1) {
        tempFilterOrder.splice(index, 1);
        tempFilterOrder.push('search');
      } else {
        tempFilterOrder.push('search');
      }
      previewList = tempFilterOrder;
      setFilters(previewList);
      triggerFilters(previewList);
    } else {
      index = tempFilterOrder.indexOf('search');
      if (index > -1) {
        tempFilterOrder.splice(index, 1);
        setFilters(tempFilterOrder);
        triggerFilters(tempFilterOrder);
      }
    }
  };

  const filterByCategory = (stackList) => {
    if (props?.selectedCategory) {
      if (props?.selectedCategory === 'All') {
        return installedSoftwareFullList;
      } else {
        let filteredList = [];
        filteredList = stackList.filter(
          (software) => software.SWCATEGORY === props?.selectedCategory
        );
        return filteredList;
      }
    }
  };

  const stackFilterByCategory = () => {
    let tempFilterOrder = filters;
    let previewList = [];
    let index;
    if (props?.selectedCategory) {
      if (props?.selectedCategory === 'All') {
        index = tempFilterOrder.indexOf('category');
        if (index > -1) {
          tempFilterOrder.splice(index, 1);
          setFilters(tempFilterOrder);
          triggerFilters(tempFilterOrder);
        }
      } else {
        index = tempFilterOrder.indexOf('category');
        if (index > -1) {
          tempFilterOrder.splice(index, 1);
          tempFilterOrder.push('category');
        } else {
          tempFilterOrder.push('category');
        }
        previewList = tempFilterOrder;
        setFilters(previewList);
        triggerFilters(previewList);
      }
    }
  };

  useEffect(() => {
    if (installedSoftwareList?.length > 0) {
      const pagination =
        searchNumber % 15
          ? Math.floor(searchNumber / 15) + 1
          : searchNumber / 15;
      if (page > pagination && pagination > 0) {
        setPage(pagination);
      }
      setSearchNumber(installedSoftwareList?.length);
      setPaginationNumber(pagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installedSoftwareList, searchNumber]);

  const getInstalledSoftware = async () => {
    setLoadingSoftware(true);
    try {
      const response = await getInstalledSoftwareForSpecificDevice(
        props?.deviceId,
        props?.installedSoftware
      );
      if (response.length === 0 && Array.isArray(response)) {
        setInstalledSoftwareList(response);
        setInstalledSoftwareFullList(response);
      } else {
        setInstalledSoftwareList(response?.data?.data);
        setInstalledSoftwareFullList(response?.data?.data);
      }
    } catch (error) {
      setLoadingSoftware(false);
    } finally {
      setLoadingSoftware(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleWhenSoftwareHasNumPrice = (cost) => {
    if (cost.includes('EUR')) {
      return cost;
    }
    return;
  };

  const handleSoftwareCost = (price) => {
    switch (price) {
      case 'Not-Available':
        return (
          <>
            <img
              src={BoschIcCashIcon}
              alt="price"
              style={{ height: '36px', width: '36px' }}
            />
          </>
        );
      case 'Free':
        return price;
      case handleWhenSoftwareHasNumPrice(price):
        return `${price}`;
      default:
        return 'Free';
    }
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const visibleRows = useMemo(
    () =>
      // Nullish coalescing operator handles case when API call fail's, sets empty array
      stableSort(
        installedSoftwareList ?? [],
        getComparator(order, orderBy)
      ).slice(0, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, installedSoftwareList]
  );

  return (
    <>
      {loadingSoftware ? (
        <SoftwareLoader />
      ) : installedSoftwareList?.length === 0 ? (
        <SoftwareNotAvailable originComponent={'softwarePage'} />
      ) : (
        <SoftwareTablePage
          visibleRows={visibleRows}
          order={order}
          orderBy={orderBy}
          page={page}
          stableSort={stableSort}
          getComparator={getComparator}
          handleRequestSort={handleRequestSort}
          handleChangePage={handleChangePage}
          paginationNumber={paginationNumber}
          handleSoftwareCost={handleSoftwareCost}
          originSoftwareFlag={props.originSoftwareFlag}
          setOriginSoftwareFlag={props.setOriginSoftwareFlag}
        />
      )}
    </>
  );
};

export default SoftwareContainerPage;
