import TopBanner from '../components/TicketDetails/TopBanner';
import { Grid } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import {
  Link,
  useParams,
  useSearchParams,
  useLocation,
  useNavigate
} from 'react-router-dom';
import Loader from '../components/Confirmations/Loader';

import TicketJourney from '../components/TicketDetails/TicketJourney';
import Comments from '../components/TicketDetails/Comments';
import Details from '../components/TicketDetails/Details';
import DetailsTabs from '../components/TicketDetails/DetailsTabs';
import { useTranslation } from 'react-i18next';

import SupportBar from '../components/SupportBar/SupportBar';

import { setSMTItemForTicketJourney } from '../utils/smtFormatting';
import { setCPSCtemForTicketJourney } from '../utils/cpscFormatting';
import { getSapKeyUser } from '../api/ticketsEndpoints';
import axiosInstance from '../utils/axiosInstance';

const TicketJourneyPage = (props) => {
  const { t } = useTranslation();

  // Translations
  const alertNotAllowedToSeeSmtDetails = t(
    'ui_spear.sapKeyUser.You_are_only_authorized'
  );

  const { userInfo, isSupportEntitle } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [source, setSource] = useState();
  const [isDataAvailable, setisDataAvailable] = useState(false);
  const [showTicketJourney, setShowTicketJourney] = useState(true);
  const [originalLastDateTime, setOriginalLastSetTime] = useState('');
  const [originalSubmittedDate, setOriginalSubmittedDate] = useState('');
  const [rawTicketData, setRawTicketData] = useState('');
  const [smtTicketSummary, setSmtTicketSummary] = useState('');
  const [sapKeyUser, setSapKeyUser] = useState('');
  const [TicketJourneyPage, setTicketJourneyPage] = useState({
    activity_id: '',
    status: '',
    serviceName: '',
    serviceDescription: '',
    performer: '',
    submittedDate: '',
    closedDate: '',
    taskDate: '',
    lastDateTime: '',
    cost: '',
    type: '',
    priority: '',
    onBehalf: '',
    requested_for: '',
    requisitionDueDate: '',
    sys_id: '',
    resolution: undefined,
    rejection_reason: '',
    requisition_id: '',
    performerLogin: ''
  });

  const handleTabClick = (val) => {
    setShowTicketJourney(val);
  };

  const getSmtDetailsAndValidateSapKeyUser = async () => {
    try {
      // Get Sap key User
      let currentUser = await props?.userInfo?.userNTID;
      const sapKeyUser = await getSapKeyUser(currentUser);
      var isSapKey = sapKeyUser?.data?.json;
      setSapKeyUser(isSapKey);
      let body = { incident_number: ticketId, start_record: 0, max_limit: 1 };
      const smtEndpoint = `/api/v1/smt/tickets/details/id`;

      // Retrieve Incident
      axiosInstance
        .post(`${smtEndpoint}`, body)
        .then((res) => {
          setRawTicketData(
            res.data['soapenv:Envelope']['soapenv:Body'][0][
            'HelpDesk_Query_ServiceResponse'
            ][0]
          );
          let tempResponse =
            res.data['soapenv:Envelope']['soapenv:Body'][0]
              .HelpDesk_Query_ServiceResponse;
          const orderById = tempResponse.find((order) => {
            return order.Incident_Number[0] === ticketId;
          });

          var smtTicketCreator = orderById['Corporate_ID'][0];
          var smtTicketContact = '';
          if (
            orderById['Contact_Corporate_ID'] &&
            orderById['Contact_Corporate_ID'][0]
          ) {
            smtTicketContact = orderById['Contact_Corporate_ID'][0];
          }

          // Validation so only SAP KEY USERs and Ticket creators (or contacts) can see incidents
          if (
            smtTicketCreator.toLowerCase() !== currentUser.toLowerCase() &&
            smtTicketContact.toLowerCase() !== currentUser.toLowerCase() &&
            !isSapKey
          ) {
            alert(alertNotAllowedToSeeSmtDetails);
            navigate('/tickets');
          } else {
            tempResponse = setSMTItemForTicketJourney(orderById);
            setSource('smt');
            setTicketJourneyPage(tempResponse);
            setisDataAvailable(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      navigate('/error');
      console.log(
        'Error while verifiying SAP KEY USER & Getting ticket details',
        error
      );
    }
  };

  useEffect(() => {
    if (location.pathname.toLowerCase().includes('ticket-journey/inc')) {
      getSmtDetailsAndValidateSapKeyUser();
    } else if (location.pathname.toLowerCase().includes('ticket-journey/r')) {
      const snowEndpoint = `/api/v1/snow/tickets/details`;
      axiosInstance
        .get(`${snowEndpoint}/${ticketId}`)
        .then((res) => {
          setSource('new');
          const ticket = res.data.data[0].response[0];
          setTicketJourneyPage({
            activity_id: ticket.activity_id,
            status: ticket.status,
            serviceName: ticket.serviceName,
            serviceDescription: ticket.serviceDescription,
            performer: ticket.performerFirst + ' ' + ticket.performerLast,
            submittedDate: ticket.submittedDate,
            closedDate: ticket.closedDate,
            taskDate: ticket.taskDate,
            lastDateTime: ticket.lastDateTime,
            cost: ticket.cost,
            type:
              ticket.serviceName === 'IT Support Request'
                ? 'failure'
                : 'request',
            priority: ticket.priority ? ticket.priority : 'low',
            onBehalf: ticket.onBehalf,
            requested_for: ticket.requested_for,
            requisitionDueDate: ticket.requisitionDueDate,
            sys_id: ticket.sys_id,
            rejection_reason: ticket.rejection_reason,
            requisition_id: ticket.requisition_id,
            performerDept: ticket.performerDept,
            performerLogin: ticket.performerLogin,
            comments: ticket.comments || []
          });
          setisDataAvailable(true);
        })
        .catch((err) => {
          console.log(err);
          setisDataAvailable(false);
        });
    } else if (/^\d+/.test(ticketId)) {
      const langCode =
        props.userInfo && props.userInfo.userLanguage
          ? props.userInfo.userLanguage
          : 'en';
      const cpscEndpoint = `/api/v1/cpsc/tickets/details`;
      axiosInstance
        .get(`${cpscEndpoint}/${ticketId}?lang=${langCode}`)
        .then((res) => {
          let tempResponse = res.data.data;
          const orderById = res.data.data.find((order) => {
            return order.requisitionid === Number(ticketId);
          });
          tempResponse = setCPSCtemForTicketJourney(orderById);
          setSource('old');
          setTicketJourneyPage(tempResponse);
          setisDataAvailable(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.error(
        'No matching ticket source type determined for ticket ID:',
        ticketId
      );
    }
  }, [location.pathname, ticketId, source, props.userInfo]);

  return (
    <div className="pb-10  border-indigo-600">
      {!isDataAvailable ? (
        <Loader customStyle="inset-x-1/2 inset-y-1/2" />
      ) : (
        <div>
          <TopBanner
            ticketId={TicketJourneyPage.activity_id}
            serviceName={TicketJourneyPage.serviceName}
            status={TicketJourneyPage.status}
          />
          <div className="flex justify-center">
            <div className="flex justify-center items-center w-4/6 border-b relative">
              <Link
                to="/tickets"
                className="ITSP_blue no-underline absolute left-0"
              >
                &lt; {t('ui_spear.ticketDetails.Back_to_Tickets')}
              </Link>
              <DetailsTabs
                showTicketJourney={showTicketJourney}
                handleTabClick={handleTabClick}
              />
            </div>
          </div>
          {showTicketJourney ? (
            <>
              <TicketJourney
                TicketJourneyPage={TicketJourneyPage}
                setOriginalLastSetTime={setOriginalLastSetTime}
                setOriginalSubmittedDate={setOriginalSubmittedDate}
                source={source}
              />
              <Comments
                userInfo={props.userInfo}
                TicketJourneyPage={TicketJourneyPage}
                source={source}
                isSupportEntitle={isSupportEntitle}
                setSmtTicketSummary={setSmtTicketSummary}
              />
            </>
          ) : (
            <>
              <Details
                SAPKeyUser={sapKeyUser}
                rawTicketData={rawTicketData}
                TicketJourneyPage={TicketJourneyPage}
                source={source}
                originalLastDateTime={originalLastDateTime}
                originalSubmittedDate={originalSubmittedDate}
                smtTicketSummary={smtTicketSummary}
              />
              <Comments
                userInfo={props.userInfo}
                TicketJourneyPage={TicketJourneyPage}
                source={source}
                isSupportEntitle={isSupportEntitle}
                setSmtTicketSummary={setSmtTicketSummary}
              />
            </>
          )}
          {isSupportEntitle && (
            <div className="py-10 w-4/6 mx-auto">
              <Grid item xs={9}>
                <div className="mb-12">
                  <h3 className="text-[1.75rem] mb-12 font-Bosch_sans_light">
                    {t('ui_spear.contactIT.Do_you_need_any_support')}
                  </h3>
                </div>
                <SupportBar
                  userInfo={props.userInfo}
                  fromSupport={false}
                  serviceName={TicketJourneyPage.serviceName}
                  ticketId={TicketJourneyPage.activity_id}
                ></SupportBar>
              </Grid>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TicketJourneyPage;
