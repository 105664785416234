import React from 'react';
import { Box, MenuItem, FormControl, Select } from '@mui/material';

const LanguageSelector = ({
  userLanguage,
  portalLanguages,
  handleLanguageChange
}) => {
  const renderLanguages = () => {
    let languageMenuList;

    languageMenuList = portalLanguages.map((portalLanguage) => (
      <MenuItem value={portalLanguage.id} key={portalLanguage.id}>
        {userLanguage === 'en'
          ? portalLanguage.name
          : portalLanguage.display_label}
      </MenuItem>
    ));
    return languageMenuList;
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          defaultValue={'en'}
          value={userLanguage}
          onChange={handleLanguageChange}
          displayEmpty
          inputProps={{ 'aria-label': 'language' }}
        >
          {renderLanguages()}
        </Select>
      </FormControl>
    </Box>
  );
};

export default LanguageSelector;
