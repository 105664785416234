import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const CancelStep2 = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle className="items-center px-[50px]">
        <Grid item xs={12} align="center">
          <Grid item xs={12}>
            <span className="support-icon-image text-[100px] bg-green_confirmation text-white rounded-full">
              &#xe0e9;
            </span>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              className="font-Bosch_sans_light text-[30px]"
            >
              {t('ui_spear.support.schedulle_callback_canceled')}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Typography className="font-Bosch_sans_light text-xl mt-7 text-center pl-3 pr-3">
          {t('ui_spear.support.schedulle_callback_your_callback')}{' '}
          {props.existingDesiredDate?.dayTag},{' '}
          {t('ui_spear.support.schedulle_callback_between')}{' '}
          {props.existingDesiredDate?.hourTag}{' '}
          {t('ui_spear.support.schedulle_callback_your_callback_was_canceled')}{' '}
          {t(
            'ui_spear.support.schedulle_callback_if_you_added _callback_slot_calendar_you_may_delete_it'
          )}
        </Typography>

        <Grid
          container
          spacing={2}
          className="mt-7"
          xs={12}
          justifyContent="center"
        >
          <Grid item>
            <Button
              className="m-auto bg-ITSP_blue h-10 w-auto text-white flex items-center normal-case font-Bosch_sans rounded-none text-xl p-7"
              onClick={() => {
                props.handleClose();
              }}
            >
              {t('ui_spear.support.schedulle_callback_close')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default CancelStep2;
