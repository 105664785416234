import React from 'react';

const Head = ({ columns }) => {
  return (
    <thead>
      <tr className="border-b-2">
        {columns.map(({ label, accessor }) => {
          return (
            <th key={accessor} className="px-6 py-2 text-left">
              {label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default Head;
