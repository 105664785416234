import React from 'react'

const SingleComment = ({ comment }) => {
    return (
        <div className='border-b mb-4'>
            <div className='flex justify-between'>
                <span className='font-medium'>{comment.user}</span>
                <span>{comment.date}</span>
            </div>
            <div className='italic'>
                {comment.text}
            </div>
        </div>
    )
}

export default SingleComment
