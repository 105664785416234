import TextField from '@mui/material/TextField';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Link, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingEllipsis from '../Ticket/Services/LoadingEllipsis';
import { styled } from '@mui/system';
import { getSapKeyUser } from '../../api/ticketsEndpoints';
import ErrorPopUp from '../ErrorPopUp/ErrorPopUp';
import EmojisNotAllowedMessage from '../Ticket/EmojisNotAllowedMessage'
import axiosInstance from '../../utils/axiosInstance';
import ErrorNetwork from '../ErrorPopUp/ErrorNetwork';
import BannerInfo from './BannerInfo';
import SingleComment from './SingleComment';

const PREFIX = 'AddAttachments';
const classes = {
  boschIcon: `${PREFIX}-boschIcon`,
  crudAttachmentIcon: `${PREFIX}-crudAttachmentIcon`,
  crudAttachmentText: `${PREFIX}-crudAttachmentText`,
  removeAtachmentIcon: `${PREFIX}-removeAtachmentIcon`,
  addAttacmentsDisabled: `${PREFIX}-addAttacmentsDisabled`,
  addScreenshotContainer: `${PREFIX}-addScreenshotContainer`,
  paperClipIcon: `${PREFIX}-paperClipIcon`,
  addScreenshot: `${PREFIX}-addScreenshot`
};

const StyledCommentContainer = styled('div')({
  backgroundColor: 'whitesmoke',
  boxSizing: 'border-box',
  textAlign: 'center'
});

const StyledComment = styled(Grid)({
  content: '""',
  marginLeft: 'auto',
  marginRight: '0px',
  marginTop: '20px',
  marginBottom: '8px',
  backgroundColor: '#ffffff',
  border: 'solid 1px lightsteelblue',
  position: 'relative',
  width: 'auto',
  maxWidth: '90%',
  display: 'inline-block',
  float: 'right',
  padding: '10px',

  '&:before': {
    content: '""',
    width: '0px',
    height: '0px',
    borderLeft: '10px solid lightsteelblue',
    borderRight: '10px solid transparent',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    display: 'block',
    position: 'absolute',
    left: '100%',
    top: '10px',
    zIndex: '1'
  },
  '&:after': {
    content: '""',
    width: '0px',
    height: '0px',
    borderLeft: '10px solid #ffffff',
    borderRight: '10px solid transparent',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    display: 'block',
    position: 'absolute',
    left: 'calc(100% - 1px)',
    top: '10px',
    zIndex: '1'
  }
});

const StyledCommentText = styled(Grid)({
  color: '#000000',
  fontSize: '14px',
  margin: 0,
  fontFamily: 'BoschSans-Light',
  marginTop: '10px',
  wordWrap: 'break-word'
});

const StyledDate = styled(Grid)({
  color: '#999999',
  fontSize: '12px',
  margin: 0,
  fontFamily: 'BoschSans-Regular'
});

const StyledIcon = styled('span')({
  fontFamily: 'bosch-icon',
  fontWeight: 400,
  fontSize: '28px',
  lineHeight: 1.6,
  color: '#004768',
  border: '1px solid #004768',
  borderRadius: '50%',
  backgroundColor: '#ffffff',
  maxHeight: '30px',
  position: 'relative',
  top: '25px',
  marginLeft: '25px',
  marginRight: '25px',
  float: 'left'
});

const StyledIconText = styled('label')({
  float: 'left',
  width: '100%',
  position: 'relative',
  top: '25px',
  left: '-10px'
});

const StyledThreeFilesMax = styled('p')({
  fontSize: '9px',
  color: '#8f8f8f'
});

const StyledInput = styled('input')({
  width: 0,
  height: 0,
  visibility: 'hidden',
  opacity: 0
});

const Comments = (props) => {
  const [commentsNumber, setCommentsNumber] = useState(0);
  const [isSapKeyUser, setIsSapUserKey] = useState(true);
  const [loading, setLoading] = useState(true);
  const [smtCommentsArray, setSmtCommentsArray] = useState([]);
  const [cpscCommentsArray, setCpscCommentsArray] = useState([]);
  const [attachmentsValue, handleAttachments] = useState([]);
  const [comment, setComment] = useState('');
  const [finalComments, setFinalComments] = useState([]);
  const [errorPopUp, setErrorPopUp] = useState(false);
  // Used for any general error when retrieving or posting data
  const [errorNetwork, setErrorNetwork] = useState(false);
  const [errorNetworkMessage, setErrorNetworkMessage] = useState({ title: '', summary: '' });
  const [submitError, setSubmitError] = useState(null);
  const [isTicketSummWithEmojis, setIsTicketSummWithEmojis] = useState(false);
  const { t } = useTranslation();
  let fileLoaderRef = React.createRef();

  // Strings
  let errorCommentsBody = t('ui_spear.errorComments.errorCommentsBody'); // 'Please try clearing the browser cache or contact IT Service Desk via call or chat.'
  let errorUploadAttachment = t('ui_spear.errorComments.errorUploadAttachment'); // 'Error while uploading your attachment'
  let errorRetrievingComments = t('ui_spear.errorComments.errorRetrievingComments'); // Error while retrieving tickets comments
  let errorUploadComment = t('ui_spear.errorComments.errorUploadComment'); // Error while uploading tickets comments

  useEffect(() => {
    retrieveComments();
  }, []);

  useEffect(() => {
    retrieveCpscComments();
  }, []);

  useEffect(() => {
    cleanComments(smtCommentsArray);
  }, [smtCommentsArray]);

  useEffect(() => {
    let wrkDtl = null;

    if (attachmentsValue.length > 0) {
      wrkDtl = {
        work_log_type: 15000,
        notes: 'user attachments',
        work_log_submitter: props.userInfo.userNTID,
        attach1_name: attachmentsValue[0]
          ? attachmentsValue[0].file.name
          : null,
        attach1_base64: attachmentsValue[0]
          ? attachmentsValue[0].base64Data
          : null,
        attach1_size: attachmentsValue[0]
          ? attachmentsValue[0].file.size
          : null,
        attach2_name: attachmentsValue[1]
          ? attachmentsValue[1].file.name
          : null,
        attach2_base64: attachmentsValue[1]
          ? attachmentsValue[1].base64Data
          : null,
        attach2_size: attachmentsValue[1]
          ? attachmentsValue[1].file.size
          : null,
        attach3_name: attachmentsValue[2]
          ? attachmentsValue[2].file.name
          : null,
        attach3_base64: attachmentsValue[2]
          ? attachmentsValue[2].base64Data
          : null,
        attach3_size: attachmentsValue[2]
          ? attachmentsValue[2].file.size
          : null,
        linked_document: ''
      };
    } else {
      wrkDtl = {};
    }
    if (attachmentsValue.length > 0) {
      wrkDtl = {
        ...wrkDtl,
        incident_number: props.TicketJourneyPage.activity_id
      };
      const submitURL = `/api/v1/worklog`;
      axiosInstance.post(submitURL, wrkDtl).then((result) => {
        console.log(result);
        retrieveComments();
        handleAttachments([]);
      })
        .catch((error) => {
          console.error(error)
          setLoading(false);
          handleAttachments([]);
          setErrorNetworkMessage({
            title: errorUploadAttachment,
            summary: errorCommentsBody,
          });
          showErrorNetworkPopUp();
        });
    }
  }, [attachmentsValue]);

  const formatedDate = (originalDate, useFormat) => {
    var months = [
      t('ui_spear.myTickets.January'),
      t('ui_spear.myTickets.February'),
      t('ui_spear.myTickets.March'),
      t('ui_spear.myTickets.April'),
      t('ui_spear.myTickets.May'),
      t('ui_spear.myTickets.June'),
      t('ui_spear.myTickets.July'),
      t('ui_spear.myTickets.August'),
      t('ui_spear.myTickets.September'),
      t('ui_spear.myTickets.October'),
      t('ui_spear.myTickets.November'),
      t('ui_spear.myTickets.December')
    ];
    const date = new Date(originalDate);
    if (!isNaN(date.getDate())) {
      const day = date.getDate().toString();
      const month = months[date.getMonth()];
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const strTime = `${hours}:${minutes} ${ampm}`;
      const monthIndex = months.indexOf(month);

      if (!useFormat) {
        return `${date.getFullYear()}/${monthIndex + 1 < 10 ? '0' + (monthIndex + 1) : monthIndex + 1
          }/${day} ${strTime}`;
      } else {
        return `${date.getFullYear()}/${month}/${day} ${strTime}`;
      }
    }
  };

  const retrieveCpscComments = async () => {
    if (props.source === 'old') {
      const getCommentsURL = `/api/v1/cpsc/tickets/comments/${props.TicketJourneyPage.activity_id}`;
      let cpscComments = [];
      setLoading(true);
      try {
        const response = await axiosInstance.get(getCommentsURL);
        setLoading(false);
        if (response.status === 200) {
          if (response.data.isSuccessful) {
            response.data.data.map((commentObject) => {
              let userName = commentObject.FirstName + ' ' + commentObject.LastName;
              let dateValue = commentObject.CommentedOn.split('T')[0] + ' ' + commentObject.CommentedOn.split('T')[1].substring(0, 8);
              cpscComments.push({
                user: userName,
                date: dateValue,
                text: commentObject.CommentText,
              })
            })
            setCpscCommentsArray(cpscComments);
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        setErrorNetworkMessage({
          title: errorUploadComment,
          summary: errorCommentsBody,
        });
        showErrorNetworkPopUp();
      }
    }
  };

  const retrieveComments = () => {
    if (props.source === 'smt') {
      setLoading(true);
      const submitURL = `/api/v1/smt/ticket/details/worklogs`;
      const data = {
        incident_number: props.TicketJourneyPage.activity_id
      };
      axiosInstance.post(submitURL, data).then(async (result) => {
        setLoading(false);
        setSmtCommentsArray(
          result.data['soapenv:Envelope']['soapenv:Body'][0][
          'GetList_Incident_WorkInfosResponse'
          ][0]['getListValues']
        );
        props.setSmtTicketSummary(
          result.data['soapenv:Envelope']['soapenv:Body'][0][
          'GetList_Incident_WorkInfosResponse'
          ][0]['getListValues']
        );
      })
        .catch((error) => {
          console.error(error)
          setLoading(false);
          setErrorNetworkMessage({
            title: errorRetrievingComments,
            summary: errorCommentsBody,
          });
          showErrorNetworkPopUp();
        })
    }
  };

  const cleanComments = async (comments) => {
    if (comments == null) {
      multiLineFormat([]);
    } else {
      let cleanComments = [];
      comments.map((comment) => {
        if (comment['Submitter'][0] === props.userInfo.userNTID) {
          cleanComments.push(comment);
        }
      });
      cleanComments.reverse();
      multiLineFormat(cleanComments);
    }
  };

  const multiLineFormat = (comments) => {
    let finalComment = '';
    comments.map((comment) => {
      if (comment['Notes'] !== undefined) {
        comment['Notes'].map((note) => {
          if (note != '') {
            finalComment += note;
          }
        });
        const commentContent = finalComment.toString();
        if (commentContent != '') {
          comment['Notes'] = finalComment;
          finalComment = '';
        }
      } else {
        comment['Notes'] = [''];
      }
    });
    setCommentsNumber(comments.length);
    setFinalComments(comments);
  };

  const validateEmojisInputs = (value) => {
    const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u26FF\u2700-\u27BF]/;
    if (emojiRegex.test(value)) {
      setIsTicketSummWithEmojis(true);
      return false;
    } else {
      setIsTicketSummWithEmojis(false);
      return true;
    }
  };

  const addNewComment = () => {
    const formatedComment = comment
      .replace(/\\/g, '/')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/\t/g, '\\t')
      .replace(/"/g, '\\"')
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    if (formatedComment.trim() != '' && validateEmojisInputs(formatedComment)) {
      const submitURL = `/api/v1/worklog`;
      const data = {
        incident_number: props.TicketJourneyPage.activity_id,
        notes: formatedComment,
        work_log_type: 'Customer Communication',
        work_log_submitter: props.userInfo.userNTID
      };
      axiosInstance.post(submitURL, data).then((result) => {
        retrieveComments();
        setComment('');
      }).catch((error) => {
        console.error(error)
        setLoading(false);
        setComment('');
        setErrorNetworkMessage({
          title: errorUploadComment,
          summary: errorCommentsBody,
        });
        showErrorNetworkPopUp();
      })
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getEncodedData = (fullBase64) => {
    const splittedData = fullBase64.split(',');
    if (splittedData[1]) {
      return splittedData[1];
    }
    return null;
  };

  const handleLoadAttachment = async (event) => {
    setLoading(true);
    if (event.target.files.length <= 3) {
      let attachmentsArray = [];
      await Promise.all(
        Array.from(event.target.files).map(async (file) => {
          if (file.size <= 5000000) {
            const url = window.URL.createObjectURL(file);
            const fullBase64 = await toBase64(file);
            const base64Data = getEncodedData(fullBase64);
            const newFile = {
              downloadUrl: url,
              file: file,
              fullBase64: fullBase64,
              base64Data: base64Data
            };
            attachmentsArray.push(newFile);
          } else {
            handleAttachments([]);
            showErrorPopUp();
          }
        })
      );
      handleAttachments(attachmentsArray);
    } else {
      handleAttachments([]);
      showErrorPopUp();
    }
  };

  const showErrorPopUp = () => {
    setLoading(false);
    setErrorPopUp(!errorPopUp);
  };

  const showErrorNetworkPopUp = () => {
    setLoading(false);
    setErrorNetwork(!errorNetwork)
  };

  const handleComment = (event) => {
    setComment(event.target.value);
  };

  const openFile = (base64, name) => {
    const MIME_TYPES = {
      '.aac': 'audio/aac',
      '.abw': 'application/x-abiword',
      '.arc': 'application/x-freearc',
      '.avif': 'image/avif',
      '.avi': 'video/x-msvideo',
      '.azw': 'application/vnd.amazon.ebook',
      '.bin': 'application/octet-stream',
      '.bmp': 'image/bmp',
      '.bz': 'application/x-bzip',
      '.bz2': 'application/x-bzip2',
      '.cda': 'application/x-cdf',
      '.csh': 'application/x-csh',
      '.css': 'text/css',
      '.csv': 'text/csv',
      '.doc': 'application/msword',
      '.docx':
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      '.eot': 'application/vnd.ms-fontobject',
      '.epub': 'application/epub+zip',
      '.gz': 'application/gzip',
      '.gif': 'image/gif',
      '.htm': 'text/html',
      '.html': 'text/html',
      '.ico': 'image/vnd.microsoft.icon',
      '.ics': 'text/calendar',
      '.jar': 'application/java-archive',
      '.jpeg': 'image/jpeg',
      '.jpg': 'image/jpeg',
      '.js': 'text/javascript',
      '.json': 'application/json',
      '.jsonld': 'application/ld+json',
      '.mid': 'audio/midi',
      '.midi': 'audio/midi',
      '.mjs': 'text/javascript',
      '.mp3': 'audio/mpeg',
      '.mp4': 'video/mp4',
      '.mpeg': 'video/mpeg',
      '.mpkg': 'application/vnd.apple.installer+xml',
      '.odp': 'application/vnd.oasis.opendocument.presentation',
      '.ods': 'application/vnd.oasis.opendocument.spreadsheet',
      '.odt': 'application/vnd.oasis.opendocument.text',
      '.oga': 'audio/ogg',
      '.ogv': 'video/ogg',
      '.ogx': 'application/ogg',
      '.opus': 'audio/opus',
      '.otf': 'font/otf',
      '.png': 'image/png',
      '.pdf': 'application/pdf',
      '.php': 'application/x-httpd-php',
      '.ppt': 'application/vnd.ms-powerpoint',
      '.pptx':
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      '.rar': 'application/vnd.rar',
      '.rtf': 'application/rtf',
      '.sh': 'application/x-sh',
      '.svg': 'image/svg+xml',
      '.swf': 'application/x-shockwave-flash',
      '.tar': 'application/x-tar',
      '.tif': 'image/tiff',
      '.tiff': 'image/tiff',
      '.ts': 'video/mp2t',
      '.ttf': 'font/ttf',
      '.txt': 'text/plain',
      '.vsd': 'application/vnd.visio',
      '.wav': 'audio/wav',
      '.weba': 'audio/webm',
      '.webm': 'video/webm',
      '.webp': 'image/webp',
      '.woff': 'font/woff',
      '.woff2': 'font/woff2',
      '.xhtml': 'application/xhtml+xml',
      '.xls': 'application/vnd.ms-excel',
      '.xlsx':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      '.xml': 'application/xml',
      '.xul': 'application/vnd.mozilla.xul+xml',
      '.zip': 'application/zip',
      '.3gp': 'video/3gpp',
      '.3g2': 'video/3gpp2',
      '.7z': 'application/x-7z-compressed'
    };
    try {
      const file = dataURLtoFile(
        base64,
        name,
        MIME_TYPES['.' + name[0].split('.').pop()]
      );
      const url = window.URL.createObjectURL(file);
      window.open(url, '_blank');
    } catch (error) {
      console.log(error);
    }
  };

  const dataURLtoFile = (base64, filename, mime) => {
    var bstr = atob(base64);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  useEffect(() => {
    validateSapKeyUser();
  }, [props.userInfo]);

  const validateSapKeyUser = async () => {
    try {
      const sapKeyUser = await getSapKeyUser(props?.userInfo?.userNTID);
      setIsSapUserKey(sapKeyUser?.data?.json);
    } catch (error) {
      console.log('Error while verifiying SAP KEY USER', error);
      setIsSapUserKey(false);
    }
  };

  const commentsSection = () => {
    const fullName =
      props?.userInfo?.userGivenName + ' ' + props?.userInfo?.userFamilyName;
    if (props.source === 'old' || props.source === 'new') {
      if (
        props?.TicketJourneyPage?.status == 'Completed' ||
        props?.TicketJourneyPage?.status == 'Rejected' ||
        props?.TicketJourneyPage?.status == 'Cancelled'
      ) {
        return (
          <>
            <h2 className="py-10 w-4/6 mx-auto text-3xl font-Bosch_sans_light">
              {t('ui_spear.ticketDetails.Comments.title')}
            </h2>
            {props.source === 'new' &&
              (props.TicketJourneyPage?.comments.length > 0 && props?.TicketJourneyPage?.status === 'Rejected') && (
                <div className='w-4/6 mx-auto p-4 gap-4 items-center mb-4'>
                  {props.TicketJourneyPage.comments.map((commentString, index) => {
                    let dateUser = commentString.split('(Comments)')[0];
                    let dateValue = dateUser.split(' - ')[0] || '';
                    let userValue = dateUser.split(' - ')[1] || '';
                    let commentValue = commentString.split('(Comments)')[1] || '';

                    let commentObject = {
                      user: userValue,
                      date: dateValue,
                      text: commentValue,
                    }

                    return <SingleComment comment={commentObject} key={index} />
                  })}
                </div >
              )
            }
            {props.source === 'old' &&
              (cpscCommentsArray.length > 0 && props?.TicketJourneyPage?.status === 'Rejected') && (
                <div className='w-4/6 mx-auto p-4 gap-4 items-center mb-4'>
                  {cpscCommentsArray.map((comment, index) => (
                    <SingleComment comment={comment} key={index} />
                  ))}
                </div >
              )
            }
            <div className="py-10 w-4/6 flex mx-auto gap-4 bg-slate-200 p-4 items-center">
              <span
                className={`boschicon-bosch-ic-settings-arrows text-[28px]`}
              ></span>
              <div className="flex flex-col">
                <p>
                  {t('ui_spear.ticketDetails.Comments.Your_request')}
                  <span className="font-bold">
                    {' '}
                    {t('ui_spear.ticketDetails.Comments.automated system')}{' '}
                    {t('ui_spear.ticketDetails.Comments.it_wont')}{' '}
                  </span>
                </p>
                <p>
                  {t('ui_spear.ticketDetails.Comments.If_you_experience')}{' '}
                  {props.isSupportEntitle ? (
                    <Link
                      href={`${process.env.REACT_APP_UI_URL}/support/ticket`}
                      className="hover:cursor-pointer"
                    >
                      {t('ui_spear.ticketDetails.Comments.create_a_ticket')}
                    </Link>
                  ) : (
                    t('ui_spear.ticketDetails.Comments.create_a_ticket')
                  )}{' '}
                  {t('ui_spear.ticketDetails.Comments.or')}{' '}
                  {t('ui_spear.ticketDetails.Comments.Contact_us')}.{' '}
                  {t('ui_spear.ticketDetails.Comments.Then_we_will')}
                </p>
              </div>
            </div>
          </>
        );
      } else if (props?.TicketJourneyPage?.status == 'In approval') {
        return (
          <>
            <h2 className="py-10 w-4/6 mx-auto text-3xl font-Bosch_sans_light">
              {t('ui_spear.ticketDetails.Comments.title')}
            </h2>
            <div className="py-10 w-4/6 flex mx-auto gap-4 bg-slate-200 p-4 gap-4 items-center">
              <span
                className={`boschicon-bosch-ic-settings-arrows text-[28px]`}
              ></span>
              <div className="flex flex-col">
                <p>
                  {t('ui_spear.ticketDetails.Comments.After_approval')}
                  <span className="font-bold">
                    {' '}
                    {t('ui_spear.ticketDetails.Comments.automated system')}{' '}
                    {t('ui_spear.ticketDetails.Comments.it_wont')}{' '}
                  </span>
                </p>
                <p>
                  {t('ui_spear.ticketDetails.Comments.If_you_experience')}{' '}
                  {props.isSupportEntitle ? (
                    <Link
                      href={`${process.env.REACT_APP_UI_URL}/support/ticket`}
                      className="hover:cursor-pointer"
                    >
                      {t('ui_spear.ticketDetails.Comments.create_a_ticket')}
                    </Link>
                  ) : (
                    t('ui_spear.ticketDetails.Comments.create_a_ticket')
                  )}{' '}
                  {t('ui_spear.ticketDetails.Comments.or')}{' '}
                  {t('ui_spear.ticketDetails.Comments.Contact_us')}.{' '}
                  {t('ui_spear.ticketDetails.Comments.Then_we_will')}
                </p>
              </div>
            </div>
          </>
        );
      } else if (props?.TicketJourneyPage?.status == 'In progress') {
        return (
          <>
            <h2 className="py-10 w-4/6 mx-auto text-3xl font-Bosch_sans_light">
              {t('ui_spear.ticketDetails.Comments.title')}
            </h2>
            <div className="py-10 w-4/6 flex mx-auto gap-4 bg-slate-200 p-4 gap-4 items-center">
              <span
                className={`boschicon-bosch-ic-settings-arrows text-[28px]`}
              ></span>
              <div className="flex flex-col">
                <p>
                  {t('ui_spear.ticketDetails.Comments.Your_request_being')}
                  <span className="font-bold">
                    {' '}
                    {t('ui_spear.ticketDetails.Comments.automated system')}{' '}
                    {t('ui_spear.ticketDetails.Comments.it_wont')}{' '}
                  </span>
                </p>
                <p>
                  {t('ui_spear.ticketDetails.Comments.If_you_experience')}{' '}
                  {props.isSupportEntitle ? (
                    <Link
                      href={`${process.env.REACT_APP_UI_URL}/support/ticket`}
                      className="hover:cursor-pointer"
                    >
                      {t('ui_spear.ticketDetails.Comments.create_a_ticket')}
                    </Link>
                  ) : (
                    t('ui_spear.ticketDetails.Comments.create_a_ticket')
                  )}{' '}
                  {t('ui_spear.ticketDetails.Comments.or')}{' '}
                  {t('ui_spear.ticketDetails.Comments.Contact_us')}.{' '}
                  {t('ui_spear.ticketDetails.Comments.Then_we_will')}
                </p>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <h2 className="py-10 w-4/6 mx-auto text-3xl font-Bosch_sans_light">
              {t('ui_spear.ticketDetails.Comments.title')}
            </h2>
            <div className="py-10 w-4/6 flex mx-auto gap-4 bg-slate-200 p-4 gap-4 items-center">
              <span
                className={`boschicon-bosch-ic-settings-arrows text-[28px]`}
              ></span>
              <div className="flex flex-col">
                <p>
                  {t('ui_spear.ticketDetails.Comments.RequestProcessed')}
                  <span className="font-bold">
                    {t('ui_spear.ticketDetails.Comments.automated system')}{' '}
                    {t('ui_spear.ticketDetails.Comments.it_wont')}
                  </span>
                </p>
                <p>
                  {t('ui_spear.ticketDetails.Comments.If_you_experience')}{' '}
                  {props.isSupportEntitle ? (
                    <Link
                      href={`${process.env.REACT_APP_UI_URL}/tickets`}
                      className="hover:cursor-pointer"
                    >
                      {t('ui_spear.ticketDetails.Comments.create_a_ticket')}
                    </Link>
                  ) : (
                    t('ui_spear.ticketDetails.Comments.create_a_ticket')
                  )}
                  . {t('ui_spear.ticketDetails.Comments.Then_we_will')}
                </p>
              </div>
            </div>
          </>
        );
      }
    } else if (
      props?.userInfo?.userNTID.toUpperCase() ==
      props?.TicketJourneyPage?.performer?.toUpperCase()
    ) {
      return (
        <div className="py-10 w-4/6 flex flex-col mx-auto gap-4">
          <h2 className="text-3xl">
            {t('ui_spear.ticketDetails.Comments.title')}({commentsNumber})
          </h2>
          <div className='flex flex-inline'>
            <p className="font-semibold">
              {t('ui_spear.ticketDetails.Comments.AnyUpdates')}
            </p>
            <BannerInfo messageString={t('ui_spear.createTicket.allowedAttachmentsTitleV2')} />
          </div>
          {loading && <LoadingEllipsis></LoadingEllipsis>}
          {commentsNumber > 0 ? (
            <Grid
              style={{
                maxHeight: '250px',
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              {finalComments.map((comment) => (
                <Fragment key={comment}>
                  <StyledCommentContainer>
                    <Grid container>
                      <StyledComment>
                        <StyledDate>
                          {formatedDate(comment.Date[0], true)}
                        </StyledDate>
                        <StyledCommentText>{comment.Notes}</StyledCommentText>
                        <StyledCommentText>
                          {comment.Work_Info1_attachmentData ? (
                            <div>
                              <span className="boschicon-bosch">&#xe1a2;</span>
                              <a
                                onClick={() =>
                                  openFile(
                                    comment.Work_Info1_attachmentData,
                                    comment.Work_Info1_attachmentName
                                  )
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                {comment.Work_Info1_attachmentName[0]}
                              </a>
                            </div>
                          ) : (
                            ''
                          )}
                        </StyledCommentText>
                        <StyledCommentText>
                          {comment.Work_Info2_attachmentData ? (
                            <div>
                              <span className="boschicon-bosch">&#xe1a2;</span>
                              <a
                                onClick={() =>
                                  openFile(
                                    comment.Work_Info2_attachmentData,
                                    comment.Work_Info2_attachmentName
                                  )
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                {comment.Work_Info2_attachmentName[0]}
                              </a>
                            </div>
                          ) : (
                            ''
                          )}
                        </StyledCommentText>
                        <StyledCommentText style={{ color: '#08c' }}>
                          {comment.Work_Info3_attachmentData ? (
                            <div>
                              <span className="boschicon-bosch">&#xe1a2;</span>
                              <a
                                onClick={() =>
                                  openFile(
                                    comment.Work_Info3_attachmentData,
                                    comment.Work_Info3_attachmentName
                                  )
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                {comment.Work_Info3_attachmentName[0]}
                              </a>
                            </div>
                          ) : (
                            ''
                          )}
                        </StyledCommentText>
                      </StyledComment>
                      <Grid>
                        <StyledIcon className="boschicon-bosch-ic-user"></StyledIcon>
                        <StyledIconText>
                          {t('ui_spear.myTickets.You')}
                        </StyledIconText>
                      </Grid>
                    </Grid>
                  </StyledCommentContainer>
                </Fragment>
              ))}
            </Grid>
          ) : (
            <Grid></Grid>
          )}
          <div>{isTicketSummWithEmojis ? <EmojisNotAllowedMessage /> : null}</div>
          <TextField
            inputProps={{ maxLength: 10000 }}
            value={comment}
            onChange={(event) => handleComment(event)}
            placeholder={t('ui_spear.ticketDetails.Comments.TypeYourcomments')}
            label={t('ui_spear.ticketDetails.Comments.TypeYourcomments')}
            variant="outlined"
          />
          <div className="flex justify-end gap-2">
            <div
              className="flex items-center gap-3"
              style={{ position: 'relative', left: '-20px' }}
            >
              <span
                className={`boschicon-bosch-ic-paperclip text-blue-500 hover:text-blue-400 hover:cursor-pointer text-[22px] font-base`}
              ></span>
              <div className="flex flex-col">
                <label
                  htmlFor={`load-attachments-input`}
                  className={
                    attachmentsValue.length === 3
                      ? classes.addAttacmentsDisabled
                      : ''
                  }
                >
                  <div className={classes.addScreenshotContainer}>
                    <span
                      style={{ color: '#005691', cursor: 'pointer' }}
                      className={classes.addScreenshot}
                    >
                      {t('ui_spear.createTicket.Add_attachment')}
                    </span>
                  </div>
                </label>
                <StyledInput
                  type="file"
                  id={`load-attachments-input`}
                  accept="*"
                  multiple
                  disabled={attachmentsValue.length === 3}
                  onChange={handleLoadAttachment}
                  ref={fileLoaderRef}
                ></StyledInput>
                <StyledThreeFilesMax>
                  {t('ui_spear.ticketDetails.Comments.MaxFiles')}
                </StyledThreeFilesMax>
              </div>
            </div>
            <Button
              style={{ position: 'relative', left: '-20px' }}
              onClick={() => addNewComment()}
              className="save-document"
            >
              {t('ui_spear.ticketDetails.Comments.AddComment')}
            </Button>
          </div>
        </div>
      );
    } else if (
      fullName == props.TicketJourneyPage.requested_for &&
      props.source == 'new'
    ) {
      return (
        <>
          <h2 className="py-10 w-4/6 mx-auto text-3xl font-Bosch_sans_light">
            {t('ui_spear.ticketDetails.Comments.title')}
          </h2>
          <div className="py-10 w-4/6 flex mx-auto gap-4 bg-slate-200 p-4 gap-4 items-center">
            <span
              className={`boschicon-bosch-ic-settings-arrows text-[28px]`}
            ></span>
            <div className="flex flex-col">
              <p>
                {t('ui_spear.ticketDetails.Comments.RequestProcessed')}
                <span className="font-bold">
                  {t('ui_spear.ticketDetails.Comments.automated system')}.{' '}
                  {t('ui_spear.ticketDetails.Comments.it_wont')}
                </span>
              </p>
              <p>
                {t('ui_spear.ticketDetails.Comments.If_you_experience')}{' '}
                {props.isSupportEntitle ? (
                  <Link
                    href={`${process.env.REACT_APP_UI_URL}/tickets`}
                    className="hover:cursor-pointer"
                  >
                    {t('ui_spear.ticketDetails.Comments.create_a_ticket')}
                  </Link>
                ) : (
                  t('ui_spear.ticketDetails.Comments.create_a_ticket')
                )}
                . {t('ui_spear.ticketDetails.Comments.Then_we_will')}
              </p>
            </div>
          </div>
        </>
      );
    } else {
      return '';
    }
  };

  return (
    <>
      {commentsSection()}
      {errorPopUp ? (
        <ErrorPopUp
          errorPopUp={errorPopUp}
          errorMessage={''}
          togglePopUp={showErrorPopUp}
          submitError={submitError}
        ></ErrorPopUp>
      ) : (
        ''
      )}
      {errorNetwork ?
        <ErrorNetwork
          errorPopUp={errorNetwork}
          errorText={errorNetworkMessage}
          togglePopUp={showErrorNetworkPopUp}
        /> : null
      }
    </>
  );
};

export default Comments;
