import React, { useState } from 'react'
import {
    Box,
    Popper,
    Typography,
    Grid,
    Paper,
    Fade,
} from '@mui/material';
import { ClickAwayListener } from '@mui/base';

const InfoPopper = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handlePopoverClose}>
            <Box className="max-w-[250px]">
                <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper className="rounded-none">
                                <Grid className="inline-flex p-4">
                                    <Typography className="flex flex-wrap max-w-[20rem] break-words">{props.popperContent}</Typography>
                                    <span
                                        onClick={handlePopoverClose}
                                        className="bosch-icon-np text-[1.2rem] cursor-pointer"
                                    >
                                        &#xe0f0;
                                    </span>
                                </Grid>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
                <Grid container justifyContent="center">
                    <Grid item container alignItems="flex-end" direction="column">
                        <Grid>
                            <button onClick={handleClick('right-end')}>
                                <img
                                    alt="info-circule"
                                    className="info-circle h-4"
                                    src={require(`../../static/deviceIcons/info_circle.png`)}
                                />
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </ClickAwayListener>
    );
};

export default InfoPopper;
