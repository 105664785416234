import React from 'react';
import StatusCell from '../StatusCell';
import { useNavigate } from 'react-router-dom';
import { formatSnowDate } from '../../../../utils/snDateFormatting';

const OpenRow = ({
  ticketId,
  name,
  status,
  lastUpdate,
  person,
  source,
  bgRow,
  submittedDate
}) => {
  let navigate = useNavigate();

  const goTicketDetails = () => {
    console.log('ticket details');
    navigate(`/tickets/ticket-journey/${ticketId}`);
  };

  return (
    <tr
      className={`cursor-pointer border-b hover:bg-gray-100 ${bgRow}`}
      onClick={goTicketDetails}
    >
      <td className="w-[14%] px-6 py-3 text-left">
        <a href={`/tickets/ticket-journey/${ticketId}`}>{ticketId}</a>
      </td>
      <td className="w-[44%] px-6 py-3 text-left">{name}</td>
      <td className="w-[13%] px-6 py-3 text-left">
        <StatusCell status={status} />
      </td>
      <td className="w-[13%] px-6 py-3 text-left">{formatSnowDate(source, submittedDate)}</td>
      <td className="w-[13%] px-6 py-3 text-left">{lastUpdate}</td>
      <td className="w-[13%] px-6 py-3 text-left">{person}</td>
    </tr>
  );
};

export default OpenRow;
