import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Slide,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import ServiceTable from './ServiceTable';
import ServiceSearch from './ServiceSearch';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ServiceModal = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchEvent, setSearchEvent] = useState(null);
  const [lastSearchedTerm, setLastSearchedTerm] = useState(null);
  const [searchResponse, setSearchResponse] = useState([]);
  const [alertEmptyResponse, setAlertEmptyResponse] = useState(false);
  const [alertErorResponse, setAlertErrorResponse] = useState(false);
  const [serviceSelected, setServiceSelected] = useState({});
  const [tempServiceSelected, setTempServiceSelected] = useState({});
  const [hasServiceBeenConfirmed, setHasServiceBeenConfirmed] = useState(false);
  const [isTempSelectedServEmpt, setIsTempSelectedServEmpt] = useState(true);
  const [wasSearchOnEnter, setWasSearchOnEnter] = useState(false);
  const [wasSuggestionSelected, setWasSuggestionSelected] = useState(false);
  const [isServiceSearchLoading, setIsServiceSearchLoading] = useState(false);
  const [searchPage, setSearchPage] = useState(0);
  const [modalGridStyle, setModalGridStyle] = useState(
    'position: relative; w-[70rem] h-[23rem] max-h-[45rem] overflow-x-hidden overflow-y-hidden mt-6 p-3'
  );
  const [submitButtonStyle, setSubmitButtonStyle] = useState(
    'rounded-none w-[8rem] capitalize text-[1rem] border-change_cust_btn hover:text-hover_blue_btns bg-[#005691] text-white opacity-30'
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchResponse([]);
    setSearchTerm('');
    setIsServiceSearchLoading(false);
    setSearchPage(0);
    setWasSearchOnEnter(false);
    setWasSuggestionSelected(false);
    setTempServiceSelected({});
  };

  const handleCloseEmptyModal = () => {
    setAlertEmptyResponse(false);
  };

  const handleCloseErrorModal = () => {
    setAlertErrorResponse(false);
  };

  const handleInputChange = (value) => {
    setSearchTerm(value);
  };

  const handleServiceSelection = (service) => {
    setTempServiceSelected(service);
  };

  const confirmServiceSelected = () => {
    setHasServiceBeenConfirmed(true);
    setServiceSelected(tempServiceSelected);
    props.setTicketSuppData({
      ...props.ticketSuppData,
      ServiceCI: tempServiceSelected.CI_NAME,
      ServiceCI_ReconID: tempServiceSelected.RECONCILIATION_ID
    });
    handleClose();
  };

  const isTempSelectedServiceEmpty = () => {
    if (Object.keys(tempServiceSelected).length !== 0) {
      setIsTempSelectedServEmpt(false);
    } else {
      setIsTempSelectedServEmpt(true);
    }
  };

  const handleButtonStyle = () => {
    if (Object.keys(tempServiceSelected)?.length === 0) {
      setSubmitButtonStyle(
        'rounded-none w-[8rem] capitalize text-[1rem] border-change_cust_btn hover:text-hover_blue_btns bg-[#005691] text-white opacity-30'
      );
    } else {
      setSubmitButtonStyle(
        'rounded-none w-[8rem] capitalize text-[1rem] border-change_cust_btn hover:text-hover_blue_btns bg-[#005691] text-white'
      );
    }
  };

  const ShowEmptyResAlert = () => {
    if (alertEmptyResponse === true && isServiceSearchLoading === false) {
      return (
        <Dialog
          open={alertEmptyResponse}
          fullWidth={true}
          maxWidth={'xs'}
          onClose={handleCloseEmptyModal}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="px-[50px] py-2 flex justify-center content-center">
            <div className="fixed rounded-full h-[65px] w-[65px] flex justify-center content-center mt-[-35px]">
              <span className="boschicon-bosch-ic-alert-warning-filled text-[#FCAF17] text-[75px] m-auto"></span>
            </div>
          </DialogTitle>
          <DialogContent className="text-[16px] mt-8 font-Bosch_sans-light text-center py-4 px-5">
            <Typography className="p-2 font-bold">
              {t('ui_spear.createTicket.service_no_results')}
            </Typography>
            <Button
              className="h-[42px] rounded-none bg-feedback_btn hover:bg-feedback_btn_hover text-[14px] mr-1.5 p-3 my-5 float-center"
              onClick={handleCloseEmptyModal}
            >
              <Typography className="text-white normal-case p-6">
                {t('ui_spear.createTicket.service_no_results_close_alert')}
              </Typography>
            </Button>
          </DialogContent>
        </Dialog>
      );
    }
  };

  const ShowErrorResAlert = () => {
    if (alertErorResponse === true && isServiceSearchLoading === false) {
      return (
        <Dialog
          open={alertErorResponse}
          fullWidth={true}
          maxWidth={'xs'}
          onClose={handleCloseErrorModal}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="px-[50px] py-2 flex justify-center content-center">
            <div className="fixed rounded-full h-[65px] w-[65px] flex justify-center content-center mt-[-35px]">
              <span className="boschicon-bosch-ic-alert-warning-filled text-[red] text-[75px] m-auto"></span>
            </div>
          </DialogTitle>
          <DialogContent className="text-[16px] mt-8 font-Bosch_sans-light text-center py-4 px-5">
            <Typography className="p-2 font-bold">
              {t('ui_spear.createTicket.service_error_retrieving_servs')}
            </Typography>
            <Button
              className="h-[42px] rounded-none bg-feedback_btn hover:bg-feedback_btn_hover text-[14px] mr-1.5 p-3 my-5 float-center"
              onClick={handleCloseErrorModal}
            >
              <Typography className="text-white normal-case p-6">
                {t('ui_spear.createTicket.service_no_results_close_alert')}
              </Typography>
            </Button>
          </DialogContent>
        </Dialog>
      );
    }
  };

  const ShowServiceButton = () => {
    let selectedServiceName = serviceSelected?.CI_NAME;

    if (hasServiceBeenConfirmed === false) {
      return (
        <Button
          className="border border-change_cust_btn rounded-none text-[0.9rem] p-6 h-10 hover:text-hover_blue_btns hover:border-hover_blue_btns capitalize mt-2"
          variant="outlined"
          onClick={handleClickOpen}
        >
          + {t('ui_spear.createTicket.service_select_button')}
        </Button>
      );
    } else if (
      Object.keys(serviceSelected)?.length !== 0 &&
      hasServiceBeenConfirmed === true
    ) {
      return (
        <Grid
          onClick={handleClickOpen}
          className="flex flex-row cursor-pointer"
        >
          <Button className="text-[#333] hover:text-blue_hover_btns">
            {selectedServiceName}
          </Button>
          <span className="change-customer-pencil-icon ml-1 mt-1 mr-[-15rem]">
            &#xe152; {t('ui_spear.createTicket.service_change_button')}{' '}
          </span>
        </Grid>
      );
    }
  };

  useEffect(() => {
    isTempSelectedServiceEmpty();
    handleButtonStyle();
  }, [tempServiceSelected]);

  useEffect(() => {
    if (searchResponse.length === 0 && isServiceSearchLoading === false) {
      setModalGridStyle(
        'position: relative; w-[70rem] h-[24rem] max-h-[45rem] overflow-x-hidden overflow-y-hidden mt-6 p-3'
      );
    } else if (searchResponse.length === 0 && isServiceSearchLoading === true) {
      setModalGridStyle(
        'position: relative; w-[70rem] max-h-[45rem] overflow-x-hidden overflow-y-hidden mt-6 p-3'
      );
    } else if (searchTerm.length > 0 && searchResponse.length >= 6) {
      setModalGridStyle(
        'position: relative; w-[70rem] h-[24.5rem] max-h-[45rem] overflow-x-hidden overflow-y-hidden mt-6 p-3'
      );
    } else {
      setModalGridStyle(
        'position: relative; w-[73rem] max-h-[45rem] overflow-x-hidden mt-6 p-3'
      );
    }
  }, [searchResponse, isServiceSearchLoading, searchTerm]);

  return (
    <>
      {ShowServiceButton()}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="overflow-x-hidden"
        maxWidth={'lg'}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '0px'
          }
        }}
      >
        <Grid className={modalGridStyle}>
          <DialogTitle className="font-Bosch_sans_bold text-3xl">
            {t('ui_spear.createTicket.search_for_a_service')}
          </DialogTitle>
          <DialogContent className="overflow-y-hidden">
            <ServiceSearch
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setSearchEvent={setSearchEvent}
              searchEvent={searchEvent}
              setSearchResponse={setSearchResponse}
              searchResponse={searchResponse}
              setLastSearchedTerm={setLastSearchedTerm}
              lastSearchedTerm={lastSearchedTerm}
              isServiceSearchLoading={isServiceSearchLoading}
              setIsServiceSearchLoading={setIsServiceSearchLoading}
              handleInputChange={handleInputChange}
              wasSearchOnEnter={wasSearchOnEnter}
              setWasSearchOnEnter={setWasSearchOnEnter}
              setWasSuggestionSelected={setWasSuggestionSelected}
              wasSuggestionSelected={wasSuggestionSelected}
              setAlertEmptyResponse={setAlertEmptyResponse}
              setAlertErrorResponse={setAlertErrorResponse}
            />
          </DialogContent>
          <DialogContent>
            <ServiceTable
              setTicketSuppData={props.setTicketSuppData}
              ticketSuppData={props.ticketSuppData}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchEvent={searchEvent}
              searchResponse={searchResponse}
              isServiceSearchLoading={isServiceSearchLoading}
              setIsServiceSearchLoading={setIsServiceSearchLoading}
              setSearchPage={setSearchPage}
              searchPage={searchPage}
              setServiceSelected={setServiceSelected}
              wasSearchOnEnter={wasSearchOnEnter}
              setWasSearchOnEnter={setWasSearchOnEnter}
              handleServiceSelection={handleServiceSelection}
              setWasSuggestionSelected={setWasSuggestionSelected}
              wasSuggestionSelected={wasSuggestionSelected}
            />
          </DialogContent>
          <DialogActions
            className={`${searchResponse.length === 0 ? 'mt-[2%] mr-6' : 'mt-[1%] mr-6'
              }`}
          >
            <Button
              onClick={handleClose}
              className="rounded-none capitalize w-[8rem] text-[1rem] border-change_cust_btn hover:text-hover_blue_btns"
              variant="outlined"
            >
              {t('ui_spear.createTicket.cancel')}
            </Button>
            <Button
              disabled={isTempSelectedServEmpt}
              onClick={confirmServiceSelected}
              className={submitButtonStyle}
              variant="outlined"
            >
              {t('ui_spear.createTicket.service_confirm_button')}
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
      {ShowEmptyResAlert()}
      {ShowErrorResAlert()}
    </>
  );
};

export default ServiceModal;
