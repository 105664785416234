import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import {
  Grid,
  Typography,
  Container,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Pagination,
  TableSortLabel,
  Box
} from '@mui/material';
import EnhancedTableHead from './ServiceSort';
import LoadingEllipsis from './LoadingEllipsis';

const columns = [
  { id: 'CI_NAME', label: 'Name', minWidth: 170 },
  { id: 'CI_KEYWORD', label: 'Alias', minWidth: 100 }
];

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'indexData'
})(({ indexData }) => ({
  ...(indexData % 2 === 0 && {
    backgroundColor: '#f5f5f5'
  }),
  '&.MuiTableRow-root.Mui-selected': {
    backgroundColor: '#7FC6E7 !important'
  }
}));

const ServiceTable = (props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentService, setCurrentService] = useState({});
  const [searchNumber, setSearchNumber] = useState(0);
  const [paginationNumber, setPaginationNumber] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('CI_NAME');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectedService = (service) => {
    setCurrentService(service);
    props.handleServiceSelection(service);
  };

  const validateResponseType = () => {
    if (
      props.searchResponse.length > 0 &&
      props.isServiceSearchLoading === false
    ) {
      if (props.wasSuggestionSelected || props.wasSearchOnEnter) {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const visibleServices = useMemo(
    () =>
      stableSort(props.searchResponse, getComparator(order, orderBy)).slice(
        0,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, props.searchResponse]
  );

  useEffect(() => {
    if (props.searchResponse.length > 0) {
      const pagination =
        searchNumber % 4 ? Math.floor(searchNumber / 4) + 1 : searchNumber / 4;
      if (page > pagination && pagination > 0) {
        setPage(pagination);
      }
      setSearchNumber(props.searchResponse.length);
      setPaginationNumber(pagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchResponse, searchNumber]);

  return (
    <>
      {validateResponseType() ? (
        <Paper
          sx={{
            width: '100%',
            overflow: 'hidden',
            boxShadow: 'none',
            borderRadius: '0px'
          }}
        >
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={props?.searchResponse?.length}
              />
              <TableBody>
                {visibleServices
                  .slice(page * 4 - 4, page * 4)
                  .map((row, index) => {
                    const indexRow = index;
                    return (
                      <StyledTableRow
                        indexData={indexRow}
                        onClick={() => handleSelectedService(row)}
                        selected={currentService === row}
                        hover
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align="left"
                              style={{ width: '50%', maxWidth: '50%' }}
                            >
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            sx={{
              paddingLeft: '35%',
              paddingTop: '1.2rem',
              fontSize: '1.2rem',
              '& .MuiPaginationItem-root.Mui-selected': {
                background: 'rgb(0 86 145)',
                color: 'rgb(255, 255, 255)'
              },
              '& .MuiPaginationItem-root': {
                color: '#005580',
                fontWeight: 'bold',
                fontSize: '1.1rem'
              },
              '& .MuiPaginationItem-root:hover': {
                background: '#4eb5ea'
              },
              '& .MuiPaginationItem-icon': {
                fontSize: '2rem',
                fill: 'rgba(0, 0, 0, 0.6)'
              }
            }}
            count={paginationNumber}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </Paper>
      ) : props.isServiceSearchLoading && props.wasSearchOnEnter ? (
        <LoadingEllipsis
          message={t('ui_spear.createTicket.service_are_loading')}
          trigger={'table'}
        />
      ) : (
        <Grid className="mt-[1rem] overflow-hidden">
          <span className="bosch-icon service-alert-icon">&#xeb09;</span>
          <span className="text-[1.1rem] pb-8">
            {t('ui_spear.createTicket.service_please_only_select')}
          </span>
        </Grid>
      )}
    </>
  );
};

export default ServiceTable;
