import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const BackToDeviceOverview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleToBack = () => {
    navigate('/devices', {
      state: null
    });
  }

  return (
    <div>
      <button onClick={handleToBack} className="text-ITSP_blue">
        &lt; {t('ui_spear.deviceDetails.back_to_device_overview')}
      </button>
    </div>
  )
}

export default BackToDeviceOverview;