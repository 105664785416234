import React from 'react';
import { Grid } from '@mui/material';

const rowsArray = [1, 2];
const columsArray = [1, 2, 3, 4, 5, 6, 7];

const TicketSearchLoader = () => {
  const renderColums = () => {
    return columsArray.map((item) => (
      <div className="rounded-xl bg-gray-300 h-12 m-2" key={item}></div>
    ));
  };

  const renderRows = () => {
    let tempRows = rowsArray.map((row) => {
      return (
        <div key={row} className={`grid grid-cols-7 p-1 m-1`}>
          {renderColums()}
        </div>
      );
    });
    return tempRows;
  };

  return (
    <Grid item>
      <div className="w-full">
        <div className="animate-pulse ">{renderRows()}</div>
      </div>
    </Grid>
  );
};

export default TicketSearchLoader;
