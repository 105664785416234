import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Banner from '../../components/Software/Details/Banner';
import Status from '../../components/Software/Details/Status';
import Details from '../../components/Software/Details/Details';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axiosInstance';

const SoftwareDetails = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [statusLoading, setStatusLoading] = useState(true);
  const [software, setSoftware] = useState({});
  const [deviceList, setDeviceList] = useState([]);
  const { userInfo } = props;
  const { softwareId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const localizedTexts = {
    status_CMI00005: t('ui_spear.softwareDetails.it_is_not_allowed'),
    status_CMI00006: t('ui_spear.softwareDetails.this_package_is_hidden'),
    status_CMI00007: t(
      'ui_spear.softwareDetails.this_package_is_hidden_region'
    ),
    status_CMI00008: t(
      'ui_spear.softwareDetails.this_package_is_hidden_system'
    ),
    status_CMI00009: t(
      'ui_spear.softwareDetails.this_package_is_hidden_property'
    ),
    status_unknown: t('ui_spear.softwareDetails.unknown_installable_status')
  };

  useEffect(() => {
    const getSoftwareDetails = async () => {
      const url = `/api/v1/cms/software/details/${softwareId}`;
      axiosInstance
        .get(url)
        .then((response) => {
          if (response.data.isSuccessful) {
            if (
              response.data.data[0] === undefined ||
              response.data.data[0] === null
            ) {
              navigate('/software/error');
            } else {
              setSoftware(response.data.data[0]);
            }
          } else {
            navigate('/software/error');
          }
        })
        .catch((e) => {
          console.log('Error: ', e);
          navigate('/software/error');
        });
    };

    softwareId && getSoftwareDetails();
  }, [softwareId]);

  useEffect(() => {
    const getServiceItems = async (devices) => {
      if (devices.length > 0) {
        let devicesIds = devices.map((d) => {
          return d.ID;
        });
        const body = {
          softwareId: software.id,
          deviceList: devicesIds
        };
        const urlStatus = `/api/v1/cms/software/status`;

        axiosInstance
          .post(urlStatus, body)
          .then((resp) => {
            if (resp.status === 200 && resp.data)
              fetchDevicesStatus(resp.data.data, devices);
          })
          .catch((e) => {
            console.log('Error: ', e);
            setStatusLoading(false);
          });
      }
    };

    const getUserDeviceList = () => {
      const deviceUrl = `/api/v1/cms/devices/${userInfo.userNTID}`;
      axiosInstance
        .get(deviceUrl)
        .then((response) => {
          if (response.status === 200) {
            getServiceItems(response.data.data);
          }
        })
        .catch((e) => console.log('Error: ', e));
    };

    const fetchDevicesStatus = async (devicesStatus, devices) => {
      let tempdeviceList = await devices.filter((item) => {
        let tempDevice = devicesStatus.find((r) => r.DEVICE_ID == item.ID);

        if (tempDevice && tempDevice.DEVICE_ID) {
          item.SWP_ID = tempDevice.SWP_ID;
          item.INSTALLATION_STATUS = tempDevice.INSTALLATION_STATUS;

          if (
            tempDevice.installableServerResponse != null &&
            tempDevice.installableServerResponse.MessageID
          ) {
            // Localize error responses
            if (tempDevice.installableServerResponse.MessageID === 'CMI00005')
              item.INSTALLABLE_STATUS = localizedTexts.status_CMI00005;
            else if (
              tempDevice.installableServerResponse.MessageID === 'CMI00006'
            )
              item.INSTALLABLE_STATUS = localizedTexts.status_CMI00006;
            else if (
              tempDevice.installableServerResponse.MessageID === 'CMI00007'
            )
              item.INSTALLABLE_STATUS = localizedTexts.status_CMI00007;
            else if (
              tempDevice.installableServerResponse.MessageID === 'CMI00008'
            )
              item.INSTALLABLE_STATUS = localizedTexts.status_CMI00008;
            else if (
              tempDevice.installableServerResponse.MessageID === 'CMI00009'
            )
              item.INSTALLABLE_STATUS = localizedTexts.status_CMI00009;
            else
              item.INSTALLABLE_STATUS =
                tempDevice.installableServerResponse.Message;
          } else {
            item.INSTALLABLE_STATUS = localizedTexts.status_unknown;
          }
          item.SWNAME = tempDevice.SWNAME;
          item.RBCMNR = tempDevice.RBCMNR;
          return item;
        }
      });

      setDeviceList(tempdeviceList);
      setStatusLoading(false);
    };

    software.id && userInfo && userInfo.userNTID && getUserDeviceList();
    software.id && setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [software, userInfo]);

  const handleToBack = (value) => {
    let goTo = value === 1 ? 'devices' : 'software';
    navigate(`/${goTo}`, {
      state: null
    });
  };

  return (
    <div className="container px-14 w-4/6 mx-auto">
      {loaded && (
        <>
          <div className="font-Bosch_sans_light font-[14px] mt-10 mb-[60px]">
            <button onClick={() => handleToBack(1)} className="text-ITSP_blue">
              {t('ui_spear.deviceMobileDetailsUnlock.my_devices')}
            </button>
            &nbsp;&gt;&nbsp;
            <button onClick={() => handleToBack(2)} className="text-ITSP_blue">
              {t('ui_spear.deviceDetails.software_tab')}
            </button>
            &nbsp; &gt;&nbsp;
            <span>{software.title}</span>
          </div>
          <Banner
            swTitle={software.title}
            swRBCMNR={software.RBCMNR}
            swDescription={software.description}
          />
          <Status
            statusLoading={statusLoading}
            deviceList={deviceList}
            setDeviceList={setDeviceList}
            software={software}
          />
          <Details software={software} />
        </>
      )}
    </div>
  );
};

export default SoftwareDetails;
