import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoadingButton from './LoadingButton';
import axiosInstance from '../../../utils/axiosInstance';

const SiriButton = ({ siriStatus, setSiriStatus, userNTID }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const _requestSiri = () => {
    let url = `/api/v1/snow/request/device/phone/setting`;
    let status = siriStatus;
    let statusBolean = siriStatus === 'Enable' ? '1' : '0';
    let dataReq = {
      requested_for: userNTID,
      variables: {
        service: 'manage SIRI',
        enable: statusBolean,
        cat_item: 'Mobile Phone Siri Request'
      }
    };

    setSiriStatus('Inactive');
    axiosInstance
      .post(url, dataReq)
      .then((resp) => {
        localStorage.setItem('siriEnable', `Pending -  ${status}`);
        navigate('/tickets');
      })
      .catch((e) => {
        console.log('error:', e);
      });
  };

  const _renderSiriButton = () => {
    let siriButton;

    switch (siriStatus) {
      case 'Enable':
        siriButton = (
          <button
            onClick={(e) => _requestSiri()}
            className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light px-6 py-2"
          >
            {t('ui_spear.deviceMobileDetails.enable')}
          </button>
        );
        break;
      case 'Disable':
        siriButton = (
          <button
            onClick={(e) => _requestSiri()}
            className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light px-6 py-2"
          >
            {t('ui_spear.deviceMobileDetails.disable')}
          </button>
        );
        break;
      case 'Pending':
        siriButton = (
          <button className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light px-6 py-2">
            {t('ui_spear.softwareDetails.pending')}
          </button>
        );
        break;
      case 'Error':
        siriButton = (
          <button className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light px-6 py-2">
            {t('ui_spear.softwareDetails.error')}
          </button>
        );
        break;
      default:
        siriButton = (
          <button
            className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light m-0 px-6 py-2"
            style={{ height: 38 }}
          >
            {
              <LoadingButton />
            }
          </button>
        );
        break;
    }
    return siriButton;
  };

  return <>{_renderSiriButton()}</>;
};

export default SiriButton;
