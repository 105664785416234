/**
 *
 * @param {Object} user with properties: ntId, location,...
 * @returns boolean
 */
export const getShowPilot = (user) => {
  let PilotSupportLocationData = process.env.REACT_APP_ENTITLE_SUPPORT_LOCATION;
  let locIds = PilotSupportLocationData
    ? PilotSupportLocationData.split(', ')
    : [];

  for (let key in locIds) {
    if (user.userLocation.toUpperCase() === locIds[key].toUpperCase()) {
      return true;
    }
  }

  return false;
}; 
