import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle, IconButton, styled } from '@mui/material';
import { softwareSupport } from '../../utils/softwareFunctions';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axiosInstance';

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
    marginTop: 80
  }
}));

const InstallModal = ({ open, setOpen, modalData, swSupport }) => {
  const { t } = useTranslation();
  const [confirmation, setConfirmation] = useState(false);
  const fullWidth = true;
  const maxWidth = 'md';

  const localizedTexts = {
    installation: t('ui_spear.softwareDetails.installation'), //"Installation",
    start_installation: t('ui_spear.softwareDetails.start_installation'), //Start Installation Process,
    cancel_installation: t('ui_spear.softwareDetails.cancel_installation'), //Cancel installation process,
    the_software_does_not: t('ui_spear.softwareDetails.the_software_does_not'), //The software does not require approval. The installation process can be started without authorization.",
    you_have_successfully: t('ui_spear.softwareDetails.you_have_sucessfully'), //You have successfully completed the installation process for the software",
    you_receive_a_message_install: t(
      'ui_spear.softwareDetails.you_receive_a_message_install'
    ), //"You receive a message via the SCCM (IT Workplace), once the software is uninstalled from your device. This process can take up to"
    days: t('ui_spear.softwareDetails.days'),
    suport_throught_IT: t('ui_spear.softwareDetails.support_through_it'),
    installation_support_interface: t(
      'ui_spear.softwareDetails.full_support_through'
    ),
    everithing_supported: t('ui_spear.softwareDetails.full_support_through_it'),
    data_not_aviable: t('ui_spear.softwareDetails.data_not_available')
  };

  const Icons = {
    checkImg: require(`../../static/deviceIcons/software-check.png`),
    clockImg: require(`../../static/deviceIcons/software-clock.png`),
    headsetImg: require(`../../static/deviceIcons/software-headset.png`)
  };
  const handleClose = () => {
    setOpen(false);
  };
  const installSoftware = () => {
    const url = `/api/v1/snow/request/software/install`;

    let payload = {
      requested_for: modalData.loginName,
      variables: {
        Type: 'install',
        Computer_Name: modalData.computer,
        Device_ID: modalData.computerId,
        RBCM_Number: modalData.rbcmnr,
        RBCM_ID: modalData.rbcmid,
        Software_Name: modalData.softwareName
      }
    };

    axiosInstance
      .post(url, payload)
      .then((resp) => {
        if (resp.data.isSuccessful) {
          //miss to  change temparaly the status of the install button to Pending
          setConfirmation(true);
        }
      })
      .catch((e) => console.log('Error:', e));
  };
  const renderSoftwareSupport = () => {
    return softwareSupport(swSupport, localizedTexts);
  };
  const _renderConfirmation = () => {
    return (
      <>
        <div className="flex items-center leading-tight">
          <img className="h-[36px] w-[36px] mr-4" src={modalData.icon} alt="" />
          <span>{localizedTexts.the_software_does_not} </span>
        </div>

        <div className="flex items-center justify-start mt-[58px] mb-10">
          <button
            className="bg-ITSP_blue border-solid border-ITSP_blue text-white border-[1px] w-[250px] h-[48px] font-Bosch_sans font-light text-[14px]"
            onClick={installSoftware}
          >
            {localizedTexts.start_installation}
          </button>
          <button
            className="bg-white border-solid border-ITSP_blue border-[1px] 
                        text-ITSP_blue  h-[48px] w-auto font-Bosch_sans font-light text-[14px] px-5 ml-5"
            onClick={handleClose}
          >
            {localizedTexts.cancel_installation}
          </button>
        </div>
      </>
    );
  };

  const _renderSuccess = () => {
    return (
      <>
        <div className="flex items-center leading-tight my-4">
          <img className="h-[36px] w-[36px] mr-4" src={Icons.checkImg} alt="" />
          <span>
            {localizedTexts.you_have_successfully} {modalData.softwareName}{' '}
            {localizedTexts.installation}{' '}
          </span>
        </div>
        <div className="flex items-center leading-tight my-4">
          <img className="h-[36px] w-[36px] mr-4" src={Icons.clockImg} alt="" />
          <span>
            {localizedTexts.you_receive_a_message_install}{' '}
            <span className="font-bold">2 {localizedTexts.days}</span>{' '}
          </span>
        </div>

        <div className="flex items-center leading-tight my-4">
          <img
            className="h-[36px] w-[36px] mr-4"
            src={Icons.headsetImg}
            alt=""
          />
          <span>{renderSoftwareSupport()}</span>
        </div>
      </>
    );
  };

  return (
    <>
      <StyledDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle className="px-[56px] py-6">
          <span className="text-[36px] mt-10 text-support_banner_color_1 font-Bosch_sans_light">
            {modalData.softwareName} {modalData.tittle}{' '}
          </span>
          <IconButton
            className="boschicon-bosch-ic-close text-[40px] absolute top-2 right-2 color-black"
            aria-label="close"
            onClick={handleClose}
          ></IconButton>
        </DialogTitle>

        <DialogContent className="font-Bosch_sans_light text-[19px] px-[56px] min-h-[90px]">
          {confirmation ? _renderSuccess() : _renderConfirmation()}
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default InstallModal;
