import React from 'react';
import { Grid } from '@mui/material';

const SAPDisclaymer = ({ disclaymer }) => {
  return (
    <Grid item>
      <div className="bg-[#D1E4FF] font-Bosch_sans text-ITSP_blue text-lg p-6 rounded">
        <span dangerouslySetInnerHTML={{ __html: disclaymer }} />
      </div>
    </Grid>
  );
};

export default SAPDisclaymer;
