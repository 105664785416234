import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const TelephonyList = (props) => {
  const { t } = useTranslation();

  const handleClose = () => {
    props.setOpenTelephonyList(false);
  };

  return (
    <>
      <Dialog
        open={props.openTelephonyList}
        fullWidth={true}
        maxWidth={'sm'}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="items-center px-[50px]">
          <Grid item xs={12} align="center">
            <Grid item xs={12}>
              <Typography
                variant="caption"
                className="font-Bosch_sans_light text-[26px]"
              >
                {t(
                  'ui_spear.support.schedulle_callback_oops_something_missing'
                )}
              </Typography>
              <IconButton
                className="boschicon-bosch-ic-close text-2xl absolute top-2 right-2 color-black"
                aria-label="close"
                onClick={handleClose}
              ></IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Typography className="font-Bosch_sans text-lg mt-7 text-left pl-3 pr-3">
            {t(
              'ui_spear.support.schedulle_callback_you_need_to_have_public_telephony'
            )}{' '}
            <strong>
              {t(
                'ui_spear.support.schedulle_callback_please_use_another_phone'
              )}
            </strong>
          </Typography>
          <br />
          <br />
          <a
            href={process.env.REACT_APP_TELEPHONE_LIST}
            className="font-Bosch_sans text-lg text-left pl-3 pr-3"
            // eslint-disable-next-line react/no-unknown-property
            underline="none"
            target="_blank"
            rel="noreferrer"
          >
            {t(
              'ui_spear.support.schedulle_callback_check_it_service_desk_contact_locs'
            )}
            {' >'}
          </a>
        </DialogContent>
      </Dialog>
    </>
  );
};

//

export default TelephonyList;
