import FooterSectionList from './FooterSectionList';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const footerSections = [
    {
      title: t('ui_spear.footer.BD_support'),
      content: [
        {
          contentInfo: t('ui_spear.footer.3311_for_Raising_a_Ticket'),
          contentTitle: '',
          link: '',
          iconForm: 'phone'
        },
        {
          contentInfo: '',
          contentTitle: t('ui_spear.footer.contact_it_service_desk'),
          link: 'https://inside-ws.bosch.com/FIRSTspiritWeb/permlink/wcms_bd_-CIOS_OSP3_CI_Hotline_Contact_2-EN',
          iconForm: 'phone'
        },
        {
          contentInfo: '',
          contentTitle: t('ui_spear.footer.News_and_Updates'),
          link: 'https://connect.bosch.com/blogs/fc29ba88-808b-49d7-9439-4f1735f3c0f5?tags=itsp',
          iconForm: 'asterisk'
        }
      ]
    },
    {
      title: t('ui_spear.footer.More_about_IT_Service'),
      content: [
        {
          contentInfo: t('ui_spear.footer.IT_Service_Portal_works'),
          contentTitle: t('ui_spear.footer.More_about_IT_Service'),
          link: 'https://inside-docupedia.bosch.com/confluence/x/Bq5qHg',
          iconForm: ''
        },
        {
          contentInfo: '',
          contentTitle: t('ui_spear.footer.spear_oss'),
          link: `${process.env.REACT_APP_OSS_DOC_URL}`,
          iconForm: ''
        }
      ]
    },
    {
      title: t('ui_spear.footer.Related_sites'),
      content: [
        {
          contentInfo: '',
          contentTitle: t('ui_spear.footer.digital_workplace'),
          link: 'https://connect.bosch.com/communities/service/html/communitystart?communityUuid=fc29ba88-808b-49d7-9439-4f1735f3c0f5',
          iconForm: ''
        },
        {
          contentInfo: '',
          contentTitle: t('ui_spear.footer.Service_Onboarding'),
          link: 'https://inside-docupedia.bosch.com/confluence/x/xDEQRg',
          iconForm: ''
        }
      ]
    }
  ];

  const renderFooterSections = () => {
    return footerSections.map((section, i) => {
      return (
        <FooterSectionList
          className="text-[#5F5F5F]"
          key={i.toString()}
          titleSection={section.title}
          content={section.content}
        />
      );
    });
  };

  return (
    <footer className="w-full bottom-0 footer-titles pt-8 text-[#5F5F5F]">
      <hr></hr>
      <div className="w-full max-w-screen-xl mx-auto">
        <div className="flex flex-row justify-center p-6">
          {renderFooterSections()}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
