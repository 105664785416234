import React from 'react';
import { getImageIconForSoftware } from '../../../utils/softwareFunctions';

const Banner = ({ swTitle, swRBCMNR, swDescription }) => {
  const swIcon = getImageIconForSoftware(swRBCMNR);

  return (
    <div className="grid grid-rows-1 font-Bosch_sans_light my-[56px]">
      <div className="flex justify-center items-center">
        <div className="mr-5">
          <img className="h-[48px] w-[48px]" src={swIcon} alt={swTitle} />
        </div>
        <div className="text-[36px] text-grey_softwareText">{swTitle}</div>
      </div>
      <div className="text-center text-[18px] p-3 text-[#525C36]">
        {swDescription}
      </div>
    </div>
  );
};

export default Banner;
