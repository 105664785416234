import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import BoschTopBar from '../Header/BoschTopBar';

const FooterCopyright = (props) => {
  return (
    <Grid className="w-full bottom-0 footer-titles bg-[#E7E7E7]">
      <Grid className="w-full max-w-screen-xl mx-auto p-4">
        <Grid item className="flex justify-around items-center h-full w-full">
          <Grid item className="w-[30%] px-4">
            <Typography
              variant="body"
              className="font-Bosch_sans w-[262px] text-[14px] text-[#5F5F5F]"
            >
              <span className=" text-[15px]">©</span> Robert Bosch GmbH
            </Typography>
          </Grid>
          <Grid item className="w-[44%]"></Grid>
          <Grid item className="w-[23%] px-4">
            <Link
              to="https://inside-docupedia.bosch.com/confluence/x/PjpiJQ"
              className="text-[11px] text-[#a1a1a1] hover:text-[#5F5F5F]"
            >
              <Typography
                variant="body2"
                className="text-[11px] text-[#a1a1a1] hover:text-[#5F5F5F]"
              >
                Data Security & Privacy Notice
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <BoschTopBar />
    </Grid>
  );
};

export default FooterCopyright;
