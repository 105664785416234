import { useState, useEffect, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

const SearchPerson = ({ options, personFilter, setPersonFilter }) => {
  //Translations
  const { t } = useTranslation();
  const personLabel = t('ui_spear.myTickets.Person');
  const searchForOthersLabel = t('ui_spear.myTickets.search_for_others');

  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    setPersonFilter(inputText);

    const filtered = options.filter((option) =>
      option.toLowerCase().includes(inputText.toLowerCase())
    );
    setFilteredOptions(filtered);
    setIsDropdownOpen(true);
  };

  const handleOptionClick = (option) => {
    setPersonFilter(option);
    setFilteredOptions([]);
  };

  // Function to close the dropdown when a click event occurs outside of it
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  // Add a click event listener to the document when the component mounts
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="m-5 max-w-[360px]">
      <h6 className="font-medium text-lg">{personLabel}</h6>
      <div className="flex items-center justify-center pt-1">
        <input
          type="text"
          value={personFilter}
          onChange={handleInputChange}
          placeholder={searchForOthersLabel}
          className="w-full border-b border-gray-500 focus:outline-none placeholder:text-gray-300 font-light text-lg"
        />
        <SearchIcon className="text-black w-7 h-7" />
      </div>
      <ul
        ref={dropdownRef}
        className={` ${personFilter.length > 0 ? 'block' : 'hidden'} 
            ${filteredOptions.length === 0 ? 'border-none' : 'border'} 
            ${personFilter.length > 0 ? 'block' : 'hidden'} 
            ${!isDropdownOpen && 'hidden'}
          border-gray-300 list-none p-0 m-0 absolute z-10 bg-white w-[335px] 
          `}
      >
        {filteredOptions.map((option, index) => (
          <li
            key={index}
            onClick={() => handleOptionClick(option)}
            className="pl-2 py-1 hover:bg-gray-100"
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchPerson;
