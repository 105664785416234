import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import React, { Fragment } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

const StyledTitleDialog = styled(DialogTitle)({
  fontFamily: 'BoschSans-Bold',
  display: 'inline-block',
  verticalAlign: 'middle',
  fontWeight: 'bold',
  fontSize: '18px'
});

const StyledTypoContactIT = styled(Typography)({
  fontFamily: 'BoschSans-Bold',
  fontWeight: 'bold',
  fontSize: '23px',
  marginLeft: '5%',
  marginBottom: '10px'
});

const StyledTypoMessage = styled(Typography)({
  fontFamily: 'BoschSans',
  fontSize: '16px',
  marginLeft: '5%'
});

const StyledClosedButton = styled(Button)({
  border: '1px solid #005691',
  backgroundColor: '#005691 !important',
  color: '#ffffff',
  borderRadius: '0px',
  padding: '8px 35px',
  textTransform: 'none',
  marginTop: '20px',
  fontFamily: 'BoschSans',
  fontSize: '16px',
  [`&:hover`]: {
    backgroundColor: '#008dcf !important'
  }
});

const StyledButtons = styled(Button)({
  border: '1px solid #005691',
  backgroundColor: '#ffffff !important',
  color: '#005691',
  borderRadius: '0px',
  padding: '8px 35px',
  textTransform: 'none',
  marginTop: '20px',
  fontFamily: 'BoschSans',
  fontSize: '16px',
  [`&:hover`]: {
    backgroundColor: '#008dcf !important'
  }
});

const ErrorMessage = (props) => {
  const { t } = useTranslation();
  const renderMessage = () => {
    if (props.errorMessage != '') {
      return (
        <Dialog
          open={props.errorPopUp}
          onClose={() => {
            props.togglePopUp();
          }}
          maxWidth="md"
        >
          <Grid className="position: relative; w-[40rem] overflow-x-hidden">
            <Grid
              style={{ borderTop: `5px solid ${props.errorMessage.COLOR ? props.errorMessage.COLOR : 'red'}` }}
              container
            >
              <Grid
                className={props.errorMessage.ICON == 'eb09' ? `err-icon ${props.errorMessage.ICON === 'eb09' && 'boschicon-bosch-ic-alert-error'}` : props.errorMessage.ICON == 'eb0d' ? `warn-icon ${props.errorMessage.ICON == 'eb0d' && 'boschicon-bosch-ic-alert-warning'}`
                  : 'err-icon eb09 boschicon-bosch-ic-alert-error'
                }
              ></Grid>
              <StyledTitleDialog>
                {t('ui_spear.errorHandler.title')}
              </StyledTitleDialog>
            </Grid>
            <hr></hr>
            <DialogContent>
              <Grid container>
                <Grid style={{ width: '100%' }}>
                  <StyledTypoContactIT>
                    {t('ui_spear.footer.contact_it_service_desk')}
                  </StyledTypoContactIT>
                </Grid>
                <Grid style={{ width: '100%' }}>
                  <StyledTypoMessage>
                    {props.errorType == 'Share article' ? t('ui_spear.errorHandler.trouble_getting_linked_article') : props.errorType == 'Search article' ? t('ui_spear.errorHandler.there_was_a_problem_searching') : t('ui_spear.errorHandler.bodyText')}
                  </StyledTypoMessage>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid style={{ width: '30%' }}></Grid>
                <Grid style={{ marginBottom: '10px' }}>
                  <StyledButtons
                    onClick={() =>
                      props.setOpenCallFromTicket(!props.openCallFromTicket)
                    }
                    style={{ marginRight: '10px' }}
                  >
                    {t('ui_spear.contactIT.Button.Call')}
                  </StyledButtons>
                  <StyledButtons
                    onClick={() =>
                      props.setOpenChatFromTicket(!props.openChatFromTicket)
                    }
                    style={{ marginRight: '10px' }}
                  >
                    {t('ui_spear.contactIT.Button.Chat')}
                  </StyledButtons>
                  <StyledClosedButton
                    onClick={() => {
                      props.togglePopUp();
                    }}
                  >
                    {t('ui_spear.search.ModalButton.Close')}
                  </StyledClosedButton>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </Dialog>
      );
    } else {
      return (
        <Dialog
          open={props.errorPopUp}
          onClose={() => {
            props.togglePopUp();
          }}
          maxWidth="md"
        >
          <Grid className="position: relative; w-[40rem] overflow-x-hidden">
            <Grid style={{ borderTop: `5px solid red` }} container>
              <Grid
                className={'err-icon boschicon-bosch-ic-alert-error'}
              ></Grid>
              <StyledTitleDialog>
                {t('ui_spear.errorHandler.title')}
              </StyledTitleDialog>
            </Grid>
            <hr></hr>
            <DialogContent>
              <Grid container>
                <Grid style={{ width: '100%' }}>
                  <StyledTypoContactIT>
                    {t('ui_spear.errorHandler.Attachments_are_too_big')}
                  </StyledTypoContactIT>
                </Grid>
                <Grid style={{ width: '100%' }}>
                  <StyledTypoMessage>
                    {t('ui_spear.errorHandler.Please_note_for_each')}
                  </StyledTypoMessage>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid style={{ width: '30%' }}></Grid>
                <Grid style={{ marginBottom: '10px' }}>
                  <StyledClosedButton
                    style={{ position: 'relative', left: '200%' }}
                    onClick={() => {
                      props.togglePopUp();
                    }}
                  >
                    {t('ui_spear.search.ModalButton.Close')}
                  </StyledClosedButton>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </Dialog>
      );
    }
  };
  return <Fragment>{renderMessage()}</Fragment>;
};

export default ErrorMessage;
