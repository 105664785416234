import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const DevAffected = ({ userDevices, chooseDevAffected }) => {
  const { t } = useTranslation();

  const renderDevices = (devices) => {
    return devices.map((device) => {
      return (
        <Fragment key={device}>
          <option value={device.NAME}>
            {t('ui_spear.createTicket.yes').toUpperCase()} - {device.NAME}
          </option>
        </Fragment>
      );
    });
  };

  return (
    <div>
      <h1 className="mb-9 font-bold">
        {t('ui_spear.createTicket.Is_a_device_affected')}
      </h1>
      <select
        name="device-affected"
        id="device-affected"
        className="border-b-2 border-border_gray py-4 pr-10 pl-4 hover:text-change_cust_btn hover:border-change_cust_btn hover:cursor-pointer"
        onChange={(e) => chooseDevAffected(e)}
      >
        <option value="">{t('ui_spear.createTicket.no')}</option>
        {renderDevices(userDevices)}
      </select>
    </div>
  );
};

export default DevAffected;
