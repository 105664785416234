export const softwareSupport = (swSupport, texts) => {
    let returnRes = "";
    let statusLevel = parseInt(swSupport);

    switch (statusLevel) {
        case 0: returnRes = texts.suport_throught_IT;
            break;
        case 1: returnRes = texts.installation_support_interface;
            break;
        case 2: returnRes = texts.everithing_supported;
            break;
        default:
            returnRes = texts.data_not_aviable;
    }
    return returnRes;
}

export const getImageIconForSoftware = (RBCMNR) => {
    try {
      return require(`../static/softwareIcons/${RBCMNR}.png`);
    } catch (e) {
      return require('../static/Bosch-IC-no-img.png');
    }
  };
