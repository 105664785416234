import { useState } from 'react';

const Pagination = ({ data, itemsPerPage, currentPage, setCurrentPage }) => {
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      let isActive =
        currentPage === number
          ? 'rounded-full bg-ITSP_blue text-white'
          : 'text-blue_font rounded-full hover:bg-article_blue hover:text-ITSP_blue';
      return (
        <li
          key={number}
          id={number.toString()}
          onClick={handleClick}
          className={`w-8 h-8 mr-2 flex items-center justify-center font-semibold cursor-pointer ${isActive}`}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <li
        className="w-8 h-8 mr-2 flex items-center justify-center font-semibold"
        onClick={handleNextBtn}
      >
        &hellip;
      </li>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = (
      <li
        className="w-8 h-8 mr-2 flex items-center justify-center font-semibold"
        onClick={handlePrevBtn}
      >
        &hellip;
      </li>
    );
  }

  const isTheFirstPage = () => {
    return currentPage === pages[0] ? true : false;
  };
  const isTheLastPage = () => {
    return currentPage === pages[pages.length - 1] ? true : false;
  };

  return (
    <div className=" flex justify-center">
      <ul className="flex">
        <li className="w-8 h-8 mr-2 flex items-center justify-center ">
          <button onClick={handlePrevBtn} disabled={isTheFirstPage()}>
            <div className="flex items center">
              <span
                className={`boschicon-bosch-ic-back-left text text-[25px] font-extrabold ${
                  isTheFirstPage() ? 'text-gray-300' : ' text-gray-500'
                }`}
              ></span>
            </div>
          </button>
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}
        <li className="w-8 h-8 mr-2 flex items-center justify-center ">
          <button onClick={handleNextBtn} disabled={isTheLastPage()}>
            <div className="transform rotate-180 flex items center">
              <span
                className={`boschicon-bosch-ic-back-left text text-[25px] font-extrabold ${
                  isTheLastPage() ? 'text-gray-300' : ' text-gray-500'
                }`}
              ></span>
            </div>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
