import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const WhatHappened = ({
  ticketSummaryVal,
  ticketDetailsVal,
  isTicketSummEmpty,
  isTicketDetEmpty,
  handleTicketSummary,
  handleTicketDetails
}) => {
  const inputTickSumm = useRef(null);
  const inputTickDet = useRef(null);
  const EMPTY_INPUTS_STYLES = 'text-red-500 font-bold';
  const { t } = useTranslation();

  useEffect(() => {
    if (isTicketDetEmpty) {
      inputTickDet.current.focus();
    } else {
      inputTickDet.current.blur();
    }
    if (isTicketSummEmpty) {
      inputTickSumm.current.focus();
    } else {
      inputTickSumm.current.blur();
    }
  }, [isTicketSummEmpty, isTicketDetEmpty]);

  return (
    <div className="w-5/6">
      <h3 className="mb-9 font-bold">
        {t('ui_spear.createTicket.What_happened')}
      </h3>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <label
            htmlFor="ticket-summary"
            className={`pl-4 pb-1 ${
              isTicketSummEmpty ? EMPTY_INPUTS_STYLES : ''
            }`}
          >
            {t('ui_spear.createTicket.Ticket_summary')}
          </label>
          <input
            ref={inputTickSumm}
            type="text"
            maxLength="100"
            placeholder={t('ui_spear.createTicket.Describe_the_topic')}
            className="mb-9 border-b-2 pl-4 pb-2"
            onChange={handleTicketSummary}
            value={ticketSummaryVal}
          />
        </div>
        <div className="h-48 px-4 py-1 border-2 flex flex-col">
          <label
            htmlFor="ticket-details"
            className={`pb-1 ${isTicketDetEmpty ? EMPTY_INPUTS_STYLES : ''}`}
          >
            {t('ui_spear.createTicket.Ticket_details')}
          </label>
          <textarea
            ref={inputTickDet}
            name="ticket-details"
            id="ticket-details"
            maxLength="10000"
            placeholder={t('ui_spear.createTicket.Please_provide_us_more')}
            className="mb-9 h-48 resize-none"
            onChange={handleTicketDetails}
            value={ticketDetailsVal}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default WhatHappened;
