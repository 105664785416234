import React, { useState } from 'react';
import InstallActionButton from './InstallActionButton';
import NotificationModal from '../NotificationModal';
import UninstallModal from '../UninstallModal';
import InstallModal from '../InstallModal';
import SoftwareLoader from '../../Confirmations/SoftwareLoader';
import { useTranslation } from 'react-i18next';

const Status = ({ statusLoading, deviceList, setDeviceList, software }) => {
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openUninstallModal, setOpenUninstallModal] = useState(false);
    const [openInstallModal, setOpenInstallModal] = useState(false);
    const { t } = useTranslation();

    const [modalData, setModalData] = useState({
        tittle: "",
        softwareName: software.title,
        description: "",
        errorMessage: "",
        icon: ""
    })

    const localizedTexts = {
        statusTitle: t('ui_spear.softwareDetails.installation_status')//"Installation status of Device"
    }

    return (
        <>
            <div className='text-[24px] text-grey_softwareText font-Bosch_sans_light my-6 text-center'>
                {localizedTexts.statusTitle}
            </div>

            {statusLoading ?
                <div className='ml-[-180px] mt-[-70px]'>
                    <SoftwareLoader></SoftwareLoader>
                </div>
                :
                deviceList.map((device, index) =>
                    <div key={index} className={`grid grid-cols-4 gap-4 items-center font-Bosch_sans_light border-solid border-[#e0e0e0] border-b-[1px] ${index === 0 ? 'border-t-[1px]' : ''} `}>
                        <div className='p-5'>{device.NODE_NAME}</div>
                        <div className='p-5'>{device.MODEL}</div>
                        <div className='p-5'>{device.DEVICE_CLASS}</div>
                        <div className='p-5'>
                            <InstallActionButton
                                installationStatus={device.INSTALLATION_STATUS}
                                installableStatus={device.INSTALLABLE_STATUS}
                                setOpenInfoModal={setOpenInfoModal}
                                setOpenUninstallModal={setOpenUninstallModal}
                                setOpenInstallModal={setOpenInstallModal}
                                setModalData={setModalData}
                                modalData={modalData}
                                loginName={device.USER_ID}
                                softwareName={device.SWNAME}
                                rbcmid={device.SWP_ID}
                                rbcmnr={device.RBCMNR}
                                computerId={device.ID}
                                computer={device.NODE_NAME}
                            />
                        </div>
                    </div>
                )
            }
            {openInfoModal && <NotificationModal open={openInfoModal} setOpen={setOpenInfoModal} modalData={modalData} />}
            {openUninstallModal && <UninstallModal open={openUninstallModal} setOpen={setOpenUninstallModal} modalData={modalData} />}
            {openInstallModal && <InstallModal open={openInstallModal} setOpen={setOpenInstallModal} modalData={modalData} swSupport={software.software_support} />}
        </>
    );
};

export default Status;