import { Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipContext } from '../../context/TooltipContext'; 

export default function TooltipSearchBar({
  children,
  open,
  index,
  handleNextTipButton,
  closeTooltip
}) {
  const { t } = useTranslation();

  const [showTooltip, setShowTooltip] = useState(open);
  const [tooltipCount, setTooltipCount] = useContext(TooltipContext);
  const [disabledButton, setDisableButton] = useState(false);

  useEffect(() => {
    setShowTooltip(open);
  }, [open]);

  // Detect click outside the menu and close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event?.target.tagName === 'SECTION') {
        closeTooltip();
        setTooltipCount(0);
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  function contentToolTip(index) {
    if (index === 1) {
      return (
        <React.Fragment>
          <div className="px-4 py-2 min-w-[300px] max-w-[350px] relative">
            <button
              className="boschicon-bosch-ic-close absolute text-[#969696] text-[2.6em] top-4 right-7 hover:opacity-70"
              onClick={handleClose}
            ></button>
            <Typography
              color="inherit"
              className="text-[22px] font-extralight pb-1 text-[#444]"
            >
              {t('ui_spear.toolTip.New_search')}
            </Typography>
            <section className="text-[#444] text-[13px]">
              <div className="pr-2">
                <p
                  className="font-Bosch_sans_light"
                  dangerouslySetInnerHTML={{
                    __html: t('ui_spear.toolTip.You_can_use')
                  }}
                ></p>
              </div>
              <div className="flex justify-end items-center">
                <button
                  disabled={disabledButton}
                  className="text-[#025f99] text-end pr-4 pt-2 cursor-pointer font-Bosch_sans_light"
                  onClick={handleNextTip}
                >
                  {t('ui_spear.toolTip.Next_Tip')} {'>'}
                </button>
              </div>
            </section>
          </div>
        </React.Fragment>
      );
    } else if (index === 2) {
      return (
        <React.Fragment>
          <div className="px-4 py-2 min-w-[300px] max-w-[350px] relative">
            <button
              className="boschicon-bosch-ic-close absolute text-[#969696] text-[2.6em] top-4 right-7 hover:opacity-70"
              onClick={handleClose}
            ></button>
            <Typography
              color="inherit"
              className="text-[22px] font-extralight pb-1 text-[#444]"
            >
              {t('ui_spear.toolTip.Save_your_search')}
            </Typography>
            <section className="text-[#444] text-[13px]">
              <p
                className="excludetooltip font-Bosch_sans_light"
                dangerouslySetInnerHTML={{
                  __html: t('ui_spear.toolTip.Have_this_issues_multiple')
                }}
              ></p>
              <p
                className="text-[#025f99] text-end pr-4 pt-2 cursor-pointer font-Bosch_sans_light"
                onClick={function () {
                  handleNextTip();
                  const article = document.querySelector('.article-0');
                  article.dispatchEvent(
                    new Event('click', {
                      bubbles: true
                    })
                  );
                  setTimeout(function () {
                    const element = document.querySelector(
                      '.article-0 + div .plain-article'
                    );
                    element.scrollIntoView();
                  }, 300);
                }}
              >
                {t('ui_spear.toolTip.Next_Tip')} {'>'}
              </p>
            </section>
          </div>
        </React.Fragment>
      );
    } else if (index === 3) {
      return (
        <React.Fragment>
          <div className="px-6 py-2 min-w-[300px] max-w-[350px] relative">
            <button
              className="boschicon-bosch-ic-close absolute text-[#969696] text-[2.6em] top-4 right-7 hover:opacity-70"
              onClick={function () {
                handleClose();
              }}
            ></button>
            <Typography
              color="inherit"
              className="text-[22px] font-extralight pb-1 text-[#444]"
            >
              {t('ui_spear.toolTip.Feedback')}
            </Typography>
            <section className="text-[#444] text-[13px]">
              <p
                className="font-Bosch_sans_light"
                dangerouslySetInnerHTML={{
                  __html: t('ui_spear.toolTip.Here_you_can_feedback')
                }}
              ></p>
              <p
                className="text-[#025f99] text-end pr-4 pt-2 cursor-pointer font-Bosch_sans_light"
                onClick={function () {
                  handleNextTip();
                }}
              >
                {t('ui_spear.toolTip.Next_Tip')} {'>'}
              </p>
            </section>
          </div>
        </React.Fragment>
      );
    } else if (index === 4) {
      return (
        <React.Fragment>
          <div className="px-6 py-2 min-w-[300px] max-w-[350px] relative">
            <button
              className="boschicon-bosch-ic-close absolute text-[#969696] text-[2.6em] top-4 right-7 hover:opacity-70"
              onClick={function () {
                setTooltipCount((prev) => prev + 1);
                handleClose();
              }}
            ></button>
            <Typography
              color="inherit"
              className="text-[22px] font-extralight pb-1 text-[#444]"
            >
              {t('ui_spear.toolTip.Contact_button')}
            </Typography>
            <section className="text-[#444] text-[13px]">
              <p
                className="font-Bosch_sans_light"
                dangerouslySetInnerHTML={{
                  __html: t('ui_spear.toolTip.You_got_stuck')
                }}
              ></p>
              <p
                className="text-[#025f99] text-end pr-4 pt-2 cursor-pointer font-Bosch_sans_light"
                onClick={function () {
                  setTooltipCount((prev) => prev + 1);
                  handleClose();
                }}
              >
                {t('ui_spear.toolTip.Close_Tips')} {'>'}
              </p>
            </section>
          </div>
        </React.Fragment>
      );
    }
  }

  function handleClose() {
    localStorage.setItem('spear_load_counter', '8');
    closeTooltip();
    setShowTooltip(false);
  }

  async function handleNextTip() {
    setDisableButton(true);
    try {
      await handleNextTipButton();
      setTooltipCount((prev) => prev + 1);
      setDisableButton(false);
    } catch (error) {
      setDisableButton(false);
    }
  }

  const styles = {
    1: {
      tooltip: 'bottom-6 right-4',
      arrow: '!translate-y-10',
      classes: 'z-[10]',
      placement: 'right-start'
    },
    2: {
      tooltip: '-ml-96 bottom-6',
      arrow: '!translate-y-10',
      classes: 'z-[10]',
      placement: 'right-start'
    },
    3: {
      tooltip: '!translate-x-[-250%] -top-[10px]',
      arrow: '!translate-y-10',
      classes: 'bg-white py-5 z-[10]',
      placement: 'right-start'
    },
    4: {
      tooltip: '',
      arrow: '',
      classes: 'z-[11]',
      placement: 'top-end'
    }
  };
  return (
    <>
      {showTooltip ? (
        <>
          <section
            className="fixed top-0 bottom-0 left-0 w-full h-full z-[10]"
            style={{ background: 'rgba(0,0,0,.6)' }}
          ></section>
          <Tooltip
            open={showTooltip}
            title={contentToolTip(index)}
            arrow
            placement={styles[index].placement}
            classes={{
              tooltip: `bg-white text-black rounded-none border border-[#025f99] ${styles[index].tooltip}`,
              arrow: `text-white before:border before:border-[#025f99] text-[20px] ${styles[index].arrow}`,
              popper: ''
            }}
          >
            {React.cloneElement(children, {
              className: `relative ${styles[index].classes}`,
              id: `toolTip-${index}`
            })}
          </Tooltip>
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
}
