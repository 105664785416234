import { Link } from 'react-router-dom';
import Profile from './Profile';

const Sidebar = ({
  mainLinks,
  userInfo,
  portalLanguages,
  handleLanguageChange,
  styles
}) => {
  return (
    <section className={styles}>
      <aside className="">
        <ul className="flex flex-col">
          {mainLinks.map((link) => (
            <li className={`pl-7 py-3`} key={link.title}>
              <Link
                to={`${link.path}`}
                className="font-normal text-[#343d47] no-underline hover:text-[#428bca] text-md"
              >
                {link.title}
              </Link>
            </li>
          ))}
          {userInfo && (
            <Profile
              className=""
              userInfo={userInfo}
              portalLanguages={portalLanguages}
              handleLanguageChange={handleLanguageChange}
            />
          )}
        </ul>
      </aside>
    </section>
  );
};

export default Sidebar;
