import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle, IconButton, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axiosInstance';

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
    marginTop: 80
  }
}));

const Icons = {
  checkImg: require(`../../static/deviceIcons/software-check.png`),
  clockImg: require(`../../static/deviceIcons/software-clock.png`),
  headsetImg: require(`../../static/deviceIcons/software-headset.png`)
};

const UninstallModal = ({ open, setOpen, modalData }) => {
  const { t } = useTranslation();

  const [confirmation, setConfirmation] = useState(false);
  const fullWidth = true;
  const maxWidth = 'md';
  const localizedTexts = {
    cancel_uninstall: 'Cancel uninstall process',
    you_have_successfully_uninstall: t(
      'ui_spear.softwareDetails.you_have_successfully_uninstall'
    ), //You have successfully completed the uninstall process for the software
    you_receive_a_message_uninstall: t(
      'ui_spear.softwareDetails.you_receive_a_message_uninstall'
    ), // "You receive a message via the SCCM (IT Workplace), once the software is uninstalled from your device. This process can take up to",
    days: t('ui_spear.softwareDetails.days') //"You have successfully completed the uninstall process for the software
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _uninstallSoftware = () => {
    const url = `/api/v1/snow/request/software/install`;

    let payload = {
      requested_for: modalData.loginName,
      variables: {
        Type: 'uninstall',
        Computer_Name: modalData.computer,
        Device_ID: modalData.computerId,
        RBCM_Number: modalData.rbcmnr,
        RBCM_ID: modalData.rbcmid,
        Software_Name: modalData.softwareName
      }
    };

    axiosInstance
      .post(url, payload)
      .then((resp) => {
        if (resp.data.isSuccessful) {
          //miss to  change temparaly the status of the install button to Pending
          setConfirmation(true);
        }
      })
      .catch((e) => console.log('Error:', e));
  };

  const _renderConfirmation = () => {
    return (
      <>
        <button
          className="bg-ITSP_blue border-solid border-ITSP_blue text-white border-[1px] w-[350px] h-[48px] font-Bosch_sans font-light text-[14px]"
          onClick={_uninstallSoftware}
        >
          {modalData.tittle} {modalData.softwareName}
        </button>
        <button
          className="bg-white border-solid border-ITSP_blue border-[1px] 
                        text-ITSP_blue  h-[48px] w-auto font-Bosch_sans font-light text-[14px] px-5 ml-5"
          onClick={handleClose}
        >
          {localizedTexts.cancel_uninstall}
        </button>
      </>
    );
  };

  const _renderSuccess = () => {
    return (
      <>
        <div className="flex items-center leading-tight my-4">
          <img className="h-[36px] w-[36px] mr-4" src={Icons.checkImg} alt="" />
          <span>
            {localizedTexts.you_have_successfully_uninstall}{' '}
            {modalData.softwareName}
          </span>
        </div>
        <div className="flex items-center leading-tight my-4">
          <img className="h-[36px] w-[36px] mr-4" src={Icons.clockImg} alt="" />
          <span>
            {localizedTexts.you_receive_a_message_uninstall}{' '}
            <span className="font-bold">2 {localizedTexts.days}</span>{' '}
          </span>
        </div>
      </>
    );
  };

  return (
    <>
      <StyledDialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle className="px-[56px] py-6">
          <span className="text-[36px] mt-10 text-support_banner_color_1 font-Bosch_sans_light">
            {modalData.tittle} {modalData.softwareName}
          </span>
          <IconButton
            className="boschicon-bosch-ic-close text-[40px] absolute top-2 right-2 color-black"
            aria-label="close"
            onClick={handleClose}
          ></IconButton>
        </DialogTitle>

        <DialogContent className="px-[56px] py-6">
          {confirmation ? _renderSuccess() : _renderConfirmation()}
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default UninstallModal;
