import SearchBar from './SearchBar';
import FunnelBtn from './FunnelBtn';

const Filters = ({
  searchText,
  setSearchText,

  statusFilter,
  setStatusFilter,
  typeFilter,
  setTypeFilter,
  priorityFilter,
  setPriorityFilter,
  personFilter,
  setPersonFilter,

  isFiltersActive,
  setIsFiltersActive,

  showFilterContain,
  setShowFilterContain,

  personList,
  toggleTicketsTabs,

  openItemsBackup,
  closedItemsBackup,

  openTickets,
  setOpenTickets,
  openAfterFilters,
  setOpenAfterFilters,

  closedTickets,
  setClosedTickets,
  closedAfterFilters,
  setClosedAfterFilters,

  setCurrentPageOpenTable,
  setCurrentPageClosedTable
}) => {
  return (
    <section className="mt-5 flex justify-end">
      <SearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        openTickets={openTickets}
        setOpenTickets={setOpenTickets}
        openAfterFilters={openAfterFilters}
        closedTickets={closedTickets}
        setClosedTickets={setClosedTickets}
        closedAfterFilters={closedAfterFilters}
        setCurrentPageOpenTable={setCurrentPageOpenTable}
        setCurrentPageClosedTable={setCurrentPageClosedTable}
      />
      <FunnelBtn
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
        priorityFilter={priorityFilter}
        setPriorityFilter={setPriorityFilter}
        personFilter={personFilter}
        setPersonFilter={setPersonFilter}
        isFiltersActive={isFiltersActive}
        setIsFiltersActive={setIsFiltersActive}
        showFilterContain={showFilterContain}
        setShowFilterContain={setShowFilterContain}
        openItemsBackup={openItemsBackup}
        closedItemsBackup={closedItemsBackup}
        setOpenTickets={setOpenTickets}
        setOpenAfterFilters={setOpenAfterFilters}
        setClosedTickets={setClosedTickets}
        setClosedAfterFilters={setClosedAfterFilters}
        setCurrentPageOpenTable={setCurrentPageOpenTable}
        setCurrentPageClosedTable={setCurrentPageClosedTable}
        setSearchText={setSearchText}
        personList={personList}
        toggleTicketsTabs={toggleTicketsTabs}
      />
    </section>
  );
};

export default Filters;
