import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import {
  Box,
  Paper,
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
  Popper,
  Typography,
  Tooltip
} from '@mui/material';
import axiosInstance from '../../utils/axiosInstance';

const PREFIX = 'PersonSearchField';
const classes = {
  redifineSearch: `${PREFIX}-redifineSearch`,
  redifineSearchCount: `${PREFIX}-redifineSearch`,
  inputBar: `${PREFIX}-circularProgressIcon`,
  boschIcon: `${PREFIX}-boschIcon`,
  emptyOption: `${PREFIX}-emptyOption`,
  customerOption: `${PREFIX}-customerOption`,
  customOption: `${PREFIX}-customOption`,
  customerDepartment: `${PREFIX}-customerDepartment`
};

const StyledSearchWrapper = styled('div')({
  width: '100%',
  height: '57px',
  marginTop: '10px',
  transition: 'all .3s ease-in-out',
  position: 'relative',
  overflow: 'hidden',
  marginBottom: '0px',
  [`& .${classes.redifineSearch}`]: {
    color: 'red',
    fontSize: '11px'
  },
  [`& .${classes.redifineSearchCount}`]: {
    color: '#000000',
    fontSize: '11px',
    textAlign: 'right'
  }
});

const StyledPaper = styled(Paper)({
  width: '100%',
  height: '34.5px',
  display: 'flex',
  marginBottom: 0,
  padding: 0,
  boxSizing: 'border-box',
  color: '#000000',
  borderRadius: '0',
  boxShadow: 'none',
  position: 'relative',
  borderBottom: '1px solid #BFC0C2',
  MozBoxShadow: 'none',
  WebkitBoxShadow: 'none'
});

const StyledAutocomplete = styled(Autocomplete)({
  [`& .MuiInputBase-input`]: {
    height: '1px'
  },
  [`& .${classes.boschIcon}`]: {
    color: '#000000',
    fontFamily: 'bosch-icon',
    fontWeight: 400,
    height: '15px',
    position: 'relative',
    top: '-3px',
    marginLeft: '10px',
    cursor: 'pointer'
  },
  [`& .${classes.emptyOption}`]: {
    opacity: '0.38'
  }
});

const StyledCustomPaper = styled(Paper)({});

const StyledIconBox = styled(Box)({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  [`& .${classes.inputBar}`]: {
    padding: 0,
    marginRight: '10px'
  }
});

const StyledMessageContainer = styled(Grid)({
  minWidth: 0,
  textOverflow: 'ellipsis',
  paddingRight: '5px',
  color: '#000000',
  [`&:hover`]: {
    color: '#ffffff'
  },
  [`& .${classes.boschIcon}`]: {
    padding: '10px',
    color: '#000000',
    fontFamily: 'bosch-icon',
    fontWeight: 400
  }
});

const StyledErroMessage = styled('span')({
  color: '#ED0007'
});

const StyledOption = styled('div')({
  width: '100%',
  maxWidth: '100%',
  height: '100%',
  overflow: 'visible',
  backgroundColor: '#ffffff',
  [`& .${classes.customOption}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'BoschSans !important',
    fontWeight: 'normal',
    fontSize: '16px',
    width: '100%',
    position: 'relative'
  },
  [`& .${classes.customerDepartment}`]: {
    maxWidth: '100%',
    overflow: 'visible !important'
  },
  [`&:hover`]: {
    backgroundColor: '#3e95f4'
  }
});

const StyledTooltip = styled(Tooltip)({
  color: '#000000',
  fontSize: 14,
  fontWeight: 'normal',
  padding: 10
});

const StyledUserTypo = styled(Typography)({
  color: '#000000',
  [`&:hover`]: {
    color: '#ffffff'
  }
});

const AutoCompleteToolTip = (props) => {
  return <StyledTooltip arrow {...props}></StyledTooltip>;
};

const PersonSearchField = (props) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([{ emptyMessage: true }]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [fullSearchLoading, setFullSearchLoading] = useState(false);
  const [errorSMT, setErrorSMT] = useState(false);
  const [errorType, setErrorType] = useState('');
  const [inputTimeOut, setInputTimeOut] = useState(null);
  const [lastSearchType, setLastSearchType] = useState('fullSearch');
  const [placeholder, setPlaceHolder] = useState('');
  const [redifineSearch, setRedifineSearch] = useState(false);
  const [showResultCount, setShowResultCount] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  let inputRefTextField;

  const suggestionTypes = {
    fullSearch: 'NT ID & Last Name',
    ntid: 'NT ID',
    email: 'Email'
  };

  const autoCompleteOpenPopper = () => {
    setShowOptions(true);
  };

  const autoCompleteClosePopper = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.keyCode !== 13) {
      setShowOptions(false);
    }
  };

  const CustomPaper = (props) => {
    return (
      <StyledCustomPaper
        id="customer-autocomplete-list"
        elevation={0}
        {...props}
      />
    );
  };

  const autoCompleteChange = (event, value) => {
    if (event.keyCode === 13) {
      setShowOptions(true);
      autoCompleteInputChange(event, event.target.value, true);
    }
    if (typeof value !== 'string') {
      setShowOptions(false);
      props.setDisabledSubmit(false);
      props.setTmpUser([value]);
    }
  };

  const autoCompleteInputChange = (event, val, isFullSearch) => {
    setShowOptions(true);
    setRedifineSearch(false);
    setShowResultCount(false);
    if (!event) {
      return;
    }
    if (inputTimeOut) {
      clearTimeout(inputTimeOut);
    }
    if (!isFullSearch && event.target.nodeName !== 'INPUT') {
      setOptions([{ emptyMessage: true }]);
      inputRefTextField.blur();
      return;
    }
    if (typeof val === 'string') {
      const inputTimeout = setTimeout(async () => {
        let valueType = 'fullSearch';

        if (val.includes('.') || val.includes('@')) {
          valueType = 'email';
        } else if (/\d/.test(val)) {
          valueType = 'ntid';
        }

        val = val.trim();
        setOptions([{ emptyMessage: true }]);

        setLastSearchType(valueType);
        setSearchLoading(false);
        setFullSearchLoading(false);
        if (val.length > 1) {
          isFullSearch ? setFullSearchLoading(true) : setSearchLoading(true);
          const submitURL = `/api/v1/smt/users`;
          try {
            isFullSearch ? setFullSearchLoading(true) : setSearchLoading(true);
            const response = await axiosInstance.post(submitURL, {
              valueType: valueType,
              userValue: val
            });
            setErrorSMT(false);
            setErrorType('');
            let rows = response?.data[0]?.ROWS;
            if (rows > 0 && response.data) {
              response.data.shift();
              const opts = response.data.map((option, index) => ({
                ...option,
                index: index
              }));
              if (opts.length === 0) {
                opts.push({ emptyMessage: true });
              }
              if (rows > 16) {
                setShowResultCount(rows);
                if (rows > 50) {
                  setRedifineSearch(true);
                }
              }
              isFullSearch
                ? setFullSearchLoading(false)
                : setSearchLoading(false);
              setOptions(opts);
            } else {
              setOptions([{ emptyResult: true }]);
              setSearchLoading(false);
            }
          } catch (error) {
            isFullSearch
              ? setFullSearchLoading(false)
              : setSearchLoading(false);
            console.log(error);
            if (!error.response) {
              setErrorSMT(true);
              setErrorType('Dash Bll');
            } else {
              setErrorSMT(true);
              setErrorType('SMT');
            }
          }
        }
      }, 550);
      setInputTimeOut(inputTimeout);
    }
  };

  const showErrorMessage = (props, option) => {
    let renderOption = '';
    if (errorSMT) {
      renderOption = (
        <StyledMessageContainer>
          <div className={`${classes.boschIcon}`}>
            &#xeb09;
            <StyledErroMessage>
              {t('ui_spear.createTicket.oops_it_was')}
              <br />
              {t('ui_spear.createTicket.an_error_has_ocurred_customer')}
            </StyledErroMessage>
          </div>
        </StyledMessageContainer>
      );
    } else if (option.emptyMessage) {
      renderOption = (
        <div className={`${classes.emptyOption}`}>
          <StyledMessageContainer>
            {searchLoading
              ? `${t('ui_spear.createTicket.loading')} ${
                  suggestionTypes[lastSearchType]
                } ${t('ui_spear.createTicket.suggestions')}`
              : fullSearchLoading
              ? `${t('ui_spear.createTicket.searching')}`
              : ''}
          </StyledMessageContainer>
        </div>
      );
    } else if (option.emptyResult) {
      renderOption = (
        <div className={`${classes.emptyOption}`}>
          <StyledMessageContainer>
            {`${t('ui_spear.createTicket.sorry_there_are_not')}`}
          </StyledMessageContainer>
        </div>
      );
    } else {
      renderOption = (
        <StyledOption>
          <AutoCompleteToolTip
            {...props}
            title={
              option.FIRST_NAME +
              ' ' +
              option.LAST_NAME +
              ' (' +
              option.DEPARTMENT +
              ')'
            }
            interactive
          >
            <div className={`${classes.customOption}`}>
              <StyledMessageContainer container>
                <Grid row style={{ width: '100%', marginBottom: '10px' }}>
                  <Typography>
                    {`${option.FIRST_NAME} ${option.LAST_NAME}`}
                  </Typography>
                </Grid>
                <Grid container row>
                  <Typography
                    nowrap
                    className={`${classes.customerDepartment}`}
                  >
                    ({option.DEPARTMENT})
                  </Typography>
                  <Typography
                    style={{ position: 'relative', left: '10%' }}
                    nowrap
                    className={`${classes.customerDepartment}`}
                  >
                    {option.CORPORATE_ID}
                  </Typography>
                  <Typography
                    style={{ position: 'relative', left: '20%' }}
                    nowrap
                    className={`${classes.customerDepartment}`}
                  >
                    {option.INTERNET_E_MAIL}
                  </Typography>
                </Grid>
              </StyledMessageContainer>
            </div>
          </AutoCompleteToolTip>
        </StyledOption>
      );
    }
    return renderOption;
  };

  return (
    <Fragment>
      <StyledSearchWrapper className="container-input">
        <Box>
          <Box>
            {redifineSearch && (
              <Box>{t('ui_spear.createTicket.too_many_matches')}</Box>
            )}
            {showResultCount && (
              <Box>
                {' '}
                {showResultCount} {t('ui_spear.createTicket.matches_found')}
              </Box>
            )}
            <Grid
              container
              spacing={0}
              alignItems="start"
              wrap="nowrap"
              direction="row"
              justifyContent="center"
            >
              <StyledPaper elevation={0} component="form" square={true}>
                <StyledAutocomplete
                  open={showOptions}
                  elevation={0}
                  freeSolo
                  style={{ width: '100%', display: 'flex', height: '10px' }}
                  autoSelect={false}
                  openOnFocus={true}
                  autoHighlight={true}
                  selectOnFocus={false}
                  clearOnEscape={true}
                  disableClearable
                  fullWidth={true}
                  PaperComponent={CustomPaper}
                  options={options}
                  onChange={autoCompleteChange}
                  onInputChange={(event, newInputValue) => {
                    autoCompleteInputChange(event, newInputValue, false);
                  }}
                  filterOptions={(x) => x}
                  getOptionLabel={(option) =>
                    option.FIRST_NAME
                      ? `${option.FIRST_NAME} ${option?.LAST_NAME}`
                      : option
                  }
                  getOptionDisabled={(opt) => opt.emptyMessage}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          endAdornment: (
                            <StyledIconBox>
                              {(searchLoading || fullSearchLoading) && (
                                <CircularProgress
                                  color="inherit"
                                  size={18}
                                  className={classes.inputBar}
                                />
                              )}
                              {params.InputProps.endAdornment}
                              <div
                                className={`${classes.boschIcon} ${classes.inputBar}`}
                                style={{ fontSize: '20px', cursor: 'pointer' }}
                                onClick={(event) =>
                                  autoCompleteInputChange(event, true)
                                }
                              >
                                &#xe235;
                              </div>
                            </StyledIconBox>
                          )
                        }}
                        placeholder={placeholder}
                        value={params.inputProps.value}
                        className={`${classes.inputBar}`}
                        onFocus={(event) => {
                          event.target.placeholder = '';
                        }}
                        onBlur={(event) => {
                          event.target.placeholder = placeholder;
                        }}
                        inputRef={(input) => {
                          inputRefTextField = input;
                        }}
                        onKeyDown={(event) => {
                          if (event.keyCode === 13) {
                            event.preventDefault();
                          }
                        }}
                      ></TextField>
                    );
                  }}
                  PopperComponent={({ style, ...props }) => (
                    <Popper {...props} style={{ ...style, height: 0 }}></Popper>
                  )}
                  onOpen={() => {
                    autoCompleteOpenPopper();
                  }}
                  onClose={(event) => {
                    autoCompleteClosePopper(event);
                  }}
                  renderOption={(props, option, state) => {
                    return showErrorMessage(props, option);
                  }}
                ></StyledAutocomplete>
              </StyledPaper>
            </Grid>
          </Box>
        </Box>
      </StyledSearchWrapper>
    </Fragment>
  );
};

export default PersonSearchField;
