import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../src/utils/axiosInstance';
import Skeleton from '@mui/material/Skeleton';

const useQuery = () => new URLSearchParams(useLocation().search);

export default function NewUser(props) {
  const { userInfo } = props;
  const query = useQuery();
  const itemID = query.get('itemID');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userInfo && userInfo.userNTID) {
      getData();
    }
  }, [userInfo]);

  const getData = () => {
    const getNewUserUrl = '/api/v1/fulfillment/newuser/details';

    axiosInstance
      .get(getNewUserUrl, {
        params: {
          userNTID: userInfo.userNTID,
          itemID: itemID
        }
      })
      .then((response) => {
        if (response.data) {
          setData(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderInfo = () => {
    if (data) {
      return (
        <div className="text-center">
          <div className="font-bold">
            <div>Requisition Number :</div>
          </div>
          <div>
            <div>{data.reqid}</div>
          </div>
          <div className="pt-3 font-bold">
            <div>Password :</div>
          </div>
          <div
            className=""
            style={{ fontFamily: ['BoschSans-MonoSemiCondensed-Print'] }}
          >
            <div data-cy="newUser-Pass">{data.password}</div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="text-center">
            <div className="py-3 font-bold text-3xl">Item not found</div>
          </div>
          <div>&nbsp;</div>
          <div className="flex justify-center">
            <div
              className="py-5 px-10 mx-auto bg-slate-200"
              data-cy="not-found-notes"
            >
              <div className="font-bold p-3">Possible Causes</div>
              <ul className="list-disc p-3" data-cy="list-causes">
                <li>
                  Access is only possible for the initiator and the customer of
                  the original request in ITSP.
                </li>
                <li>
                  This information may no longer be available. All records are
                  purged after 3 months of request closure.
                </li>
              </ul>
              <div className="text-center font-bold p-3" data-cy="hotlineNote">
                Please contact IT Service Desk for any further assistance.
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  if (isLoading) {
    return (
      <div>
        <Skeleton
          variant="rectangular"
          width={2100}
          height={600}
          style={{ marginTop: '18px' }}
        />
      </div>
    );
  }

  return (
    <div className="newUserRoot">
      <div id="bosch-newuser">
        <div className="text-center">
          <div className="pt-10 text-4xl">New user information</div>
        </div>
        {data && data.purgedate && (
          <div className="py-10 text-center">
            After {data.purgedate} the information provided will be purged and
            no longer available
          </div>
        )}
        {renderInfo()}
      </div>
    </div>
  );
}
