import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import alertWarningFilled from '../../../static/alert-warning-filled.png';

const SoftwareNotAvailable = (props) => {

    const { t } = useTranslation();

    return (
        <Grid container className={props?.originComponent === 'softwarePage' ? 'grid justify-items-center' : 'mt-[2.5rem] grid justify-items-center'}>
            <img src={alertWarningFilled} alt='warning-icon' className='h-[1.5rem]'/>
            <Typography className='text-center font-Bosch_sans_light text-[1rem] mt-[0.5rem]'>
                {t('ui_spear.devicesDetails.no_software_found')}.
            </Typography>
        </Grid>
    )
};

export default SoftwareNotAvailable;