import thoughtfulTicketGuy from '../../media/thoughtfulTicketGuy.png';
import { useTranslation } from 'react-i18next';

const WritePerfectTicket = () => {
  const { t } = useTranslation();

  const writePerfectTicketsSteps = [
    t('ui_spear.createTicket.Make_sure_your_conctact'),
    t('ui_spear.createTicket.Write_a_concise_ticket'),
    t('ui_spear.createTicket.as_for_ticket_details')
  ];
  const writeTicketDetailsSteps = [
    '• ' + t('ui_spear.createTicket.short_but_clear'),
    '• ' + t('ui_spear.createTicket.technical_details'),
    '• ' + t('ui_spear.createTicket.time_and_frequency'),
    '• ' + t('ui_spear.createTicket.whether_you_tried'),
    '• ' + t('ui_spear.createTicket.screenshot_or_screen'),
    '• ' + t('ui_spear.createTicket.possible_users_affected')
  ];

  const renderWritePerfectTicketsSteps = () => {
    return writePerfectTicketsSteps.map((item, i) => {
      return (
        <p
          dangerouslySetInnerHTML={{ __html: item }}
          key={i.toString()}
          className="inline-block pt-3.5"
        ></p>
      );
    });
  };

  const renderWriteTicketDetailsSteps = () => {
    return writeTicketDetailsSteps.map((item, i) => {
      return (
        <li className="inline-block pt-3.5" key={i.toString()}>
          <strong dangerouslySetInnerHTML={{ __html: item }}></strong>
        </li>
      );
    });
  };

  return (
    <div className="w-full p-6 pb-8x bg-gray_bg_perfect_ticket sm:mt-8 md:mt-8 lg:mt-0">
      <h3 className="font-bold">
        {t('ui_spear.createTicket.How_to_write_a_perfect')}
      </h3>
      <div>
        <div className="flex flex-row justify-between">
          <div>{renderWritePerfectTicketsSteps()}</div>
          <img
            src={thoughtfulTicketGuy}
            alt="thoughtful-guy"
            className="w-24 ml-8"
          />
        </div>
        <ul>{renderWriteTicketDetailsSteps()}</ul>
      </div>
    </div>
  );
};

export default WritePerfectTicket;
