import FooterSectionListItem from './FooterSectionListItem';
import { useTranslation } from 'react-i18next';

const FooterSectionList = ({ titleSection, content }) => {
  const renderFooterItems = () => {
    return content.map((item, i) => {
      return <FooterSectionListItem key={i.toString()} content={item} />;
    });
  };
  const { t } = useTranslation();

  return (
    <section
      className={`font-Bosch_sans_light text-[12px] m-0 ${
        titleSection === t('ui_spear.footer.More_about_IT_Service')
          ? 'w-[44%] py-0 pl-4 pr-4}'
          : `${
              titleSection === t('ui_spear.footer.Related_sites')
                ? 'w-[23%] py-0 pl-6 pr-0'
                : 'w-[30%] p-0'
            }`
      }`}
    >
      <h6 className="font-semibold tracking-tight py-4 text-[#5F5F5F]">
        {titleSection}
      </h6>
      <ul className="m-0 p-0">{renderFooterItems()}</ul>
    </section>
  );
};

export default FooterSectionList;
