import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoadingButton from './LoadingButton';
import axiosInstance from '../../../utils/axiosInstance';

const EmailButton = ({
  emailEncryptStatus,
  setEmailEncryptStatus,
  userNTID
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const _reqEncrypt = () => {
    let url = `/api/v1/snow/request/device/phone/setting`;
    let status = emailEncryptStatus;
    let statusBolean = emailEncryptStatus === 'Enable' ? '1' : '0';
    let dataReq = {
      requested_for: userNTID,
      variables: {
        service: 'manage encryption',
        enable: statusBolean,
        cat_item: 'Mobile Phone Email Encryption Request'
      }
    };

    /** prevent double click*/
    setEmailEncryptStatus('Inactive');

    axiosInstance
      .post(url, dataReq)
      .then((resp) => {
        localStorage.setItem('emailEncrypt', `Pending -  ${status}`);
        navigate('/tickets');
      })
      .catch((e) => {
        console.log('error:', e);
      });
  };

  const _renderEmailEncryptButton = () => {
    let emailButton;

    switch (emailEncryptStatus) {
      case 'Enable':
        emailButton = (
          <button
            onClick={(e) => _reqEncrypt()}
            className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light px-6 py-2"
          >
            {t('ui_spear.deviceMobileDetails.enable')}
          </button>
        );
        break;
      case 'Disable':
        emailButton = (
          <button
            onClick={(e) => _reqEncrypt()}
            className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light px-6 py-2"
          >
            {t('ui_spear.deviceMobileDetails.disable')}
          </button>
        );
        break;
      case 'Pending':
        emailButton = (
          <button className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light px-6 py-2">
            {t('ui_spear.softwareDetails.pending')}
          </button>
        );
        break;
      case 'Error':
        emailButton = (
          <button className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light px-6 py-2">
            {t('ui_spear.softwareDetails.error')}
          </button>
        );
        break;
      case 'Uncertificate':
        emailButton = (
          <p>
            {t('ui_spear.softwareDetails.email_encryption')}
            <a
              href="https://inside.bosch.com/irj/portal/?NavigationTarget=HLPFS://wcms_so_bosch_globalnet/wcms_so_02_organization/wcms_so_energy_and_building_technology/wcms_so_bosch_service_solutions/wcms_so_ST_CC_Communication_Services/wcms_so_ST_CC_TrustCenter_Services/wcms_so_ST_CC_RB_Trustcenter/wcms_so_email_and_file_encryption/wcms_so_email_and_file_encryption_1&NavigationContext=HLPFS://wcms_so_bosch_globalnet/wcms_so_02_organization/wcms_so_energy_and_building_technology/wcms_so_bosch_service_solutions/wcms_so_ST_CC_Communication_Services/wcms_so_ST_CC_TrustCenter_Services/wcms_so_ST_CC_RB_Trustcenter/wcms_so_email_and_file_encryption"
              target="_blank"
              className="border-solid border-2 border-[#036AA2] hover:border-[#08c] font-Bosch_sans_light px-6 py-2"
              rel="noreferrer"
            >
              {t('ui_spear.deviceMobileDetailsLost.here')}
            </a>
          </p>
        );
        break;
      default:
        emailButton = (
          <button
            className="border-solid border-2 border-[#036AA2] hover:border-[#08c] w-56 font-Bosch_sans_light m-0 px-6 py-2"
            style={{ height: 38 }}
          >
            {
              <LoadingButton />
            }
          </button>
        );
        break;
    }
    return emailButton;
  };

  return <>{_renderEmailEncryptButton()}</>;
};

export default EmailButton;
