import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
TableRow, 
TableHead, 
TableCell, 
TableSortLabel,
Box
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';


const SoftwareTableHeader = (props) => {

    const { t } = useTranslation();
    const { order, orderBy, onRequestSort } = props;
    
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const columns = [
        { id: 'SWNAME', label: t('ui_spear.deviceDetails.software_header_software')}, 
        { id: 'SWCATEGORY', label: t('ui_spear.deviceDetails.software_header_category')},
        { id: 'COST', label: t('ui_spear.deviceDetails.software_header_cost')}, 
        { id: 'INSTALLATION_STATUS', label: t('ui_spear.deviceDetails.software_header_installation_status')}, 
        { id: 'SEE_DETAILS', label: ''},
    ];

    return (
        <TableHead>
            <TableRow>
                {columns.map((column) => (
                <TableCell
                    className='border-solid border-[#BFC0C2] py-[16px] px-0'
                    key={column.id}
                    align={column.numeric ? 'right' : 'left'}
                    padding={column.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === column.id ? order : false}
                >
                    <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={createSortHandler(column.id)}
                        sx={{
                            ...(column.id !== 'SEE_DETAILS' && {
                                '& .MuiTableSortLabel-icon': {
                                opacity: "0.25"
                                },
                                '& .MuiTableSortLabel-icon.Mui-active': {
                                    opacity: "3"
                                },
                            }),
                            ...(column.id === 'SEE_DETAILS' && {
                                display: 'none'
                            }),
                            ...(column.id === 'COST' && props?.originSoftwareFlag === 'softwarePage' && {
                                '& .MuiTableSortLabel-icon': {
                                    display: 'none'
                                }
                            }),
                            ...(column.id === 'INSTALLATION_STATUS' && props?.originSoftwareFlag === 'softwarePage' && {
                                '& .MuiTableSortLabel-icon': {
                                    display: 'none'
                                }
                            }),
                        }}
                    >
                        <p className='font-[800] text-[rgb(0,0,0,0.89)] text-[0.89rem]'>
                            {column.label}
                        </p>
                        {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default SoftwareTableHeader;