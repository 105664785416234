import React, { useEffect, useState } from 'react';
import SoftwareContainerPage from '../../components/DeviceDetails/Software/SoftwarePage/SoftwareContainerPage';
import DevicesTabs from '../Devices/DevicesTabs';
import InstallationTab from './InstallationTab';
import SoftwareBanner from './SoftwareBanner';
import SoftwareFilters from './SoftwareFilters';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MySoftware = (props) => {

  const [softwareCategories, setSoftwareCategories] = useState([]);
  const [userDevices, setUserDevices] = useState([]);
  const [installedSoftware, setInstalledSoftware] = useState(false);
  const [softwareSearch, setSoftwareSearch] = useState('');
  const [selectedDevice, setSelectedDevice] = useState('');
  const [costFilter, setCostFilter] = useState('No Filter');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [originSoftwareFlag, setOriginSoftwareFlag] = useState('softwarePage');
  const { t } = useTranslation();

  return (
    <>
      <SoftwareBanner></SoftwareBanner>
      <div className="px-14 w-4/6 mx-auto mt-5">
        <Link to="/devices" className="ITSP_blue no-underline">
          &lt; {t('ui_spear.softwarePage.back_devices')}
        </Link>
        <InstallationTab
          className=""
          devices={false}
          installedSoftware={installedSoftware}
          setInstalledSoftware={setInstalledSoftware}
          setSoftwareSearch={setSoftwareSearch}
          setCostFilter={setCostFilter}
          setSelectedCategory={setSelectedCategory}
        ></InstallationTab>

        <SoftwareFilters
          userInfo={props.userInfo}
          softwareCategories={softwareCategories}
          setSoftwareCategories={setSoftwareCategories}
          userDevices={userDevices}
          setUserDevices={setUserDevices}
          softwareSearch={softwareSearch}
          setSoftwareSearch={setSoftwareSearch}
          selectedDevice={selectedDevice}
          setSelectedDevice={setSelectedDevice}
          costFilter={costFilter}
          setCostFilter={setCostFilter}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        ></SoftwareFilters>
      </div>
      <div className="px-14 w-4/6 mx-auto">
        <SoftwareContainerPage
          deviceId={selectedDevice}
          installedSoftware={installedSoftware}
          selectedCategory={selectedCategory}
          softwareSearch={softwareSearch}
          setSoftwareSearch={setSoftwareSearch}
          setCostFilter={setCostFilter}
          costFilter={costFilter}
          setSelectedCategory={setSelectedCategory}
          originSoftwareFlag={originSoftwareFlag}
          setOriginSoftwareFlag={setOriginSoftwareFlag}
        />
      </div>
    </>
  );
};

export default MySoftware;
