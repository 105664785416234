import React, { useEffect, useState, useLayoutEffect } from 'react';
import './styles/globals.css';

import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation
} from 'react-router-dom';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { Layout } from './components/Layout/Layout';
import MyDevices from './pages/Devices/MyDevices';
import MyDevicesError from './pages/Devices/MyDevicesError';
import DeviceDetails from './pages/Devices/DeviceDetails';
import MySoftware from './pages/Software/MySoftware';
import MyTickets from './pages/MyTickets';
import TicketJourney from './pages/TicketJourneyPage';
import NewUser from './pages/NewUser';
import Support from './pages/Support';
import Error from './pages/Error';
import Approvals from './pages/Approvals';
import Ticket from './components/Ticket/Ticket';
import { useTranslation } from 'react-i18next';
import Loader from './components/Confirmations/Loader';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { getShowPilot } from './utils/properties/utils';
import SoftwareDetails from './pages/Software/SoftwareDetails';
import MySoftwareError from './pages/Software/MySoftwareError';
import axiosInstance from './utils/axiosInstance';

const headers = {
  'Content-Type': 'application/json; charset=utf-8',
  Connection: 'keep-alive',
  Accept: '*/*'
};

const ssoCookieOptions = {
  httpOnly: false, // this must be false if you want to access the cookie
  sameSite: 'none', // this must be none to send the cookie with same-site and cross-site requests
  secure: true,
  expires: Number(process.env.REACT_APP_COOKIE_MAX_AGE)
};

function App() {
  const [userInfo, setUserInfo] = useState(null);
  const [isLoadingLng, setIsLoadingLng] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const [isSupportEntitle, setIsSupportEntitle] = useState(null);
  const [announcementsArray, setAnnouncementsArray] = useState([]);

  const snowUserInfoURL = `/api/v1/snow/user/info`;
  const { i18n } = useTranslation();

  const snowAnnouncementsURL = `/api/v1/snow/portal/announcements`;

  const setSupportEntitle = (userInfo) => {
    const supportPilot = getShowPilot(userInfo);
    setIsSupportEntitle(supportPilot);
  };

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (userInfo !== null) {
      setSupportEntitle(userInfo);
      if (userInfo.userLanguage !== i18n.language) {
        i18n.changeLanguage(userInfo.userLanguage);
      }
      setIsLoadingLng(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (!userInfo?.userNTID || userInfo == null) setUserDetails();
    if (userInfo?.userLanguage)
      getAnnouncements(userInfo.userNTID, userInfo.userLanguage);
  }, [userInfo]);

  const setUserDetails = () => {
    let queryParams = new URLSearchParams(location.search);
    if (queryParams.get('token')) {
      Cookies.set('spear-sso', queryParams.get('token'), ssoCookieOptions);
    }
    const token = Cookies.get('spear-sso');
    if (Cookies.get('spear-sso') && token !== undefined) {
      try {
        let userGivenName, userFamilyName, userInitials, userNTID, userEmail;

        userNTID = `${jwtDecode(token).data.ntid}`;
        userGivenName = `${jwtDecode(token).data.given_name}`;
        userFamilyName = `${jwtDecode(token).data.family_name}`;
        userInitials = `${jwtDecode(token).data.user_initials}`;
        userEmail = `${jwtDecode(token).data.email}`;

        axiosInstance
          .post(snowUserInfoURL, { ntid: userNTID })
          .then((snowUserInfoResponse) => {
            let snowUserDetails = snowUserInfoResponse?.data?.data?.[0];
            if (snowUserDetails) {
              setUserInfo({
                userSSO: true,
                userNTID: userNTID,
                userGivenName: userGivenName,
                userFamilyName: userFamilyName,
                userInitials: userInitials,
                userEmail: userEmail,
                userBusinessUnit: snowUserDetails?.business_unit,
                userDepartment: snowUserDetails?.department,
                userCountry: snowUserDetails?.country,
                userLocation: snowUserDetails?.location,
                userLanguage: snowUserDetails?.language,
                userPhone: snowUserDetails?.phone,
                userMobilePhone: snowUserDetails?.mobile_phone
              });
            } else {
              console.error(
                `The SNOW user info endpoint gave an unexpected response for ${userNTID}`
              );
              setIsLoadingLng(false);
              navigate('/error');
            }
          })
          .catch((err) => {
            console.error(
              `There was an exception while calling the SNOW user info endpoint: ${err}`
            );
          });
      } catch (error) {
        console.error(
          `There was an exception while decoding the token: ${error}`
        );
        setIsLoadingLng(false);
        navigate('/error');
      } finally {
        if (queryParams.has('token')) {
          queryParams.delete('token');
          navigate(`${location.pathname}?${queryParams}`);
        }
      }
      //on local, SSO doesn't work, so populate with dummy information from .env.development file
    } else if (process.env.NODE_ENV === 'development') {
      let userNTID = process.env.REACT_APP_SSO_NTID;

      axiosInstance
        .post(snowUserInfoURL, { ntid: userNTID })
        .then((snowUserInfoResponse) => {
          let snowUserDetails = snowUserInfoResponse?.data?.data?.[0];
          if (snowUserDetails) {
            setUserInfo({
              userSSO: true,
              userNTID: userNTID,
              userGivenName: Cookies.get('spear-sso')
                ? jwtDecode(Cookies.get('spear-sso')).data.given_name
                : process.env.REACT_APP_SSO_FIRSTNAME,
              userFamilyName: Cookies.get('spear-sso')
                ? jwtDecode(Cookies.get('spear-sso')).data.family_name
                : process.env.REACT_APP_SSO_LASTNAME,
              userInitials: Cookies.get('spear-sso')
                ? jwtDecode(Cookies.get('spear-sso')).data.family_name
                : process.env.REACT_APP_SSO_INITIALS,
              userEmail: Cookies.get('spear-sso')
                ? jwtDecode(Cookies.get('spear-sso')).data.email
                : process.env.REACT_APP_SSO_EMAIL,
              userBusinessUnit: snowUserDetails?.business_unit ? snowUserDetails.business_unit : process.env.REACT_APP_SNOW_USER_BUSINESS_UNIT,
              userDepartment: snowUserDetails?.department ? snowUserDetails.department : process.env.REACT_APP_SNOW_USER_DEPARTMENT,
              userCountry: snowUserDetails?.country ? snowUserDetails.country : process.env.REACT_APP_SNOW_USER_COUNTRY,
              userLocation: snowUserDetails?.location ? snowUserDetails.location : process.env.REACT_APP_SNOW_USER_LOCATION,
              userLanguage: snowUserDetails?.language
                ? snowUserDetails.language
                : process.env.REACT_APP_SNOW_USER_LANGUAGE,
              userPhone: snowUserDetails?.phone
                ? snowUserDetails.phone
                : process.env.REACT_APP_SNOW_USER_PHONE,
              userMobilePhone: snowUserDetails?.mobile_phone
                ? snowUserDetails.mobile_phone
                : process.env.REACT_APP_SNOW_USER_MOBILE_PHONE,
              userRefreshToken: snowUserDetails?.userRefreshToken
                ? snowUserDetails.userRefreshToken
                : process.env.REACT_APP_SSO_USER_REFRESH_TOKEN
            });
          } else {
            console.error(
              `The snow user info endpoint sent a response that was unexpected for ${userNTID}`
            );
            setIsLoadingLng(false);
            navigate('/error');
          }
        })
        .catch((err) => {
          console.error(
            `There was an exception while calling the snow user info endpoint: ${err}`
          );
          setIsLoadingLng(false);
          navigate(`/error`);
        });
    } else {
      if (!userInfo) {
        console.error(
          `No sso cookie found. Redirecting to login from ${window.location.pathname}...`
        );
        window.location.replace(
          `${process.env.REACT_APP_SSO_URL}/auth?startpage=${window.location.pathname}${window.location.search}`
        );
      }
    }
  };

  const handleLanguageChange = (event) => {
    const snowUserLanguageUpdateURL = `/api/v1/snow/user/${userInfo.userNTID}/lang`;
    setUserInfo({ ...userInfo, userLanguage: event.target.value });
    i18n.changeLanguage(event.target.value);
    try {
      axiosInstance
        .post(snowUserLanguageUpdateURL, { lang: event.target.value }, headers)
        .then((snowUserLanguageUpdateResponse) => {
          if (!snowUserLanguageUpdateResponse?.isSuccessful) {
            console.error(
              'The SNOW user language endpoint gave an unexpected response:',
              snowUserLanguageUpdateResponse?.message
            );
          }
        })
        .catch((err) => {
          console.error(
            'There was an exception while updating the language selection in SNOW:',
            err
          );
        });
    } catch (error) {
      console.error(
        'There was an exception while making the call to update the language selection in SNOW:',
        error
      );
    }
  };

  const getAnnouncements = (userId, lang) => {
    axiosInstance
      .post(snowAnnouncementsURL, { userId, lang })
      .then((snowAnnouncementsResponse) => {
        let snowAnnouncementsDetails =
          snowAnnouncementsResponse?.data?.data?.[0];
        if (snowAnnouncementsDetails?.hasError) {
          console.error(
            'The SNOW announcements endpoint gave an unexpected response: ',
            snowAnnouncementsDetails?.Message
          );
        }
        setAnnouncementsArray(snowAnnouncementsDetails.response);
      })
      .catch((err) => {
        console.error(
          `There was an exception while calling the SNOW user info endpoint: ${err}`
        );
      });
  };

  return (
    <div>
      {isLoadingLng && (
        <Loader
          customStyle="inset-x-1/2 inset-y-1/2 h-full w-full"
          hasBackground={true}
        />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <Layout
              userInfo={userInfo}
              handleLanguageChange={handleLanguageChange}
              announcementsArray={announcementsArray}
              setAnnouncementsArray={setAnnouncementsArray}
            />
          }
        >
          <Route exact path="/" element={<Navigate to="/tickets" replace />} />
          <Route path="/approvals" element={<Approvals />} />
          <Route path="/tickets" element={<MyTickets userInfo={userInfo} />} />
          <Route
            path="/tickets/ticket-journey/:ticketId"
            element={
              <TicketJourney
                userInfo={userInfo}
                isSupportEntitle={isSupportEntitle}
              />
            }
          />
          <Route
            path="/support"
            element={<PrivateRoute isEntitle={isSupportEntitle} />}
          >
            <Route path="/support" element={<Support userInfo={userInfo} />} />
          </Route>
          <Route path="/devices" element={<MyDevices userInfo={userInfo} />} />
          <Route
            path="/devices/error"
            element={<MyDevicesError userInfo={userInfo}></MyDevicesError>}
          ></Route>
          <Route
            path="/devices/details/:deviceId"
            element={<DeviceDetails userInfo={userInfo} />}
          />
          <Route
            path="/software"
            element={<MySoftware userInfo={userInfo} />}
          />
          <Route
            path="/software/error"
            element={<MySoftwareError userInfo={userInfo}></MySoftwareError>}
          ></Route>
          <Route
            path="/software/details/:softwareId"
            element={<SoftwareDetails userInfo={userInfo} />}
          />
          <Route
            path="/fulfillment/new-user"
            element={<NewUser userInfo={userInfo} />}
          />
          <Route
            path="/support/ticket"
            element={<PrivateRoute isEntitle={isSupportEntitle} />}
          >
            <Route
              path="/support/ticket"
              element={<Ticket userInfo={userInfo} />}
            />
          </Route>
          <Route path="/error" element={<Error userInfo={userInfo} />} />
          <Route path="*" element={<Navigate to="/tickets" replace />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
