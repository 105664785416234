import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

const StyledTypoContactIT = styled(Typography)({
fontFamily: 'BoschSans-Bold',
fontWeight: 'bold',
fontSize: '23px',
marginLeft: '5%',
marginBottom: '10px'
});

const StyledTypoMessage = styled(Typography)({
fontFamily: 'BoschSans',
fontSize: '16px',
marginLeft: '5%'
});

const StyledClosedButton = styled(Button)({
border: '1px solid #005691',
backgroundColor: '#005691 !important',
color: '#ffffff',
borderRadius: '0px',
padding: '8px 35px',
textTransform: 'none',
marginTop: '20px',
fontFamily: 'BoschSans',
fontSize: '16px',
[`&:hover`]: {
    backgroundColor: '#008dcf !important'
}
});


const ErrorNetwork = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <Dialog
                open={props.errorPopUp}
                onClose={() => {
                    props.togglePopUp();
                }}
                maxWidth="md"
            >
                <Grid className="position: relative; w-[40rem] overflow-x-hidden">
                    <Grid style={{ borderTop: `5px solid red` }} container>
                    <Grid
                        className={'err-icon boschicon-bosch-ic-alert-error'}
                    ></Grid>
                    </Grid>
                    <hr></hr>
                    <DialogContent>
                    <Grid container>
                        <Grid style={{ width: '100%' }}>
                        <StyledTypoContactIT>
                            {props.errorText.title}
                        </StyledTypoContactIT>
                        </Grid>
                        <Grid style={{ width: '100%' }}>
                        <StyledTypoMessage>
                            {props.errorText.summary}
                        </StyledTypoMessage>
                        </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                    <Grid container>
                        <Grid style={{ width: '30%' }}></Grid>
                        <Grid style={{ marginBottom: '10px' }}>
                        <StyledClosedButton
                            style={{ position: 'relative', left: '200%' }}
                            onClick={() => {
                                props.togglePopUp();
                            }}
                        >
                            {t('ui_spear.search.ModalButton.Close')}
                        </StyledClosedButton>
                        </Grid>
                    </Grid>
                    </DialogActions>
                </Grid>
            </Dialog>
        </>
    )
};

export default ErrorNetwork;
