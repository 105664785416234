import React, { useRef, useState } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
  Button,
  Dialog,
  DialogContent,
  styled,
  Typography,
  Popover,
  Box
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: 12,
    border: '1px solid #dadde9'
  }
}));

const RateCloseTicketsModal = (props) => {
  const { t } = useTranslation();
  const { itspUrl, icon, label, source } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classIframe = source === 'smt' ? 'w-full h-[92vh]' : 'w-full h-[40vh]';
  const size = source === 'smt' ? 'lg' : 'sm';
  const tempURL =
    'https://survey.bosch.com/cgi-bin/s.app?A=MIxbkBXc&T-TRID=Digital%20Production%20Engineering&EXTID=767657&EXTCHK=VeSODhxiD1';

  const style =
    label.label == 'Rate'
      ? 'text-blue-500 hover:text-blue-800'
      : 'text-gray-400';

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handlePopOverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopOverClose = () => {
    setAnchorEl(null)
  }

  const openPopOver = Boolean(anchorEl)

  return (
    <>
      {
        label.label != 'Expired' &&
        <Button
          onClick={handleClickOpen}
          className={`${style} group border-none flex items-center justify-left no-underline bg-transparent border-2 m-0 h-14 w-auto text-blue_font hover:text-[#005580] text-[12px] normal-case`}
        >
          <span className={`text-[16px] pr-1 ${icon}`}></span>
          <span className="capitalize"> {label.localizedLabel}</span>
        </Button>
      }

      {
        label.label == 'Expired' &&
        <Button
          className={`${style} group border-none flex items-center justify-left no-underline bg-transparent border-2 m-0 h-14 w-auto text-blue_font text-[12px] normal-case`}
        >
          <span className={`text-[16px] pr-1 ${icon}`}></span>
          <span
            onMouseEnter={(event) => { handlePopOverOpen(event) }}
            onMouseLeave={() => { handlePopOverClose() }} className="capitalize"> {label.localizedLabel}</span>
        </Button>
      }

      <Popover
        style={{ top: '-10px', left: '25px' }}
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={openPopOver}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={handlePopOverClose}
        disableRestoreFocus
        PaperProps={{
          style: {
            maxWidth: '250px',
            overflow: 'initial',
            borderRadius: 0,
            boxShadow: 'none',
            border: '1px solid #ACACAC'
          }
        }}
      >
        <Box
          sx={{
            position: "relative",
            mt: "5px",
            "&::after": {
              content: '""',
              position: 'absolute',
              width: '12px',
              height: '12px',
              left: '90%',
              top: '57px',
              transform: 'translate(-50%,50%)',
              rotate: '45deg',
              backgroundColor: '#FFFFFF',
              borderBottom: '1px solid #ACACAC',
              borderRight: '1px solid #ACACAC',
              boxShadow: '0 1px 8px transparent',
            }
          }}
        />
        <Typography style={{ width: '100%', textAlign: 'center', padding: '10px' }}>
          {t('ui_spear.myTickets.You_may_leave_a_feedback')}
        </Typography>
      </Popover>

      <Dialog
        fullWidth={true}
        maxWidth={size}
        open={open}
        keepMounted
        onClose={handleClose}
      >
        <DialogContent>
          <span
            className="absolute top-0 right-0 h-10 w-10 mt-2 mr-6 boschicon-bosch-ic-close text-[24px] cursor-pointer font-bold"
            onClick={handleClose}
            style={{ fontFamily: 'BoschSans-Bold', fontWeight: 900 }}
          >
            {' '}
          </span>
          <div className="m-4">
            <iframe
              id="feedback-form"
              title={label.label}
              className={`flex items-center justify-center m-auto p-0 ${classIframe}`}
              src={itspUrl}
            ></iframe>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RateCloseTicketsModal;
