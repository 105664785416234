import React, { useState, useEffect } from 'react';
import { ReactComponent as Star } from '../../static/filled_star.svg';
import happyArticleGuy from '../../static/happy-article.png';

import {
  Box,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingEllipsis from '../Ticket/Services/LoadingEllipsis';

const DisplaySavedSearches = (props) => {
  const { t } = useTranslation();
  let [searchNumber, setSearchNumber] = useState(0);
  let [paginationNumber, setPaginationNumber] = useState(0);
  let [page, setPage] = useState(1);
  const [targetUserAlias, setTargetUserAlias] = useState('');
  const [showServicesAndChanging, setShowServiceAndChanging] = useState(false);

  let savedSearchItems = null;

  useEffect(() => {
    props.targetUserAlias
      ? setTargetUserAlias(props.targetUserAlias)
      : setTargetUserAlias('');
  }, [props.targetUserAlias]);

  useEffect(() => {
    if (props.userSavedQueries) {
      const pagination =
        searchNumber % 9 ? Math.floor(searchNumber / 9) + 1 : searchNumber / 9;
      if (page > pagination && pagination > 0) {
        setPage(pagination);
      }
      setSearchNumber(props.userSavedQueries.length);
      setPaginationNumber(pagination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userSavedQueries, searchNumber]);

  let searchQuery = async (e, value) => {
    props.handleSavedSearch(e, value.query_description);
  };

  let handleChange = (event, value) => {
    setPage(value);
  };

  const deleteSavedSearch = (e, searchId) => {
    props.handleDeleteSearch(searchId, 'saved');
  };

  const renderSaveSearchLblOnSaveSearch = () => {
    const SAVE_SEARCH = t(
      'ui_spear.support.Saved-Searches.To_do_so_make_a_search'
    ).split('-|0|-');
    return (
      <>
        <span>{SAVE_SEARCH[0]}</span>
        <strong>
          <span className={`boschicon-bosch-ic-wishlist font-bold mr-1 text-[17px] align-middle`}></span>
          {t('ui_spear.search.Save_search')}
        </strong>
        <span>{SAVE_SEARCH[1]}</span>
      </>
    );
  };

  const renderWhyToSaveSearches = () => {
    const SERVICE_CHANGING_AND_SOLUTIONS = (
      <Typography className="max-w-[400px] font-Bosch_sans text-lg">
        {t('ui_spear.support.Saved-Searches.Services_are_changing')}
      </Typography>
    );
    return (
      <>
        <span
          className="flex flex-row items-center my-6 font-Bosch_sans text-lg text-ITSP_blue hover:text-article_blue hover:cursor-pointer"
          onClick={() => setShowServiceAndChanging(!showServicesAndChanging)}
        >
          <span className={`${showServicesAndChanging ? 'boschicon-bosch-ic-down' : 'boschicon-bosch-ic-forward-right'} font-bold mr-3 text-3xl align-middle`}></span>
          {t('ui_spear.support.Saved-Searches.Why_to_save_searches')}
        </span>
        {showServicesAndChanging ? SERVICE_CHANGING_AND_SOLUTIONS : <></>}
      </>
    );
  };

  let SearchDisplay = () => {
    if (props.stateSavedSeached?.isLoading) {
      return (
        <div className="flex items-center justify-center pt-2 pb-5">
          <LoadingEllipsis></LoadingEllipsis>
        </div>
      );
    }
    if (props.userSavedQueries && props.userSavedQueries.length > 0) {
      let currentElements = props.userSavedQueries.slice(
        page * 9 - 9,
        page * 9
      );
      savedSearchItems = currentElements.map((savedSearch) => (
        <Grid key={savedSearch.id} item className="h-[60px] w-full">
          <Stack className="group border rounded-lg w-full flex flex-row justify-around items-center px-5 h-full hover:border-blue_hover_btns ">
            <Box
              className="w-11/12 break-all h-full hover:text-blue_hover_btns hover:cursor-pointer flex items-center"
              onClick={(e) => searchQuery(e, savedSearch)}
            >
              <Grid className="flex flex-row items-center w-full">
                <Grid className="">
                  <Star className="text-support_banner_color_2 mr-5 group-hover:text-blue_hover_btns" />
                </Grid>
                <Typography className="truncate font-Bosch_sans text-lg max-w-[85%]">
                  {savedSearch?.query_description}
                </Typography>
              </Grid>
            </Box>
            <span
              className="w-1/12 text-end font-bosch-icon text-2xl hover:text-blue-400 hover:cursor-pointer"
              onClick={(e) => deleteSavedSearch(e, savedSearch.id)}
            >
              &#xe118;
            </span>
          </Stack>
        </Grid>
      ));
      let table = (
        <>
          <Typography className="text-black font-Bosch_sans_light text-[28px] float-left mt-16 mb-7">
            {t('ui_spear.support.Your_saved_searches')}
          </Typography>
          <Grid
            container
            className="grid gap-3 grid-cols-3 sm:max-lg:grid-cols-2"
          >
            {savedSearchItems}
          </Grid>
          {props.userSavedQueries && props.userSavedQueries.length > 9 && (
            <Pagination
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  className={item.selected ? 'text-white bg-sky-800' : ''}
                />
              )}
              count={paginationNumber}
              page={page}
              onChange={handleChange}
              className="mt-16 flex items-center justify-center"
            />
          )}
        </>
      );
      return table;
    } else if (props.stateSavedSeached?.isEmpty) {
      return (
        <>
          <Typography className="text-black font-Bosch_sans_light text-[28px] float-left mt-16 mb-7">
            {t('ui_spear.support.Your_saved_searches')}
          </Typography>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            id="saved-searches-container"
            className="border"
          >
            <Grid item>
              <div>
                <img
                  id="sad-article"
                  src={happyArticleGuy}
                  className="max-h-[300px]"
                  alt="article-guy"
                ></img>
              </div>
            </Grid>
            <Grid item>
              <Typography className="max-w-[400px] font-Bosch_sans text-lg mb-4">
                {t(
                  'ui_spear.support.Saved-Searches.Save_important_searches_and_access'
                )}
              </Typography>
              <Typography className="max-w-[400px] font-Bosch_sans text-lg mb-4">
                {renderSaveSearchLblOnSaveSearch()}
              </Typography>
              {renderWhyToSaveSearches()}
            </Grid>
          </Grid>
        </>
      );
    }
  };

  return (
    <>
      <div>
        <div className="container">
          <SearchDisplay></SearchDisplay>
        </div>
      </div>
    </>
  );
};

export default DisplaySavedSearches;
