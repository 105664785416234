import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InfoPopper from '../InfoPopper';

const DetailsTab = ({ deviceDetSect, pcDetails, isMobile }) => {
  const [settingDetVal, setSettingDetVal] = useState([]);
  const { t } = useTranslation();
  const SNOW_SP_URL = process.env.REACT_APP_SNOW_SP_URL;
  const LINKS_TO_DEVICE_SERVICES = [
    `${SNOW_SP_URL}?id=ui_sc_cat_item&sys_id=2342ca9c1b1a191078087403dd4bcbfd&device=${pcDetails?.NODE_NAME}`,
    `${SNOW_SP_URL}?id=ui_sc_cat_item&sys_id=3fb9ee6f1b7d251480f076e09b4bcb2c&device=${pcDetails?.NODE_NAME}`,
    `${SNOW_SP_URL}?id=ui_sc_cat_item&sys_id=8a4125921b05559078087403dd4bcbfb&device=${pcDetails?.NODE_NAME}`,
    `${SNOW_SP_URL}?id=ui_sc_cat_item&sys_id=ecb14b8b1bbe951078087403dd4bcb39&device=${pcDetails?.NODE_NAME}`,
    `${process.env.REACT_APP_SERVICE_CHANGE_PLATFORM_URL}${process.env.REACT_APP_SERVICE_CHANGE_FIREWALL_ID}&device=${pcDetails?.NODE_NAME}`
  ];

  const labelsOFLinks = [
    [
      t('ui_spear.deviceDetails.enable_temp_admin_rights'),
      t('ui_spear.deviceDetails.disable_temp_admin_rights')
    ],
    [
      t('ui_spear.deviceDetails.enable_perm_admin_rights'),
      t('ui_spear.deviceDetails.disable_perm_admin_rights')
    ],
    [
      t('ui_spear.deviceDetails.enable_remote_access'),
      t('ui_spear.deviceDetails.disable_remote_access')
    ],
    [
      t('ui_spear.deviceDetails.unlock_usb_port'),
      t('ui_spear.deviceDetails.lock_usb_port')
    ],
    [
      t('ui_spear.deviceDetails.change_firewall_settings'),
      t('ui_spear.deviceDetails.change_firewall_settings')
    ]
  ];

  useEffect(() => {
    setSettingDetValues();
  }, [pcDetails]);

  const setSettingDetValues = () => {
    const settDetailsLbls = [
      'ADMIN_RIGHTS_TEMP',
      'ADMIN_RIGHTS_PERM',
      'REMOTE_ACCESS',
      'USB_ENABLED',
      'FIREWALL_SETTINGS'
    ];

    const arrayDetails = Object.entries(pcDetails);
    const pcDetValues = [];
    settDetailsLbls.forEach((settItem) => {
      arrayDetails.forEach((detItem) => {
        if (settItem === detItem[0]) {
          pcDetValues.push(detItem[1]);
        }
      });
    });
    setSettingDetVal(pcDetValues);
  };

  const renderDeviceDetItem = () => {
    return deviceDetSect.items.map((item, i) => {
      return (
        <tr key={item} className="h-12">
          <td className="text-right w-1/2">{item}:</td>
          {deviceDetSect.title === t('ui_spear.deviceDetails.settings_details')
            ? isMobile
              ? renderEnableDisableBtn(item)
              : renderEnableDisableOrLockLbl(item, settingDetVal[i], i)
            : renderAddDetailsLbl(item)}
        </tr>
      );
    });
  };

  const infoModalsData = [
    t('ui_spear.deviceDetails.first_temp_admin_rights'),
    t('ui_spear.deviceDetails.perm_admin_rights_can_be_enabled'),
    t('ui_spear.deviceDetails.select_this_serv_to_request_corason'),
    t('ui_spear.deviceDetails.choose_this_serv_to_unlock_usb'),
    t('ui_spear.deviceDetails.choose_this_serv_to_change_standard_rules')
  ];

  const renderEnableDisableOrLockLbl = (section, lblValue, index) => {
    let valueToDisplay = lblValue;
    switch (lblValue) {
      case 'Disabled':
        if (section.toLowerCase().includes('usb')) {
          valueToDisplay = t('ui_spear.deviceDetails.locked');
        } else {
          valueToDisplay = t('ui_spear.deviceDetails.disabled');
        }
        break;
      case 'Enabled':
        if (section.toLowerCase().includes('usb')) {
          valueToDisplay = `${t('ui_spear.deviceDetails.unlocked_until')} ${pcDetails.USB_EXPIRE_DATE ?? '-'
            }`;
        } else {
          valueToDisplay = t('ui_spear.deviceDetails.enable');
        }
        break;
      default:
        valueToDisplay = t('ui_spear.deviceDetails.standard');
        break;
    }
    return (
      <>
        <td className="p-3 w-14">{valueToDisplay}</td>
        <td className="p-3 w-14">
          <InfoPopper popperContent={infoModalsData[index]} />
        </td>
        <td className="p-3 w-[40rem] text-support_banner_color_1">
          <a href={LINKS_TO_DEVICE_SERVICES[index]}>
            {renderLblLinksOF(lblValue, index)}
            <span className={`boschicon-bosch-ic-forward-right font-bold ml-1 text-[17px] align-middle`}></span>
          </a>
        </td>
      </>
    );
  };

  const renderLblLinksOF = (lblValue, index) => {
    let link = '';
    switch (lblValue) {
      case 'Disabled':
        link = labelsOFLinks[index][0];
        break;
      case 'Enabled':
        link = labelsOFLinks[index][1];
        break;
      default:
        link = labelsOFLinks[index][0];
        break;
    }
    return link;
  };

  const renderEnableDisableBtn = (label) => {
    const infoIcon = label !== 'SIRI while locked' && (
      <button>
        <img
          alt="info-circule"
          className="info-circle h-4"
          src={require(`../../../static/deviceIcons/info_circle.png`)}
        />
      </button>
    );
    return (
      <>
        <td className="p-3">
          <button className="border">Enable</button>
        </td>
        <td className="p-3">{infoIcon}</td>
      </>
    );
  };

  const renderAddDetailsLbl = (item) => {
    const updateComputerInfoService = `${SNOW_SP_URL}/?id=ui_sc_cat_item&sys_id=1e9173741b1d51d078087403dd4bcbde&device=${pcDetails?.NODE_NAME}`;
    return (
      <>
        {item === t('ui_spear.deviceDetails.operating_sys') ? (
          <td className="p-3 w-1/2">{pcDetails.OPERATINGSYSTEM}</td>
        ) : (
          <Link style={{ color: 'inherit' }} to={updateComputerInfoService}>
            <div className="inline-flex">
              <td className="p-3 w-1/2">{pcDetails.SITE}</td>
              <span className="device-location-pencil-icon ml-2 mt-1 h-[2rem]">
                &#xe152;
              </span>
            </div>
          </Link>
        )}
      </>
    );
  };

  return (
    <>
      <div className="ml-72 text-md text-ITSP_blue my-2">
        {deviceDetSect.title}:
      </div>
      <div className=" flex justify-center">
        <table className="text-md tracking-wide min-w-[32rem]">
          {renderDeviceDetItem()}
        </table>
      </div>
    </>
  );
};

export default DetailsTab;
