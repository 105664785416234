import { memo } from 'react';
import { Snackbar, Alert } from '@mui/material';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line react/display-name
const TicketCreatedConfirm = memo(
  ({
    incidentID,
    openINCTicketSnackBar,
    handleCloseINCTicketSnackBar,
    setOpenSnackBar
  }) => {
    const transitionUp = (props) => {
      return <Slide {...props} direction="up" />;
    };
    const { t } = useTranslation();

    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);

    return (
      <>
        <Snackbar
          open={openINCTicketSnackBar}
          autoHideDuration={3000}
          TransitionComponent={transitionUp}
          onClose={() => handleCloseINCTicketSnackBar()}
        >
          <Alert
            onClose={() => handleCloseINCTicketSnackBar()}
            severity="success"
            sx={{ width: '100%' }}
          >
            <span className="font-bold font-8xl">
              {t('ui_spear.ticketDetails.TicketJourney.Ticket_Created')}{' '}
              {incidentID}
            </span>
          </Alert>
        </Snackbar>
      </>
    );
  }
);

export default TicketCreatedConfirm;
