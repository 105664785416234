import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ResultsOptions = (props) => {
  const { t } = useTranslation();
  const showOriginalLanguage = () => {
    props.setOriginalUserLanguage(!props.originalUserLanguage);
  };

  return (
    <>
      <Stack spacing={2} direction="row" className="flow-root mt-16 mb-10">
        <Typography className="text-black font-Bosch_sans text-xl float-left">
          {props.searchResponse.length}{' '}
          {t('ui_spear.support.search_relevant_results_found')}
        </Typography>
        {props.userLocaleCode !== 'en-US' && props.translateKMSLanguage && (
          <>
            {props.originalUserLanguage ? (
              <Typography className="text-black font-Bosch_sans text-xl float-right">
                {t('ui_spear.support.search_folgende_artikel')}
                <Link
                  component="button"
                  onClick={() => {
                    showOriginalLanguage();
                  }}
                >
                  {' '}
                  {t('ui_spear.support.siehe_original')}
                </Link>
              </Typography>
            ) : (
              <Typography className="text-black font-Bosch_sans text-xl float-right">
                <Link
                  component="button"
                  onClick={() => {
                    showOriginalLanguage();
                  }}
                >
                  {t('ui_spear.support.search_uberetze')}
                </Link>
              </Typography>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default ResultsOptions;
