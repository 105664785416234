import { useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Head = ({ columns, handleSorting, data, setData }) => {
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder, data, setData);
  };

  return (
    <thead>
      <tr className="border-b-2">
        {columns.map(({ label, accessor, sortable, alignedText }) => {
          const caretIcon = sortable ? (
            sortField === accessor && order === 'asc' ? (
              <ArrowUpwardIcon className="text-black ml-1 w-4 h-4 cursor-pointer" />
            ) : sortField === accessor && order === 'desc' ? (
              <ArrowDownwardIcon className="text-black ml-1 w-4 h-4 cursor-pointer" />
            ) : (
              <ArrowUpwardIcon className="text-gray-400 ml-1 w-4 h-4 cursor-pointer" />
            )
          ) : (
            ''
          );
          return (
            <th
              key={label}
              onClick={() => handleSortingChange(accessor)}
              className={`px-6 py-2 ${alignedText}`}
            >
              {label}
              {caretIcon}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default Head;
