import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackToDeviceOverview from '../../components/DeviceDetails/BackToDeviceOverview';
import ComputerDetails from '../../components/DeviceDetails/Computer/ComputerDetails';
import ComputerButtonsSection from '../../components/DeviceDetails/Computer/ButtonsSection';
import ComputerTabsSection from '../../components/DeviceDetails/Computer/TabsSection';
import ComputerDetailsTab from '../../components/DeviceDetails/Computer/DetailsTab';
import ComputerSoftwareTab from '../../components/DeviceDetails/Computer/SoftwareTab';
import MobileDevices from '../../components/DeviceDetails/Mobile/MobileDevices';
import axiosInstance from '../../utils/axiosInstance';

const DeviceDetails = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [toggleComputerTab, setToggleComputerTab] = useState(true);
  const [togglMobileTab, setTogglMobileTab] = useState(true);
  const [URLDeviceAccess, setURLDeviceAccess] = useState(false);
  const [deviceBelongstoUser, setDeviceBelongstoUser] = useState(false);
  const [deviceId, setDeviceId] = useState('');
  const [deviceType, setDeviceType] = useState('');
  const [pcDetails, setPcDetails] = useState({
    NODE_NAME: '',
    ITSP_SERVICE_TYPE_NAME: '',
    MODEL: '',
    OPERATINGSYSTEM: '',
    MANUFACTURER_NAME: '',
    LIFECYCLE_EXPIRATION_Q: '',
    LIFECYCLE_START: '',
    MANUFACTURER_NAME_1: '',
    DEVICE_CLASS: '',
    REPLACEMENTCATEGORY: '',
    SITE: '',
    REMOTE_ACCESS: '',
    ADMIN_RIGHTS_TEMP: '',
    ADMIN_RIGHTS_PERM: '',
    USB_ENABLED: '',
    USB_EXPIRE_DATE: '',
    FIREWALL_SETTINGS: '',
    NEXTHINK_AVAILABILITY: '',
    CPU_TYPE: '',
    MEMORY: '',
    DRIVE: '',
    DISPLAY_SIZE: '',
    GRAPHICS_ADAPTER: '',
    MOBILE_EMAIL_ENCRYPTION: ''
  });
  const [isMobile, setIsMobile] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.userInfo != null) {
      if (location.state == null) {
        setDeviceId(location.pathname.split('/')[3]);
        setURLDeviceAccess(true);
      }
    }
  }, [props.userInfo]);

  useEffect(() => {
    if (location.state !== null) {
      if (location.state.device_id !== null) {
        setDeviceId(location.state.device_id);
      }
      if (location.state.device_type !== null) {
        setDeviceType(location.state.device_type);
      }
    }
  }, [location]);

  useEffect(() => {
    const deviceURL = `/api/v1/cms/devices/details/${deviceId}`;

    async function fetchData() {
      try {
        const response = await axiosInstance.get(deviceURL);
        if (response.data.isSuccessful) {
          if (URLDeviceAccess) {
            if (props.userInfo.userNTID == response.data.data[0].USER_ID) {
              setDeviceType(response.data.data[0].ITSP_SERVICE_TYPE_NAME);
              setPcDetails({ ...pcDetails, ...response.data.data[0] });
              if (
                response.data.data[0].ITSP_SERVICE_TYPE_NAME !== 'Notebook' &&
                response.data.data[0].ITSP_SERVICE_TYPE_NAME !== 'Desktop' &&
                response.data.data[0].ITSP_SERVICE_TYPE_NAME !== 'Tablet'
              ) {
                setIsMobile(true);
              }
              setDeviceBelongstoUser(true);
            } else {
              navigate(`/devices`);
            }
          } else {
            setPcDetails({ ...pcDetails, ...response.data.data[0] });
            if (
              response.data.data[0].ITSP_SERVICE_TYPE_NAME !== 'Notebook' &&
              response.data.data[0].ITSP_SERVICE_TYPE_NAME !== 'Desktop' &&
              response.data.data[0].ITSP_SERVICE_TYPE_NAME !== 'Tablet'
            ) {
              setIsMobile(true);
            }
            setDeviceBelongstoUser(true);
          }
        } else {
          console.log('Error:', response.data.message);
          navigate('/devices/error');
        }
      } catch (error) {
        console.log('Error fetch Device Details', error);
        navigate('/devices/error');
      }
    }
    if (deviceId) fetchData();
  }, [deviceId]);

  const deviceDetSect = [
    {
      title: t('ui_spear.deviceDetails.settings_details'),
      items: [
        t('ui_spear.deviceDetails.temp_admin_right'),
        t('ui_spear.deviceDetails.perm_admin_right'),
        t('ui_spear.deviceDetails.remote_access'),
        t('ui_spear.deviceDetails.usb_port'),
        t('ui_spear.deviceDetails.firewall_settings')
      ]
    },
    {
      title: t('ui_spear.deviceDetails.additional_details'),
      items: [
        t('ui_spear.deviceDetails.operating_sys'),
        t('ui_spear.deviceDetails.device_location')
      ]
    }
  ];

  const mobileDetSect = [
    {
      title: 'Settings Details',
      items: ['Email Encryption', 'SIRI while locked', 'VPN Access']
    }
  ];

  const renderComputerDetailsTab = () => {
    const sectionToRender = isMobile ? mobileDetSect : deviceDetSect;
    return sectionToRender.map((item) => {
      return (
        <ComputerDetailsTab
          key={item.title}
          isMobile={isMobile}
          deviceDetSect={item}
          pcDetails={pcDetails}
        />
      );
    });
  };

  return (
    <div className="px-14 w-4/6 mx-auto">
      <BackToDeviceOverview />
      {/* Computer Device */}
      {deviceType == 'Desktop' ||
      deviceType == 'Laptop' ||
      deviceType == 'Notebook' ||
      deviceType == 'Tablet' ? (
        <>
          <ComputerDetails pcDetails={pcDetails} deviceType={deviceType} />
          <ComputerButtonsSection nodeName={pcDetails.NODE_NAME} />
          <ComputerTabsSection
            toggleComputerTab={toggleComputerTab}
            setToggleComputerTab={setToggleComputerTab}
          />
          {toggleComputerTab ? (
            <div className="my-8">{renderComputerDetailsTab()}</div>
          ) : (
            <ComputerSoftwareTab deviceId={deviceId} />
          )}
        </>
      ) : (
        <>
          <MobileDevices
            pcDetails={pcDetails}
            deviceType={deviceType}
            deviceBelongstoUser={deviceBelongstoUser}
            userInfo={props.userInfo}
          ></MobileDevices>
        </>
      )}
    </div>
  );
};

export default DeviceDetails;
