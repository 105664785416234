import { useTranslation } from 'react-i18next';

const DetailsTabs = ({ showTicketJourney, handleTabClick }) => {
  const { t } = useTranslation();
  return (
    <div className="border-rose-600">
      <div className="tabs justify-center">
        <a
          href="javascript:;"
          className={`tab tab-lg tab-bordered mr-8 ${
            showTicketJourney ? 'tab-active text-sky-700 border-sky-700' : ''
          }`}
          onClick={() => handleTabClick(true)}
        >
          {t('ui_spear.ticketDetails.Ticket_Journey')}
        </a>
        <a
          href="javascript:;"
          className={`tab tab-lg tab-bordered ${
            showTicketJourney ? '' : 'tab-active text-sky-700'
          }`}
          onClick={() => handleTabClick(false)}
        >
          {t('ui_spear.ticketDetails.Details')}
        </a>
      </div>
    </div>
  );
};

export default DetailsTabs; 
