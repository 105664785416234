import React from 'react';
import { Link } from 'react-router-dom';
import SmtTicketSolutionModal from './SmtTicketSolutionModal';
import { Button } from '@mui/material';

const TicketSolutionButton = (props) => {
  const {
    source,
    incidentId,
    reqEntryId,
    serviceId,
    status,
    label,
    icon,
    localizationLabel
  } = props;
  const url = `${process.env.REACT_APP_CPSC_URL}/RequestCenter/ordermanagement/reqentrystatus.do?query=requisitionentrystatus&reqid=${incidentId}&reqentryid=${reqEntryId}&formAction=displayEntryStatus&performerID=&serviceid=${serviceId}&requisitionId=${incidentId}`;

  const handleClickOpen = (e) => {
    window.open(url, '_blank', 'noreferrer');
    e.stopPropagation();
  };

  return (
    <>
      {/* if the tickect is from SMT */}
      {String(source).toUpperCase() === 'SMT' && (
        <SmtTicketSolutionModal
          incidentId={incidentId}
          status={status}
          label={label}
          localizationLabel={localizationLabel}
          icon={icon}
        />
      )}

      {/* if the tickect is from SN */}
      {String(source).toUpperCase() === 'SN' && (
        <Link
          to={`/tickets/ticket-journey/${incidentId}`}
          className="group border-none flex items-center justify-left no-underline bg-transparent border-2 m-0 p-0 h-14 w-auto text-blue_font hover:text-[#005580] text-[12px] normal-case"
        >
          <span className={`${icon} text-[16px] block pr-[5px]`}></span>
          <span className="capitalize block pr-[5px]">{localizationLabel}</span>
          <div className="transform -rotate-90 group-hover:translate-x-2">
            <span className={`boschicon-bosch-ic-down text text-[18px]`}></span>
          </div>
        </Link>
      )}

      {String(source).toUpperCase() === 'CPSC' && (
        <Button
          onClick={handleClickOpen}
          className="group border-none flex items-center justify-left no-underline bg-transparent border-2 m-0 p-0 h-14 w-auto text-blue_font hover:text-[#005580] text-[12px] normal-case"
        >
          <span className={`${icon} text-[16px] block pr-[5px]`}></span>
          <span className="capitalize block pr-[5px]">{localizationLabel}</span>
          <div className="transform -rotate-90 group-hover:translate-x-2">
            <span className={`boschicon-bosch-ic-down text text-[18px]`}></span>
          </div>
        </Button>
      )}
    </>
  );
};

export default TicketSolutionButton;
