import React from 'react';
import { Snackbar, Alert, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SearchSnackBar = (props) => {
  const { t } = useTranslation();
  const {
    openSnackBar,
    handleCloseSnackBar,
    undoDeleteSearch,
    MsgLabel,
    BtnLabel,
    wasSearchSaved,
    backHomePage
  } = props;

  return (
    <Snackbar
      className="bg-color-[#78be20]"
      open={openSnackBar}
      autoHideDuration={3000}
      onClose={handleCloseSnackBar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        elevation={2}
        icon={false}
        severity="success"
        className="snack-back font-Bosch_sans text-[#78be20]"
      >
        <Stack spacing={2} direction="row" className="leading-[1.5rem]">
          {wasSearchSaved ? (
            <>
              <span className="snackbar-icon-save">&#xeb0a;</span>
              <Typography className="text-[#78be20] mb-[0.1rem] font-semibold">
                {t('ui_spear.support.search_is_saved')}
              </Typography>
              <Typography
                onClick={() => backHomePage()}
                className="text-[#78be20] font-semibold ml-[0.5rem]"
              >
                {t('ui_spear.support.find_it')}{' '}
                <span className="text-[#78be20] font-semibold underline decoration-2 cursor-pointer">
                  {t('ui_spear.support.in_your_saved_searches')}
                </span>
              </Typography>
            </>
          ) : (
            <>
              <span className="snackbar-icon-save">&#xeb0a;</span>
              <Typography className="text-[#78be20] font-semibold">
                {MsgLabel}
              </Typography>
            </>
          )}
          <Link
            className="text-[#78be20] font-semibold decoration-2 ml-[1.5rem] underline"
            component="button"
            onClick={() => {
              undoDeleteSearch();
            }}
          >
            {BtnLabel}
          </Link>
        </Stack>
      </Alert>
    </Snackbar>
  );
};

export default SearchSnackBar;
