import { useTranslation } from 'react-i18next';

const BlueBannerImmediateAction = () => {
  const { t } = useTranslation();

  return (
    <div className="my-8 p-4 bg-blue_banner max-w-[36%] text-[#1565c0]">
      <p>{t('ui_spear.createTicket.If_your_case_requires')}</p>
      <p className="underline decoration-2 font-semibold">
        <a href="https://inside-ws.bosch.com/FIRSTspiritWeb/permlink/wcms_bd_-CIOS_OSP3_CI_Hotline_Contact_2-EN">
          {t('ui_spear.createTicket.Find_IT_Service_Desk')}
        </a>
      </p>
    </div>
  );
};

export default BlueBannerImmediateAction;
