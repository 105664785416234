import React from 'react';
import { useTranslation } from 'react-i18next';

const MobileTabsSection = () => {
    const { t } = useTranslation();
    return (
        <nav className='bg-gray-200'>
            <ul className='px-10 pt-4 flex flex-row justify-center font-Bosch_sans_light font-normal'>
                <li className='list-none pb-3.5 px-7 border-b-2 border-ITSP_blue color-blue'>
                    {t('ui_spear.deviceMobileDetails.details')}
                </li>
            </ul>
            <hr />
        </nav>
    );
};

export default MobileTabsSection;