import { Grid, Container } from '@mui/material';

const LoadingEllipsis = (props) => {
  if (props.trigger === 'autosuggestions') {
    return (
      <Grid className="overflow-y-hidden">
        <span className="text-[1.1rem] font-semibold pb-2">
          {props.message}
        </span>
        <div className="loading-services">
          <div className="lds-ellipsis-autosuggest">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </Grid>
    );
  } else {
    return (
      <Grid className="overflow-y-hidden">
        <Container className="pl-0">
          <span className="text-[1.1rem] font-semibold pb-8">
            {props.message}
          </span>
          <div className="loading-services">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </Container>
      </Grid>
    );
  }
};

export default LoadingEllipsis;
