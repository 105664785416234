import { Link } from 'react-router-dom';

import logo from '../../media/itsp-portal-header-img.png';

const ITSPLogo = () => {
  return (
    <Link to="/" className="">
      <img src={logo} alt="SPEAR" className="h-12" />
    </Link>
  );
};

export default ITSPLogo;
