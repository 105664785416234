import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector';
import PropTypes from 'prop-types';

const StepSchedule = (props) => {
  const steps = ['Slot', 'Telephone', 'Confirmation'];

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(51,120,167) 0%,rgb(51,120,167) 50%,rgb(51,120,167) 100%)'
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(51,120,167) 0%,rgb(51,120,167) 50%,rgb(51,120,167) 100%)'
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1
    }
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(51,120,167) 0%, rgb(51,120,167) 50%, rgb(51,120,167) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(51,120,167) 0%, rgb(51,120,167) 50%, rgb(51,120,167) 100%)'
    })
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {props.icon === 1 && (
          <span className="support-icon-image text-4xl bg-transparent text-white">
            &#xe0c8;
          </span>
        )}
        {props.icon === 2 && (
          <span className="support-icon-image text-4xl bg-transparent text-white">
            &#xe0cb;
          </span>
        )}
        {props.icon === 3 && (
          <span className="support-icon-image text-4xl bg-transparent text-white">
            &#xe0e9;
          </span>
        )}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node
  };

  return (
    <Box className="mt-10">
      <Stepper
        alternativeLabel
        activeStep={props.scheduleStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepSchedule;
