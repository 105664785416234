import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

let refreshToken = Cookies.get('spear-sso') ? jwtDecode(Cookies.get('spear-sso'))?.data?.refresh_token : null;

const headers = {
  'Content-Type': 'application/json; charset=utf-8',
  Accept: '*/*'
};
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
  headers,
});

axiosInstance.interceptors.request.use(
  async (req) => {
    if(refreshToken) {
      const response = await getAccessToken(refreshToken);
      req.headers.Authorization = `Bearer ${response.data.data[0].accessToken}`;
    } else if (process.env.NODE_ENV === 'development') {
      const response = await getAccessToken(process.env.REACT_APP_SSO_USER_REFRESH_TOKEN);
      req.headers.Authorization = `Bearer ${response.data.data[0].accessToken}`;
    } else {                        //refreshToken isn't defined yet, try to set it now
      if (Cookies.get('spear-sso')){ 
        refreshToken = jwtDecode(Cookies.get('spear-sso'))?.data?.refresh_token 
        const response = await getAccessToken(refreshToken);
        req.headers.Authorization = `Bearer ${response.data.data[0].accessToken}`;
      } else {
        console.log('no token available');
      }
    }
    return req;
  },
  (error) => {
    // Handle request errors
    console.log('error while creating a token')
    return Promise.reject(error);
  }
);

const getAccessToken = async (refreshToken) => await axios.post(
  `${process.env.REACT_APP_BE_TOKEN_URL}/api/v1/ad/user/token`,
  { refreshToken }
);

export default axiosInstance;
