import CircularProgress from '@mui/material/CircularProgress';

const Loader = ({ customStyle, hasBackground = false }) => {
  return (
    <div className={`${hasBackground && 'bg-white h-full w-full fixed'} z-50`}>
      <div className={`absolute  ${customStyle}`}>
        <CircularProgress
          color="inherit"
          size={80}
          className="text-ITSP_blue"
        />
      </div>
    </div>
  );
};

export default Loader;
