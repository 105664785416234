import { useTranslation } from 'react-i18next';

const BusinessCriticalTicketInfor = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full sm:mt-8 md:mt-8 lg:mt-0 mt-11 py-5 pl-5 bg-gray_bg_perfect_ticket font-medium flex flex-col">
      <span
        className="inline-block mb-4"
        dangerouslySetInnerHTML={{
          __html: t('ui_spear.createTicket.Bussiness_critical_tickets')
        }}
      ></span>
      <span
        dangerouslySetInnerHTML={{
          __html: t('ui_spear.createTicket.Alternative_approach')
        }}
      ></span>
    </div>
  );
};

export default BusinessCriticalTicketInfor;
