import { Typography, Button, Grid } from '@mui/material';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import { styled } from '@mui/system';
import sadArticle from '../../static/sad-article.png'
import { useNavigate } from 'react-router-dom';

const StyledHomeButton = styled(Button)({
    backgroundColor: '#005691 !important',
    color: '#ffffff',
    fontFamily: 'BoschSans',
    fontSize: '1.25rem',
    textTransform: 'none',
    boxSizing: 'border-box',
    border: '1px solid #005691',
    borderRadius: '0px',
    marginTop: '1rem'
});

const MySoftwareError = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const oopsText = `${(t('ui_spear.support.schedulle_callback_oops_something_missing')).split('!')[0]}...`;
    const noSoftware = t(
        'ui_spear.softwareError.no_software'
    );

    const goToSoftware = t(
        'ui_spear.softwareError.go_to_software'
    )

    const goMySoftware = () => {
        navigate('/software', {
        });
    }
    return (
        <Fragment>
            <Container>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: '50vh', minWidth: '125vh' }}
                >
                    <Grid
                        item
                        alt={sadArticle}
                        style={{
                            color: '#999999',
                            fontFamily: 'bosch-icon',
                            marginRight: '2rem',
                            fontSize: '14rem'
                        }}
                    >
                        <img id="info-guy" src={sadArticle} alt="sad-article"></img>
                    </Grid>
                    <Grid item>
                        <Typography className="font-Bosch_sans text-3xl">
                            {oopsText}
                        </Typography>
                        <Grid container>
                            <Grid>
                                <Typography style={{ fontWeight: 'bold', marginRight: '2px' }} className="font-Bosch_sans text-lg mt-4">
                                    {`${noSoftware}`}
                                </Typography>
                            </Grid>
                        </Grid>

                        <StyledHomeButton onClick={() => { goMySoftware() }}>
                            {goToSoftware}
                        </StyledHomeButton>
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export default MySoftwareError;