import { useState, createContext } from 'react';

const TooltipContext = createContext();

function TooltipContextProvider(props) {
  
  const valueState = localStorage.getItem("spear_load_counter")
    const getURLParams = () => {
      let urlParams = new URLSearchParams(window.location.search);
      // If article passed in params, display it
      return urlParams.has("article")
    };
    
    const typeOfCounter = valueState === null || valueState === "false" || valueState === "0" || +valueState < 3;
    const expectedCondition = function() {
      if (getURLParams()) {
        return 0;
      }
      return !typeOfCounter ? 0 : 1
    }
    let [tooltipCount, setTooltipCount] = useState(expectedCondition())
    let value = [tooltipCount, setTooltipCount];

  return (
    <TooltipContext.Provider value={value}>
      {props.children}
    </TooltipContext.Provider>
  );
}

let TooltipContextConsumer = TooltipContext.Consumer;

export { TooltipContext, TooltipContextProvider, TooltipContextConsumer };
