import {
Dialog,
DialogContent,
DialogTitle,
Grid,
IconButton,
Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const TelephonyCallBanner = (props) => {
    const { t } = useTranslation();
    let teamsCallURL = process.env.REACT_APP_USER_MICROSOFT_TEAMS_CALL_URL ?? 'https://teams.microsoft.com/l/call/0/0?users=4:3311';

    const handleClose = () => {
        props.setOpenTelephonyModal(false);
    };

    const setOtherTypeOfCall = () => {
        window.location.href = 'tel:3311' 
    }

    return (
        <>
        <Dialog
            open={props.openTelephonyModal}
            fullWidth={true}
            maxWidth={'sm'}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle className="items-center px-[50px] my-8">
                <Grid item xs={12} align="center">
                    <Grid item xs={12}>
                    <Typography
                        variant="caption"
                        className="font-Bosch_sans_bold text-2xl"
                    >
                        {t('ui_spear.call.pleaseChooseCallType')}
                    </Typography>
                    <IconButton
                        className="boschicon-bosch-ic-close text-2xl absolute top-2 right-2 color-black my-8"
                        aria-label="close"
                        onClick={handleClose}
                    ></IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <div className='flex justify-center'>
                    <a href={teamsCallURL} target='_blank' rel="noreferrer">
                        <button className="font-Bosch_sans mx-2 text-lg bg-white border border-sky-800 h-14 p-3 text-sky-800 flex items-center">
                            {t('ui_spear.call.microsoftTeams')}
                        </button>
                    </a>
                    <button className="font-Bosch_sans mx-2 text-lg bg-white border border-sky-800 h-14 p-3 text-sky-800 flex items-center" onClick={setOtherTypeOfCall}>
                        {t('ui_spear.call.otherApplication')}
                    </button>
                </div>
            </DialogContent>
        </Dialog>
        </>
    );
};

export default TelephonyCallBanner;