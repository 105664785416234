import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Outlet } from 'react-router';
import FooterCopyright from '../Footer/FooterCopyright';
import AnnouncementManager from '../Announcements/AnnouncementManager';

export const Layout = ({ userInfo, handleLanguageChange, announcementsArray, setAnnouncementsArray }) => {
  return (
    <>
      {announcementsArray.length >= 1 &&
        <AnnouncementManager
          announcementsArray={announcementsArray}
          setAnnouncementsArray={setAnnouncementsArray}
          language={userInfo.userLanguage}
          userNtid={userInfo.userNTID}
        />
      }
      <div className="flex flex-col mx-auto header-container">
        <Header
          userInfo={userInfo}
          handleLanguageChange={handleLanguageChange}
        />
      </div>
      <div className="main-container" id="main-container">
        <div className="flex flex-col mx-auto h-full">
          <main className="flex-grow">
            <Outlet />
          </main>
          <Footer />
          <FooterCopyright />
        </div>
      </div>
    </>
  );
};
