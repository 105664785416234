import { Link } from 'react-router-dom';
import Profile from './Profile';

const TopNavbar = ({
  location,
  userInfo,
  styles,
  mainLinks,
  portalLanguages,
  handleLanguageChange
}) => {
  const renderLinks = (links) => {
    return links.map((link, key) => {
      return (
        <li
          key={link.title}
          className={`px-4`}
          style={{
            borderRight: link.path.includes('support')
              ? '.1rem solid #aab3b5'
              : ''
          }}
        >
          <Link
            to={`${link.path}`}
            className="font-normal text-[#343d47] no-underline hover:text-[#428bca] text-md "
          >
            {link.title}
          </Link>
        </li>
      );
    });
  };

  return (
    <div className={styles}>
      <nav>
        <ul className="flex flex-row items-center">
          {renderLinks(mainLinks)}
          {userInfo && (
            <Profile
              className=""
              userInfo={userInfo}
              portalLanguages={portalLanguages}
              handleLanguageChange={handleLanguageChange}
            />
          )}
        </ul>
      </nav>
    </div>
  );
};

export default TopNavbar;
