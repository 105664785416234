const format = (submittedDate) => {
    const date = new Date(submittedDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
}

export const formatSnowDate = (source, submittedDate) => {
    return source === 'sn' ? format(submittedDate) : submittedDate;
};