import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

const SearchBar = ({
  searchText,
  setSearchText,

  openTickets,
  setOpenTickets,
  openAfterFilters,

  closedTickets,
  setClosedTickets,
  closedAfterFilters,

  setCurrentPageOpenTable,
  setCurrentPageClosedTable
}) => {
  //Translations
  const { t } = useTranslation();
  const searchPlaceholderLabel = t(
    'ui_spear.myTickets.SearchBar.Search_for_open_and_close'
  );
  let [rightIcon, setRightIcon] = useState('searchIcon');
  let [leftIcon, setLeftIcon] = useState(false);

  const handleChange = (value) => {
    setSearchText(value);
    filterData(value);
    setCurrentPageOpenTable(1);
    setCurrentPageClosedTable(1);
  };

  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === '') {
      const sortField = 'lastUpdate';
      const sortOrder = 'desc';
      const sortedOpen = [...openAfterFilters].sort((a, b) => {
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true
          }) * (sortOrder === 'asc' ? 1 : -1)
        )
      });
      setOpenTickets(sortedOpen);
      const sortedClose = [...closedAfterFilters].sort((a, b) => {
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true
          }) * (sortOrder === 'asc' ? 1 : -1)
        )
      });
      setClosedTickets(sortedClose);
    } else {
      // TODO: change this for open tickets
      const filteredData = openAfterFilters.filter((item) => {
        return Object.keys(item).some((key) =>
          item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setOpenTickets(filteredData);

      //Filter for my closed tickets
      const filteredClosetTickets = closedAfterFilters.filter((item) => {
        return Object.keys(item).some((key) =>
          item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setClosedTickets(filteredClosetTickets);
    }
  };

  const blurSearchBar = () => {
    if (searchText === '') {
      setRightIcon('searchIcon');
      setLeftIcon(false);
    }
  };
  const focusSearchBar = () => {
    if (searchText === '') {
      setRightIcon('');
    }
    setLeftIcon(true);
  };

  const cleanSearchText = () => {
    setSearchText('');
    filterData('');
  };

  const setupRightIcon = () => {
    var tempRightIcon = null;
    switch (rightIcon) {
      case '':
        tempRightIcon = '';
        break;
      case 'searchIcon':
        tempRightIcon = (
          <div className="bosch-icon search-icon text-[26px]">&#xe235;</div>
        );
        break;
      default:
        tempRightIcon = '';
        break;
    }
    return tempRightIcon;
  };

  return (
    <div className="relative w-[365px] mr-4">
      <div
        className="flex"
        // eslint-disable-next-line react/no-unknown-property
        square={true}
        onFocus={focusSearchBar}
        onBlur={blurSearchBar}
      >
        <CSSTransition
          in={leftIcon}
          timeout={300}
          classNames="search-slide-ticket"
          mountOnEnter
          unmountOnExit
          onFocus={focusSearchBar}
          onBlur={blurSearchBar}
        >
          <span className={`inset-y-0  flex items-center`}>
            <div className="bosch-icon search-icon text-[26px] absolute left-[-40px]">
              &#xe235;
            </div>
          </span>
        </CSSTransition>
        <input
          type="text"
          placeholder={isFocused ? '' : searchPlaceholderLabel}
          className={`w-full py-2 border-b border-gray-500 focus:outline-none placeholder:text-gray-950 font-light text-lg`}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          value={searchText}
          onChange={(e) => handleChange(e.target.value)}
        />
        <span
          className={`absolute inset-y-0  flex items-center right-0 translate-x-0' transition-all duration-3000`}
        >
          <div>{setupRightIcon()}</div>
        </span>

        <span
          className={`absolute cursor-pointer inset-y-0  right-0 flex items-center pr-3 w-[30px]`}
          onClick={cleanSearchText}
        >
          <span
            className={`boschicon-bosch-ic-close text text-[26px] ${isFocused ? 'block' : 'hidden'
              }`}
          ></span>
        </span>
      </div>
    </div>
  );
};

export default SearchBar;
