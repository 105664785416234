import React from 'react';
import { useTranslation } from 'react-i18next';

const MobileDetails = (props) => {
    const { t } = useTranslation();
    const { pcDetails } = props;

    return (
        <>
            <div className='mt-8'>
                <div className='flex justify-center font-Bosch_sans m-devices-type'>
                    {pcDetails.MODEL}
                </div>
                <div className="mobile-responsive lg:flex lg:justify-center">
                    <div className='mobile-responsive w-full lg:w-[45%] grid justify-items-center lg:justify-items-end'>
                        <img className='device-icon img-size img-gradient-png'
                            alt='mobile-Device'
                            src={require(`../../../static/deviceIcons/phone_icon_200.png`)} />
                    </div>
                    <div className='mobile-responsive w-full lg:w-[55%] px-4 grid justify-items-center lg:justify-items-start'>
                        <div className='w-full my-[0]'>
                            <table className='table-auto m-devices-table'>
                                <tbody>
                                    <tr>
                                        <td className='w-full lg:w-[50%] text-right font-Bosch_sans_light px-0 py-2'>
                                            {t('ui_spear.deviceMobileDetails.imei_serial')} :</td>
                                        <td className='w-full lg:w-[50%] text-left ps-2 font-Bosch_sans_light px-0 py-2'>
                                            {pcDetails.NODE_NAME}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='w-full lg:w-[50%] text-right font-Bosch_sans_light px-0 py-2'>
                                            {t('ui_spear.deviceMobileDetails.current_device')} :</td>
                                        <td className='w-full lg:w-[50%] text-left ps-2 font-Bosch_sans_light px-0 py-2'>
                                            {pcDetails.DEVICE_CLASS}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='w-full lg:w-[50%] text-right font-Bosch_sans_light px-0 py-2'>
                                            {t('ui_spear.deviceMobileDetails.model')} :</td>
                                        <td className='w-full lg:w-[50%] text-left ps-2 font-Bosch_sans_light px-0 py-2'>
                                            {pcDetails.MODEL}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='w-full lg:w-[50%] text-right font-Bosch_sans_light px-0 py-2'>
                                            {t('ui_spear.deviceMobileDetails.operating_stem')} :</td>
                                        <td className='w-full lg:w-[50%] text-left ps-2 font-Bosch_sans_light px-0 py-2'>
                                            {pcDetails.OPERATINGSYSTEM}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MobileDetails;