import {
  Chip,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import SlotSelector from '../SlotSelection/SlotSelector';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../../utils/axiosInstance';

const ScheduleStep1 = (props) => {
  const { t } = useTranslation();
  const [availableSlots, setAvailableSlots] = useState([]);

  useEffect(() => {
    getHoursFromGenesys();
  }, [props.callbackPrimaryLanguage]);

  useEffect(() => {
    getHoursFromGenesys();
  }, [props.localLanguage]);

  const getHoursFromGenesys = () => {
    let GenesysHoursUrl = '';
    GenesysHoursUrl = props.callbackPrimaryLanguage?.slotEndpoint;
    if (!props.localLanguage && props.callbackSecondaryLanguage) {
      //English slots
      GenesysHoursUrl = props.callbackSecondaryLanguage?.slotEndpoint;
    }

    if (!GenesysHoursUrl) {
      return;
    }

    const submitURL = `/api/v1/genesys/support/slots`;

    axiosInstance
      .post(submitURL, {
        url: GenesysHoursUrl
      })
      .then((result) => {
        let date = new Date();
        let hourOffset = -1 * Math.floor(date.getTimezoneOffset() / 60);
        localSlots(result.data.data[0].slots);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const localSlots = (slots) => {
    let availableSlotsList = [];
    slots.map((slot) => {
      let date = new Date(slot.utcTime);
      availableSlotsList.push(date);
    });
    setAvailableSlots(filterPastDates(availableSlotsList));
  };

  const filterPastDates = (slots) => {
    let currentHour = new Date();
    currentHour.setHours(currentHour.getHours(), 0, 0);
    let filteredHours = [];
    slots.map((slot) => {
      if (slot > currentHour) {
        filteredHours.push(slot);
      }
    });
    return filteredHours;
  };

  return (
    <>
      <DialogTitle className="items-center px-[50px] pt-8">
        <Grid item xs={12} align="center">
          <Typography
            variant="caption"
            className="font-Bosch_sans_light text-[26px]"
          >
            {t('ui_spear.support.schedulle_callback_select_a_callback')}
          </Typography>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid item xs={12} align="center">
          {props.callbackSecondaryLanguage && (
            <>
              <Chip
                label={props.callbackPrimaryLanguage?.languageTag}
                variant="outlined"
                onClick={() => {
                  if (!props.localLanguage) {
                    props.setLocalLanguage(true);
                    props.setSelectedSlot('');
                  }
                }}
                className={`text-base w-32 h-10 border-2 border-ITSP_blue rounded-l-full  ${
                  props.localLanguage
                    ? 'bg-ITSP_blue text-white hover:cursor-default'
                    : 'hover:cursor-pointer'
                } font-Bosch_sans`}
              />
              <Chip
                label={props.callbackSecondaryLanguage?.languageTag}
                variant="outlined"
                onClick={() => {
                  if (props.localLanguage) {
                    props.setLocalLanguage(false);
                    props.setSelectedSlot('');
                  }
                }}
                className={`text-base w-32 h-10 border-2 border-ITSP_blue rounded-r-full ${
                  props.localLanguage
                    ? 'hover:cursor-pointer'
                    : 'bg-ITSP_blue text-white hover:cursor-default'
                } font-Bosch_sans`}
              />
            </>
          )}

          {!props.callbackSecondaryLanguage && (
            <>
              <Chip
                label={props.callbackPrimaryLanguage?.languageTag}
                variant="outlined"
                className={`text-base w-32 h-10 border-2 border-ITSP_blue bg-ITSP_blue text-white hover:cursor-default font-Bosch_sans`}
              />
            </>
          )}
        </Grid>

        <SlotSelector
          handleClose={props.handleClose}
          currentLocalDate={props.currentLocalDate}
          availableSlots={availableSlots}
          setSelectedSlot={props.setSelectedSlot}
          selectedSlot={props.selectedSlot}
          setScheduleStep={props.setScheduleStep}
        ></SlotSelector>
      </DialogContent>
    </>
  );
};

export default ScheduleStep1;
