import { Button, Chip, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Hours = (props) => {
  const { t } = useTranslation();

  // Translations
  const strPreviousSlots = t(
    'ui_spear.support.schedulle_callback_previous_slots'
  );
  const strPreviousDay = t('ui_spear.support.schedulle_callback_previous_day');
  const strNextSlots = t('ui_spear.support.schedulle_callback_next_slots');
  const strNextDay = t('ui_spear.support.schedulle_callback_next_day');

  const [page, setPage] = useState(1);
  const [previousText, setPreviousText] = useState('< Previous slots');
  const [nextText, setNextText] = useState('Next slots >');
  const [loading, setLoading] = useState(true);

  const [timeslots, setTimeslots] = useState([
    { hour: 0, label: '12am - 1am', active: false },
    { hour: 1, label: '1am - 2am', active: false },
    { hour: 2, label: '2am - 3am', active: false },
    { hour: 3, label: '3am - 4am', active: false },
    { hour: 4, label: '4am - 5am', active: false },
    { hour: 5, label: '5am - 6am', active: false },
    { hour: 6, label: '6am - 7am', active: false },
    { hour: 7, label: '7am - 8am', active: false },
    { hour: 8, label: '8am - 9am', active: false },
    { hour: 9, label: '9am - 10am', active: false },
    { hour: 10, label: '10am - 11am', active: false },
    { hour: 11, label: '11am - 12pm', active: false },
    { hour: 12, label: '12pm - 1pm', active: false },
    { hour: 13, label: '1pm - 2pm', active: false },
    { hour: 14, label: '2pm - 3pm', active: false },
    { hour: 15, label: '3pm - 4pm', active: false },
    { hour: 16, label: '4pm - 5pm', active: false },
    { hour: 17, label: '5pm - 6pm', active: false },
    { hour: 18, label: '6pm - 7pm', active: false },
    { hour: 19, label: '7pm - 8pm', active: false },
    { hour: 20, label: '8pm - 9pm', active: false },
    { hour: 21, label: '9pm - 10pm', active: false },
    { hour: 22, label: '10pm - 11pm', active: false },
    { hour: 23, label: '11pm - 12am', active: false }
  ]);

  useEffect(() => {
    let tempTimeslots = timeslots;
    tempTimeslots.map((slot, index) => {
      props.slotsDay.map((availableSlot) => {
        if (slot.hour === availableSlot.getHours()) {
          tempTimeslots[index].active = true;
        }
      });
    });
    setTimeslots(tempTimeslots);
    setLoading(false);
  }, [props.slotsDay]);

  let SetVisibleHours = () => {
    let currentSlots = timeslots.slice(page * 9 - 9, page * 9);

    let numberOfSlots = currentSlots.length;
    let unactiveSlots = 0;
    let activePage = true;
    currentSlots.map((slot) => {
      if (!slot.active) {
        unactiveSlots++;
      }
    });
    if (unactiveSlots === numberOfSlots) {
      activePage = false;
    }

    let table = '';

    if (activePage) {
      table = (
        <>
          {currentSlots.map((slot) => (
            <Grid key={slot.label} item xs={4}>
              {props.selectedSlot.selectedDayTab === props.selectedDay &&
              props.selectedSlot.hour === slot.hour ? (
                <Chip
                  label={slot.label}
                  variant="outlined"
                  onClick={() => {
                    selectSlot(slot.active, slot.hour);
                  }}
                  className={`text-xl w-48 h-12 border-0 ${
                    slot.active
                      ? 'font-Bosch_sans bg-ITSP_blue hover:cursor-pointer text-white'
                      : 'font-Bosch_sans_light text-gray-400'
                  }`}
                />
              ) : (
                <Chip
                  label={slot.label}
                  variant="outlined"
                  onClick={() => {
                    selectSlot(slot.active, slot.hour);
                  }}
                  className={`text-xl w-48 h-12 border-0 ${
                    slot.active
                      ? 'font-Bosch_sans hover:bg-ITSP_lightBlue hover:cursor-pointer'
                      : 'font-Bosch_sans_light text-gray-400'
                  }`}
                />
              )}
            </Grid>
          ))}
        </>
      );
    } else {
      table = (
        <>
          <Grid item xs={12}>
            <Typography className="font-Bosch_sans text-lg text-center border pt-20 pb-20">
              {t(
                'ui_spear.support.schedulle_callback_were_sorry_but_no_available_timeslots_this_day'
              )}
            </Typography>
          </Grid>
        </>
      );
    }

    return table;
  };

  useEffect(() => {
    if (props.selectedDay === 0) {
      //setPreviousText
    }
  }, [props.selectedDay]);

  useEffect(() => {
    if (page === 1) {
      if (props.selectedDay > 0) {
        setPreviousText('< ' + strPreviousDay);
      }
      setNextText(strNextSlots + ' >');
    } else if (page > 1 && page < 3) {
      setPreviousText('< ' + strPreviousSlots);
      setNextText(strNextSlots + ' >');
    } else if (page === 3) {
      setPreviousText('< ' + strPreviousSlots);
      if (props.selectedDay < 3) {
        setNextText(strNextDay + ' >');
      }
    }
  }, [page]);

  let selectSlot = (active, hour) => {
    if (active) {
      props.setSelectedDateSlot(props.selectedDay, hour, timeslots[hour].label);
    }
  };

  let changeSlots = (previous) => {
    if (previous) {
      if (page - 1 === 0) {
        props.setSelectedDay(props.selectedDay - 1);
      } else {
        setPage(page - 1);
      }
    } else {
      if (page + 1 > 3) {
        props.setSelectedDay(props.selectedDay + 1);
      } else {
        setPage(page + 1);
      }
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        className={`transition-all ${
          loading ? 'opacity-0' : 'opacity-100'
        } duration-1000`}
      >
        <SetVisibleHours></SetVisibleHours>
      </Grid>
      <hr className="mt-6 mb-3"></hr>
      <Grid container xs={12} align="center" spacing={2}>
        <Grid item xs={4}>
          {(props.selectedDay > 0 || page > 1) && (
            <Link
              component="button"
              onClick={() => {
                changeSlots(true);
              }}
            >
              {previousText}
            </Link>
          )}
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          {(props.selectedDay < 3 || page < 3) && (
            <Link
              component="button"
              onClick={() => {
                changeSlots(false);
              }}
            >
              {nextText}
            </Link>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        className="mt-7"
        xs={12}
        justifyContent="flex-end"
      >
        <Grid item>
          <Button
            variant="outlined"
            className="m-auto h-10 w-auto p-3 border-1 border-ITSP_blue text-blue-600 flex items-center normal-case font-Bosch_sans rounded-none text-lg"
            onClick={() => {
              props.handleClose();
            }}
          >
            {t('ui_spear.support.schedulle_callback_cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={`m-auto ${
              props.selectedSlot
                ? 'bg-ITSP_blue'
                : 'bg-gray-200 hover:cursor-default'
            } h-10 w-auto p-3 text-white flex items-center normal-case font-Bosch_sans rounded-none text-lg`}
            onClick={() => {
              if (props.selectedSlot) {
                props.setScheduleStep(1);
              }
            }}
          >
            {t('ui_spear.support.schedulle_callback_next_step')} &gt;
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Hours;
